import { mapKeys } from 'lodash-es'

import { REPORTS_PERMISSIONS } from '../../common/constants'

export const USER_REPORT = 'USER_REPORT'
export const USER_COUNTS_REPORT = 'USER_COUNTS_REPORT'
export const MENU_REPORT = 'MENU_REPORT'
export const SCRAPING_ENGINE_REPORT = 'SCRAPING_ENGINE_REPORT'
export const SCRAPING_ATTEMPTS_REPORT = 'SCRAPING_ATTEMPTS_REPORT'
export const SCRAPING_VERIFICATION_REPORT = 'SCRAPING_VERIFICATION_REPORT'
export const SCRAPING_ISSUE_REPORT = 'SCRAPING_ISSUE_REPORT'
export const COUNTS_REPORT = 'COUNTS_REPORT'
export const QUALITY_CHECKED_REPORT = 'QUALITY_CHECKED_REPORT'
export const OPEN_TASKS_REPORT = 'OPEN_TASKS_REPORT'
export const SCRAPING_CLUSTERS_REPORT = 'SCRAPING_CLUSTERS_REPORT'
export const CHANGED_MENU_URLS_REPORT = 'CHANGED_MENU_URLS_REPORT'
export const MENUS_IN_PROBING_STATUS_REPORT = 'MENUS_IN_PROBING_STATUS_REPORT'
export const MISC_TASKS_VERIFICATION_REPORT = 'MISC_TASKS_VERIFICATION_REPORT'

export const REPORT_TYPES = mapKeys(
  [
    {
      name: 'User',
      value: USER_REPORT,
      key: 'user',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_USERS],
    },
    {
      name: 'User Counts',
      value: USER_COUNTS_REPORT,
      key: 'user-counts',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_USER_COUNTS],
    },
    {
      name: 'Menu',
      value: MENU_REPORT,
      key: 'menus-having-allergens',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_MENUS_HAVING_ALLERGENS],
    },
    {
      name: 'Scraping Engine',
      value: SCRAPING_ENGINE_REPORT,
      key: 'scraping-engine',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ENGINE],
    },
    {
      name: 'Scraping Issue',
      value: SCRAPING_ISSUE_REPORT,
      key: 'scraping-issue',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ISSUE],
    },
    {
      name: 'Scraping Attempts',
      value: SCRAPING_ATTEMPTS_REPORT,
      key: 'scraping-attempts',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ATTEMPTS],
    },
    {
      name: 'Scraping Verification',
      value: SCRAPING_VERIFICATION_REPORT,
      key: 'scraping-verification',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_SCRAPING_VERIFICATION],
    },
    {
      name: 'Scraping Clusters',
      value: SCRAPING_CLUSTERS_REPORT,
      key: 'scraping-clusters',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_SCRAPING_CLUSTERS],
    },
    {
      name: 'Counts',
      value: COUNTS_REPORT,
      key: 'counts',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_COUNTS],
    },
    {
      name: 'Quality Checked',
      value: QUALITY_CHECKED_REPORT,
      key: 'quality-checked',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_QUALITY_CHECKED],
    },
    {
      name: 'Open Tasks',
      value: OPEN_TASKS_REPORT,
      key: 'open-tasks',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_OPEN_TASKS],
    },
    {
      name: 'Changed Menu URLs',
      value: CHANGED_MENU_URLS_REPORT,
      key: 'changed-menu-urls',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_CHANGED_MENU_URLS],
    },
    {
      name: 'Menus in Probing status',
      value: MENUS_IN_PROBING_STATUS_REPORT,
      key: 'menus-in-probing-status',
      permissions: [REPORTS_PERMISSIONS.GET_MENUS_IN_PROBING_STATUS],
    },
    {
      name: 'Menus in Probing status',
      value: MENUS_IN_PROBING_STATUS_REPORT,
      key: 'menus-in-probing-status',
      permissions: [REPORTS_PERMISSIONS.GET_MENUS_IN_PROBING_STATUS],
    },
    {
      name: 'Misc Tasks Verification',
      value: MISC_TASKS_VERIFICATION_REPORT,
      key: 'misc-tasks-verification',
      permissions: [REPORTS_PERMISSIONS.GET_FOR_MISC_TASKS_VERIFICATION],
    },
  ],
  'value',
)
