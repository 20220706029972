import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { SCRAPING } from '../../../../../menus/constants'

import {
  MenuScrapingClusterMenusTable,
  MenuScrapingSessionsTable,
  MenuStatusesTable,
} from './tables'

const MenuScrapingClusterScrapingStep = ({ menuScrapingCluster }) => {
  const [
    menuStatusesTableRefreshKey,
    setMenuStatusesTableRefreshKey,
  ] = useState(Math.random())

  const refreshMenuStatusesTable = useCallback(() => {
    setMenuStatusesTableRefreshKey(Math.random())
  }, [])

  const [menusTableRefreshKey, setMenusTableRefreshKey] = useState(
    Math.random(),
  )

  const refreshMenusTable = useCallback(() => {
    setMenusTableRefreshKey(Math.random())
  }, [])

  return (
    <>
      <MenuStatusesTable
        key={menuStatusesTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isResetMenusButtonHidden
      />
      <MenuScrapingClusterMenusTable
        key={menusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isUpdateRoleButtonHidden
        isMenuNotesDisabled={false}
        onRemoveMenuFromMenuScrapingCluster={() => {
          refreshMenuStatusesTable()
        }}
      />
      <MenuScrapingSessionsTable
        menuScrapingCluster={menuScrapingCluster}
        rerunStepType={SCRAPING}
        onStartScrapingSession={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
        onAbortScrapingSession={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
      />
    </>
  )
}

MenuScrapingClusterScrapingStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterScrapingStep
