import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { BOOLEAN_TYPES, DEFAULT_PAGE } from '../constants'

const MenuIsAwaitingScrapingFilter = ({ queryParamName }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const menuIsAwaitingScraping = getQueryParams(search)[queryParamName]

  const handleChange = useCallback(
    menuIsAwaitingScraping =>
      history.push(
        createUrl(pathname, search, {
          [queryParamName]: menuIsAwaitingScraping,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, queryParamName],
  )

  return (
    <Form.Item label="Awaiting scraping">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={menuIsAwaitingScraping?.toString()}
        onChange={handleChange}
      >
        {map(BOOLEAN_TYPES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

MenuIsAwaitingScrapingFilter.propTypes = {
  queryParamName: PropTypes.string,
}

MenuIsAwaitingScrapingFilter.defaultProps = {
  queryParamName: 'menuIsAwaitingScraping',
}

export default MenuIsAwaitingScrapingFilter
