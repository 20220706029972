import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal, Row, Table, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { ADMIN, DEFAULT_PAGE } from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'
import {
  useIsLoggedInUser,
  useUserHasRoles,
} from '../../../../../../common/hooks'

import {
  ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER,
  EXCLUDE_MENU_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS,
} from '../../../../../graphql/mutations'
import { MENU_SCRAPING_CLUSTER_SIMILAR_MENUS } from '../../../../../graphql/queries'

const SimilarMenusByUrlSignatureTable = ({
  menuScrapingCluster,
  onAttachMenuToCluster,
  onExcludeMenuFromClusterSimilarMenus,
  isAttachButtonHidden,
  isExcludeButtonHidden,
}) => {
  const [similarMenusPage, setSimilarMenusPage] = useState(DEFAULT_PAGE)

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const {
    data: {
      menuScrapingClusterSimilarMenus: {
        menus: similarMenus = [],
        total: similarMenusTotal = 0,
      } = {},
    } = {},
    loading: isLoadingSimilarMenus,
    refetch: refetchSimilarMenus,
  } = useQuery(MENU_SCRAPING_CLUSTER_SIMILAR_MENUS, {
    variables: {
      id: menuScrapingCluster.id,
      page: similarMenusPage,
    },
    fetchPolicy: 'no-cache',
  })

  const [attachMenuToMenuScrapingCluster] = useMutation(
    ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER,
    {
      onCompleted() {
        refetchSimilarMenus({
          id: menuScrapingCluster.id,
          page: similarMenusPage,
        })
        onAttachMenuToCluster()
        showSuccessNotification({
          message: 'Menu attached to menu scraping cluster.',
          description:
            'Menu has been successfully attached to menu scraping cluster.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu attach to menu scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  const [excludeMenuFromClusterSimilarMenus] = useMutation(
    EXCLUDE_MENU_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS,
    {
      onCompleted() {
        refetchSimilarMenus({
          id: menuScrapingCluster.id,
          page: similarMenusPage,
        })
        onExcludeMenuFromClusterSimilarMenus()
        showSuccessNotification({
          message: 'Menu excluded from menu scraping cluster.',
          description:
            'Menu has been successfully excluded from menu scraping cluster.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu exclusion from menu scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  const handleAttachMenuToCluster = async menuId => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'You are going to attach this menu to the cluster.',
      onOk() {
        attachMenuToMenuScrapingCluster({
          variables: {
            id: menuScrapingCluster.id,
            menuId,
          },
        })
      },
    })
  }

  const handleExcludeMenuFromClusterSimilarMenus = menuId =>
    excludeMenuFromClusterSimilarMenus({
      variables: {
        id: menuScrapingCluster.id,
        menuId,
      },
    })

  const similarMenusTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '40px',
      render: id => <Link to={`/menus/${id}`}>{id}</Link>,
    },
    {
      title: 'Brand',
      dataIndex: 'brand.name',
      width: '150px',
    },
    {
      title: 'Website',
      dataIndex: 'url',
      width: '200px',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    ...(isAttachButtonHidden && isExcludeButtonHidden
      ? []
      : [
          {
            title: 'Actions',
            width: '300px',
            dataIndex: 'id',
            render: id => (
              <>
                {!isAttachButtonHidden && (
                  <Button
                    type="primary"
                    disabled={!isLoggedInUser && !isAdmin}
                    onClick={() => handleAttachMenuToCluster(id)}
                    margin="no small no no"
                  >
                    Attach
                  </Button>
                )}
                {!isExcludeButtonHidden && (
                  <Button
                    type="primary"
                    disabled={!isLoggedInUser && !isAdmin}
                    onClick={() => handleExcludeMenuFromClusterSimilarMenus(id)}
                    margin="no small no no"
                  >
                    Exclude
                  </Button>
                )}
              </>
            ),
          },
        ]),
  ]

  return (
    <Row>
      <Typography.Title level={3}>
        Similar menus by URL signature ({similarMenusTotal})
      </Typography.Title>
      <Table
        rowKey="id"
        loading={isLoadingSimilarMenus}
        columns={similarMenusTableColumns}
        dataSource={similarMenus}
        pagination={{
          current: similarMenusPage,
          pageSize: 10,
          total: similarMenusTotal,
          onChange: setSimilarMenusPage,
        }}
      />
    </Row>
  )
}

SimilarMenusByUrlSignatureTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onAttachMenuToCluster: PropTypes.func,
  onExcludeMenuFromClusterSimilarMenus: PropTypes.func,
  isAttachButtonHidden: PropTypes.bool,
  isExcludeButtonHidden: PropTypes.bool,
}

SimilarMenusByUrlSignatureTable.defaultProps = {
  onAttachMenuToCluster: () => {},
  onExcludeMenuFromClusterSimilarMenus: () => {},
  isAttachButtonHidden: false,
  isExcludeButtonHidden: false,
}

export default SimilarMenusByUrlSignatureTable
