import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Spin } from 'antd'

import { PageOverlay } from '../../../common/components/styled'
import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { LOGIN_WITH_EMAIL, SET_USER } from '../../graphql/mutations'
import { createSession, decodeToken } from '../../services'
import { LoginWithEmailForm } from '../forms'

const EmailLoginPage = () => {
  useDocumentTitle('Login')

  const [setUser] = useMutation(SET_USER)

  const [loginWithEmail, { loading }] = useMutation(LOGIN_WITH_EMAIL, {
    onCompleted({
      loginWithEmail: { accessToken, refreshToken, permissions },
    }) {
      const userData = decodeToken(accessToken)
      const user = { ...userData, permissions }
      createSession(user, accessToken, refreshToken)
      setUser({ variables: { user } })
    },

    onError({ message }) {
      showErrorNotification({
        message: 'Login with email failed.',
        description: message,
      })
    },
  })

  const handleLoginWithEmail = useCallback(
    data =>
      loginWithEmail({
        variables: { email: data.email, code: data.code },
      }),
    [loginWithEmail],
  )

  return (
    <>
      {loading && (
        <PageOverlay>
          <Spin tip="Loading..." />
        </PageOverlay>
      )}
      <LoginWithEmailForm onSubmit={handleLoginWithEmail} />
    </>
  )
}

export default EmailLoginPage
