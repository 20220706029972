import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'antd'
import { isEmpty, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { formatDate } from '../../../../common/services/formatter'

const MenuScrapingVersionsDropdown = ({
  successScrapingResponses,
  onChange,
}) => {
  const [selectedVersion, setSelectedVersion] = useState()
  const [versions, setVersions] = useState([])

  useEffect(() => {
    const versions = map(
      successScrapingResponses,
      ({ menuScrapingJobId, completedDate }) => ({
        value: menuScrapingJobId,
        name: formatDate(completedDate),
      }),
    )

    if (!isEmpty(versions)) {
      setVersions(versions)

      const lastVersion = versions[0].value
      setSelectedVersion(lastVersion)
      onChange(lastVersion)
    }
  }, [successScrapingResponses, onChange])

  const handleChange = useCallback(
    value => {
      setSelectedVersion(value)
      onChange(value)
    },
    [onChange],
  )

  return (
    <Select
      style={{ paddingBottom: '20px' }}
      value={selectedVersion}
      onChange={handleChange}
    >
      {map(versions, ({ name, value }) => (
        <Select.Option key={value} value={value}>
          {name}
        </Select.Option>
      ))}
    </Select>
  )
}

MenuScrapingVersionsDropdown.propTypes = {
  successScrapingResponses: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MenuScrapingVersionsDropdown
