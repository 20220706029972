import { gql } from 'apollo-boost'

const MARK_BRAND_DEFINITION_TASK_MISC_AS_VERIFIED = gql`
  mutation markBrandDefinitionTaskMiscAsVerified($id: ID!, $comment: String) {
    markBrandDefinitionTaskMiscAsVerified(id: $id, comment: $comment) {
      id
      miscTaskVerifiedDate
      miscTaskVerifiedComment
    }
  }
`

export default MARK_BRAND_DEFINITION_TASK_MISC_AS_VERIFIED
