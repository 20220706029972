import { gql } from 'apollo-boost'

const BRAND_GATEWAY_TASKS = gql`
  query brandGatewayTasks(
    $filters: BrandGatewayTaskFiltersInput!
    $page: Int
    $pageSize: Int
    $sortingCriterion: BrandGatewayTaskSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    brandGatewayTasks(
      filters: $filters
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      brandGatewayTasks {
        id
        status
        brand {
          id
          name
          locationCount
          includedLocationCount
          messinessScore
          hasOnlyUnsupportedBusinessTypes
        }
        assignedTo {
          id
          name
        }
        modifiedDate
      }
      total
    }
  }
`

export default BRAND_GATEWAY_TASKS
