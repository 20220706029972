import { gql } from 'apollo-boost'

const REMOVE_MENU_FROM_CLUSTER_EXCLUDED_MENUS = gql`
  mutation removeMenuFromClusterExcludedMenus($id: ID!, $menuId: ID!) {
    removeMenuFromClusterExcludedMenus(id: $id, menuId: $menuId) {
      id
    }
  }
`

export default REMOVE_MENU_FROM_CLUSTER_EXCLUDED_MENUS
