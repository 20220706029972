import * as Yup from 'yup'

const shouldGoogleLocationIdBeRequired = (
  duplicateOfId,
  isHasNoGoogleMatchConfirmed,
) => !duplicateOfId && !isHasNoGoogleMatchConfirmed

const brandDefinitionTaskSchema = Yup.object().shape({
  brand: Yup.object().shape({
    name: Yup.string()
      .required(`Brand's name is required`)
      .nullable(),
    website: Yup.string()
      .when('isHasNoWebsiteConfirmed', {
        is: isHasNoWebsiteConfirmed => !isHasNoWebsiteConfirmed,
        then: Yup.string()
          .url('Website is not valid')
          .required('Brand website is required')
          .nullable(),
      })
      .nullable(),
  }),

  locations: Yup.array().of(
    Yup.object()
      .shape({
        googleLocationId: Yup.mixed().when(
          ['duplicateOfId', 'isHasNoGoogleMatchConfirmed'],
          {
            is: shouldGoogleLocationIdBeRequired,
            then: Yup.string()
              .required('Google location is required')
              .nullable(),
          },
        ),
      })
      .uniqueProperties([['googleLocationId', 'Google places must be unique']])
      .nullable(),
  ),
})

export default brandDefinitionTaskSchema
