import axios from 'axios'

import { getEnvironmentVariable } from '../helpers'

const defaultHttpSettings = {
  responseType: 'json',
}

const http = axios.create({
  ...defaultHttpSettings,
  baseURL: getEnvironmentVariable('REACT_APP_AUTOEAT_API_URL'),
})

export default http
