import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { includes, map, size } from 'lodash-es'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { REOPENED, TASK_STATUSES } from '../../tasks/constants'
import { DEFAULT_PAGE } from '../constants'

const TaskStatusFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { statuses, reopenReasons } = getQueryParams(search)

  const handleChange = useCallback(
    statuses =>
      history.push(
        createUrl(pathname, search, {
          statuses,
          reopenReasons: includes(statuses, REOPENED) ? reopenReasons : '',
          page: DEFAULT_PAGE,
          resolvedType: '',
        }),
      ),
    [history, pathname, search, reopenReasons],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Task status">
      <Select
        value={statuses}
        onChange={handleChange}
        style={{ width: '100%' }}
        dropdownStyle={{ minWidth: 'fit-content' }}
        allowClear
        mode="multiple"
        maxTagCount={2}
        maxTagPlaceholder={getMaxTagPlaceholder}
      >
        {map(TASK_STATUSES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default TaskStatusFilter
