import { gql } from 'apollo-boost'

const START_MENU_SCRAPING_RERUN = gql`
  mutation startMenuScrapingRerun($data: StartMenuScrapingRerunInput!) {
    startMenuScrapingRerun(data: $data) {
      id
    }
  }
`

export default START_MENU_SCRAPING_RERUN
