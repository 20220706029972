import { gql } from 'apollo-boost'

const SAVE_MENU_DEFINITION_TASK = gql`
  mutation saveMenuDefinitionTask(
    $id: ID!
    $brandData: SaveMenuDefinitionTaskBrandInput
    $locationsData: [SaveMenuDefinitionTaskLocationInput!]!
  ) {
    saveMenuDefinitionTask(
      id: $id
      brandData: $brandData
      locationsData: $locationsData
    ) {
      id
    }
  }
`

export default SAVE_MENU_DEFINITION_TASK
