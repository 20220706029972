import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { FormText, Input } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { createBrandFormInitialValues } from './initial-values'
import { createBrandSchema } from './schemas'

const CreateBrandForm = ({
  originalName,
  website,
  onSubmit,
  closeModal,
  isLoading,
}) => {
  const formMethods = useForm({
    defaultValues: createBrandFormInitialValues(originalName, website),
    resolver: yupResolver(createBrandSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <FormText name="originalName" label="Original name" />
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Input name="name" label="Brand name" />
          </Col>
          <Col span={12}>
            <Input name="website" label="Website" />
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Button loading={isLoading} onClick={closeModal} margin="no small">
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

CreateBrandForm.propTypes = {
  originalName: PropTypes.string.isRequired,
  website: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

CreateBrandForm.defaultProps = {
  website: undefined,
}

export default CreateBrandForm
