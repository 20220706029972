import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

const MenuStructureFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const [menuStructure, setMenuStructure] = useState('')

  const { structureSearchTerm } = getQueryParams(search)

  const handleSearch = useCallback(
    structureSearchTerm =>
      history.push(
        createUrl(pathname, search, {
          structureSearchTerm,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  useEffect(() => setMenuStructure(structureSearchTerm), [structureSearchTerm])

  return (
    <Form.Item label="Structure">
      <Input.Search
        value={menuStructure}
        allowClear
        onChange={({ target: { value } }) => setMenuStructure(value)}
        onSearch={handleSearch}
      />
    </Form.Item>
  )
}

export default MenuStructureFilter
