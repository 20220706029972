import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { LockedStatus } from '../../../../common/components'
import { BRAND, LOCKABLE_ENTITIES } from '../../../../common/constants'

const TaskTableBrandLinkColumn = ({ getUserLockingEntity, brand, to }) => {
  const userLockingEntity = useMemo(
    () => getUserLockingEntity(LOCKABLE_ENTITIES.BRAND, Number(brand.id)),
    [brand, getUserLockingEntity],
  )

  return (
    <Link to={to} target="_blank">
      <LockedStatus
        userLockingEntity={userLockingEntity}
        entityType={BRAND}
        margin="no xxSmall no no"
      />
      {brand.name}
    </Link>
  )
}

TaskTableBrandLinkColumn.propTypes = {
  getUserLockingEntity: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired,
}

export default TaskTableBrandLinkColumn
