import { isEmpty, map } from 'lodash-es'

const commonWebsiteDomainsFormInitialValues = values => {
  const commonWebsiteDomains = map(values, ({ id, websiteDomain }) => ({
    id,
    websiteDomain,
  }))

  return {
    commonWebsiteDomains: isEmpty(commonWebsiteDomains)
      ? [{ id: '', websiteDomain: '' }]
      : commonWebsiteDomains,
  }
}

export default commonWebsiteDomainsFormInitialValues
