import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { SET_MENU_SCRAPING_TEMPLATE_SCRAPING_ISSUE } from '../../../../menus/graphql/mutations'
import { ScrapingTemplateScrapingIssueForm } from '../../forms'

const MenuScrapingTemplateScrapingIssueModal = ({
  scrapingTemplate,
  isModalOpen,
  setIsModalOpen,
}) => {
  const handleClose = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const [
    setMenuScrapingTemplateScrapingIssue,
    { loading: isSettingScrapingTemplateScrapingIssue },
  ] = useMutation(SET_MENU_SCRAPING_TEMPLATE_SCRAPING_ISSUE, {
    onCompleted({
      setMenuScrapingTemplateScrapingIssue: { scrapingIssueComment },
    }) {
      showSuccessNotification({
        message: 'Scraping issue',
        description: isNil(scrapingIssueComment)
          ? 'Scraping issue removed'
          : 'Scraping issue saved',
      })

      setIsModalOpen(false)
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Scraping issue',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    scrapingTemplate =>
      setMenuScrapingTemplateScrapingIssue({ variables: scrapingTemplate }),
    [setMenuScrapingTemplateScrapingIssue],
  )

  return (
    <Modal
      title="Scraping issue"
      visible={isModalOpen}
      onCancel={handleClose}
      footer={null}
    >
      <ScrapingTemplateScrapingIssueForm
        scrapingTemplate={scrapingTemplate}
        closeModal={handleClose}
        isSettingScrapingTemplateScrapingIssue={
          isSettingScrapingTemplateScrapingIssue
        }
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

MenuScrapingTemplateScrapingIssueModal.propTypes = {
  scrapingTemplate: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default MenuScrapingTemplateScrapingIssueModal
