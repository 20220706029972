import { gql } from 'apollo-boost'

const CREATE_MENU = gql`
  mutation createMenu($data: CreateMenuInput!) {
    createMenu(data: $data) {
      id
      url
    }
  }
`

export default CREATE_MENU
