import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'

import {
  RESOLVE_BRAND_DEFINITION_TASK,
  SAVE_BRAND_DEFINITION_TASK,
} from '../../graphql/mutations'
import { BRAND_DEFINITION_TASK } from '../../graphql/queries'
import { transformToBrandDefinitionTaskInput } from '../../graphql/transformers'
import { BrandDefinitionTaskForm } from '../forms'

const onSubmit = (values, saveTask, resolveTask) => {
  const variables = transformToBrandDefinitionTaskInput(values)

  return values.task.isDone
    ? resolveTask({
        variables,
      })
    : saveTask({
        variables,
      })
}

const BrandDefinitionTaskPage = () => {
  useDocumentTitle('Tasks - Brand Definition')
  const { taskId } = useParams()
  const history = useHistory()

  const {
    data: { brandDefinitionTask } = {},
    loading: isLoadingTask,
    refetch: refetchBrandDefinitionTask,
    networkStatus: taskQueryNetworkStatus,
  } = useQuery(BRAND_DEFINITION_TASK, {
    variables: { id: taskId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [resolveTask, { loading: isResolvingTask }] = useMutation(
    RESOLVE_BRAND_DEFINITION_TASK,
    {
      onCompleted({ resolveBrandDefinitionTask: { menuDefinitionTaskId } }) {
        showSuccessNotification({
          message: 'Brand definition task resolved.',
          description: 'Task has been successfully resolved.',
        })

        if (menuDefinitionTaskId) {
          history.push(`/tasks/menu-definition/${menuDefinitionTaskId}`)
        } else {
          history.push(getCachedFilterForPage('/tasks/brand-definition'))
        }
      },

      onError(error) {
        showErrorNotification({
          message: 'Resolve brand definition task failed.',
          error,
        })
      },
    },
  )

  const [saveTask, { loading: isSavingTask }] = useMutation(
    SAVE_BRAND_DEFINITION_TASK,
    {
      onCompleted() {
        refetchBrandDefinitionTask()
        showSuccessNotification({
          message: 'Brand definition task saved.',
          description: 'Task has been successfully saved.',
        })
      },

      onError(error) {
        showErrorNotification({
          message: 'Save brand definition task failed.',
          error,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    task => onSubmit(task, saveTask, resolveTask),
    [saveTask, resolveTask],
  )

  return isLoadingTask ? (
    <Spinner />
  ) : (
    <BrandDefinitionTaskForm
      task={brandDefinitionTask}
      isSavingTask={isSavingTask}
      isRefetchingTask={taskQueryNetworkStatus === NetworkStatus.refetch}
      isResolvingTask={isResolvingTask}
      onSubmit={handleSubmit}
    />
  )
}

export default BrandDefinitionTaskPage
