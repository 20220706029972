import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Row, Tabs } from 'antd'
import arrayToTree from 'array-to-tree'
import {
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  lowerCase,
  map,
} from 'lodash-es'
import PropTypes from 'prop-types'

import { createUrl } from '../../../../core/utils/services/queryParams'

import { SCRAPING_EVENT } from '../../../../common/constants'

import { SCRAPED_ITEM_PLACEHOLDER_TITLES } from '../../../constants'
import {
  COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU,
  MENU_SCRAPING_JOB,
} from '../../../graphql/queries'
import {
  attachMenuTitlePathToDishes,
  getMenuScrapingVersionDishesChanges,
  getMenuScrapingVersionMenuTitlesChanges,
} from '../../../helpers'

import DishesTable from './dishes-table'
import MenuChangesColorLegend from './menu-changes-color-legend'
import MenuScrapingJobStats from './menu-scraping-job-stats'
import MenuScrapingVersionsDropdown from './menu-scraping-versions-dropdown'
import MenuTitlesTable from './menu-titles-table'

const { TabPane } = Tabs

const MenuTitlesAndDishesTables = ({
  menu,
  menuTitles = [],
  dishes = [],
  isExpanded,
  page,
  pageSize,
  onShowScrapingDetailsButtonClick,
}) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const [selectedMenuTitleIds, setSelectedMenuTitleIds] = useState([])
  const [searchByDishName, setSearchByDishName] = useState('')
  const [selectedDescriptionFilter, setSelectedDescriptionFilter] = useState()
  const [selectedCaloriesFilter, setSelectedCaloriesFilter] = useState()
  const [selectedDishDietsFilter, setSelectedDishDietsFilter] = useState()
  const [selectedDishAddonsFilter, setSelectedDishAddonsFilter] = useState()
  const [
    selectedDishMiscInfosFilter,
    setSelectedDishMiscInfosFilter,
  ] = useState()
  const [
    selectedDishAllergensFilter,
    setSelectedDishAllergensFilter,
  ] = useState()
  const [
    selectedDishIngredientsFilter,
    setSelectedDishIngredientsFilter,
  ] = useState()
  const [
    selectedDishNutritionsFilter,
    setSelectedDishNutritionsFilter,
  ] = useState()

  const [menuTitlesToShow, setMenuTitlesToShow] = useState(menuTitles)
  const [dishesToShow, setDishesToShow] = useState(dishes)
  const [showFullMenu, setShowFullMenu] = useState(true)
  const [menuScrapingJob, setMenuScrapingJob] = useState()
  const [menuScrapingJobId, setMenuScrapingJobId] = useState()
  const [successScrapingResponses, setSuccessScrapingResponses] = useState([])

  const {
    data: { menuScrapingJob: scrapingJob } = {},
    refetch: refetchMenuScrapingJob,
  } = useQuery(MENU_SCRAPING_JOB, {
    skip: !menuScrapingJobId,
    variables: { id: menuScrapingJobId },
    fetchPolicy: 'cache-first',
    onCompleted() {
      setMenuScrapingJob(scrapingJob)
    },
  })

  const { data: { completedMenuScrapingJobsForMenu } = {} } = useQuery(
    COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU,
    {
      variables: { menuId: menu.id },
      fetchPolicy: 'no-cache',
      onCompleted() {
        const scrapingSuccessResponses = filter(
          completedMenuScrapingJobsForMenu,
          completedMenuScrapingJob =>
            completedMenuScrapingJob.responseType === SCRAPING_EVENT,
        )
        setSuccessScrapingResponses(scrapingSuccessResponses)
      },
    },
  )

  useEffect(() => {
    if (!menuScrapingJobId) return
    refetchMenuScrapingJob()
  }, [menuScrapingJobId, refetchMenuScrapingJob])

  const showLastFullMenu = useMemo(() => {
    if (!menuScrapingJob) return true
    return (
      menu.scrapingResponseId === menuScrapingJob?.menuScrapingResponse.id &&
      showFullMenu
    )
  }, [menu, menuScrapingJob, showFullMenu])

  useEffect(() => {
    if (showLastFullMenu) {
      setMenuTitlesToShow(menuTitles)
      setDishesToShow(dishes)
    } else if (showFullMenu) {
      const {
        menuTree: { menuTitles: scrapedMenuTitles, dishes: scrapedDishes },
      } = menuScrapingJob.menuScrapingResponse.data

      setMenuTitlesToShow(scrapedMenuTitles)
      setDishesToShow(
        attachMenuTitlePathToDishes(scrapedDishes, scrapedMenuTitles),
      )
    } else {
      const changedDishes = getMenuScrapingVersionDishesChanges(menuScrapingJob)

      const changedMenuTitles = getMenuScrapingVersionMenuTitlesChanges(
        menuScrapingJob,
        changedDishes,
      )

      setMenuTitlesToShow(changedMenuTitles)

      setDishesToShow(
        attachMenuTitlePathToDishes(changedDishes, changedMenuTitles),
      )
    }
  }, [menuTitles, dishes, menuScrapingJob, showFullMenu, showLastFullMenu])

  const menuTitlesForFilter = useMemo(
    () =>
      arrayToTree(
        map(menu.menuTitles, ({ id, title, parentId }) => ({
          text: title || SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_MENU_TITLE,
          value: id,
          id,
          parentId,
        })),
        {
          parentProperty: 'parentId',
        },
      ),
    [menu.menuTitles],
  )

  const handleMenuTitleRowClick = useCallback(
    menuTitle => {
      const collectIds = item => {
        let ids = [get(item, 'id')]
        forEach(get(item, 'children'), child => {
          ids = ids.concat(collectIds(child))
        })
        return ids
      }

      const newSelectedMenuTitleIds = collectIds(menuTitle)
      setSelectedMenuTitleIds(newSelectedMenuTitleIds)
    },
    [setSelectedMenuTitleIds],
  )

  const handleDishTableChange = useCallback(
    (pagination, filters) => {
      const { current, pageSize } = pagination
      history.push(createUrl(pathname, search, { page: current, pageSize }))

      setSelectedMenuTitleIds(filters.menuTitlePath)
      setSelectedDescriptionFilter(filters.description)
      setSelectedCaloriesFilter(filters.calories)
      setSelectedDishDietsFilter(filters.diets)
      setSelectedDishAddonsFilter(filters.addons)
      setSelectedDishMiscInfosFilter(filters.miscInfo)
      setSelectedDishAllergensFilter(filters.allergens)
      setSelectedDishIngredientsFilter(filters.ingredients)
      setSelectedDishNutritionsFilter(filters.nutritions)
    },

    [history, pathname, search],
  )

  const handleSelectedVersionChanged = useCallback(
    selectedMenuScrapingJobId =>
      setMenuScrapingJobId(selectedMenuScrapingJobId),
    [setMenuScrapingJobId],
  )

  const onSearchByDishName = useCallback(
    ({ target: { value } }) => setSearchByDishName(value),
    [setSearchByDishName],
  )

  const toggleMenuView = () => setShowFullMenu(!showFullMenu)

  const filteredDishesToShow = useMemo(() => {
    const lowercaseSearch = lowerCase(searchByDishName)
    return filter(dishesToShow, dish =>
      includes(lowerCase(dish.name), lowercaseSearch),
    )
  }, [dishesToShow, searchByDishName])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        {!isEmpty(successScrapingResponses) && (
          <Col span={4}>
            <MenuScrapingVersionsDropdown
              successScrapingResponses={successScrapingResponses}
              onChange={handleSelectedVersionChanged}
            />
          </Col>
        )}
        {menuScrapingJob && (
          <>
            <Col span={13} offset={1}>
              <MenuScrapingJobStats menuScrapingJob={menuScrapingJob} />
            </Col>
            {!showFullMenu && (
              <Col span={3} offset={3}>
                <MenuChangesColorLegend />
              </Col>
            )}
          </>
        )}
      </Row>
      <Row>
        <Tabs defaultActiveKey="1" onChange={toggleMenuView}>
          <TabPane tab="Full Menu" key="1" />
          <TabPane tab="Changes" key="2" disabled={!menuScrapingJob} />
        </Tabs>

        <MenuTitlesTable
          menu={menu}
          menuTitlesToShow={menuTitlesToShow}
          lastScrapedMenuTitles={menuTitles}
          isExpanded={isExpanded}
          showLastFullMenu={showLastFullMenu}
          onRowClick={handleMenuTitleRowClick}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />

        <DishesTable
          menu={menu}
          dishes={filteredDishesToShow}
          selectedCaloriesFilter={selectedCaloriesFilter}
          selectedDishDietsFilter={selectedDishDietsFilter}
          selectedDishAddonsFilter={selectedDishAddonsFilter}
          selectedDishMiscInfosFilter={selectedDishMiscInfosFilter}
          selectedDishAllergensFilter={selectedDishAllergensFilter}
          selectedDishIngredientsFilter={selectedDishIngredientsFilter}
          selectedDishNutritionsFilter={selectedDishNutritionsFilter}
          selectedDescriptionFilter={selectedDescriptionFilter}
          search={searchByDishName}
          onSearch={onSearchByDishName}
          menuTitlesForFilter={menuTitlesForFilter}
          selectedMenuTitleIds={selectedMenuTitleIds}
          showLastFullMenu={showLastFullMenu}
          page={page}
          pageSize={pageSize}
          onTableChange={handleDishTableChange}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />
      </Row>
    </div>
  )
}

MenuTitlesAndDishesTables.propTypes = {
  menu: PropTypes.object.isRequired,
  menuTitles: PropTypes.array.isRequired,
  dishes: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onShowScrapingDetailsButtonClick: PropTypes.func,
}

MenuTitlesAndDishesTables.defaultProps = {
  onShowScrapingDetailsButtonClick: undefined,
}

export default MenuTitlesAndDishesTables
