import { gql } from 'apollo-boost'

const START_MENU_SCRAPING_SESSION_FOR_CLUSTER = gql`
  mutation startMenuScrapingSessionForCluster(
    $id: ID!
    $data: StartMenuScrapingSessionForClusterInput!
  ) {
    startMenuScrapingSessionForCluster(id: $id, data: $data) {
      id
    }
  }
`

export default START_MENU_SCRAPING_SESSION_FOR_CLUSTER
