import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import fileDownload from 'js-file-download'

import { Checkbox } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateMenusInProbingStatusReport } from '../../rest'
import { menusInProbingStatusReportSchema } from '../forms/schemas'

const MenusInProbingStatusReportPage = () => {
  useDocumentTitle('Reports - menus in probing status')
  const formMethods = useForm({
    defaultValues: {
      includeOnlyStuckMenus: true,
    },
    resolver: yupResolver(menusInProbingStatusReportSchema),
  })

  const { handleSubmit } = formMethods

  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(
    async filters => {
      try {
        setIsGeneratingReport(true)
        const reportResponse = await generateMenusInProbingStatusReport(filters)
        fileDownload(reportResponse.data, 'menus_in_probing_status.csv')
        setIsGeneratingReport(false)
      } catch (e) {
        showErrorNotification({
          message: 'Failed to generate a report.',
          description: e,
        })
      }
    },
    [setIsGeneratingReport],
  )

  const onSubmit = useCallback(filters => generateReport(filters), [
    generateReport,
  ])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Checkbox
          name="includeOnlyStuckMenus"
          label="Include only stuck menus"
        />

        <Button
          margin="no no medium"
          type="primary"
          htmlType="submit"
          loading={isGeneratingReport}
        >
          Generate menus in probing status report
        </Button>
      </form>
    </FormProvider>
  )
}

export default MenusInProbingStatusReportPage
