import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { UPDATE_MENU_SCRAPING_TEMPLATE } from '../../graphql/mutations'
import { MENU_SCRAPING_TEMPLATE } from '../../graphql/queries'
import { transformToUpdateMenuScrapingTemplateInput } from '../../graphql/transformers'
import { UpdateMenuScrapingTemplateForm } from '../forms'

const onSubmit = (menuScrapingTemplate, updateMenuScrapingTemplate) => {
  const variables = transformToUpdateMenuScrapingTemplateInput(
    menuScrapingTemplate,
  )

  return updateMenuScrapingTemplate({ variables })
}

const UpdateMenuScrapingTemplatePage = () => {
  const { id: menuScrapingTemplateId } = useParams()
  useDocumentTitle('Update Menu Scraping Template')

  const {
    data: { menuScrapingTemplate } = {},
    refetch: refetchMenuScrapingTemplate,
    loading: isLoadingMenuScrapingTemplate,
  } = useQuery(MENU_SCRAPING_TEMPLATE, {
    variables: { id: menuScrapingTemplateId },
    skip: !menuScrapingTemplateId,
    fetchPolicy: 'no-cache',
  })

  const [updateMenuScrapingTemplate] = useMutation(
    UPDATE_MENU_SCRAPING_TEMPLATE,
    {
      onCompleted() {
        refetchMenuScrapingTemplate()
        showSuccessNotification({
          message: 'Menu updated.',
          description: 'Menu scraping template has been sucesfully updated.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping template update failed.',
          description: message,
        })
      },
    },
  )
  const handleSubmit = useCallback(
    menuScrapingTemplate =>
      onSubmit(menuScrapingTemplate, updateMenuScrapingTemplate),
    [updateMenuScrapingTemplate],
  )

  return isLoadingMenuScrapingTemplate ? (
    <Spinner />
  ) : (
    <UpdateMenuScrapingTemplateForm
      menuScrapingTemplate={menuScrapingTemplate}
      onSubmit={handleSubmit}
    />
  )
}

export default UpdateMenuScrapingTemplatePage
