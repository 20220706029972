import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Table, Typography } from 'antd'
import fileDownload from 'js-file-download'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { isValidRegex } from '../../../common/utils'

import { MenuUrlForm } from '../../../menus/components/pages/sections'
import { generateMenusNotMatchingNormalizationRegexReport } from '../../../reports/rest'
import { CREATE_PLATFORM, UPDATE_PLATFORM } from '../../graphql/mutations'
import { MENUS_BY_URL_REGEX, PLATFORM } from '../../graphql/queries'
import { transformToPlatformInput } from '../../graphql/transformers'
import { PlatformEditorForm } from '../forms'

const onSubmit = (platform, createPlatform, updatePlatform) => {
  if (platform.id) {
    updatePlatform({
      variables: {
        id: platform.id,
        data: transformToPlatformInput(platform),
      },
    })
  } else {
    createPlatform({
      variables: {
        data: transformToPlatformInput(platform),
      },
    })
  }
}

const PlatformEditorPage = () => {
  const { id: platformId } = useParams()
  const documentTitle = platformId
    ? 'Settings - Edit platform'
    : 'Settings - Create platform'
  useDocumentTitle(documentTitle)
  const history = useHistory()
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [currentValidMenusPage, setCurrentValidMenusPage] = useState(1)
  const [currentInvalidMenusPage, setCurrentInvalidMenusPage] = useState(1)
  const [urlIdentificationRegex, setUrlIdentificationRegex] = useState(null)
  const [urlNormalizationRegex, setUrlNormalizationRegex] = useState(null)

  const { data: { platform } = {}, loading: isLoadingPlatform } = useQuery(
    PLATFORM,
    {
      variables: { id: platformId },
      skip: !platformId,
      fetchPolicy: 'no-cache',
    },
  )

  const [createPlatform, { loading: isCreatingPlatform }] = useMutation(
    CREATE_PLATFORM,
    {
      onCompleted({ createPlatform: { name } }) {
        showSuccessNotification({
          message: 'Platform created.',
          description: `${name} has been successfully created.`,
        })
        history.push('/settings/platforms')
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Platform creation failed.',
          description: message,
        })
      },
    },
  )

  const [updatePlatform, { loading: isUpdatingPlatform }] = useMutation(
    UPDATE_PLATFORM,
    {
      onCompleted({ updatePlatform: { name } }) {
        showSuccessNotification({
          message: 'Platform updated.',
          description: `${name} has been successfully updated.`,
        })
        history.push('/settings/platforms')
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Platform update failed.',
          description: message,
        })
      },
    },
  )

  const [
    refetchValidMenus,
    {
      loading: isLoadingValidMenus,
      data: { menusByUrlRegex: validMenus = {} } = {},
    },
  ] = useLazyQuery(MENUS_BY_URL_REGEX, {
    fetchPolicy: 'no-cache',
    onError({ message }) {
      showErrorNotification({
        message: 'Error trying to fetch valid menus.',
        description: message,
      })
    },
  })

  const [
    refetchInvalidMenus,
    {
      loading: isLoadingInvalidMenus,
      data: { menusByUrlRegex: invalidMenus = {} } = {},
    },
  ] = useLazyQuery(MENUS_BY_URL_REGEX, {
    fetchPolicy: 'no-cache',
    onError({ message }) {
      showErrorNotification({
        message: 'Error trying to fetch invalid menus.',
        description: message,
      })
    },
  })

  const handleRegexChange = useCallback(
    ({ urlIdentificationRegex, urlNormalizationRegex }) => {
      if (
        urlIdentificationRegex &&
        isValidRegex(urlIdentificationRegex) &&
        urlNormalizationRegex &&
        isValidRegex(urlNormalizationRegex)
      ) {
        setUrlIdentificationRegex(urlIdentificationRegex)
        setUrlNormalizationRegex(urlNormalizationRegex)
      } else {
        setUrlIdentificationRegex(null)
        setUrlNormalizationRegex(null)
      }
    },
    [setUrlIdentificationRegex, setUrlNormalizationRegex],
  )

  useEffect(() => {
    setCurrentValidMenusPage(1)
    setCurrentInvalidMenusPage(1)
  }, [urlIdentificationRegex, urlNormalizationRegex])

  const areRegexesValid = useMemo(
    () =>
      Boolean(
        urlIdentificationRegex &&
          isValidRegex(urlIdentificationRegex) &&
          urlNormalizationRegex &&
          isValidRegex(urlNormalizationRegex),
      ),
    [urlIdentificationRegex, urlNormalizationRegex],
  )

  useEffect(() => {
    if (areRegexesValid) {
      refetchValidMenus({
        variables: {
          page: currentValidMenusPage,
          urlIdentificationRegex,
          urlNormalizationRegex,
          shouldMatchNormalizationRegex: true,
        },
      })
    }
  }, [
    areRegexesValid,
    refetchValidMenus,
    currentValidMenusPage,
    urlIdentificationRegex,
    urlNormalizationRegex,
  ])

  useEffect(() => {
    if (areRegexesValid) {
      refetchInvalidMenus({
        variables: {
          page: currentInvalidMenusPage,
          urlIdentificationRegex,
          urlNormalizationRegex,
          shouldMatchNormalizationRegex: false,
        },
      })
    }
  }, [
    areRegexesValid,
    refetchInvalidMenus,
    currentInvalidMenusPage,
    urlIdentificationRegex,
    urlNormalizationRegex,
  ])

  useEffect(() => {
    if (platform) {
      handleRegexChange(platform)
    }
  }, [handleRegexChange, platform])

  const generateReport = useCallback(async () => {
    try {
      setIsGeneratingReport(true)
      const reportResponse = await generateMenusNotMatchingNormalizationRegexReport(
        platformId,
      )

      fileDownload(
        reportResponse.data,
        `MenusNotMatchingNormalizationRegexReport-${platform.name}.csv`,
      )
      setIsGeneratingReport(false)
    } catch ({ response }) {
      showErrorNotification({
        message: 'Failed to generate report.',
        description: response && response.statusText,
      })
    }
  }, [setIsGeneratingReport, platform, platformId])

  const handleSubmit = useCallback(
    platform => onSubmit(platform, createPlatform, updatePlatform),
    [createPlatform, updatePlatform],
  )

  const invalidMenusColumns = [
    {
      title: 'Menu id',
      dataIndex: 'id',
      width: '20%',
      render: id => (
        <Link to={`/menus/${id}`} target="_blank" rel="noopener noreferrer">
          {id}
        </Link>
      ),
    },
    {
      title: 'Menu URL',
      width: '60%',
      dataIndex: 'url',
      render: (url, { id }) => (
        <MenuUrlForm
          id={id}
          url={url}
          onSubmit={() => {
            refetchInvalidMenus({
              variables: {
                page: currentInvalidMenusPage,
                urlIdentificationRegex,
                urlNormalizationRegex,
                shouldMatchNormalizationRegex: false,
              },
            })
            refetchValidMenus({
              variables: {
                page: currentValidMenusPage,
                urlIdentificationRegex,
                urlNormalizationRegex,
                shouldMatchNormalizationRegex: true,
              },
            })
          }}
        />
      ),
    },
    {
      title: 'Menu definition task id',
      dataIndex: 'menuDefinitionTask.id',
      width: '20%',
      render: id => (
        <Link
          to={`/tasks/menu-definition/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {id}
        </Link>
      ),
    },
  ]

  const validMenusColumns = [
    {
      title: 'Menu id',
      dataIndex: 'id',
      width: '20%',
      render: id => (
        <Link to={`/menus/${id}`} target="_blank" rel="noopener noreferrer">
          {id}
        </Link>
      ),
    },
    {
      title: 'Menu URL',
      width: '60%',
      dataIndex: 'url',
      render: url => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: 'Menu definition task id',
      dataIndex: 'menuDefinitionTask.id',
      width: '20%',
      render: id => (
        <Link
          to={`/tasks/menu-definition/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {id}
        </Link>
      ),
    },
  ]

  return isLoadingPlatform ? (
    <Spinner />
  ) : (
    <>
      <PlatformEditorForm
        platform={platform}
        isCreatingPlatform={isCreatingPlatform}
        isUpdatingPlatform={isUpdatingPlatform}
        isGeneratingReport={isGeneratingReport}
        onSubmit={handleSubmit}
        onGenerateReportClick={generateReport}
        onRegexChange={handleRegexChange}
      />

      <Typography.Title level={4}>Invalid menus</Typography.Title>
      <Typography.Text>
        {invalidMenus.total || 0} matching records
      </Typography.Text>
      <Table
        rowKey="id"
        loading={isLoadingInvalidMenus}
        columns={invalidMenusColumns}
        dataSource={invalidMenus.menus}
        pagination={{
          pageSize: 10,
          total: invalidMenus.total || 0,
          current: currentInvalidMenusPage,
          onChange: page => setCurrentInvalidMenusPage(page),
        }}
      />

      <Typography.Title level={4}>Valid menus</Typography.Title>
      <Typography.Text>
        {validMenus.total || 0} matching records
      </Typography.Text>
      <Table
        rowKey="id"
        loading={isLoadingValidMenus}
        columns={validMenusColumns}
        dataSource={validMenus.menus}
        pagination={{
          pageSize: 10,
          total: validMenus.total || 0,
          current: currentValidMenusPage,
          onChange: page => setCurrentValidMenusPage(page),
        }}
      />
    </>
  )
}

export default PlatformEditorPage
