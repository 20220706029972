import { gql } from 'apollo-boost'

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $data: UserInput!) {
    updateUser(id: $id, data: $data) {
      id
      name
      email
    }
  }
`

export default UPDATE_USER
