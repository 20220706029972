import { gql } from 'apollo-boost'

const MENUS_BY_URL_REGEX = gql`
  query menusByUrlRegex(
    $urlIdentificationRegex: String!
    $urlNormalizationRegex: String!
    $shouldMatchNormalizationRegex: Boolean
    $page: Int
    $pageSize: Int
  ) {
    menusByUrlRegex(
      urlIdentificationRegex: $urlIdentificationRegex
      urlNormalizationRegex: $urlNormalizationRegex
      shouldMatchNormalizationRegex: $shouldMatchNormalizationRegex
      page: $page
      pageSize: $pageSize
    ) {
      menus {
        id
        url
        menuDefinitionTask {
          id
        }
      }
      total
    }
  }
`

export default MENUS_BY_URL_REGEX
