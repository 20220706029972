import { push } from '../../common/utils'

import { RESOLVED } from '../constants'

import {
  getBrandDefinitionBreadcrumbItemByTaskId,
  getBrandGatewayBreadcrumbItemByTaskId,
  getDishReviewBreadcrumbItemByBrandId,
  getMenuDefinitionBreadcrumbItemByTaskId,
  getMenuScrapingBreadcrumbItemByBrandId,
} from './get-breadcrumb-item-paths'

const getBreadcrumbItemsForMenuDefinitionTaskPage = ({ relatedTasks }) => {
  const {
    brandId,
    brandGatewayTask,
    brandDefinitionTask,
    menuDefinitionTask,
  } = relatedTasks

  const items = []

  push(items, getBrandGatewayBreadcrumbItemByTaskId(brandGatewayTask.id))
  push(items, getBrandDefinitionBreadcrumbItemByTaskId(brandDefinitionTask.id))
  push(items, getMenuDefinitionBreadcrumbItemByTaskId(menuDefinitionTask.id))

  if (menuDefinitionTask.status !== RESOLVED) return items

  push(items, getMenuScrapingBreadcrumbItemByBrandId(brandId))
  push(items, getDishReviewBreadcrumbItemByBrandId(brandId))

  return items
}

export default getBreadcrumbItemsForMenuDefinitionTaskPage
