import { reduce } from 'lodash-es'

const getFirstScreenshotIndexByScrapedItemHash = screenshots =>
  reduce(
    screenshots,
    (result, screenshot, index) => {
      if (!result.has(screenshot.scrapedItemHash)) {
        result.set(screenshot.scrapedItemHash, index)
      }
      return result
    },
    new Map(),
  )

export default getFirstScreenshotIndexByScrapedItemHash
