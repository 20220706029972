import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import { MarkTaskAsAwaitingForm } from '../../forms'

const MarkTaskAsAwaitingModal = ({
  markTaskAsAwaiting,
  isModalOpen,
  closeModal,
}) => (
  <Modal
    title="Mark task as awaiting"
    visible={isModalOpen}
    footer={null}
    onCancel={closeModal}
  >
    <MarkTaskAsAwaitingForm
      onSubmit={markTaskAsAwaiting}
      closeModal={closeModal}
    />
  </Modal>
)

MarkTaskAsAwaitingModal.propTypes = {
  markTaskAsAwaiting: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default MarkTaskAsAwaitingModal
