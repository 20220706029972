const {
  filter,
  map,
  isEmpty,
  pick,
  pickBy,
  concat,
  intersectionWith,
  indexOf,
  get,
  isBoolean,
} = require('lodash-es')
const {
  SLAVE,
  NOT_SAME,
  INCLUDED_LOCATION,
  EXCLUDED_LOCATION,
} = require('../../../brands/constants')

const transformBrand = values => {
  const nonEmptyValues = pickBy(
    values,
    value => isBoolean(value) || !isEmpty(value),
  )

  const brand = pick(
    nonEmptyValues,
    'id',
    'name',
    'rank',
    'status',
    'exclusionReason',
    'exclusionReasonComment',
    'isHasNoWebsiteConfirmed',
  )

  return nonEmptyValues.masterBrand
    ? {
        ...brand,
        website: isEmpty(values.website) ? null : values.website,
        masterId: nonEmptyValues.masterBrand.id,
      }
    : brand
}

const transformLocation = values => {
  const nonEmptyValues = pickBy(values, value => !isEmpty(value))

  const brandId = values.newBrand.id || values.brandId

  const location = pick(
    nonEmptyValues,
    'id',
    'website',
    'status',
    'exclusionReason',
    'exclusionReasonComment',
  )

  return {
    ...location,
    brandId,
  }
}

const newBrandRelations = (
  initialValues,
  similarBrands,
  relatedBrands = [],
) => {
  const initialSlaveBrandIds = map(initialValues.brand.slaveBrands, 'id')
  const initialNotSameBrandIds = map(initialValues.brand.notSameBrands, 'id')

  const brandsToCheck = concat(
    get(similarBrands, 'brandsSimilarByName', []),
    get(similarBrands, 'brandsSimilarByLocationWebsite', []),
    relatedBrands,
  )

  const newSlaveBrandIds = map(
    filter(
      brandsToCheck,
      ({ id, relationship }) =>
        relationship === SLAVE && indexOf(initialSlaveBrandIds, id) === -1,
    ),
    'id',
  )

  const newNotSameBrandIds = map(
    filter(
      brandsToCheck,
      ({ id, relationship }) =>
        relationship === NOT_SAME && indexOf(initialNotSameBrandIds, id) === -1,
    ),
    'id',
  )

  return { newSlaveBrandIds, newNotSameBrandIds }
}

const removedBrandRelations = (initialValues, relatedBrands) => {
  const removedSlaveBrandIds = map(
    intersectionWith(
      initialValues.brand.slaveBrands,
      relatedBrands,
      (slaveBrand, relatedBrand) =>
        slaveBrand.id === relatedBrand.id &&
        relatedBrand.relationship !== SLAVE,
    ),
    'id',
  )

  const removedNotSameBrandIds = map(
    intersectionWith(
      initialValues.brand.notSameBrands,
      relatedBrands,
      (slaveBrand, relatedBrand) =>
        slaveBrand.id === relatedBrand.id &&
        relatedBrand.relationship !== NOT_SAME,
    ),
    'id',
  )

  return {
    removedSlaveBrandIds,
    removedNotSameBrandIds,
  }
}

const onlyIncludedExcludedOrChangedLocations = locations =>
  filter(
    locations,
    ({ status, newBrand }) =>
      status === INCLUDED_LOCATION ||
      status === EXCLUDED_LOCATION ||
      !isEmpty(newBrand.id),
  )

const transformToBrandGatewayTaskInput = (values, initialValues) => {
  const { task, similarBrands, relatedBrands } = values
  const brandData = transformBrand(values.brand)

  const locationsData = map(
    onlyIncludedExcludedOrChangedLocations(values.locations),
    transformLocation,
  )

  const { newSlaveBrandIds, newNotSameBrandIds } = newBrandRelations(
    initialValues,
    similarBrands,
    relatedBrands,
  )

  const {
    removedSlaveBrandIds,
    removedNotSameBrandIds,
  } = removedBrandRelations(initialValues, relatedBrands)

  return {
    id: task.id,
    brandData,
    locationsData,
    newSlaveBrandIds,
    removedSlaveBrandIds,
    newNotSameBrandIds,
    removedNotSameBrandIds,
  }
}

export default transformToBrandGatewayTaskInput
