import { pick } from 'lodash-es'

import {
  BUSINESS_STATUSES,
  LOCATION_MENU_STATUSES,
  LOCATION_STATUSES,
} from '../../../constants'

const getBrandInitialValues = brand => pick(brand, 'id', 'name')

const locationFormInitialValues = values => {
  if (!values) return {}
  const location = pick(
    values,
    'id',
    'name',
    'website',
    'modifiedDate',
    'brand',
    'fsaLocation',
    'googleLocation',
  )

  const brand = getBrandInitialValues(location.brand)

  const initialValues = {
    ...location,
    brand,
    businessStatus: BUSINESS_STATUSES[values.businessStatus]?.name,
    status: LOCATION_STATUSES[values.status]?.name,
    menuStatus: LOCATION_MENU_STATUSES[values.menuStatus]?.name,
  }

  return initialValues
}

export default locationFormInitialValues
