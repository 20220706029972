import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import { AdditionalMenuUrlsForm } from '../../forms'

const AdditionalMenuUrlsModal = ({ isModalOpen, onCancel, onSave, menu }) => (
  <Modal
    title="Additional menu urls"
    visible={isModalOpen}
    onCancel={onCancel}
    footer={null}
  >
    <AdditionalMenuUrlsForm onSave={onSave} menu={menu} />
  </Modal>
)

AdditionalMenuUrlsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  menu: PropTypes.object,
}

AdditionalMenuUrlsModal.defaultProps = {
  menu: undefined,
}

export default AdditionalMenuUrlsModal
