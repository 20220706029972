import { gql } from 'apollo-boost'

const UNMARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED = gql`
  mutation unmarkBrandDefinitionTaskAsQualityChecked($id: ID!) {
    unmarkBrandDefinitionTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default UNMARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED
