import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Form, Row, Select, Table, Typography } from 'antd'
import { map } from 'lodash-es'

import { Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  ClusterAssignedToFilter,
  ClusterIsAssignedFilter,
  LondonDateTime,
} from '../../../common/components'
import { ADMIN, DEFAULT_PAGE, TEAM_LEADER } from '../../../common/constants'
import { yesNoFilter } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder, tableOnRowClick } from '../../../common/utils'

import {
  MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE,
  MENU_SCRAPING_CLUSTER_SORTING_CRITERIA,
} from '../../../menus/constants'
import { MENU_SCRAPING_TEMPLATE_STATUSES } from '../../constants'
import { MENU_SCRAPING_CLUSTERS } from '../../graphql/queries'

const MenuScrapingClustersPage = () => {
  useDocumentTitle('Menu Scraping Clusters')

  const history = useHistory()
  const { search, pathname } = useLocation()
  const {
    page = DEFAULT_PAGE,
    hasTemplate,
    hasUpdatedFingerprints,
    templateStatus,
    sortingCriterion,
    sortingOrder,
    isAssigned,
    assignedToId,
    rerunStepType,
  } = getQueryParams(search)

  const {
    data: { menuScrapingClusters = {} } = {},
    loading: isLoadingMenuScrapingClusters,
  } = useQuery(MENU_SCRAPING_CLUSTERS, {
    variables: {
      page,
      filters: {
        hasTemplate,
        hasUpdatedFingerprints,
        templateStatus,
        isAssigned,
        assignedToId,
        rerunStepType,
      },
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  const tableColumns = [
    {
      title: 'Id',
      width: '5%',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'Menus',
      width: '5%',
      dataIndex: 'menuCount',
      sorter: true,
    },
    {
      title: 'Approved menus',
      width: '7%',
      dataIndex: 'approvedMenuCount',
      sorter: true,
    },
    {
      title: 'Template name',
      width: '10%',
      dataIndex: 'menuScrapingTemplate.name',
      sorter: true,
    },
    {
      title: 'Template status',
      width: '10%',
      dataIndex: 'menuScrapingTemplate.status',
      sorter: true,
      render: status =>
        status ? MENU_SCRAPING_TEMPLATE_STATUSES[status].name : '',
    },
    {
      title: 'Assigned to',
      width: '10%',
      dataIndex: 'assignedTo.name',
    },
    {
      title: 'Rerun step',
      width: '10%',
      dataIndex: 'rerunFlow.currentStep.type',
      render: type =>
        type ? MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE[type].name : '',
    },
    {
      title: 'Has updated fingerprints',
      width: '10%',
      dataIndex: 'hasUpdatedFingerprints',
      sorter: true,
      render: hasUpdatedFingerprints => (hasUpdatedFingerprints ? 'Yes' : 'No'),
    },
    {
      title: 'Created date',
      width: '10%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Modified date',
      width: '10%',
      dataIndex: 'modifiedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Modified by',
      width: '10%',
      dataIndex: 'modifiedBy.name',
    },
  ]

  const handlePageChange = useCallback(
    page => history.push(createUrl(pathname, search, { page })),
    [history, search, pathname],
  )

  const handleTemplateStatusChange = useCallback(
    templateStatus =>
      history.push(
        createUrl(pathname, search, {
          page: DEFAULT_PAGE,
          hasTemplate,
          hasUpdatedFingerprints,
          templateStatus,
          sortingCriterion,
          sortingOrder,
          isAssigned,
          assignedToId,
        }),
      ),
    [
      history,
      pathname,
      search,
      hasTemplate,
      hasUpdatedFingerprints,
      sortingCriterion,
      sortingOrder,
      isAssigned,
      assignedToId,
    ],
  )

  const handleHasTemplateChange = useCallback(
    hasTemplate =>
      history.push(
        createUrl(pathname, search, {
          page: DEFAULT_PAGE,
          hasTemplate,
          templateStatus: hasTemplate ? templateStatus : null,
          hasUpdatedFingerprints,
          sortingCriterion,
          sortingOrder,
          isAssigned,
          assignedToId,
        }),
      ),
    [
      history,
      pathname,
      search,
      templateStatus,
      hasUpdatedFingerprints,
      sortingCriterion,
      sortingOrder,
      isAssigned,
      assignedToId,
    ],
  )

  const handleRerunStepChange = useCallback(
    rerunStepType =>
      history.push(
        createUrl(pathname, search, {
          page: DEFAULT_PAGE,
          hasTemplate,
          hasUpdatedFingerprints,
          templateStatus,
          sortingCriterion,
          sortingOrder,
          isAssigned,
          assignedToId,
          rerunStepType,
        }),
      ),
    [
      history,
      pathname,
      search,
      hasTemplate,
      templateStatus,
      hasUpdatedFingerprints,
      sortingCriterion,
      sortingOrder,
      isAssigned,
      assignedToId,
    ],
  )

  const handleHasUpdatedFingerprintsChange = useCallback(
    hasUpdatedFingerprints =>
      history.push(
        createUrl(pathname, search, {
          page: DEFAULT_PAGE,
          hasUpdatedFingerprints,
          sortingCriterion,
          sortingOrder,
          isAssigned,
          assignedToId,
        }),
      ),
    [
      assignedToId,
      history,
      isAssigned,
      pathname,
      search,
      sortingCriterion,
      sortingOrder,
    ],
  )

  const redirectToMenuScrapingCluster = useCallback(
    menuScrapingCluster =>
      history.push(
        `/menu-scraping-templates/clusters/${menuScrapingCluster.id}`,
      ),
    [history],
  )

  const handleRowClick = useCallback(
    row => ({
      onClick: event =>
        tableOnRowClick(event, row, redirectToMenuScrapingCluster),
    }),
    [redirectToMenuScrapingCluster],
  )

  const getSortingCriterion = (field, order) => {
    if (!order) {
      return ''
    }

    if (field === 'id') {
      return MENU_SCRAPING_CLUSTER_SORTING_CRITERIA.ID
    }
    if (field === 'menuCount') {
      return MENU_SCRAPING_CLUSTER_SORTING_CRITERIA.MENU_COUNT
    }
    if (field === 'approvedMenuCount') {
      return MENU_SCRAPING_CLUSTER_SORTING_CRITERIA.APPROVED_MENU_COUNT
    }
    if (field === 'menuScrapingTemplate.name') {
      return MENU_SCRAPING_CLUSTER_SORTING_CRITERIA.TEMPLATE_NAME
    }
    if (field === 'menuScrapingTemplate.status') {
      return MENU_SCRAPING_CLUSTER_SORTING_CRITERIA.TEMPLATE_STATUS
    }
    return ''
  }

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          page: pagination.current,
          hasTemplate,
          hasUpdatedFingerprints,
          templateStatus,
          sortingCriterion,
          sortingOrder,
          isAssigned,
          assignedToId,
        }),
      )
    },
    [
      history,
      pathname,
      hasTemplate,
      hasUpdatedFingerprints,
      templateStatus,
      search,
      isAssigned,
      assignedToId,
    ],
  )

  return (
    <>
      <Typography.Title level={4}>Clusters</Typography.Title>
      <Panel margin="small no">
        <Row gutter={[16, 16]}>
          <Col span={3}>
            <Form.Item label="Has template">
              <Select
                style={{ width: '100%' }}
                value={hasTemplate}
                onChange={handleHasTemplateChange}
                allowClear
              >
                {map(yesNoFilter, ({ text, value }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Template status">
              <Select
                style={{ width: '100%' }}
                value={templateStatus}
                onChange={handleTemplateStatusChange}
                allowClear
                disabled={!hasTemplate}
              >
                {map(MENU_SCRAPING_TEMPLATE_STATUSES, ({ name, value }) => (
                  <Select.Option key={value} value={value}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <ClusterIsAssignedFilter />
          </Col>
          <Col span={3}>
            <ClusterAssignedToFilter
              disabled={!isAssigned}
              roles={[ADMIN, TEAM_LEADER]}
            />
          </Col>
          <Col span={4}>
            <Form.Item label="Rerun step">
              <Select
                style={{ width: '100%' }}
                value={rerunStepType}
                onChange={handleRerunStepChange}
                allowClear
              >
                {map(
                  MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE,
                  ({ name, value }) => (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Has updated fingerprints">
              <Select
                style={{ width: '100%' }}
                value={hasUpdatedFingerprints}
                onChange={handleHasUpdatedFingerprintsChange}
                allowClear
              >
                {map(yesNoFilter, ({ text, value }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
      <Table
        loading={isLoadingMenuScrapingClusters}
        rowKey="id"
        columns={tableColumns}
        dataSource={menuScrapingClusters.menuScrapingClusters}
        onRow={handleRowClick}
        pagination={{
          current: page,
          total: menuScrapingClusters.total,
          onChange: handlePageChange,
        }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default MenuScrapingClustersPage
