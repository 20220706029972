import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Icon, Popconfirm, Row, Spin } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { DELETE_MENU_SCRAPING_TEMPLATE } from '../../../graphql/mutations'

const MenuScrapingTemplatesTableActionsColumn = ({
  menuScrapingTemplate,
  refetchMenuScrapingTemplates,
}) => {
  const [
    deleteMenuScrapingTemplate,
    { loading: isDeletingMenuScrapingTemplate },
  ] = useMutation(DELETE_MENU_SCRAPING_TEMPLATE, {
    onCompleted({ deleteMenuScrapingTemplate: { name } }) {
      refetchMenuScrapingTemplates()
      showSuccessNotification({
        message: 'Menu ccraping template deleted.',
        description: `${name} template has been successfully deleted.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping template deletion failed.',
        description: message,
      })
    },
  })

  const history = useHistory()

  return isDeletingMenuScrapingTemplate ? (
    <Spin />
  ) : (
    <Row>
      <Button
        onClick={() =>
          history.push(
            `/menu-scraping-templates/templates/${menuScrapingTemplate.id}`,
          )
        }
        margin="no small"
      >
        Edit
      </Button>
      <Popconfirm
        title="Are you sure you want to delete this menu scraping template?"
        onConfirm={() =>
          deleteMenuScrapingTemplate({
            variables: { id: menuScrapingTemplate.id },
          })
        }
        okText="Yes"
        cancelText="No"
      >
        <Button>
          <Icon type="delete" />
        </Button>
      </Popconfirm>
    </Row>
  )
}

MenuScrapingTemplatesTableActionsColumn.propTypes = {
  menuScrapingTemplate: PropTypes.object.isRequired,
  refetchMenuScrapingTemplates: PropTypes.func.isRequired,
}

export default MenuScrapingTemplatesTableActionsColumn
