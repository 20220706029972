import { mapKeys } from 'lodash-es'

export const NEW = 'NEW'
export const VALIDATED = 'VALIDATED'
export const OPTIMIZED = 'OPTIMIZED'
export const CONFIRMED = 'CONFIRMED'
export const NO_TEMPLATE = 'NO_TEMPLATE'

export const MENU_SCRAPING_TEMPLATE_STATUSES = mapKeys(
  [
    { name: 'New', value: NEW },
    { name: 'Validated', value: VALIDATED },
    { name: 'Optimized', value: OPTIMIZED },
    { name: 'Confirmed', value: CONFIRMED },
  ],
  'value',
)
