import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { CreateBrandForm } from '../../../../tasks/components/forms'
import { CREATE_BRAND } from '../../../graphql/mutations'
import { transformToCreateBrandInput } from '../../../graphql/transformers'

const CreateBrandModal = ({
  website,
  originalName,
  isModalOpen,
  closeModal,
}) => {
  const [createBrand, { loading: isCreatingBrand }] = useMutation(
    CREATE_BRAND,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Brand created.',
          description: 'Brand has been successfully created.',
        })
        closeModal()
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Create brand failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    brand => {
      createBrand({ variables: { data: transformToCreateBrandInput(brand) } })
    },
    [createBrand],
  )

  return (
    <Modal
      title="Create brand"
      visible={isModalOpen}
      footer={null}
      onCancel={closeModal}
    >
      <CreateBrandForm
        originalName={originalName}
        website={website}
        onSubmit={handleSubmit}
        isLoading={isCreatingBrand}
        closeModal={closeModal}
      />
    </Modal>
  )
}

CreateBrandModal.propTypes = {
  website: PropTypes.string,
  originalName: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

CreateBrandModal.defaultProps = {
  website: undefined,
}

export default CreateBrandModal
