import React, { Fragment, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Table, Typography } from 'antd'
import arrayToTree from 'array-to-tree'
import { forEach, map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  HiddenCheckboxes,
  HiddenInputs,
  PopoverReactJson,
} from '../../../../core/components'
import { Button } from '../../../../core/components/styled'

import { tableOnRowClick } from '../../../../common/utils'

import { SCRAPED_ITEM_PLACEHOLDER_TITLES } from '../../../constants'
import {
  getTableRowStyleForMenuItem,
  isMenuScrapedInDebugMode,
} from '../../../helpers'

const setRecordStyle = ({ status, dishCount, childDishCount }) => {
  if (status) return getTableRowStyleForMenuItem({ status })
  if (dishCount === childDishCount && dishCount === 0) return 'removedRow'
  return undefined
}

const MenuTitlesTable = ({
  menu,
  menuTitlesToShow = [],
  lastScrapedMenuTitles = [],
  isExpanded,
  showLastFullMenu,
  onRowClick,
  onShowScrapingDetailsButtonClick,
}) => {
  const { setValue } = useFormContext()

  const onCheckboxChange = useCallback(
    (isChecked, menuTitleId) => {
      const updatedMenuTitles = map(menuTitlesToShow, menuTitle =>
        menuTitle.id === menuTitleId
          ? {
              ...menuTitle,
              isEverywhereAvailable: isChecked,
            }
          : menuTitle,
      )

      forEach(updatedMenuTitles, ({ isEverywhereAvailable }, index) => {
        setValue(
          `menuTitles.${index}.isEverywhereAvailable`,
          isEverywhereAvailable,
        )
      })
    },
    [setValue, menuTitlesToShow],
  )

  const isScrapingDetailsDataAvailable = useMemo(
    () => isMenuScrapedInDebugMode(menu) && showLastFullMenu,
    [menu, showLastFullMenu],
  )

  const getTitleWithChildrenCount = (title, children) =>
    children ? `${title} (${size(children)})` : title

  const menuTitleTableData = arrayToTree(menuTitlesToShow, {
    parentProperty: 'parentId',
  })

  const tableColumns = useMemo(
    () => [
      {
        title: getTitleWithChildrenCount('Menu Title', menuTitleTableData),
        dataIndex: 'title',
        width: '400px',
        render: (title, menuTitle) =>
          title ? (
            getTitleWithChildrenCount(title, menuTitle.children)
          ) : (
            <Typography.Text style={{ fontStyle: 'italic' }}>
              {getTitleWithChildrenCount(
                SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_MENU_TITLE,
                menuTitle.children,
              )}
            </Typography.Text>
          ),
      },
      { title: 'Description', dataIndex: 'description' },
      {
        title: 'Scraping Details',
        width: '80px',
        align: 'center',
        dataIndex: 'hash',
        render: (hash, menuTitle) => (
          <Button
            size="small"
            icon="eye"
            disabled={menuTitle.isDeleted || !isScrapingDetailsDataAvailable}
            onClick={() => onShowScrapingDetailsButtonClick(hash)}
          />
        ),
      },
      {
        title: 'Available Everywhere',
        width: '150px',
        dataIndex: 'isEverywhereAvailable',
        render: (isEverywhereAvailable, menuTitle) => (
          <Checkbox
            disabled={menuTitle.isDeleted || !showLastFullMenu}
            onChange={event =>
              onCheckboxChange(event.target.checked, menuTitle.id)
            }
            defaultChecked={isEverywhereAvailable}
          />
        ),
      },
      {
        title: 'Dishes',
        width: '120px',
        dataIndex: 'dishCount',
      },
      {
        title: 'Dishes On Children',
        width: '120px',
        dataIndex: 'childDishCount',
      },
      {
        title: 'Calories',
        dataIndex: 'calories',
      },
      {
        title: 'Dish Diet',
        dataIndex: 'diets',
        render: diets =>
          map(diets, (diet, index) => (
            <PopoverReactJson key={index} source={diet} />
          )),
      },
      {
        title: 'Choice Information',
        dataIndex: 'addons',
        render: addons =>
          map(addons, (addon, index) => (
            <PopoverReactJson key={index} source={addon} />
          )),
      },
      {
        title: 'Additional Information',
        dataIndex: 'miscInfo',
        render: miscInfo =>
          map(miscInfo, (info, index) => (
            <PopoverReactJson key={index} source={info} />
          )),
      },
      {
        title: 'Allergens Information',
        dataIndex: 'allergens',
        render: allergens =>
          map(allergens, (allergen, index) => (
            <PopoverReactJson key={index} source={allergen} />
          )),
      },
      {
        title: 'Nutritions Information',
        dataIndex: 'nutritions',
        render: nutritions =>
          map(nutritions, (nutrition, index) => (
            <PopoverReactJson key={index} source={JSON.parse(nutrition)} />
          )),
      },
    ],
    [
      menuTitleTableData,
      isScrapingDetailsDataAvailable,
      showLastFullMenu,
      onCheckboxChange,
      onShowScrapingDetailsButtonClick,
    ],
  )

  return (
    <>
      <Table
        rowKey="id"
        scroll={{ x: 3000, y: 500 }}
        columns={tableColumns}
        dataSource={menuTitleTableData}
        defaultExpandAllRows={isExpanded}
        rowClassName={setRecordStyle}
        pagination={false}
        onRow={menuTitle => ({
          onClick: event => tableOnRowClick(event, menuTitle, onRowClick),
        })}
      />

      {lastScrapedMenuTitles.map((menuTitle, index) => (
        <Fragment key={menuTitle.id}>
          <HiddenInputs names={[`menuTitles.${index}.id`]} />
          <HiddenCheckboxes
            names={[
              `menuTitles.${index}.isEverywhereAvailable`,
              `menuTitles.${index}.isDeleted`,
            ]}
          />
        </Fragment>
      ))}
    </>
  )
}

MenuTitlesTable.propTypes = {
  menu: PropTypes.object.isRequired,
  menuTitlesToShow: PropTypes.array.isRequired,
  lastScrapedMenuTitles: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  showLastFullMenu: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func,
  onShowScrapingDetailsButtonClick: PropTypes.func,
}

MenuTitlesTable.defaultProps = {
  onRowClick: undefined,
  onShowScrapingDetailsButtonClick: undefined,
}

export default MenuTitlesTable
