import { isEmpty } from 'lodash-es'

import {
  SCRAPED_ITEM_PLACEHOLDER_TITLES,
  SCRAPED_ITEM_STATUSES,
} from '../constants'

const getDishName = dish => {
  if (dish.status === SCRAPED_ITEM_STATUSES.ERROR && isEmpty(dish.name)) {
    return SCRAPED_ITEM_PLACEHOLDER_TITLES.CORRUPT_DISH
  }
  if (isEmpty(dish.name)) {
    return SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_DISH_NAME
  }
  return dish.name
}

export default getDishName
