import React from 'react'
import { Row, Typography } from 'antd'

import { THEME } from '../../../../core/constants'

const MenuChangesColorLegend = () => {
  const renderRow = (backgroundColor, text, extraStyleOptions = {}) => (
    <Row
      style={{
        backgroundColor,
        paddingLeft: '10px',
        marginBottom: '2px',
        ...extraStyleOptions,
      }}
    >
      <Typography.Text>{text}</Typography.Text>
    </Row>
  )

  return (
    <>
      {renderRow(THEME.colors.lightRed, 'Deleted')}
      {renderRow(THEME.colors.lightYellow, 'Restored')}
      {renderRow(THEME.colors.darkBlue, 'Updated (significantly)')}
      {renderRow(THEME.colors.lightBlue, 'Updated')}
      {renderRow(THEME.colors.lightGreen, 'Created')}
      {renderRow(THEME.colors.white, 'Not changed', {
        border: '1px solid #b6b0b0',
      })}
    </>
  )
}

export default MenuChangesColorLegend
