import { mapKeys } from 'lodash-es'

export const HTML = 'HTML'
export const PDF = 'PDF'
export const IMAGE = 'IMAGE'
export const DOCUMENT = 'DOCUMENT'
export const HTML_NOT_SCRAPEABLE = 'HTML_NOT_SCRAPEABLE'

export const MENU_FORMATS = mapKeys(
  [
    { name: 'HTML', value: HTML },
    { name: 'PDF', value: PDF },
    { name: 'IMAGE', value: IMAGE },
    { name: 'DOCUMENT', value: DOCUMENT },
    { name: 'HTML (not scrapeable)', value: HTML_NOT_SCRAPEABLE },
  ],
  'value',
)
