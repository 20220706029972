const validateState = async (state, schema) => {
  try {
    await schema.validate(state, { abortEarly: false })
    return { isValid: true, errors: {} }
  } catch ({ inner }) {
    const errors = {}
    inner.forEach(error => {
      errors[error.path] = error.message
    })
    return { isValid: false, errors }
  }
}

export default validateState
