import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateUsersReport } from '../../rest'
import { UsersReportForm } from '../forms'

const UserReportPage = () => {
  useDocumentTitle('Reports - User')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(
    async filters => {
      const { dateRange, ...usersReportValues } = filters

      try {
        setIsGeneratingReport(true)
        const [startDateMoment, endDateMoment] = dateRange
        const reportReponse = await generateUsersReport({
          startDate: startDateMoment.format('YYYY-MM-DD'),
          endDate: endDateMoment.format('YYYY-MM-DD'),
          ...usersReportValues,
        })

        fileDownload(reportReponse.data, 'UsersReport.zip')
        setIsGeneratingReport(false)
      } catch ({ response }) {
        showErrorNotification({
          message: 'Failed to generate report.',
          description: response.statusText,
        })
      }
    },
    [setIsGeneratingReport],
  )

  const handleSubmit = useCallback(filters => generateReport(filters), [
    generateReport,
  ])

  return (
    <UsersReportForm
      onSubmit={handleSubmit}
      isGeneratingReport={isGeneratingReport}
    />
  )
}

export default UserReportPage
