import { gql } from 'apollo-boost'

const BRAND_OPTIONS = gql`
  query brands($filters: BrandFiltersInput) {
    brands(filters: $filters) {
      brands {
        id
        name
        brandGatewayTask {
          id
        }
      }
      total
    }
  }
`

export default BRAND_OPTIONS
