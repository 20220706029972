export const USERS_PERMISSIONS = {
  CREATE: 'user.create',
  UPDATE: 'user.update',
  DELETE: 'user.delete',
  GET: 'user.get',
  LIST: 'user.list',
}

export const BRANDS_PERMISSIONS = {
  CREATE: 'brand.create',
  UPDATE: 'brand.update',
  UPDATE_IMAGES: 'brand.updateImages',
  GET: 'brand.get',
  LIST: 'brand.list',
}

export const LOCATIONS_PERMISSIONS = {
  UPDATE: 'location.update',
  GET: 'location.get',
  LIST: 'location.list',
}

export const MENUS_PERMISSIONS = {
  UPDATE: 'menu.update',
  GET: 'menu.get',
  LIST: 'menu.list',
}

export const BRAND_GATEWAY_TASKS_PERMISSIONS = {
  ASSIGN: 'brandGatewayTask.assign',
  UNASSIGN: 'brandGatewayTask.unassign',
  MARK_AWAITING: 'brandGatewayTask.markAwaiting',
  UNMARK_AWAITING: 'brandGatewayTask.unmarkAwaiting',
  MARK_AS_QUALITY_CHECKED: 'brandGatewayTask.markAsQualityChecked',
  UNMARK_AS_QUALITY_CHECKED: 'brandGatewayTask.unmarkAsQualityChecked',
  RESOLVE: 'brandGatewayTask.resolve',
  REOPEN: 'brandGatewayTask.reopen',
  ADMIN: 'brandGatewayTask.admin',
  GET: 'brandGatewayTask.get',
  LIST: 'brandGatewayTask.list',
}

export const BRAND_DEFINITION_TASKS_PERMISSIONS = {
  ASSIGN: 'brandDefinitionTask.assign',
  UNASSIGN: 'brandDefinitionTask.unassign',
  MARK_AWAITING: 'brandDefinitionTask.markAwaiting',
  UNMARK_AWAITING: 'brandDefinitionTask.unmarkAwaiting',
  MARK_AS_QUALITY_CHECKED: 'brandGatewayTask.markAsQualityChecked',
  UNMARK_AS_QUALITY_CHECKED: 'brandGatewayTask.unmarkAsQualityChecked',
  RESOLVE: 'brandDefinitionTask.resolve',
  REOPEN: 'brandDefinitionTask.reopen',
  ADMIN: 'brandDefinitionTask.admin',
  GET: 'brandDefinitionTask.get',
  LIST: 'brandDefinitionTask.list',
}

export const MENU_DEFINITION_TASKS_PERMISSIONS = {
  ASSIGN: 'menuDefinitionTask.assign',
  UNASSIGN: 'menuDefinitionTask.unassign',
  MARK_AWAITING: 'menuDefinitionTask.markAwaiting',
  UNMARK_AWAITING: 'menuDefinitionTask.unmarkAwaiting',
  MARK_AS_QUALITY_CHECKED: 'brandGatewayTask.markAsQualityChecked',
  UNMARK_AS_QUALITY_CHECKED: 'brandGatewayTask.unmarkAsQualityChecked',
  RESOLVE: 'menuDefinitionTask.resolve',
  REOPEN: 'menuDefinitionTask.reopen',
  ADMIN: 'menuDefinitionTask.admin',
  GET: 'menuDefinitionTask.get',
  LIST: 'menuDefinitionTask.list',
}

export const MENU_SCRAPING_TASKS_PERMISSIONS = {
  ASSIGN: 'menuScrapingTask.assign',
  UNASSIGN: 'menuScrapingTask.unassign',
  MARK_AWAITING: 'menuScrapingTask.markAwaiting',
  UNMARK_AWAITING: 'menuScrapingTask.unmarkAwaiting',
  MARK_AS_QUALITY_CHECKED: 'brandGatewayTask.markAsQualityChecked',
  UNMARK_AS_QUALITY_CHECKED: 'brandGatewayTask.unmarkAsQualityChecked',
  RESOLVE: 'menuScrapingTask.resolve',
  REOPEN: 'menuScrapingTask.reopen',
  ADMIN: 'menuScrapingTask.admin',
  GET: 'menuScrapingTask.get',
  LIST: 'menuScrapingTask.list',
}

export const DISH_REVIEW_TASKS_PERMISSIONS = {
  ASSIGN: 'dishReviewTask.assign',
  UNASSIGN: 'dishReviewTask.unassign',
  MARK_AWAITING: 'dishReviewTask.markAwaiting',
  UNMARK_AWAITING: 'dishReviewTask.unmarkAwaiting',
  MARK_AS_QUALITY_CHECKED: 'brandGatewayTask.markAsQualityChecked',
  UNMARK_AS_QUALITY_CHECKED: 'brandGatewayTask.unmarkAsQualityChecked',
  RESOLVE: 'dishReviewTask.resolve',
  REOPEN: 'dishReviewTask.reopen',
  ADMIN: 'dishReviewTask.admin',
  GET: 'dishReviewTask.get',
  LIST: 'dishReviewTask.list',
}

export const EVENTS_PERMISSIONS = {
  LIST: 'event.list',
}

export const REPORTS_PERMISSIONS = {
  GET_FOR_MENU: 'report.getForMenu',
  GET_FOR_MENUS_HAVING_ALLERGENS: 'report.getForMenusHavingAllergens',
  GET_FOR_USERS: 'report.getForUsers',
  GET_FOR_SCRAPING_ENGINE: 'report.getForScrapingEngine',
  GET_FOR_SCRAPING_ISSUE: 'report.getForScrapingIssue',
  GET_FOR_SCRAPING_ATTEMPTS: 'report.getForScrapingAttempts',
  GET_FOR_SCRAPING_VERIFICATION: 'report.getForScrapingVerification',
  GET_FOR_COUNTS: 'report.getForCounts',
  GET_FOR_QUALITY_CHECKED: 'report.getForQualityChecked',
  GET_FOR_USER_COUNTS: 'report.getForUserCounts',
  GET_FOR_SCRAPING_CLUSTER_MENUS: 'report.getForScrapingClusterMenus',
  GET_FOR_SCRAPING_CLUSTERS: 'report.getForScrapingClusters',
  GET_FOR_MENUS_NOT_MATCHING_NORMALIZATION_REGEX:
    'report.getMenusNotMatchingNormalizationRegex',
  GET_FOR_OPEN_TASKS: 'report.getForOpenTasks',
  GET_FOR_CHANGED_MENU_URLS: 'report.getChangedMenuUrls',
  GET_MENUS_IN_PROBING_STATUS: 'report.getMenusInProbingStatus',
  GET_FOR_MISC_TASKS_VERIFICATION: 'report.getForMiscTasksVerification',
}

export const COMMON_WEBSITE_DOMAIN_PERMISSIONS = {
  CREATE: 'commonWebsiteDomain.create',
  UPDATE: 'commonWebsiteDomain.update',
  DELETE: 'commonWebsiteDomain.delete',
  LIST: 'commonWebsiteDomain.list',
}

export const PLATFORM_PERMISSIONS = {
  GET: 'platform.get',
  LIST: 'platform.list',
  UPDATE: 'platform.update',
  CREATE: 'platform.create',
}

export const MENU_SCRAPING_RERUNS_PERMISSIONS = {
  GET: 'menuScrapingRerun.get',
  LIST: 'menuScrapingRerun.list',
}

export const MENU_SCRAPING_TEMPLATE_PERMISSIONS = {
  ADMIN: 'menuScrapingTemplate.admin',
}

export const MENU_SCRAPING_CLUSTER_PERMISSIONS = {
  ASSIGN: 'menuScrapingCluster.assign',
  UNASSIGN: 'menuScrapingCluster.unassign',
  ADMIN: 'menuScrapingCluster.admin',
}
