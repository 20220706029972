import * as Yup from 'yup'

import { LOCATION_WITH_OWN_MENU } from '../../../../brands/constants'
import { HTML_NOT_SCRAPEABLE } from '../../../../menus/constants'

const menuDefinitionTaskSchema = Yup.object().shape({
  brand: Yup.object().shape({
    hasMenu: Yup.bool(),
    menu: Yup.object().when('hasMenu', {
      is: true,
      then: Yup.object().shape({
        format: Yup.string()
          .required('Menu format is required!')
          .nullable(),

        notScrapeableReason: Yup.string()
          .when('format', {
            is: format => format === HTML_NOT_SCRAPEABLE,
            then: Yup.string().required('Not scrapeable reason is required'),
          })
          .nullable(),

        availabilityComment: Yup.string().nullable(),
      }),
    }),
  }),

  locations: Yup.array().of(
    Yup.object().shape({
      menuStatus: Yup.string().required('Menu status is required'),
      menu: Yup.object()
        .when(['menuStatus'], {
          is: menuStatus => menuStatus === LOCATION_WITH_OWN_MENU,
          then: Yup.object().shape({
            format: Yup.string()
              .required('Menu format is required')
              .nullable(),

            url: Yup.string()
              .url('Website is not valid')
              .required('Menu URL is required')
              .nullable(),

            notScrapeableReason: Yup.mixed().when('format', {
              is: HTML_NOT_SCRAPEABLE,
              then: Yup.string()
                .required('Not scrapeable reason is required')
                .nullable(),
            }),
          }),
        })
        .nullable(),
    }),
  ),
})

export default menuDefinitionTaskSchema
