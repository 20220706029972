import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Form } from 'antd'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import { Select } from '../../../../core/components'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { MENU_FORMATS, MENU_NOT_SCRAPEABLE_REASONS } from '../../../constants'
import { UPDATE_MENU_FORMAT_AND_NOT_SCRAPEABLE_REASON } from '../../../graphql/mutations'
import { menuFormatAndNoScrapeableReasonSchema } from '../../forms/schemas'

const MenuFormatAndNotScrapeableReasonFormatForm = ({
  id,
  format,
  notScrapeableReason,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: {
      format,
      notScrapeableReason,
    },
    resolver: yupResolver(menuFormatAndNoScrapeableReasonSchema),
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods

  const menuFormatOptions = useMemo(() => values(MENU_FORMATS), [])

  const menuNotScrapeableReasonOptions = useMemo(
    () => values(MENU_NOT_SCRAPEABLE_REASONS),
    [],
  )

  const [updateMenuFormatAndNotScrapeableReason, { loading }] = useMutation(
    UPDATE_MENU_FORMAT_AND_NOT_SCRAPEABLE_REASON,
    {
      onCompleted() {
        onSubmit()
        showSuccessNotification({
          message: `Successfully updated the format for menu ${id}.`,
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: `Failed to update the menu format for menu ${id}.`,
          description: message,
        })
      },
    },
  )

  const handleOnSubmit = data => {
    if (!isDirty) return
    updateMenuFormatAndNotScrapeableReason({
      variables: {
        id,
        data: {
          format: data.format,
          notScrapeableReason: data.notScrapeableReason,
        },
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        onSubmit={handleSubmit(handleOnSubmit)}
        onBlur={handleSubmit(handleOnSubmit)}
      >
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Select
            name="format"
            disabled={loading}
            options={menuFormatOptions}
            //     // onSelectChange={format => onLocationMenuFormatChange(format, index)}
          />
          <Select
            name="notScrapeableReason"
            disabled={loading}
            options={menuNotScrapeableReasonOptions}
            //     // onSelectChange={format => onLocationMenuFormatChange(format, index)}
          />
        </div>
      </Form>
    </FormProvider>
  )
}

MenuFormatAndNotScrapeableReasonFormatForm.defaultProps = {
  notScrapeableReason: null,
}

MenuFormatAndNotScrapeableReasonFormatForm.propTypes = {
  id: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  notScrapeableReason: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default MenuFormatAndNotScrapeableReasonFormatForm
