import { gql } from 'apollo-boost'

const MARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED = gql`
  mutation markMenuDefinitionTaskAsQualityChecked($id: ID!) {
    markMenuDefinitionTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default MARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED
