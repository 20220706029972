import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

import { BUSINESS_STATUSES } from '../../../constants'

const BrandBusinessStatusFilter = ({ mode }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { businessStatus } = getQueryParams(search)

  const handleChange = useCallback(
    businessStatus => {
      history.push(
        createUrl(pathname, search, {
          businessStatus,
          page: DEFAULT_PAGE,
        }),
      )
    },
    [history, pathname, search],
  )

  return (
    <Form.Item label="Business status">
      <Select
        style={{ width: '100%' }}
        value={businessStatus}
        onChange={handleChange}
        mode={mode}
        allowClear
      >
        {map(BUSINESS_STATUSES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

BrandBusinessStatusFilter.propTypes = {
  mode: PropTypes.string,
}

BrandBusinessStatusFilter.defaultProps = {
  mode: 'default',
}

export default BrandBusinessStatusFilter
