import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal, Row, Table, Typography } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { ADMIN } from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'
import {
  useIsLoggedInUser,
  useUserHasRoles,
} from '../../../../../../common/hooks'

import { MENU_STATUSES, NEW } from '../../../../../../menus/constants'
import { SCRAPING_STATUSES } from '../../../../../constants'
import { RESET_MENUS_SCRAPING_DATA_FOR_SCRAPING_CLUSTER } from '../../../../../graphql/mutations'
import { MENU_SCRAPING_CLUSTER_MENU_COUNT_BY_STATUSES } from '../../../../../graphql/queries'

const MenuStatusesTable = ({
  menuScrapingCluster,
  onMenusReset,
  isResetMenusButtonHidden,
}) => {
  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const {
    data: { menuScrapingClusterMenuCountByStatuses } = {},
    loading: isLoadingMenuCounts,
    refetch: refetchMenuCounts,
  } = useQuery(MENU_SCRAPING_CLUSTER_MENU_COUNT_BY_STATUSES, {
    variables: {
      id: menuScrapingCluster.id,
    },
    fetchPolicy: 'no-cache',
  })

  const [resetMenus, { loading: isResettingMenus }] = useMutation(
    RESET_MENUS_SCRAPING_DATA_FOR_SCRAPING_CLUSTER,
    {
      onCompleted({
        resetMenusScrapingDataForScrapingCluster: resetMenuCount,
      }) {
        refetchMenuCounts({
          id: menuScrapingCluster.id,
        })
        onMenusReset()
        showSuccessNotification({
          message: `${resetMenuCount} menus were reset.`,
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Failed to reset menus.',
          description: message,
        })
      },
    },
  )

  const handleResetMenus = async ({ menuStatus, scrapingStatus }) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'You are going to reset all menus.',
      onOk() {
        resetMenus({
          variables: { id: menuScrapingCluster.id, menuStatus, scrapingStatus },
        })
      },
    })
  }

  const menuCountsByStatuses = useMemo(
    () =>
      map(menuScrapingClusterMenuCountByStatuses, (item, index) => ({
        ...item,
        id: index,
      })),
    [menuScrapingClusterMenuCountByStatuses],
  )

  const menuStatusesTableColumns = [
    {
      title: 'Menu status',
      dataIndex: 'menuStatus',
      width: '80px',
      render: status => status && MENU_STATUSES[status].name,
    },
    {
      title: 'Scraping status',
      dataIndex: 'scrapingStatus',
      width: '80px',
      render: scrapingStatus =>
        scrapingStatus && SCRAPING_STATUSES[scrapingStatus].name,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      width: '80px',
    },
    ...(isResetMenusButtonHidden
      ? []
      : [
          {
            title: 'Actions',
            width: '300px',
            dataIndex: 'id',
            render: (id, row) => (
              <Button
                loading={isResettingMenus}
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  isResettingMenus ||
                  (!row.scrapingStatus && row.menuStatus === NEW)
                }
                onClick={() => handleResetMenus(row)}
                margin="no small no no"
              >
                Reset menus
              </Button>
            ),
          },
        ]),
  ]

  return (
    <Row>
      <Typography.Title level={3}>Menu statuses</Typography.Title>
      <Table
        rowKey="id"
        loading={isLoadingMenuCounts}
        columns={menuStatusesTableColumns}
        dataSource={menuCountsByStatuses}
      />
    </Row>
  )
}

MenuStatusesTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onMenusReset: PropTypes.func,
  isResetMenusButtonHidden: PropTypes.bool,
}

MenuStatusesTable.defaultProps = {
  onMenusReset: () => {},
  isResetMenusButtonHidden: false,
}

export default MenuStatusesTable
