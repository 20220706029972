import { gql } from 'apollo-boost'

const EXCLUDE_MENU_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS = gql`
  mutation excludeMenuFromClusterSimilarMenus($id: ID!, $menuId: ID!) {
    excludeMenuFromClusterSimilarMenus(id: $id, menuId: $menuId) {
      id
    }
  }
`

export default EXCLUDE_MENU_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS
