import { mapKeys } from 'lodash-es'

export const YES = 'Yes'
export const NO = 'No'

export const BOOLEAN_TYPES = mapKeys(
  [
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ],
  'value',
)
