import { gql } from 'apollo-boost'

const MENU_SCRAPING_TEMPLATE_INSTRUCTIONS_VERSIONS = gql`
  query menuScrapingTemplateInstructionsVersions($menuScrapingTemplateId: ID!) {
    menuScrapingTemplateInstructionsVersions(
      menuScrapingTemplateId: $menuScrapingTemplateId
    ) {
      id
      menuScrapingTemplateId
      number
      comment
      scrapingInstructions
      createdDate
      createdBy {
        id
        name
      }
    }
  }
`

export default MENU_SCRAPING_TEMPLATE_INSTRUCTIONS_VERSIONS
