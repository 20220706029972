import { basename } from 'path'

import { isNull } from 'lodash-es'

const getFileNameFromPathWithoutExtension = (path, extension) => {
  if (isNull(path)) {
    return '-'
  }

  return basename(path, extension)
}

export default getFileNameFromPathWithoutExtension
