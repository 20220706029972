import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { isEmpty, noop, stubFalse } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  convertFileListToDataUrls,
  normalizeFileForUpload,
} from '../utils/services'

const ImageUpload = ({ onUpload, onRemove, disabled, fileList, onChange }) => {
  const [isPreviewingImage, setIsPreviewingImage] = useState(false)
  const [imageDataUrl, setImageDataUrl] = useState()

  const normalizedFileList = useMemo(() => normalizeFileForUpload(fileList), [
    fileList,
  ])

  const closePreview = useCallback(() => {
    setIsPreviewingImage(false)
  }, [])

  const openPreview = useCallback(() => {
    setIsPreviewingImage(true)
  }, [setIsPreviewingImage])

  useEffect(() => {
    const initializeImageDataUrl = async () => {
      const imageDataUrls = await convertFileListToDataUrls(normalizedFileList)

      setImageDataUrl(imageDataUrls[0])
    }

    initializeImageDataUrl()
  }, [normalizedFileList])

  return (
    <div>
      <Modal
        visible={isPreviewingImage}
        footer={null}
        onCancel={closePreview}
        width="auto"
      >
        <img alt="preview" src={imageDataUrl} />
      </Modal>
      <Upload
        disabled={disabled}
        accept="image/*"
        listType="picture-card"
        fileList={normalizedFileList}
        onPreview={openPreview}
        onChange={onChange}
        beforeUpload={onUpload}
        onRemove={onRemove}
      >
        {isEmpty(normalizedFileList) && (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        )}
      </Upload>
    </div>
  )
}

ImageUpload.propTypes = {
  fileList: PropTypes.array,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
}

ImageUpload.defaultProps = {
  fileList: [],
  onUpload: stubFalse,
  onRemove: noop,
  onChange: undefined,
  disabled: false,
}

export default ImageUpload
