import { gql } from 'apollo-boost'

const MARK_MENU_AS_SCRAPING_CUSTER_CORE = gql`
  mutation markMenuAsScrapingClusterCore($id: ID!, $menuId: ID!) {
    markMenuAsScrapingClusterCore(id: $id, menuId: $menuId) {
      id
      scrapingClusterRole
    }
  }
`

export default MARK_MENU_AS_SCRAPING_CUSTER_CORE
