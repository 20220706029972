import { includes, keys, map } from 'lodash-es'

const { ROLES } = require('../constants')

const isDeveloperUser = userEmail =>
  includes(
    map(keys(ROLES), role => `developer-${role}@foodstyles.com`),
    userEmail,
  )

export default isDeveloperUser
