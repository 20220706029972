import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import { isMenuScrapedSuccessfully } from '../../../helpers'

import ScrapingAnalysis from './scraping-analysis'
import ScrapingDebugJsonPanel from './scraping-debug-json-panel'

const ScrapingDebugInformationMenu = ({ menu }) => (
  <Collapse
    defaultActiveKey={[0, 1, 2, 3, 4, 5]}
    style={{
      overflow: 'auto',
      height: '85vh',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Menu
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Menu Structure: </strong>
            {menu.menuStructure}
          </Typography.Text>
        </List.Item>
        {menu.status && (
          <List.Item>
            <Typography.Text>
              <strong>
                {isMenuScrapedSuccessfully(menu.status)
                  ? 'Status: '
                  : 'Error Code: '}
              </strong>
              {menu.status}
            </Typography.Text>
          </List.Item>
        )}
        <List.Item>
          <Typography.Text>
            <strong>Dish Count: </strong>
            {menu.dishCount}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Start: </strong>
            {menu.timestampBegin}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping End: </strong>
            {menu.timestampEnd}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Duration: </strong>
            {menu.duration}
          </Typography.Text>
        </List.Item>
      </List>
    </Collapse.Panel>
    <Collapse.Panel header="Analysis" key={1}>
      <ScrapingAnalysis analysis={menu.analysisFull} />
    </Collapse.Panel>
    <ScrapingDebugJsonPanel
      header="Menu Annotations"
      panelId={2}
      items={menu.menuAnnotations}
    />
    <ScrapingDebugJsonPanel
      header="Messages"
      panelId={3}
      items={menu.messages}
    />
    <ScrapingDebugJsonPanel header="Flags" panelId={4} items={menu.flags} />
    <ScrapingDebugJsonPanel
      header="Exceptions"
      panelId={5}
      items={menu.exceptions}
    />
  </Collapse>
)

ScrapingDebugInformationMenu.propTypes = {
  menu: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationMenu
