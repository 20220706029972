import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Checkbox as AntCheckbox, Col, Divider, Modal, Row } from 'antd'
import fileDownload from 'js-file-download'
import { forEach } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormDate,
  FormText,
  HiddenInputs,
} from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { HistoryDrawer, Spinner } from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  ADMIN,
  MENU_SCRAPING_CLUSTER_PERMISSIONS,
} from '../../../common/constants'
import { MENU_SCRAPING_CLUSTER } from '../../../common/constants/entity-types'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../common/hooks'

import { AuthContext } from '../../../account/contexts'
import { DONE } from '../../../menus/constants'
import {
  ASSIGN_MENU_SCRAPING_CLUSTER,
  START_NEW_RERUN_FLOW_FOR_MENU_SCRAPING_CLUSTER,
  UNASSIGN_MENU_SCRAPING_CLUSTER,
  UPDATE_MENU_SCRAPING_CLUSTER,
} from '../../graphql/mutations'
import { transformToUpdateMenuScrapingClusterInput } from '../../graphql/transformers'
import { getFingerprintsFile } from '../../helpers'
import { useCanUserAssignCluster, useCanUserUnassignCluster } from '../../hooks'
import {
  GenerateScrapingClusterMenusReportButton,
  MenuScrapingClusterExpectedMenuFormatDropdown,
  MenuScrapingClustersDropdown,
  MenuScrapingTemplatesDropdown,
  PlatformsList,
} from '../pages/sections'

const UNASSIGNED_PLACEHOLDER = 'Assign to me'

const MenuScrapingClusterForm = ({
  menuScrapingCluster,
  refetchMenuScrapingCluster,
}) => {
  const history = useHistory()

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const templateCanBeDetached =
    !menuScrapingCluster.rerunFlow ||
    menuScrapingCluster.rerunFlow?.currentStep?.type === DONE

  const expectedMenuFormatCanBeChanged =
    !menuScrapingCluster.rerunFlow ||
    menuScrapingCluster.rerunFlow?.currentStep?.type === DONE

  const [
    updateMenuScrapingCluster,
    { loading: isUpdatingCluster },
  ] = useMutation(UPDATE_MENU_SCRAPING_CLUSTER, {
    onCompleted() {
      refetchMenuScrapingCluster()
      showSuccessNotification({
        message: 'Menu scraping cluster updated.',
        description: 'Menu scraping cluster has been successfully updated.',
      })
    },

    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping cluster update failed.',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    values => {
      const variables = transformToUpdateMenuScrapingClusterInput(values)
      return updateMenuScrapingCluster({ variables })
    },
    [updateMenuScrapingCluster],
  )

  const formMethods = useForm({
    defaultValues: menuScrapingCluster,
  })

  const {
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    watch,
  } = formMethods

  const handleOnSubmit = e => {
    e.preventDefault()

    const {
      menuScrapingTemplate: prevMenuScrapingTemplate,
    } = menuScrapingCluster

    const { menuScrapingTemplate: newMenuScrapingTemplate } = getValues()

    const prevMenuScrapingTemplateId = prevMenuScrapingTemplate?.id
    const newMenuScrapingTemplateId = newMenuScrapingTemplate?.id

    if (prevMenuScrapingTemplateId !== newMenuScrapingTemplateId) {
      Modal.confirm({
        title: 'Are you sure?',
        content:
          'Changing the menu scraping template will reset all menu statuses.',
        onOk() {
          handleFormSubmit(handleSubmit)()
        },
      })
    } else {
      handleFormSubmit(handleSubmit)()
    }
  }

  const { user: loggedInUser } = useContext(AuthContext)

  const canUserAssignCluster = useCanUserAssignCluster(
    menuScrapingCluster,
    MENU_SCRAPING_CLUSTER_PERMISSIONS.ASSIGN,
    MENU_SCRAPING_CLUSTER_PERMISSIONS.ADMIN,
  )

  const canUserUnassignCluster = useCanUserUnassignCluster(
    menuScrapingCluster,
    MENU_SCRAPING_CLUSTER_PERMISSIONS.UNASSIGN,
    MENU_SCRAPING_CLUSTER_PERMISSIONS.ADMIN,
  )

  const [isAssigned, setIsAssigned] = useState(!!menuScrapingCluster.assignedTo)
  const [assignedToCheckboxText, setAssignedToCheckboxText] = useState(
    menuScrapingCluster.assignedTo?.name || UNASSIGNED_PLACEHOLDER,
  )

  const [
    assignMenuScrapingCluster,
    { loading: isAssigningCluster },
  ] = useMutation(ASSIGN_MENU_SCRAPING_CLUSTER, {
    onCompleted({ assignMenuScrapingCluster: { modifiedDate } }) {
      showSuccessNotification({
        message: 'Cluster assigned.',
      })

      refetchMenuScrapingCluster()
      setAssignedToCheckboxText(loggedInUser.name)
      setValue('modifiedDate', modifiedDate)
      setValue('modifiedBy.name', loggedInUser.name)
    },
    onError({ message }) {
      setIsAssigned(false)
      showErrorNotification({
        message: 'Cluster assign failed.',
        description: message,
      })
    },
  })

  const [
    unassignMenuScrapingCluster,
    { loading: isUnassigningCluster },
  ] = useMutation(UNASSIGN_MENU_SCRAPING_CLUSTER, {
    onCompleted({ unassignMenuScrapingCluster: { modifiedDate } }) {
      showSuccessNotification({
        message: 'Cluster unassigned.',
      })

      refetchMenuScrapingCluster()
      setAssignedToCheckboxText(UNASSIGNED_PLACEHOLDER)
      setValue('modifiedDate', modifiedDate)
      setValue('modifiedBy.name', loggedInUser.name)
    },
    onError({ message }) {
      setIsAssigned(true)
      showErrorNotification({
        message: 'Cluster unassign failed.',
        description: message,
      })
    },
  })

  const [startNewRerunFlow, { loading: isStartingNewRerunFlow }] = useMutation(
    START_NEW_RERUN_FLOW_FOR_MENU_SCRAPING_CLUSTER,
    {
      onCompleted() {
        refetchMenuScrapingCluster()
        setValue('nextRerunStartDate', menuScrapingCluster.nextRerunStartDate)

        showSuccessNotification({
          message: 'New rerun flow started successfully.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Start new rerun flow failed.',
          description: message,
        })
      },
    },
  )

  const onAssignMenuScrapingCluster = useCallback(() => {
    setIsAssigned(true)
    assignMenuScrapingCluster({
      variables: { id: menuScrapingCluster.id, toUserId: loggedInUser.id },
    })
  }, [assignMenuScrapingCluster, menuScrapingCluster, loggedInUser])

  const onUnassignMenuScrapingCluster = useCallback(() => {
    setIsAssigned(false)
    unassignMenuScrapingCluster({
      variables: { id: menuScrapingCluster.id },
    })
  }, [unassignMenuScrapingCluster, menuScrapingCluster])

  const onAssignedCheckboxChange = useCallback(
    ({ target: { checked } }) =>
      checked ? onAssignMenuScrapingCluster() : onUnassignMenuScrapingCluster(),
    [onAssignMenuScrapingCluster, onUnassignMenuScrapingCluster],
  )

  const onStartNewRerunFlow = useCallback(() => {
    const onOk = () =>
      startNewRerunFlow({
        variables: { id: menuScrapingCluster.id },
      })

    Modal.confirm({
      title: 'Are you sure you want to start a new rerun flow?',
      content: 'All menus attached to this cluster will be reset.',
      onOk,
    })
  }, [startNewRerunFlow, menuScrapingCluster])

  useEffect(() => {
    setValue('nextRerunStartDate', menuScrapingCluster.nextRerunStartDate, {
      shouldValidate: true,
    })
  }, [menuScrapingCluster.nextRerunStartDate, setValue])

  const attachedTemplate = watch('menuScrapingTemplate')

  return (
    <>
      {(isUpdatingCluster ||
        isAssigningCluster ||
        isUnassigningCluster ||
        isStartingNewRerunFlow) && <Spinner size="large" />}

      <FormProvider {...formMethods}>
        <form onSubmit={handleOnSubmit} style={{ marginBottom: 20 }}>
          <PageHeader sticky>
            <PageTitle>
              Menu Scraping Cluster
              <HistoryDrawer
                entityType={MENU_SCRAPING_CLUSTER}
                entityId={menuScrapingCluster.id}
              />
            </PageTitle>
            <PageActions>
              <AntCheckbox
                style={{ marginTop: '5px' }}
                checked={isAssigned}
                onChange={onAssignedCheckboxChange}
                disabled={
                  isAssigned ? !canUserUnassignCluster : !canUserAssignCluster
                }
              >
                {assignedToCheckboxText}
              </AntCheckbox>
              <Button
                disabled={!isLoggedInUser && !isAdmin}
                type="primary"
                htmlType="submit"
                margin="no small no small"
              >
                Save
              </Button>
              <GenerateScrapingClusterMenusReportButton
                scrapingClusterId={menuScrapingCluster.id}
              />
              <Button
                margin="no no no small"
                onClick={() => {
                  forEach(
                    menuScrapingCluster.menuScrapingClusterFingerprints,
                    menuScrapingClusterFingerprint => {
                      fileDownload(
                        getFingerprintsFile(menuScrapingClusterFingerprint),
                        `menu-scraping-cluster-fingerprints-${menuScrapingCluster.id}-menu-${menuScrapingClusterFingerprint.menuId}.json`,
                      )
                    },
                  )
                }}
              >
                Download fingerprints
              </Button>
              <Button
                onClick={() =>
                  history.push('/menu-scraping-templates/clusters')
                }
                margin="no no no small"
              >
                Go back
              </Button>
            </PageActions>
          </PageHeader>

          <Row gutter={16}>
            <Col span={1}>
              <FormText name="id" label="ID" />
            </Col>
            <Col span={3}>
              <PlatformsList name="platforms" label="Platform(s)" />
            </Col>
            <Col span={4}>
              <MenuScrapingTemplatesDropdown
                name="menuScrapingTemplate"
                disabled={
                  (!isLoggedInUser && !isAdmin) || !templateCanBeDetached
                }
              />
            </Col>
            <Col span={3}>
              <MenuScrapingClusterExpectedMenuFormatDropdown
                name="expectedMenuFormat"
                label="Expected menu format"
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  !expectedMenuFormatCanBeChanged
                }
              />
            </Col>
            <Col span={3}>
              <Checkbox
                label="Working hours irrelevant"
                name="areWorkingHoursIrrelevant"
                disabled={!isLoggedInUser && !isAdmin}
              />
            </Col>
            <Col span={2}>
              <MenuScrapingClustersDropdown
                name="masterScrapingCluster"
                label="Master cluster"
                currentMenuScrapingClusterId={menuScrapingCluster.id}
                disabled={!isLoggedInUser && !isAdmin}
              />
            </Col>
            <Col span={3}>
              <FormDate name="createdDate" label="Created at" />
            </Col>
            <Col span={3}>
              <FormDate name="modifiedDate" label="Modified at" />
            </Col>
            <Col span={2}>
              <FormText name="modifiedBy.name" label="Modified by" />
            </Col>
          </Row>
          <Divider type="horizontal" />
          <Row>
            <Col span={2} offset={0}>
              <FormText
                name="averageScrapingDuration"
                label="Average scraping duration"
                valuePostfix="s"
              />
            </Col>
            <Col span={2} offset={1}>
              <FormDate
                name="oldestMenuScrapedDate"
                label="Oldest menu scraped date"
              />
            </Col>
            <Col span={2} offset={1}>
              <FormDate
                name="nextRerunStartDate"
                label="Next rerun start date"
              />
            </Col>
            <Col span={2} offset={1}>
              <Button
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  !attachedTemplate?.id ||
                  !menuScrapingCluster.menuScrapingTemplate ||
                  (menuScrapingCluster.rerunFlow &&
                    !menuScrapingCluster.rerunFlow?.finishedDate)
                }
                type="primary"
                style={{ marginTop: '12px' }}
                onClick={onStartNewRerunFlow}
              >
                Start new rerun flow
              </Button>
            </Col>
            <Divider type="horizontal" />
          </Row>
          <HiddenInputs names={['id']} />
        </form>
      </FormProvider>
    </>
  )
}

MenuScrapingClusterForm.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  refetchMenuScrapingCluster: PropTypes.func.isRequired,
}

export default MenuScrapingClusterForm
