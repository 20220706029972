import { useContext } from 'react'

import { AuthContext } from '../../account/contexts'

const useIsLoggedInUser = user => {
  const { user: loggedInUser } = useContext(AuthContext)
  if (!user) return false
  return Number(user.id) === Number(loggedInUser.id)
}

export default useIsLoggedInUser
