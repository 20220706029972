import React from 'react'
import { Layout } from 'antd'
import PropTypes from 'prop-types'

import NavigationBar from './navigation-bar'
import { Page as PageContainer, PageContent } from './styled'

const Page = ({ children }) => {
  return (
    <Layout>
      <PageContainer>
        <NavigationBar />
        <PageContent>
          <Layout.Content>{children}</Layout.Content>
        </PageContent>
      </PageContainer>
    </Layout>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
