import { gql } from 'apollo-boost'

const UNMARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED = gql`
  mutation unmarkBrandGatewayTaskAsQualityChecked($id: ID!) {
    unmarkBrandGatewayTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default UNMARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED
