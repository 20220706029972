import React, { useCallback, useContext } from 'react'
import { Redirect, Route, withRouter } from 'react-router'
import RouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { AuthContext } from '../../contexts'

const GuestRoute = ({ location, component: Component, ...rest }) => {
  const { user } = useContext(AuthContext)

  const renderComponent = useCallback(
    props => {
      if (!user) return <Component {...props} />

      return <Redirect to={{ pathname: '/users' }} />
    },
    [user],
  )

  return <Route {...rest} render={renderComponent} />
}

GuestRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: RouterPropTypes.location.isRequired,
}

export default withRouter(GuestRoute)
