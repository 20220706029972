import { gql } from 'apollo-boost'

const RESET_MENUS_SCRAPING_DATA_FOR_SCRAPING_CLUSTER = gql`
  mutation resetMenusScrapingDataForScrapingCluster(
    $id: ID!
    $menuStatus: MenuStatus!
    $scrapingStatus: ScrapingStatus
  ) {
    resetMenusScrapingDataForScrapingCluster(
      id: $id
      menuStatus: $menuStatus
      scrapingStatus: $scrapingStatus
    )
  }
`

export default RESET_MENUS_SCRAPING_DATA_FOR_SCRAPING_CLUSTER
