import { gql } from 'apollo-boost'

const REOPEN_MENU_DEFINITION_TASK = gql`
  mutation reopenMenuDefinitionTask($id: ID!) {
    reopenMenuDefinitionTask(id: $id) {
      id
      modifiedDate
      reopenedDate
    }
  }
`

export default REOPEN_MENU_DEFINITION_TASK
