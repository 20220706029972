import React from 'react'
import GoogleLogin from 'react-google-login'
import PropTypes from 'prop-types'

import { getEnvironmentVariable } from '../../../core/helpers'

import { showErrorNotification } from '../../../common/helpers'

const onFailure = () =>
  showErrorNotification({
    message: 'Login with google failed.',
  })

const GoogleLoginButton = ({ onSuccess }) => {
  const clientId = getEnvironmentVariable('REACT_APP_GOOGLE_CLIENT_ID')

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
    />
  )
}

GoogleLoginButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default GoogleLoginButton
