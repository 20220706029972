import React, { useCallback } from 'react'
import { Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Spin, Tabs } from 'antd'
import { map } from 'lodash-es'

import { Panel } from '../../../core/components/styled'

import { PageOverlay } from '../../../common/components/styled'
import {
  BRAND_DEFINITION_TASKS_PERMISSIONS,
  BRAND_GATEWAY_TASKS_PERMISSIONS,
  DISH_REVIEW_TASKS_PERMISSIONS,
  MENU_DEFINITION_TASKS_PERMISSIONS,
  MENU_SCRAPING_TASKS_PERMISSIONS,
} from '../../../common/constants'
import { useFilterOnPermission } from '../../../common/hooks'
import { useLastPathnameSegment } from '../../../common/hooks/router'
import { getCachedFilterForPage } from '../../../common/services'

import { PermissionRoute } from '../../../account/components/routes'
import { TASK_TYPES } from '../../constants'
import { TASK_COUNTS_BY_TYPE } from '../../graphql/queries'

import {
  BrandDefinitionTasksFilters,
  BrandGatewayTasksFilters,
  DishReviewTasksFilters,
  MenuDefinitionTasksFilters,
  MenuScrapingTasksFilters,
} from './sections'
import {
  BrandDefinitionTasksPage,
  BrandGatewayTasksPage,
  DishReviewTasksPage,
  MenuDefinitionTasksPage,
  MenuScrapingTasksPage,
} from '.'

const tasksTabs = (taskCountsByType = {}) => [
  {
    name: `${TASK_TYPES.BRAND_GATEWAY.name} (${taskCountsByType.brandGatewayTask})`,
    key: TASK_TYPES.BRAND_GATEWAY.key,
    permissions: [BRAND_GATEWAY_TASKS_PERMISSIONS.LIST],
  },
  {
    name: `${TASK_TYPES.BRAND_DEFINITION.name} (${taskCountsByType.brandDefinitionTask})`,
    key: TASK_TYPES.BRAND_DEFINITION.key,
    permissions: [BRAND_DEFINITION_TASKS_PERMISSIONS.LIST],
  },
  {
    name: `${TASK_TYPES.MENU_DEFINITION.name} (${taskCountsByType.menuDefinitionTask})`,
    key: TASK_TYPES.MENU_DEFINITION.key,
    permissions: [MENU_DEFINITION_TASKS_PERMISSIONS.LIST],
  },
  {
    name: `${TASK_TYPES.MENU_SCRAPING.name} (${taskCountsByType.menuScrapingTask})`,
    key: TASK_TYPES.MENU_SCRAPING.key,
    permissions: [MENU_SCRAPING_TASKS_PERMISSIONS.LIST],
  },
  {
    name: `${TASK_TYPES.DISH_REVIEW.name} (${taskCountsByType.dishReviewTask})`,
    key: TASK_TYPES.DISH_REVIEW.key,
    permissions: [DISH_REVIEW_TASKS_PERMISSIONS.LIST],
  },
]

const TasksPage = () => {
  const currentTab = useLastPathnameSegment()

  const history = useHistory()

  const {
    data: { taskCountsByType } = {},
    loading: isLoadingTaskCounts,
  } = useQuery(TASK_COUNTS_BY_TYPE, {
    fetchPolicy: 'no-cache',
  })

  const handleChangeTab = useCallback(
    tabKey => history.push(getCachedFilterForPage(`/tasks/${tabKey}`)),
    [history],
  )

  const tasksTabsOnPermissions = useFilterOnPermission(
    tasksTabs(taskCountsByType),
  )

  return isLoadingTaskCounts ? (
    <PageOverlay>
      <Spin tip="Loading..." />
    </PageOverlay>
  ) : (
    <Panel margin="small no">
      <Tabs type="card" activeKey={currentTab} onChange={handleChangeTab}>
        {map(tasksTabsOnPermissions, tab => (
          <Tabs.TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>

      {currentTab === TASK_TYPES.BRAND_GATEWAY.key && (
        <BrandGatewayTasksFilters />
      )}

      {currentTab === TASK_TYPES.BRAND_DEFINITION.key && (
        <BrandDefinitionTasksFilters />
      )}

      {currentTab === TASK_TYPES.MENU_DEFINITION.key && (
        <MenuDefinitionTasksFilters />
      )}

      {currentTab === TASK_TYPES.MENU_SCRAPING.key && (
        <MenuScrapingTasksFilters />
      )}

      {currentTab === TASK_TYPES.DISH_REVIEW.key && <DishReviewTasksFilters />}

      <Switch>
        <PermissionRoute
          permissions={[BRAND_GATEWAY_TASKS_PERMISSIONS.LIST]}
          path="/tasks/brand-gateway"
          component={BrandGatewayTasksPage}
        />

        <PermissionRoute
          permissions={[BRAND_DEFINITION_TASKS_PERMISSIONS.LIST]}
          path="/tasks/brand-definition"
          component={BrandDefinitionTasksPage}
        />

        <PermissionRoute
          permissions={[MENU_DEFINITION_TASKS_PERMISSIONS.LIST]}
          path="/tasks/menu-definition"
          component={MenuDefinitionTasksPage}
        />

        <PermissionRoute
          permissions={[MENU_SCRAPING_TASKS_PERMISSIONS.LIST]}
          path="/tasks/menu-scraping"
          component={MenuScrapingTasksPage}
        />

        <PermissionRoute
          permissions={[DISH_REVIEW_TASKS_PERMISSIONS.LIST]}
          path="/tasks/dish-review"
          component={DishReviewTasksPage}
        />
      </Switch>
    </Panel>
  )
}

export default TasksPage
