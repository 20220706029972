import { gql } from 'apollo-boost'

const GET_ROLES = gql`
  query roles {
    roles {
      id
      name
    }
  }
`

export default GET_ROLES
