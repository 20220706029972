import { gql } from 'apollo-boost'

const MENUS = gql`
  query menus($filters: MenuFiltersInput!, $page: Int, $pageSize: Int) {
    menus(filters: $filters, page: $page, pageSize: $pageSize) {
      menus {
        id
        url
        modifiedDate
        scrapingInstructions
        brand {
          id
          name
        }
        locationCount
        dishCount
        structure
        fullAnnotations
      }
      total
    }
  }
`

export default MENUS
