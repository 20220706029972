import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Icon, Row, Table, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { DEFAULT_PAGE } from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'

import { ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER } from '../../../../../graphql/mutations'
import { MENUS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER } from '../../../../../graphql/queries'

const MenusManuallyRemovedFromClusterMenusTable = ({
  menuScrapingCluster,
  isHidden,
  toggleIsHidden,
  onAttachMenuBackToClusterMenus,
  isAttachMenuBackToClusterButtonHidden,
}) => {
  const [
    manuallyRemovedFromClusterMenusPage,
    setManuallyRemovedFromClusterMenusPage,
  ] = useState(DEFAULT_PAGE)

  const [
    refetchManuallyRemovedFromClusterMenus,
    {
      data: {
        menusManuallyRemovedFromScrapingCluster: {
          menus: manuallyRemovedFromClusterMenus = [],
          total: manuallyRemovedFromClusterMenusTotal = 0,
        } = {},
      } = {},
      loading: isLoadingManuallyRemovedFromClusterMenus,
    },
  ] = useLazyQuery(MENUS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER, {
    variables: {
      id: menuScrapingCluster.id,
      page: manuallyRemovedFromClusterMenusPage,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!isHidden) {
      refetchManuallyRemovedFromClusterMenus({
        id: menuScrapingCluster.id,
        page: manuallyRemovedFromClusterMenusPage,
      })
    }
  }, [
    isHidden,
    menuScrapingCluster.id,
    manuallyRemovedFromClusterMenusPage,
    refetchManuallyRemovedFromClusterMenus,
  ])

  const [attachMenuBackToCluster] = useMutation(
    ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER,
    {
      onCompleted() {
        onAttachMenuBackToClusterMenus()
        refetchManuallyRemovedFromClusterMenus({
          id: menuScrapingCluster.id,
          page: manuallyRemovedFromClusterMenusPage,
        })
        showSuccessNotification({
          message: 'Menu attached back to menu scraping cluster.',
          description:
            'Menu has been successfully attached back to menu scraping cluster.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu attach back to menu scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  const manuallyRemovedFromClusterMenusTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '40px',
      render: id => <Link to={`/menus/${id}`}>{id}</Link>,
    },
    {
      title: 'Brand',
      dataIndex: 'brand.name',
      width: '150px',
    },
    {
      title: 'Website',
      dataIndex: 'url',
      width: '200px',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    ...(isAttachMenuBackToClusterButtonHidden
      ? []
      : [
          {
            title: 'Actions',
            width: '100px',
            dataIndex: 'id',
            render: id => (
              <Button
                type="primary"
                onClick={() =>
                  attachMenuBackToCluster({
                    variables: {
                      menuId: id,
                      id: menuScrapingCluster.id,
                    },
                  })
                }
                margin="no small no no"
              >
                Attach back to cluster
              </Button>
            ),
          },
        ]),
  ]

  return (
    <Row>
      <div style={{ display: 'flex' }}>
        <Typography.Title level={3}>
          {`Manually removed menus`}
          {!isHidden && ` (${manuallyRemovedFromClusterMenusTotal})`}
        </Typography.Title>
        <Button onClick={toggleIsHidden} style={{ marginLeft: 'auto' }}>
          <Icon type={!isHidden ? 'up' : 'down'} />
        </Button>
      </div>
      {!isHidden && (
        <Table
          rowKey="id"
          loading={isLoadingManuallyRemovedFromClusterMenus}
          columns={manuallyRemovedFromClusterMenusTableColumns}
          dataSource={manuallyRemovedFromClusterMenus}
          pagination={{
            current: manuallyRemovedFromClusterMenusPage,
            pageSize: 10,
            total: manuallyRemovedFromClusterMenusTotal,
            onChange: setManuallyRemovedFromClusterMenusPage,
          }}
        />
      )}
    </Row>
  )
}

MenusManuallyRemovedFromClusterMenusTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  toggleIsHidden: PropTypes.func,
  onAttachMenuBackToClusterMenus: PropTypes.func,
  isAttachMenuBackToClusterButtonHidden: PropTypes.bool,
}

MenusManuallyRemovedFromClusterMenusTable.defaultProps = {
  isHidden: false,
  toggleIsHidden: () => {},
  onAttachMenuBackToClusterMenus: () => {},
  isAttachMenuBackToClusterButtonHidden: false,
}

export default MenusManuallyRemovedFromClusterMenusTable
