import { gql } from 'apollo-boost'

const MENUS_EXCLUDED_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS = gql`
  query menusExcludedFromMenuScrapingClusterSimilarMenus(
    $id: ID!
    $page: Int
    $pageSize: Int
  ) {
    menusExcludedFromMenuScrapingClusterSimilarMenus(
      id: $id
      page: $page
      pageSize: $pageSize
    ) {
      menus {
        id
        url
        brand {
          id
          name
        }
      }
      total
    }
  }
`

export default MENUS_EXCLUDED_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS
