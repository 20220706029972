import { mapKeys } from 'lodash-es'

export const OPERATIONAL = 'OPERATIONAL'
export const CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY'
export const CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY'
export const FUTURE_OPENING = 'FUTURE_OPENING'

export const BUSINESS_STATUSES = mapKeys(
  [
    { name: 'Operational', value: OPERATIONAL },
    { name: 'Closed temp.', value: CLOSED_TEMPORARILY },
    { name: 'Closed perm.', value: CLOSED_PERMANENTLY },
    { name: 'Future opening', value: FUTURE_OPENING },
  ],
  'value',
)
