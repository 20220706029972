import { gql } from 'apollo-boost'

const APPLY_NEW_FINGERPRINTS_FROM_LEAD_TO_CLUSTER = gql`
  mutation applyNewFingerprintsFromLeadToCluster($menuProbingJobId: ID!) {
    applyNewFingerprintsFromLeadToCluster(menuProbingJobId: $menuProbingJobId) {
      id
    }
  }
`

export default APPLY_NEW_FINGERPRINTS_FROM_LEAD_TO_CLUSTER
