import http from '../../core/services/http'

export const generateScrapingAttemptsReport = ({
  userIds,
  startDate,
  endDate,
  includeAllAttempts,
}) =>
  http.get(
    `/reports/scraping-attempts?userIds=${userIds}&startDate=${startDate}&endDate=${endDate}&includeAllAttempts=${includeAllAttempts}`,
    { responseType: 'blob' },
  )
