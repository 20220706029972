import React, { useCallback } from 'react'
import { Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'
import { map } from 'lodash-es'

import { Panel } from '../../../core/components/styled'

import { REPORTS_PERMISSIONS } from '../../../common/constants'
import { useFilterOnPermission } from '../../../common/hooks'
import { useLastPathnameSegment } from '../../../common/hooks/router'
import { getCachedFilterForPage } from '../../../common/services'

import { PermissionRoute } from '../../../account/components/routes'
import { REPORT_TYPES } from '../../constants'

import {
  ChangedMenuUrlsReportPage,
  CountsReportPage,
  MenuReportPage,
  MenusInProbingStatusReportPage,
  MiscTasksVerificationReportPage,
  OpenTasksReportPage,
  QualityCheckedReportPage,
  ScrapingAttemptsReportPage,
  ScrapingClustersReportPage,
  ScrapingEngineReportPage,
  ScrapingIssueReportPage,
  ScrapingVerificationReportPage,
  UserCountsReportPage,
  UserReportPage,
} from '.'

const ReportsPage = () => {
  const history = useHistory()
  const currentTab = useLastPathnameSegment()

  const handleChangeTab = useCallback(
    tabKey => history.push(getCachedFilterForPage(`/reports/${tabKey}`)),
    [history],
  )

  const tasksTabsOnPermissions = useFilterOnPermission(REPORT_TYPES)

  return (
    <Panel margin="small no">
      <Tabs
        type="card"
        defaultActiveKey={currentTab}
        onChange={handleChangeTab}
      >
        {map(tasksTabsOnPermissions, tab => (
          <Tabs.TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>
      <Switch>
        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_USERS}
          exact
          path="/reports/user"
          component={UserReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_USER_COUNTS}
          exact
          path="/reports/user-counts"
          component={UserCountsReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_MENUS_HAVING_ALLERGENS}
          exact
          path="/reports/menus-having-allergens"
          component={MenuReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ENGINE}
          exact
          path="/reports/scraping-engine"
          component={ScrapingEngineReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ATTEMPTS}
          exact
          path="/reports/scraping-attempts"
          component={ScrapingAttemptsReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_SCRAPING_VERIFICATION}
          exact
          path="/reports/scraping-verification"
          component={ScrapingVerificationReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_SCRAPING_ISSUE}
          exact
          path="/reports/scraping-issue"
          component={ScrapingIssueReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_COUNTS}
          exact
          path="/reports/counts"
          component={CountsReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_QUALITY_CHECKED}
          exact
          path="/reports/quality-checked"
          component={QualityCheckedReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_SCRAPING_CLUSTERS}
          exact
          path="/reports/scraping-clusters"
          component={ScrapingClustersReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_OPEN_TASKS}
          exact
          path="/reports/open-tasks"
          component={OpenTasksReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_CHANGED_MENU_URLS}
          exact
          path="/reports/changed-menu-urls"
          component={ChangedMenuUrlsReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_MENUS_IN_PROBING_STATUS}
          exact
          path="/reports/menus-in-probing-status"
          component={MenusInProbingStatusReportPage}
        />

        <PermissionRoute
          permissions={REPORTS_PERMISSIONS.GET_FOR_MISC_TASKS_VERIFICATION}
          exact
          path="/reports/misc-tasks-verification"
          component={MiscTasksVerificationReportPage}
        />
      </Switch>
    </Panel>
  )
}

export default ReportsPage
