import { mapKeys } from 'lodash-es'

export const NEW = 'NEW'
export const EXCLUDED = 'EXCLUDED'
export const INCLUDED = 'INCLUDED'

export const BRAND_STATUSES = mapKeys(
  [
    { name: 'New', value: NEW },
    { name: 'Excluded', value: EXCLUDED },
    { name: 'Included', value: INCLUDED },
  ],
  'value',
)
