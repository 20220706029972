import { find, map } from 'lodash-es'

const buildMenuTitlePathForMenuTitleId = (menuTitleId, menuTitles, path) => {
  if (!menuTitleId) return ''

  const menuTitle = find(menuTitles, ({ id }) => id === menuTitleId)
  const newPath = path ? `${menuTitle.title} / ${path}` : menuTitle.title

  return menuTitle.parentId
    ? buildMenuTitlePathForMenuTitleId(menuTitle.parentId, menuTitles, newPath)
    : newPath
}

const attachMenuTitlePathToDishes = (dishes, menuTitles) =>
  map(dishes, ({ menuTitleId, ...values }) => ({
    ...values,
    menuTitleId,
    menuTitlePath: buildMenuTitlePathForMenuTitleId(menuTitleId, menuTitles),
  }))

export default attachMenuTitlePathToDishes
