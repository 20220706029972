import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { PLATFORM_PERMISSIONS } from '../../../../common/constants'

import { PermissionGuard } from '../../../../account/components/routes'

const PlatformsTableActionColumn = ({ platform }) => {
  const history = useHistory()

  return (
    <PermissionGuard permissions={PLATFORM_PERMISSIONS.UPDATE}>
      <Button
        onClick={() => history.push(`/settings/platforms/${platform.id}`)}
        margin="no small"
      >
        Edit
      </Button>
    </PermissionGuard>
  )
}

PlatformsTableActionColumn.propTypes = {
  platform: PropTypes.object.isRequired,
}

export default PlatformsTableActionColumn
