import { gql } from 'apollo-boost'

const SAVE_BRAND_DEFINITION_TASK = gql`
  mutation saveBrandDefinitionTask(
    $id: ID!
    $brandData: SaveBrandDefinitionTaskBrandInput
    $locationsData: [SaveBrandDefinitionTaskLocationInput!]!
  ) {
    saveBrandDefinitionTask(
      id: $id
      brandData: $brandData
      locationsData: $locationsData
    ) {
      id
    }
  }
`

export default SAVE_BRAND_DEFINITION_TASK
