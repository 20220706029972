import { map } from 'lodash-es'

const transformToSaveCommonWebsitesInput = (
  newDomainWebsites,
  updatedDomainsWebsites,
  removedDomainWebsites,
) => {
  const newCommonWebsiteDomains = map(newDomainWebsites, 'websiteDomain')
  const removedCommonWebsiteDomainIds = map(removedDomainWebsites, 'id')

  return {
    newBulkData: newCommonWebsiteDomains,
    updatedBulkData: updatedDomainsWebsites,
    removedIds: removedCommonWebsiteDomainIds,
  }
}

export default transformToSaveCommonWebsitesInput
