import { mapKeys } from 'lodash-es'

export const SUCCESS = 'Success'
export const PARTIAL_SUCCESS = 'PartialSuccess'

export const MENU_SCRAPING_STATUSES = mapKeys(
  [
    { name: 'Success', value: SUCCESS },
    { name: 'Partial success', value: PARTIAL_SUCCESS },
  ],
  'value',
)
