import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Form } from 'antd'
import PropTypes from 'prop-types'

import { EMPTY_VALUE } from '../../common/constants'

const FormInternalLink = ({ name, label, transform }) => {
  const { control } = useFormContext()
  return (
    <Form.Item label={label}>
      <Controller
        control={control}
        name={name}
        render={({ value }) =>
          value ? <Link to={transform(value)}>{value}</Link> : EMPTY_VALUE
        }
      />
    </Form.Item>
  )
}

FormInternalLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  transform: PropTypes.func.isRequired,
}

FormInternalLink.defaultProps = {
  label: '',
}

export default FormInternalLink
