import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Col, Collapse, Form as AntForm, Icon, Modal, Row } from 'antd'
import {
  filter,
  has,
  includes,
  isEmpty,
  keys,
  map,
  mergeWith,
  size,
  slice,
  uniqBy,
  values,
} from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  Checkbox,
  FormDate,
  FormInternalLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
  Input,
  Select,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  HistoryDrawer,
  LockedStatus,
  OpenLink,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  BRAND,
  BRAND_GATEWAY_TASK,
  BRAND_GATEWAY_TASKS_PERMISSIONS,
  DEFAULT_PAGE_SIZE,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../../common/helpers'

import { AuthContext } from '../../../account/contexts'
import {
  BrandsDropdown,
  CreateBrandModal,
} from '../../../brands/components/pages/sections'
import {
  BRAND_EXCLUSION_REASONS,
  BRAND_RANKS,
  BRAND_STATUSES,
  EXCLUDED,
  INACCESSIBLE_URL,
  INCLUDED,
  MASTER,
  MISC,
  NEW,
  SLAVE,
} from '../../../brands/constants'
import { NO_INCLUDED_LOCATIONS } from '../../../brands/constants/brand-exclusion-reasons'
import {
  BRAND_GATEWAY,
  MANUAL_CHANGE,
  REOPENED,
  RESOLVED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
} from '../../constants'
import {
  ASSIGN_BRAND_GATEWAY_TASK,
  MARK_BRAND_GATEWAY_TASK_AS_AWAITING,
  MARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED,
  MARK_BRAND_GATEWAY_TASK_MISC_AS_QC_VERIFIED,
  MARK_BRAND_GATEWAY_TASK_MISC_AS_VERIFIED,
  REOPEN_BRAND_GATEWAY_TASK,
  UNASSIGN_BRAND_GATEWAY_TASK,
  UNMARK_BRAND_GATEWAY_TASK_AS_AWAITING,
  UNMARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForBrandGatewayTaskPage } from '../../helpers'
import { mergeFormValues } from '../../utils'
import {
  AwaitingReasonField,
  BrandExclusionCommentModal,
  BrandGatewayLocations,
  BrandGatewayRelatedBrands,
  BrandGatewaySimilarBrandsByLocationWebsite,
  BrandGatewaySimilarBrandsByName,
  BrandGatewaySimilarMasterBrandsByLocationWebsite,
  BrandGatewayTaskBrandExclusionReasonComment,
  MarkMiscTaskAsVerifiedModal,
  MiscTaskVerificationThreeStateSwitch,
  QualityCheckedSwitch,
  TaskActionSection,
} from '../pages/sections'
import BrandGatewaySimilarMasterBrandsByName from '../pages/sections/brand-gateway-similar-master-brands-by-name'

import { brandGatewayTaskState } from './global-states'
import { brandGatewayTaskSchema } from './schemas'
import { brandGatewayTaskStateValidator } from './validators'

const inclusionOptions = [
  { name: BRAND_STATUSES.INCLUDED.name, value: BRAND_STATUSES.INCLUDED.value },
  { name: BRAND_STATUSES.EXCLUDED.name, value: BRAND_STATUSES.EXCLUDED.value },
]

const getFormState = (
  taskState,
  locationsPage,
  locationsPageSize = DEFAULT_PAGE_SIZE,
  relatedBrandsPage,
  originalBrandId,
) => {
  const locations = originalBrandId
    ? filter(
        taskState.locations,
        ({ originalBrand }) => originalBrand.id === originalBrandId,
      )
    : taskState.locations

  return {
    ...taskState,
    locations: slice(
      locations,
      (locationsPage - 1) * locationsPageSize,
      (locationsPage - 1) * locationsPageSize + locationsPageSize,
    ),
    filteredLocationCount: size(locations),
    relatedBrands: slice(
      taskState.relatedBrands,
      (relatedBrandsPage - 1) * DEFAULT_PAGE_SIZE,
      (relatedBrandsPage - 1) * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE,
    ),
  }
}

const recalculateFormValues = (
  getValues,
  taskState,
  setTaskState,
  locationsPage,
  locationsPageSize,
  relatedBrandsPage,
  reset,
  originalBrandId,
  setFilteredLocationCount,
) => {
  const formValues = getValues()
  const updatedTaskState = mergeWith(taskState, formValues, mergeFormValues)
  setTaskState(updatedTaskState)

  if (originalBrandId) {
    const filteredLocations = filter(
      taskState.locations,
      ({ originalBrand }) => originalBrand.id === originalBrandId,
    )

    const formState = {
      ...taskState,
      locations: slice(
        filteredLocations,
        (locationsPage - 1) * locationsPageSize,
        (locationsPage - 1) * locationsPageSize + locationsPageSize,
      ),
      relatedBrands: slice(
        taskState.relatedBrands,
        (relatedBrandsPage - 1) * DEFAULT_PAGE_SIZE,
        (relatedBrandsPage - 1) * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE,
      ),
    }

    reset(formState)
    setFilteredLocationCount(size(filteredLocations))
  } else {
    const formState = getFormState(
      updatedTaskState,
      locationsPage,
      locationsPageSize,
      relatedBrandsPage,
      originalBrandId,
    )
    setFilteredLocationCount(size(taskState.locations))
    reset(formState)
  }
}

const getOriginalBrands = ({ locations }) =>
  uniqBy(map(locations, 'originalBrand'), 'id')

const BrandGatewayTaskForm = ({
  task,
  isRefetchingTask,
  isLoadingSimilarBrands,
  similarBrands,
  refetchSimilarBrands,
  isLoadingSimilarMasterBrandsByName,
  similarMasterBrandsByName,
  refetchSimilarMasterBrandsByName,
  isLoadingSimilarMasterBrandsByLocationWebsite,
  similarMasterBrandsByLocationWebsite,
  refetchSimilarMasterBrandsByLocationWebsite,
  isSavingTask,
  isResolvingTask,
  userLockingBrand,
  onSubmit,
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const {
    fromSlaveBrandId,
    locationsPageSize = DEFAULT_PAGE_SIZE,
  } = getQueryParams(search)

  const setLocationsPageSize = useCallback(
    locationsPageSize =>
      history.push(createUrl(pathname, search, { locationsPageSize })),
    [history, search, pathname],
  )

  const { user: loggedInUser } = useContext(AuthContext)

  const [locationsPage, setLocationsPage] = useState(1)
  const [relatedBrandsPage, setRelatedBrandsPage] = useState(1)
  const [originalBrandId, setOriginalBrandId] = useState(
    fromSlaveBrandId && String(fromSlaveBrandId),
  )
  const [allLocationsStatus, setAllLocationsStatus] = useState()

  const [cachedBrandWebsiteValue, setCachedBrandWebsiteValue] = useState(
    task.brand.website,
  )

  const [isExclusionReasonModalOpen, setIsExclusionCommentModalOpen] = useState(
    false,
  )

  const [isCreateBrandModalOpen, setIsCreateBrandModalOpen] = useState(false)

  const [isQCVerified, setIsQCVerified] = useState(false)

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const taskStateCurrentValues = useMemo(
    () => brandGatewayTaskState(task, similarBrands),
    [task, similarBrands],
  )

  const [taskState, setTaskState] = useState(taskStateCurrentValues)

  const [filteredLocationCount, setFilteredLocationCount] = useState(
    size(taskState.locations),
  )

  const formMethods = useForm({
    defaultValues: getFormState(
      taskState,
      locationsPage,
      locationsPageSize,
      relatedBrandsPage,
      originalBrandId,
    ),
    resolver: yupResolver(brandGatewayTaskSchema),
  })

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    errors,
    formState: { dirtyFields, isSubmitted },
  } = formMethods

  useEffect(() => {
    const { task, brand: formBrand } = getValues()

    const {
      locations,
      filteredLocationCount,
      relatedBrands,
      similarBrands,
      brand: taskStateBrand,
    } = getFormState(
      taskStateCurrentValues,
      1,
      locationsPageSize,
      1,
      originalBrandId,
    )

    const formValues = {
      task,
      brand: {
        ...taskStateBrand,
        name: formBrand.name,
        website: formBrand.website,
        isHasNoWebsiteConfirmed: formBrand.isHasNoWebsiteConfirmed,
        rank: formBrand.rank,
        status:
          taskStateBrand.exclusionReason === NO_INCLUDED_LOCATIONS
            ? taskStateBrand.status
            : formBrand.status,
        exclusionReason:
          taskStateBrand.exclusionReason === NO_INCLUDED_LOCATIONS
            ? taskStateBrand.exclusionReason
            : formBrand.exclusionReason,
        exclusionReasonComment:
          taskStateBrand.exclusionReason === NO_INCLUDED_LOCATIONS
            ? taskStateBrand.exclusionReasonComment
            : formBrand.exclusionReasonComment,
      },
      locations,
      relatedBrands,
      similarBrands,
    }

    setTaskState(taskStateCurrentValues)
    setLocationsPage(1)
    setFilteredLocationCount(filteredLocationCount)
    setRelatedBrandsPage(1)
    setAllLocationsStatus()
    reset(formValues)
  }, [
    getValues,
    taskState,
    similarBrands,
    reset,
    taskStateCurrentValues,
    fromSlaveBrandId,
    originalBrandId,
    locationsPageSize,
  ])

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_BRAND_GATEWAY_TASK,
    {
      onCompleted({ assignBrandGatewayTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignBrandGatewayTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand gateway task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand gateway assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_BRAND_GATEWAY_TASK,
    {
      onCompleted({ unassignBrandGatewayTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignBrandGatewayTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand gateway task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand gateway task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_BRAND_GATEWAY_TASK_AS_AWAITING, {
    onCompleted({ markBrandGatewayTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: markBrandGatewayTaskAsAwaiting.modifiedDate,
        isAwaiting: true,
        awaitingReason:
          TASK_AWAITING_REASONS[markBrandGatewayTaskAsAwaiting.awaitingReason]
            .name,
        awaitingReasonComment:
          markBrandGatewayTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand gateway task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand gateway task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_BRAND_GATEWAY_TASK_AS_AWAITING, {
    onCompleted({ unmarkBrandGatewayTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkBrandGatewayTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand gateway task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand gateway task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_BRAND_GATEWAY_TASK,
    {
      onCompleted({ reopenBrandGatewayTask: { modifiedDate, reopenedDate } }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          qualityCheckedBy: {
            name: null,
          },
          qualityCheckedDate: null,
          modifiedDate,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand gateway task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand gateway task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsQualityChecked,
    { loading: isMarkingTaskAsQualityChecked },
  ] = useMutation(MARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: loggedInUser.name,
        },
        qualityCheckedDate: new Date().toISOString(),
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand gateway task marked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand gateway task mark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsQualityChecked,
    { loading: isUnmarkingTaskAsQualityChecked },
  ] = useMutation(UNMARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: null,
        },
        qualityCheckedDate: null,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand gateway task unmarked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand gateway task unmark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_BRAND_GATEWAY_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markBrandGatewayTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_BRAND_GATEWAY_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markBrandGatewayTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onSave = useCallback(async () => {
    setValue('task.isDone', false)
    handleSubmit(onSubmit)()
  }, [setValue, handleSubmit, onSubmit])

  const onDone = useCallback(async () => {
    setValue('task.isDone', true)
    handleSubmit(async formValues => {
      const updatedTaskState = mergeWith(taskState, formValues, mergeFormValues)
      const isValid = await brandGatewayTaskStateValidator(updatedTaskState)

      if (isValid) {
        onSubmit(updatedTaskState)
      }
    })()
  }, [setValue, taskState, handleSubmit, onSubmit])

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onMiscTaskVerifiedChanged = isQCVerifiedButtonClicked => {
    setIsQCVerified(isQCVerifiedButtonClicked)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const onExclusionCommentSubmit = ({ exclusionComment }) => {
    setValue('brand.exclusionReasonComment', exclusionComment)
    setIsExclusionCommentModalOpen(false)
  }

  const onLocationsPageChange = useCallback(
    (locationsPage, locationsPageSize) => {
      if (includes(keys(dirtyFields), 'locations') && !isSubmitted) {
        Modal.confirm({
          title: 'Unsaved changes',
          content:
            'There are locations that are not saved. Do you want to continue?',
          onOk() {
            recalculateFormValues(
              getValues,
              taskState,
              setTaskState,
              locationsPage,
              locationsPageSize,
              relatedBrandsPage,
              reset,
              originalBrandId,
              setFilteredLocationCount,
            )
            setAllLocationsStatus()
            setLocationsPage(locationsPage)
            setLocationsPageSize(locationsPageSize)
          },
        })
      } else {
        recalculateFormValues(
          getValues,
          taskState,
          setTaskState,
          locationsPage,
          locationsPageSize,
          relatedBrandsPage,
          reset,
          originalBrandId,
          setFilteredLocationCount,
        )
        setAllLocationsStatus()
        setLocationsPage(locationsPage)
        setLocationsPageSize(locationsPageSize)
      }
    },
    [
      dirtyFields,
      isSubmitted,
      getValues,
      relatedBrandsPage,
      reset,
      taskState,
      originalBrandId,
      setLocationsPageSize,
    ],
  )

  const onRelatedBrandsPageChange = useCallback(
    relatedBrandsPage => {
      if (includes(keys(dirtyFields), 'relatedBrands') && !isSubmitted) {
        Modal.confirm({
          title: 'Unsaved changes',
          content:
            'There are related brands that are not saved. Do you want to continue?',
          onOk() {
            recalculateFormValues(
              getValues,
              taskState,
              setTaskState,
              locationsPage,
              locationsPageSize,
              relatedBrandsPage,
              reset,
              originalBrandId,
              setFilteredLocationCount,
            )
            setRelatedBrandsPage(relatedBrandsPage)
          },
        })
      } else {
        recalculateFormValues(
          getValues,
          taskState,
          setTaskState,
          locationsPage,
          locationsPageSize,
          relatedBrandsPage,
          reset,
          originalBrandId,
          setFilteredLocationCount,
        )
        setRelatedBrandsPage(relatedBrandsPage)
      }
    },
    [
      dirtyFields,
      isSubmitted,
      getValues,
      taskState,
      locationsPage,
      locationsPageSize,
      reset,
      originalBrandId,
    ],
  )

  const onSelectOriginalBrandChange = useCallback(
    brandId => {
      history.push(createUrl(pathname))

      setLocationsPage(1)
      setAllLocationsStatus()
      setOriginalBrandId(brandId)
      recalculateFormValues(
        getValues,
        taskState,
        setTaskState,
        1,
        DEFAULT_PAGE_SIZE,
        relatedBrandsPage,
        reset,
        brandId,
        setFilteredLocationCount,
      )
    },
    [getValues, relatedBrandsPage, reset, taskState, history, pathname],
  )

  const onQualityCheckChanged = useCallback(
    isQualityChecked =>
      isQualityChecked
        ? markTaskAsQualityChecked({ variables: { id: task.id } })
        : unmarkTaskAsQualityChecked({ variables: { id: task.id } }),
    [markTaskAsQualityChecked, unmarkTaskAsQualityChecked, task],
  )

  const brandRank = watch('brand.rank')
  const brandStatus = watch('brand.status')
  const masterBrand = watch('brand.masterBrand')
  const taskStatus = watch('task.status')

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForBrandGatewayTaskPage(task),
    [task],
  )

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isSavingTask ||
        isResolvingTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isRefetchingTask) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={BRAND_GATEWAY} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          Brand gateway task
          <LockedStatus
            userLockingEntity={userLockingBrand}
            entityType={BRAND}
            margin="no no small xxSmall"
          />
          <HistoryDrawer entityType={BRAND_GATEWAY_TASK} entityId={task.id} />
          <QualityCheckedSwitch
            task={watch('task')}
            permissions={BRAND_GATEWAY_TASKS_PERMISSIONS}
            onChange={onQualityCheckChanged}
            loading={
              isMarkingTaskAsQualityChecked || isUnmarkingTaskAsQualityChecked
            }
          />
        </PageTitle>
        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={BRAND_GATEWAY_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={onSave}
            onDone={onDone}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/brand-gateway"
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form>
          <Collapse defaultActiveKey={['brand-section', 'task-section']}>
            <Collapse.Panel
              header="Brand"
              key="brand-section"
              style={has(errors, 'brand') ? { border: '1px solid red' } : {}}
            >
              <Row gutter={16}>
                <Col span={2}>
                  <FormInternalLink
                    name="brand.id"
                    label="ID"
                    transform={id => `/brands/${id}`}
                  />
                </Col>
                <Col span={3}>
                  <Input name="brand.name" label="Name" />
                </Col>
                <Col span={4}>
                  <Input
                    name="brand.website"
                    label="Website"
                    disabled={watch('brand.isHasNoWebsiteConfirmed')}
                    addonAfter={
                      <OpenLink
                        onClick={() =>
                          !watch('brand.isHasNoWebsiteConfirmed') &&
                          window.open(getValues('brand.website'))
                        }
                      />
                    }
                  />
                </Col>
                <Col span={2}>
                  <Checkbox
                    name="brand.isHasNoWebsiteConfirmed"
                    label="No website"
                    onCheckboxChange={isHasNoWebsiteConfirmed => {
                      if (isHasNoWebsiteConfirmed) {
                        setCachedBrandWebsiteValue(getValues('brand.website'))
                        setValue('brand.website', null)
                      }
                      if (!isHasNoWebsiteConfirmed) {
                        setValue('brand.website', cachedBrandWebsiteValue)
                      }
                    }}
                  />
                </Col>
                <Col span={2}>
                  <Select
                    label="Rank"
                    name="brand.rank"
                    disabled={task.brand.rank === MASTER}
                    options={values(BRAND_RANKS)}
                    onSelectChange={rank => {
                      if (rank === SLAVE) {
                        setValue('brand.status', NEW)
                      } else {
                        refetchSimilarMasterBrandsByName()
                        setValue('brand.status', '')
                      }
                    }}
                  />
                </Col>
                <Col span={2}>
                  <FormText label="Messiness" name="brand.messinessScore" />
                </Col>
                <VisibilityContainer isHidden={brandRank !== MASTER}>
                  <Col span={2}>
                    <Select
                      label="Inclusion"
                      name="brand.status"
                      options={inclusionOptions}
                      onSelectChange={status => {
                        if (status === INCLUDED) {
                          setValue('brand.exclusionReason', '')
                          setValue('brand.exclusionReasonComment', '')
                        }
                      }}
                    />
                  </Col>
                </VisibilityContainer>
                <VisibilityContainer isHidden={brandRank !== SLAVE}>
                  <VisibilityContainer isHidden={taskStatus !== RESOLVED}>
                    <Col span={5}>
                      <Input
                        name="brand.masterBrand.name"
                        value={`${masterBrand.name} - ${masterBrand.id}`}
                        label="Master brand"
                        disabled
                        addonAfter={
                          <Link
                            to={`/tasks/brand-gateway/${masterBrand.brandGatewayTaskId}?fromSlaveBrandId=${task.brand.id}`}
                            target="_blank"
                          >
                            <Button size="small" shape="circle">
                              <Icon type="link" />
                            </Button>
                          </Link>
                        }
                      />
                    </Col>
                  </VisibilityContainer>
                  <VisibilityContainer isHidden={taskStatus === RESOLVED}>
                    <Col span={5}>
                      <BrandsDropdown
                        name="brand.masterBrand"
                        label="Master brand"
                        includeOnlyMasterBrands
                      />
                    </Col>
                  </VisibilityContainer>
                </VisibilityContainer>
                <VisibilityContainer isHidden={brandStatus !== EXCLUDED}>
                  <Col span={3}>
                    <Select
                      label="Exclusion reason"
                      name="brand.exclusionReason"
                      options={values(BRAND_EXCLUSION_REASONS)}
                      onSelectChange={exclusionReason => {
                        setValue('brand.exclusionReasonComment', '')

                        if (exclusionReason === INACCESSIBLE_URL) {
                          showWarningNotification({
                            message:
                              'Make sure you checked the website with VPN',
                            description:
                              'Some websites are only accessible with VPN, please check the website with VPN before excluding it.',
                          })
                        }

                        if (exclusionReason === MISC) {
                          setIsExclusionCommentModalOpen(true)
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </VisibilityContainer>
                <Col span={2}>
                  <BrandGatewayTaskBrandExclusionReasonComment
                    onClick={() => setIsExclusionCommentModalOpen(true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <FormText
                    label="Has only unsupported businesses"
                    name="brand.hasOnlyUnsupportedBusinessTypes"
                  />
                </Col>
                <Col span={4}>
                  <FormDate name="brand.modifiedDate" label="Updated at" />
                </Col>
                <Col span={2}>
                  <AntForm.Item label="Actions">
                    <Button onClick={() => setIsCreateBrandModalOpen(true)}>
                      New brand
                      <Icon type="plus" />
                    </Button>
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={3}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={3}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={5}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={3}>
                  <AntForm.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onChange={onMiscTaskVerifiedChanged}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                    />
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <VisibilityContainer isHidden={brandRank !== MASTER}>
            <Panel margin="small no">
              <BrandGatewaySimilarMasterBrandsByName
                brand={taskState.brand}
                similarMasterBrandsByName={similarMasterBrandsByName}
                isLoading={isLoadingSimilarMasterBrandsByName}
                refetchSimilarMasterBrandsByName={
                  refetchSimilarMasterBrandsByName
                }
                refetchSimilarBrands={refetchSimilarBrands}
              />
              <BrandGatewaySimilarMasterBrandsByLocationWebsite
                brand={taskState.brand}
                similarMasterBrandsByLocationWebsite={
                  similarMasterBrandsByLocationWebsite
                }
                isLoading={isLoadingSimilarMasterBrandsByLocationWebsite}
                refetchSimilarMasterBrandsByLocationWebsite={
                  refetchSimilarMasterBrandsByLocationWebsite
                }
                refetchSimilarBrands={refetchSimilarBrands}
              />
            </Panel>

            <Panel margin="small no">
              <BrandGatewaySimilarBrandsByName
                brand={taskState.brand}
                isLoading={isLoadingSimilarBrands}
                refetchSimilarBrands={refetchSimilarBrands}
              />
            </Panel>
            <VisibilityContainer
              isHidden={isEmpty(
                taskState.similarBrands?.brandsSimilarByLocationWebsite,
              )}
            >
              <Panel margin="small no">
                <BrandGatewaySimilarBrandsByLocationWebsite
                  isLoading={isLoadingSimilarBrands}
                />
              </Panel>
            </VisibilityContainer>

            <Panel margin="small no">
              <BrandGatewayRelatedBrands
                brandCount={size(taskState.relatedBrands)}
                currentPage={relatedBrandsPage}
                onPageChange={onRelatedBrandsPageChange}
              />
            </Panel>
          </VisibilityContainer>
          <Panel margin="small no">
            <BrandGatewayLocations
              currentBrandId={task.brand.id}
              originalBrandId={originalBrandId}
              originalBrands={getOriginalBrands(taskState)}
              onChange={onSelectOriginalBrandChange}
              filteredLocationCount={filteredLocationCount}
              totalLocationCount={size(taskState.locations)}
              currentPage={locationsPage}
              currentPageSize={locationsPageSize}
              onPageChange={onLocationsPageChange}
              allLocationsStatus={allLocationsStatus}
              setAllLocationsStatus={setAllLocationsStatus}
            />
          </Panel>
          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.qualityCheckedBy.name',
              'task.qualityCheckedDate',
              'task.reopenedDate',
              'task.reopenedBy.id',
              'task.reopenedBy.name',
              'task.reopenReason',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
              'brand.id',
              'brand.exclusionReasonComment',
              'brand.isMasterBrand',
              'brand.slaveBrands',
              'brand.notSameBrands',
            ]}
          />
          <HiddenCheckboxes
            names={['task.isAssigned', 'task.isDone', 'task.isAwaiting']}
          />
        </form>
      </FormProvider>

      <BrandExclusionCommentModal
        exclusionComment={watch('brand.exclusionReasonComment')}
        isModalOpen={isExclusionReasonModalOpen}
        onSave={onExclusionCommentSubmit}
        onCancel={() => setIsExclusionCommentModalOpen(false)}
      />

      <CreateBrandModal
        isModalOpen={isCreateBrandModalOpen}
        website={getValues('brand.website')}
        originalName={task.brand.name}
        closeModal={() => setIsCreateBrandModalOpen(false)}
      />

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

BrandGatewayTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isRefetchingTask: PropTypes.bool.isRequired,
  isLoadingSimilarBrands: PropTypes.bool.isRequired,
  similarBrands: PropTypes.object,
  refetchSimilarBrands: PropTypes.func.isRequired,
  isLoadingSimilarMasterBrandsByName: PropTypes.bool.isRequired,
  similarMasterBrandsByName: PropTypes.object.isRequired,
  refetchSimilarMasterBrandsByName: PropTypes.func.isRequired,
  isLoadingSimilarMasterBrandsByLocationWebsite: PropTypes.bool.isRequired,
  similarMasterBrandsByLocationWebsite: PropTypes.object.isRequired,
  refetchSimilarMasterBrandsByLocationWebsite: PropTypes.func.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  userLockingBrand: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

BrandGatewayTaskForm.defaultProps = {
  userLockingBrand: undefined,
  similarBrands: undefined,
}

export default BrandGatewayTaskForm
