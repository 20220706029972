import { gql } from 'apollo-boost'

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $data: UpdateLocationInput!) {
    updateLocation(id: $id, data: $data) {
      id
      name
    }
  }
`

export default UPDATE_LOCATION
