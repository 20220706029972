import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { CLONE_BRAND, UPDATE_BRAND } from '../../graphql/mutations'
import { BRAND } from '../../graphql/queries'
import { transformToUpdateBrandInput } from '../../graphql/transformers'
import { useUpdateBrandImages } from '../../hooks'
import { BrandForm } from '../forms'

const BrandPage = () => {
  useDocumentTitle('Brand')
  const { brandId } = useParams()

  const {
    data: { brand } = {},
    refetch: refetchBrand,
    networkStatus: brandQueryNetworkStatus,
  } = useQuery(BRAND, {
    variables: { id: brandId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [updateBrand, { loading: isUpdatingBrand }] = useMutation(
    UPDATE_BRAND,
    {
      onCompleted() {
        refetchBrand()
        showSuccessNotification({
          message: 'Brand updated',
          description: `Brand has been successfully updated`,
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand update failed.',
          description: message,
        })
      },
    },
  )

  const [
    updateBrandImages,
    { loading: isUpdatingBrandImages },
  ] = useUpdateBrandImages(refetchBrand)

  const [cloneBrand, { loading: isCloningBrand }] = useMutation(CLONE_BRAND, {
    onCompleted({ cloneBrand: { name } }) {
      refetchBrand()
      showSuccessNotification({
        message: 'Brand cloned',
        description: `Brand has been successfully cloned: ${name}`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand clone failed.',
        description: message,
      })
    },
  })

  const handleSave = useCallback(
    brand =>
      updateBrand({
        variables: { id: brand.id, data: transformToUpdateBrandInput(brand) },
      }),
    [updateBrand],
  )

  const handleClone = useCallback(
    brandId =>
      cloneBrand({
        variables: { id: brandId },
      }),
    [cloneBrand],
  )

  return brandQueryNetworkStatus === NetworkStatus.loading ? (
    <Spinner />
  ) : (
    <BrandForm
      brand={brand}
      isUpdatingBrand={
        isUpdatingBrand || isCloningBrand || isUpdatingBrandImages
      }
      isRefetchingBrand={brandQueryNetworkStatus === NetworkStatus.refetch}
      onSave={handleSave}
      onSaveImages={updateBrandImages}
      onClone={handleClone}
    />
  )
}

export default BrandPage
