import React from 'react'
import PropTypes from 'prop-types'

import { MenuScrapingClusterMenusTable } from './tables'

const MenuScrapingClusterNoTemplateStep = ({ menuScrapingCluster }) => (
  <MenuScrapingClusterMenusTable
    menuScrapingCluster={menuScrapingCluster}
    isUpdateRoleButtonHidden
    isUpdateMenuScrapingClusterHidden
  />
)

MenuScrapingClusterNoTemplateStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterNoTemplateStep
