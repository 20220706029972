import { mapKeys } from 'lodash-es'

export const INACCESSIBLE_URL = 'INACCESSIBLE_URL'
export const NO_MENU = 'NO_MENU'
export const GROCERY = 'GROCERY'
export const CATERING = 'CATERING'
export const NO_WEBSITE = 'NO_WEBSITE'
export const DELIVERY = 'DELIVERY'
export const AMBIGUOUS_LOCATION_TYPES = 'AMBIGUOUS_LOCATION_TYPES'
export const MULTIPLE_BRANDS_COMBINED = 'MULTIPLE_BRANDS_COMBINED'
export const NOT_A_RESTAURANT = 'NOT_A_RESTAURANT'
export const MISC = 'MISC'
export const NO_INCLUDED_LOCATIONS = 'NO_INCLUDED_LOCATIONS'

export const BRAND_EXCLUSION_REASONS = mapKeys(
  [
    { name: 'Inaccessible URL', value: INACCESSIBLE_URL },
    { name: 'No menu', value: NO_MENU },
    { name: 'Grocery', value: GROCERY },
    { name: 'Catering', value: CATERING },
    { name: 'No website', value: NO_WEBSITE },
    { name: 'Delivery', value: DELIVERY },
    { name: 'Ambiguous location types', value: AMBIGUOUS_LOCATION_TYPES },
    { name: 'Multiple brands combined', value: MULTIPLE_BRANDS_COMBINED },
    { name: 'Not a restaurant', value: NOT_A_RESTAURANT },
    { name: 'Misc', value: MISC },
    {
      name: 'No included locations',
      value: NO_INCLUDED_LOCATIONS,
      disabled: true,
    },
  ],
  'value',
)
