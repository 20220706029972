import { gql } from 'apollo-boost'

const ASSIGN_BRAND_GATEWAY_TASK = gql`
  mutation assignBrandGatewayTask($id: ID!, $toUserId: ID!) {
    assignBrandGatewayTask(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_BRAND_GATEWAY_TASK
