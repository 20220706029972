import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

import { BRAND_STATUSES } from '../../../constants'

const BrandStatusFilter = ({ mode }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { status } = getQueryParams(search)

  const handleChange = useCallback(
    status =>
      history.push(
        createUrl(pathname, search, {
          status,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  return (
    <Form.Item label="Brand status">
      <Select
        style={{ width: '100%' }}
        value={status}
        mode={mode}
        onChange={handleChange}
        allowClear
      >
        {map(BRAND_STATUSES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

BrandStatusFilter.propTypes = {
  mode: PropTypes.string,
}

BrandStatusFilter.defaultProps = {
  mode: 'default',
}

export default BrandStatusFilter
