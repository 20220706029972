import { mapKeys } from 'lodash-es'

export const NEW = 'NEW'
export const INCLUDED = 'INCLUDED'
export const EXCLUDED = 'EXCLUDED'

export const LOCATION_STATUSES = mapKeys(
  [
    { name: 'New', value: NEW },
    { name: 'Excluded', value: EXCLUDED },
    { name: 'Included', value: INCLUDED },
  ],
  'value',
)

export const LOCATION_STATUSES_BY_NAME = mapKeys(
  [
    { name: 'New', value: NEW },
    { name: 'Included', value: INCLUDED },
    { name: 'Excluded', value: EXCLUDED },
  ],
  'name',
)

export const LOCATION_STATUSES_SORT_VALUES = mapKeys(
  [
    { sortValue: 0, value: NEW },
    { sortValue: 1, value: EXCLUDED },
    { sortValue: 2, value: INCLUDED },
  ],
  'value',
)
