import { gql } from 'apollo-boost'

const UNASSIGN_MENU_DEFINITION_TASK = gql`
  mutation unassignMenuDefinitionTask($id: ID!) {
    unassignMenuDefinitionTask(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNASSIGN_MENU_DEFINITION_TASK
