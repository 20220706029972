import { gql } from 'apollo-boost'

const ASSIGN_MENU_DEFINITION_TASK = gql`
  mutation assignMenuDefinitionTask($id: ID!, $toUserId: ID!) {
    assignMenuDefinitionTask(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_MENU_DEFINITION_TASK
