import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { DateRangePicker } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { UsersDropdown } from '../../../users/components/pages/sections'

import { scrapingEngineReportSchema } from './schemas'

const ScrapingEngineReportForm = ({ isGeneratingReport, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      userIds: [],
      dateRange: '',
    },
    resolver: yupResolver(scrapingEngineReportSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom">
            <Col span={5}>
              <UsersDropdown
                name="userIds"
                mode="multiple"
                allowClear
                disableInctiveUsers={false}
              />
            </Col>
            <Col span={5} offset={1}>
              <DateRangePicker name="dateRange" label="Date range" />
            </Col>
            <Col span={1} offset={1}>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isGeneratingReport}
              >
                Generate scraping engine report
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

ScrapingEngineReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isGeneratingReport: PropTypes.bool.isRequired,
}

export default ScrapingEngineReportForm
