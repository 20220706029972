/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/browser'

import { getEnvironmentVariable } from '../helpers'

const initSentry = () =>
  Sentry.init({
    dsn: getEnvironmentVariable('REACT_APP_SENTRY_DSN'),
    environment: getEnvironmentVariable('REACT_APP_SENTRY_APP_ENVIRONMENT'),
  })

export default initSentry
