import React from 'react'
import { notification } from 'antd'

import { DUPLICATE_LOCATION } from '../../constants/error-types'

import { DuplicateLocationErrorDescription } from './error-descriptions'

const showErrorNotification = ({ message, description, error }) => {
  const { type, data } = error?.graphQLErrors?.[0]?.extensions?.exception || {}

  let formattedDescription = description || error?.message
  if (type === DUPLICATE_LOCATION) {
    formattedDescription = <DuplicateLocationErrorDescription {...data} />
  }

  return notification.open({
    type: 'error',
    placement: 'bottomRight',
    duration: 0,
    message,
    description: formattedDescription,
  })
}

export default showErrorNotification
