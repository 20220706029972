import { gql } from 'apollo-boost'

const ASSIGN_MENU_SCRAPING_CLUSTER = gql`
  mutation assignMenuScrapingCluster($id: ID!, $toUserId: ID!) {
    assignMenuScrapingCluster(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_MENU_SCRAPING_CLUSTER
