import { gql } from 'apollo-boost'

const MENU_RESCRAPING_COMMENT = gql`
  query menuRescrapingComment($id: ID!) {
    menuRescrapingComment(id: $id) {
      rescrapingComment
      scrapedById
      assignedToId
    }
  }
`

export default MENU_RESCRAPING_COMMENT
