import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTERS_BASIC = gql`
  query menuScrapingClustersBasic {
    menuScrapingClustersBasic {
      id
    }
  }
`

export default MENU_SCRAPING_CLUSTERS_BASIC
