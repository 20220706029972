import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { HiddenInputs, Input } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { Spinner } from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'

import { platformEditorFormInitialValues } from './initial-values'
import { platformEditorSchema } from './schemas'

const PlatformEditorForm = ({
  platform,
  isCreatingPlatform,
  isUpdatingPlatform,
  isGeneratingReport,
  onSubmit,
  onGenerateReportClick,
  onRegexChange,
}) => {
  const history = useHistory()
  const defaultValues = useMemo(
    () => platformEditorFormInitialValues(platform),
    [platform],
  )

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(platformEditorSchema),
    mode: 'onChange',
  })

  const handleOnChange = () => {
    const {
      urlIdentificationRegex,
      urlNormalizationRegex,
    } = formMethods.getValues()

    onRegexChange({
      urlIdentificationRegex,
      urlNormalizationRegex,
    })
  }

  return (
    <Panel margin="no no medium no">
      {(isCreatingPlatform || isUpdatingPlatform) && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeader sticky>
            <PageTitle>
              {platform ? 'Edit platform' : 'Create platform'}
            </PageTitle>
            <PageActions>
              <Button
                onClick={() => history.push('/settings/platforms')}
                margin="no small no no"
              >
                Cancel
              </Button>
              {platform ? (
                <Button
                  onClick={onGenerateReportClick}
                  margin="no small no no"
                  loading={isGeneratingReport}
                >
                  Generate report
                </Button>
              ) : null}
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </PageActions>
          </PageHeader>
          <Row>
            <Col span={4}>
              <Input name="name" label="Name" />
            </Col>
            <Col span={4} offset={1}>
              <Input
                name="urlIdentificationRegex"
                label="Identification Regex"
                onBlur={handleOnChange}
              />
            </Col>
            <Col span={4} offset={1}>
              <Input
                name="urlNormalizationRegex"
                label="Normalization Regex"
                onBlur={handleOnChange}
              />
            </Col>
          </Row>
          <HiddenInputs names={['id']} />
        </form>
      </FormProvider>
    </Panel>
  )
}

PlatformEditorForm.propTypes = {
  platform: PropTypes.object,
  isGeneratingReport: PropTypes.bool.isRequired,
  isCreatingPlatform: PropTypes.bool.isRequired,
  isUpdatingPlatform: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onGenerateReportClick: PropTypes.func.isRequired,
  onRegexChange: PropTypes.func.isRequired,
}

PlatformEditorForm.defaultProps = {
  platform: undefined,
}

export default PlatformEditorForm
