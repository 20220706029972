import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactJson from 'react-json-view'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Col, Form as AntForm, Icon, Row, Table, Typography } from 'antd'
import copyToClipboard from 'copy-to-clipboard'
import fileDownload from 'js-file-download'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  FormDate,
  FormText,
  HiddenInputs,
  Input,
  Select,
  Upload,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { DEFAULT_PAGE } from '../../../common/constants'

import {
  MenuScrapingTemplateCommentModal,
  MenuScrapingTemplateScrapingIssueModal,
} from '../../../tasks/components/pages/sections'
import { MENU_SCRAPING_TEMPLATE_STATUSES } from '../../constants'
import { MENU_SCRAPING_TEMPLATE_CLUSTERS } from '../../graphql/queries'
import { MenuScrapingTemplateInstructionsVersionsDrawer } from '../pages/sections'

import { updateMenuScrapingTemplateSchema } from './schemas'

const UpdateMenuScrapingTemplateForm = ({ menuScrapingTemplate, onSubmit }) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const { page = DEFAULT_PAGE } = getQueryParams(search)

  const [
    isScrapingTemplateCommentModalOpen,
    setIsScrapingTemplateCommentModalOpen,
  ] = useState(false)

  const [
    isScrapingIssueCommentModalOpen,
    setIsScrapingIssueCommentModalOpen,
  ] = useState(false)

  const {
    data: { menuScrapingTemplateClusters = {} } = {},
    loading: isLoadingClusters,
  } = useQuery(MENU_SCRAPING_TEMPLATE_CLUSTERS, {
    variables: {
      id: menuScrapingTemplate.id,
      page,
    },
    fetchPolicy: 'no-cache',
  })

  const formMethods = useForm({
    defaultValues: menuScrapingTemplate || {},
    resolver: yupResolver(updateMenuScrapingTemplateSchema),
  })

  const { watch, handleSubmit } = formMethods

  const scrapingInstructions = watch('scrapingInstructions')

  const menuScrapingTemplateStatusOptions = useMemo(
    () => values(MENU_SCRAPING_TEMPLATE_STATUSES),
    [],
  )

  const handlePageChange = useCallback(
    page => history.push(createUrl(pathname, search, { page })),
    [history, search, pathname],
  )

  const tableColumns = [
    {
      title: 'Cluster id',
      dataIndex: 'id',
      width: '100px',
      render: id => (
        <Link to={`/menu-scraping-templates/clusters/${id}`}>{id}</Link>
      ),
    },
    {
      title: 'Menu count',
      dataIndex: 'menuCount',
      width: '100px',
    },
  ]

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageHeader sticky>
            <PageTitle>Menu Scraping Template</PageTitle>
            <PageActions>
              <Button
                onClick={() =>
                  history.push('/menu-scraping-templates/templates')
                }
                margin="no small"
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </PageActions>
          </PageHeader>
          <Panel>
            <Row gutter={[16, 16]}>
              <Col span={1}>
                <FormText name="id" label="ID" />
              </Col>

              <Col span={4}>
                <Input name="name" label="Name" />
              </Col>

              <Col span={4}>
                <Select
                  style={{ width: '100%' }}
                  label="Status"
                  name="status"
                  options={menuScrapingTemplateStatusOptions}
                />
              </Col>

              <Col span={3}>
                <Upload
                  label="Scraping instructions"
                  name="scrapingInstructions"
                  accept=".json"
                />
              </Col>
              <Col span={2}>
                <AntForm.Item label="Comment">
                  <Button
                    onClick={() => setIsScrapingTemplateCommentModalOpen(true)}
                  >
                    <Icon type="form" />
                  </Button>
                </AntForm.Item>
              </Col>
              <Col span={2}>
                <AntForm.Item label="Sraping issue">
                  <Button
                    onClick={() => setIsScrapingIssueCommentModalOpen(true)}
                  >
                    <Icon type="form" />
                  </Button>
                </AntForm.Item>
              </Col>
              <Col span={2}>
                <FormDate name="createdDate" label="Created at" />
              </Col>
              <Col span={3}>
                <FormText name="createdBy.name" label="Created by" />
              </Col>
              <Col span={2}>
                <FormDate name="modifiedDate" label="Modified at" />
              </Col>
              <Col span={3}>
                <FormText name="modifiedBy.name" label="Modified by" />
              </Col>
            </Row>
          </Panel>

          <Table
            loading={isLoadingClusters}
            rowKey="id"
            columns={tableColumns}
            dataSource={
              menuScrapingTemplateClusters.menuScrapingTemplateClusters
            }
            pagination={{
              current: page,
              total: menuScrapingTemplateClusters.total,
              onChange: handlePageChange,
            }}
          />

          <Panel margin="small no">
            <Typography.Title level={4} style={{ margin: '5px' }}>
              Scraping instructions
            </Typography.Title>

            {scrapingInstructions ===
              menuScrapingTemplate.scrapingInstructions &&
            menuScrapingTemplate.scrapingInstructionsVersionNumber ? (
              <Typography.Text strong style={{ margin: '5px' }}>
                {`(V.${menuScrapingTemplate.scrapingInstructionsVersionNumber})`}
              </Typography.Text>
            ) : (
              <Typography.Text strong style={{ margin: '5px' }}>
                {scrapingInstructions && 'New version(not uploaded)'}
              </Typography.Text>
            )}

            {scrapingInstructions && (
              <>
                <Button
                  size="small"
                  style={{ margin: '5px' }}
                  onClick={() =>
                    copyToClipboard(menuScrapingTemplate.scrapingInstructions)
                  }
                >
                  Copy to clipboard
                  <Icon type="copy" />
                </Button>
                <Button
                  size="small"
                  onClick={() =>
                    fileDownload(
                      menuScrapingTemplate.scrapingInstructions,
                      `${menuScrapingTemplate.id}-menu-scraping-template.json`,
                    )
                  }
                >
                  Download
                  <Icon type="download" />
                </Button>
              </>
            )}

            {menuScrapingTemplate.scrapingInstructionsVersionNumber > 1 && (
              <MenuScrapingTemplateInstructionsVersionsDrawer
                menuScrapingTemplate={menuScrapingTemplate}
              />
            )}

            {scrapingInstructions && (
              <ReactJson
                src={JSON.parse(scrapingInstructions)}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
              />
            )}
          </Panel>
          <HiddenInputs names={['id']} />
        </form>
      </FormProvider>

      <MenuScrapingTemplateCommentModal
        scrapingTemplate={menuScrapingTemplate}
        isModalOpen={isScrapingTemplateCommentModalOpen}
        setIsModalOpen={setIsScrapingTemplateCommentModalOpen}
      />
      <MenuScrapingTemplateScrapingIssueModal
        scrapingTemplate={menuScrapingTemplate}
        isModalOpen={isScrapingIssueCommentModalOpen}
        setIsModalOpen={setIsScrapingIssueCommentModalOpen}
      />
    </>
  )
}

UpdateMenuScrapingTemplateForm.propTypes = {
  menuScrapingTemplate: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

UpdateMenuScrapingTemplateForm.defaultProps = {
  menuScrapingTemplate: undefined,
}

export default UpdateMenuScrapingTemplateForm
