import { useIsLoggedInUser, useUserHasPermissions } from '../../common/hooks'

const useCanUserUnassignCluster = (
  menuScrapingCluster,
  permissions,
  adminPermission,
) => {
  const isAssignedToUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const hasPermission = useUserHasPermissions(permissions)
  const hasAdminPermission = useUserHasPermissions(adminPermission)
  return (isAssignedToUser && hasPermission) || hasAdminPermission
}

export default useCanUserUnassignCluster
