import React, { useCallback, useContext } from 'react'
import { Redirect, Route, withRouter } from 'react-router'
import PropTypes from 'prop-types'

import { AuthContext } from '../../contexts'

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext)

  const renderComponent = useCallback(
    props => {
      if (user) return <Component {...props} />
      return <Redirect to={{ pathname: '/account/login' }} />
    },
    [user],
  )

  return <Route {...rest} render={renderComponent} />
}

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default withRouter(AuthRoute)
