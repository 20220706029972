import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { GET_ROLES } from '../../../account/graphql/queries'
import { CREATE_USER, UPDATE_USER } from '../../graphql/mutations'
import { USER } from '../../graphql/queries'
import { transformToUserInput } from '../../graphql/transformers'
import { UserEditorForm } from '../forms'

const onSubmit = (user, createUser, updateUser) => {
  if (user.id) {
    updateUser({
      variables: {
        id: user.id,
        data: transformToUserInput(user),
      },
    })
  } else {
    createUser({
      variables: {
        data: transformToUserInput(user),
      },
    })
  }
}

const UserEditorPage = () => {
  const { id: userId } = useParams()
  const documentTitle = userId ? 'Edit user' : 'Create user'
  useDocumentTitle(documentTitle)
  const history = useHistory()

  const { data: { user } = {}, loading: isLoadingUser } = useQuery(USER, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
  })

  const [createUser, { loading: isCreatingUser }] = useMutation(CREATE_USER, {
    onCompleted({ createUser: { name } }) {
      showSuccessNotification({
        message: 'User created.',
        description: `${name} has been successfully created.`,
      })
      history.push('/users')
    },

    onError({ message }) {
      showErrorNotification({
        message: 'User creation failed.',
        description: message,
      })
    },
  })

  const [updateUser, { loading: isUpdatingUser }] = useMutation(UPDATE_USER, {
    onCompleted({ updateUser: { name } }) {
      showSuccessNotification({
        message: 'User updated.',
        description: `${name} has been successfully updated.`,
      })
      history.push('/users')
    },

    onError({ message }) {
      showErrorNotification({
        message: 'User update failed.',
        description: message,
      })
    },
  })

  const { data: { roles } = {}, loading: isLoadingRoles } = useQuery(GET_ROLES)

  const handleSubmit = useCallback(
    user => onSubmit(user, createUser, updateUser),
    [createUser, updateUser],
  )

  return isLoadingUser || isLoadingRoles ? (
    <Spinner />
  ) : (
    <UserEditorForm
      user={user}
      roles={roles}
      isCreatingUser={isCreatingUser}
      isUpdatingUser={isUpdatingUser}
      onSubmit={handleSubmit}
    />
  )
}

export default UserEditorPage
