import { isEmpty } from 'lodash-es'

function validateMenuStatusesOrScrapingStatuses(value) {
  const { menuStatuses, scrapingStatuses } = value
  if (isEmpty(menuStatuses) && isEmpty(scrapingStatuses)) {
    return this.createError({
      path: 'menuStatuses',
      message: 'At least one of menuStatuses or scrapingStatuses is required',
    })
  }
  return true
}

export default validateMenuStatusesOrScrapingStatuses
