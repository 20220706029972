import styled from 'styled-components'

export const NavigationBar = styled.div`
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
`

export const NavigationBarActions = styled.div`
  display: flex;
  min-width: fit-content;
  flex: 1;
  justify-content: flex-end;
`

export const NavigationBarGreeting = styled.div`
  display: flex;
  margin-left: auto;
  line-height: 45px;
  margin-right: 15px;
`
