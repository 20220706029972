import { gql } from 'apollo-boost'

const REOPEN_BRAND_GATEWAY_TASK = gql`
  mutation reopenBrandGatewayTask($id: ID!) {
    reopenBrandGatewayTask(id: $id) {
      id
      modifiedDate
      reopenedDate
    }
  }
`

export default REOPEN_BRAND_GATEWAY_TASK
