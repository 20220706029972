import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateOpenTasksReport } from '../../rest'
import { OpenTasksReportForm } from '../forms'

const OpenTasksReportPage = () => {
  useDocumentTitle('Reports - Open tasks')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(
    async ({ taskTypes }) => {
      try {
        setIsGeneratingReport(true)
        const { data } = await generateOpenTasksReport({ taskTypes })
        fileDownload(data, `OpenTasksReport.csv`)
        setIsGeneratingReport(false)
      } catch ({ response }) {
        showErrorNotification({
          message: 'Failed to generate report.',
          description: response.statusText,
        })
      }
    },
    [setIsGeneratingReport],
  )

  const handleSubmit = useCallback(filters => generateReport(filters), [
    generateReport,
  ])

  return (
    <OpenTasksReportForm
      onSubmit={handleSubmit}
      isGeneratingReport={isGeneratingReport}
    />
  )
}

export default OpenTasksReportPage
