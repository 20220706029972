import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateUserCountsReport } from '../../rest'
import { UserCountsReportForm } from '../forms'

const UserCountsReportPage = () => {
  useDocumentTitle('Reports - User counts')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(
    async filters => {
      const { userId } = filters

      try {
        setIsGeneratingReport(true)
        const reportResponse = await generateUserCountsReport({ userId })

        fileDownload(reportResponse.data, 'UserCountsReport.csv')
        setIsGeneratingReport(false)
      } catch ({ response }) {
        showErrorNotification({
          message: 'Failed to generate report.',
          description: response && response.statusText,
        })
      }
    },
    [setIsGeneratingReport],
  )

  const handleSubmit = useCallback(filters => generateReport(filters), [
    generateReport,
  ])

  return (
    <UserCountsReportForm
      onSubmit={handleSubmit}
      isGeneratingReport={isGeneratingReport}
    />
  )
}

export default UserCountsReportPage
