import { useContext } from 'react'

import { AuthContext } from '../../account/contexts'
import { isDeveloperUser } from '../helpers'

const useCanUserMarkBrandAsTesting = () => {
  const { user: loggedInUser } = useContext(AuthContext)
  return isDeveloperUser(loggedInUser.email)
}

export default useCanUserMarkBrandAsTesting
