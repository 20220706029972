import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Radio } from 'antd'
import { get, map } from 'lodash-es'
import PropTypes from 'prop-types'

const RadioGroupButtons = ({
  name,
  onRadioGroupChange,
  label,
  options,
  defaultValue,
  ...rest
}) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        options={options}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name }) => (
          <Radio.Group
            buttonStyle="solid"
            onBlur={onBlur}
            onChange={e => {
              onChange(e.target.value)
              if (onRadioGroupChange) onRadioGroupChange(e.target.value)
            }}
            value={value}
            name={name}
          >
            {map(options, ({ name, value }) => (
              <Radio.Button key={value} value={value} {...rest}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
    </Form.Item>
  )
}

RadioGroupButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array.isRequired,
  onRadioGroupChange: PropTypes.func,
}

RadioGroupButtons.defaultProps = {
  label: '',
  defaultValue: '',
  onRadioGroupChange: undefined,
}

export default RadioGroupButtons
