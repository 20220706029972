import React from 'react'
import { Switch } from 'react-router'

import { EmailLoginPage, LoginPage, LogoutPage } from './pages'
import { AuthRoute, GuestRoute } from './routes'

const AccountRouter = () => (
  <Switch>
    <GuestRoute exact path="/account/login" component={LoginPage} />
    <GuestRoute exact path="/account/login-email" component={EmailLoginPage} />
    <AuthRoute exact path="/account/logout" component={LogoutPage} />
  </Switch>
)
export default AccountRouter
