import * as Yup from 'yup'

const scrapingVerificationReportSchema = Yup.object().shape({
  userIds: Yup.array()
    .of(Yup.string())
    .required('User(s) is required'),
  dateRange: Yup.array().required('Date range is required'),
})

export default scrapingVerificationReportSchema
