import { gql } from 'apollo-boost'

const MARK_BRAND_DEFINITION_TASK_AS_AWAITING = gql`
  mutation markBrandDefinitionTaskAsAwaiting(
    $id: ID!
    $awaitingReason: TaskAwaitingReason!
    $awaitingReasonComment: String
  ) {
    markBrandDefinitionTaskAsAwaiting(
      id: $id
      awaitingReason: $awaitingReason
      awaitingReasonComment: $awaitingReasonComment
    ) {
      id
      awaitingReason
      awaitingReasonComment
      modifiedDate
    }
  }
`

export default MARK_BRAND_DEFINITION_TASK_AS_AWAITING
