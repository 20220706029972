import { gql } from 'apollo-boost'

const SET_DISHES_STATUSES = gql`
  mutation setDishStatusesByIds($data: [DishIdStatusPair!]!) {
    setDishStatusesByIds(data: $data) {
      id
    }
  }
`

export default SET_DISHES_STATUSES
