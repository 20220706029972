import { isNull, map, pick } from 'lodash-es'

import { APP_NAME, EMPTY_VALUE, NO, YES } from '../../../../common/constants'

import {
  MENU_REJECTION_REASONS,
  MENU_STATUSES,
} from '../../../../menus/constants'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'

const getMenuScrapingSummary = scrapingSummary => ({
  ...scrapingSummary,
  menuAnnotations:
    map(scrapingSummary?.menuAnnotations, ({ fullMenu }) => fullMenu).join(
      ', ',
    ) || EMPTY_VALUE,
})

const getRejectedBy = values => {
  if (!values.rejectedDate) return { name: '-' }

  if (values.rejectedBy) {
    return values.rejectedBy
  }

  return { name: 'Scraping service' }
}

const getMenuInitialValues = values => ({
  ...values,
  status: values.status && MENU_STATUSES[values.status].name,
  scrapingSummary: getMenuScrapingSummary(values.scrapingSummary),
  rejectedBy: getRejectedBy(values),
  rejectionReason: values.rejectionReason
    ? MENU_REJECTION_REASONS[values.rejectionReason].name
    : '',
})

const getTaskInitialValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'modifiedDate',
    'verifiedBy',
    'verifiedDate',
    'isVerifiedInDebugMode',
    'resolvedBy',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isRerunFlow: values.isRerunFlow ? YES : NO,
    isDone: false,
  }
}

const menuScrapingTaskFormInitialValues = values => ({
  task: getTaskInitialValues(values),
  menu: getMenuInitialValues(values.menu || {}),
})

export default menuScrapingTaskFormInitialValues
