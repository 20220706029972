import { gql } from 'apollo-boost'

const MENU_SCRAPING_TASKS = gql`
  query menuScrapingTasks(
    $filters: MenuScrapingTaskFiltersInput!
    $page: Int
    $pageSize: Int
    $sortingCriterion: MenuScrapingTaskSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    menuScrapingTasks(
      filters: $filters
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      menuScrapingTasks {
        id
        status
        menu {
          id
          url
          status
          format
          scrapingIssueComment
          isAwaitingScraping
          brand {
            id
            name
          }
          locationCount
          scrapedDate
          menuScrapingTemplateId
          menuScrapingClusterId
        }
        assignedTo {
          id
          name
        }
        resolvedBy {
          id
          name
        }
        modifiedBy {
          id
          name
        }
        createdDate
        modifiedDate
        resolvedDate
      }
      total
    }
  }
`

export default MENU_SCRAPING_TASKS
