import { head } from 'lodash-es'

const userEditorFormInitialValues = values => {
  if (!values) return { isActive: true }

  return {
    ...values,
    roleId: head(values.roles)?.id,
  }
}

export default userEditorFormInitialValues
