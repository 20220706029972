import { mapKeys } from 'lodash-es'

export const PREPARATION = 'PREPARATION'
export const TEMPLATE_VALIDATION = 'TEMPLATE_VALIDATION'
export const SCRAPING = 'SCRAPING'
export const CLEANUP = 'CLEANUP'
export const DONE = 'DONE'

export const MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER = [
  PREPARATION,
  TEMPLATE_VALIDATION,
  SCRAPING,
  CLEANUP,
  DONE,
]

export const MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE = mapKeys(
  [
    {
      name: 'Preparation',
      value: PREPARATION,
      info:
        'Review the Similar menus by URL signature table and manually attach relevant menus to the cluster.\nExclude unsuitable menus to avoid redundancy.\nEnsure all menus are evaluated before proceeding.',
    },
    {
      name: 'Template validation',
      value: TEMPLATE_VALIDATION,
      info: `Initiate a scraping session to verify the template's validity.\nInclude core, lead, and randomly selected menus for comprehensive validation.\nEnsure the template is optimized and validated before proceeding.\nIf a template requires updating, the flow will remain in this status until the template update has been validated.`,
    },
    {
      name: 'Scraping',
      value: SCRAPING,
      info:
        'Perform a comprehensive scraping session for all menus in the cluster.\nAddress any failed menus through retry sessions.\nConfirm that at least 90% of menus are successfully scraped and the template is confirmed before moving on.',
    },
    {
      name: 'Cleanup',
      value: CLEANUP,
      info:
        'Remove or update invalid menus and ensure the cluster is optimized for the next rerun.\nInvestigate and handle menus with scraping errors accordingly.',
    },
    {
      name: 'Done',
      value: DONE,
      info:
        'No further actions can be taken at this step.\nThe cluster is marked as Done, and the next rerun flow will start automatically or manually.',
    },
  ],
  'value',
)
