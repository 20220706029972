import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Radio } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

const RadioGroup = ({ name, label, options, defaultValue, ...rest }) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        options={options}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name }) => (
          <Radio.Group
            options={options}
            onBlur={onBlur}
            onChange={e => onChange(e.target.value)}
            value={value}
            name={name}
            {...rest}
          />
        )}
      />
    </Form.Item>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.array.isRequired,
}

RadioGroup.defaultProps = {
  label: '',
  defaultValue: null,
}

export default RadioGroup
