import { mapKeys } from 'lodash-es'

export const IN_PROGRESS = 'IN_PROGRESS'
export const COMPLETED = 'COMPLETED'
export const MANUALLY_ABORTED = 'MANUALLY_ABORTED'
export const AUTOMATICALLY_ABORTED = 'AUTOMATICALLY_ABORTED'

export const MENU_SCRAPING_RERUN_STATUSES = mapKeys(
  [
    { name: 'In progress', value: IN_PROGRESS },
    { name: 'Completed', value: COMPLETED },
    { name: 'Manually aborted', value: MANUALLY_ABORTED },
    { name: 'Automatically aborted', value: AUTOMATICALLY_ABORTED },
  ],
  'value',
)
