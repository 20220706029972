import * as Yup from 'yup'

const platformEditorSchema = Yup.object().shape({
  name: Yup.string().required(`Platform name is required`),
  urlIdentificationRegex: Yup.string()
    .required(`Platform identification regex is required`)
    .validateRegex(`Platform identification regex is invalid`),
  urlNormalizationRegex: Yup.string()
    .required(`Platform normalization regex is required`)
    .validateRegex(`Platform normalization regex is invalid`),
})

export default platformEditorSchema
