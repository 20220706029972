import React from 'react'
import ReactJson from 'react-json-view'
import { Collapse } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

const ScrapingDebugJsonPanel = ({ header, panelId, items, ...props }) =>
  size(items) ? (
    <Collapse.Panel header={header} key={panelId} {...props}>
      {map(items, (item, index) => (
        <ReactJson
          key={index}
          src={item}
          name={false}
          enableClipboard={false}
          displayDataTypes={false}
        />
      ))}
    </Collapse.Panel>
  ) : (
    <div />
  )

ScrapingDebugJsonPanel.propTypes = {
  header: PropTypes.string.isRequired,
  panelId: PropTypes.number.isRequired,
  items: PropTypes.array,
}

ScrapingDebugJsonPanel.defaultProps = {
  items: undefined,
}

export default ScrapingDebugJsonPanel
