import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { CREATE_MENU_SCRAPING_TEMPLATE } from '../../graphql/mutations'
import { transformToCreateMenuScrapingTemplateInput } from '../../graphql/transformers'
import { CreateMenuScrapingTemplateForm } from '../forms'

const onSubmit = (menuScrapingTemplate, createMenuScrapingTemplate) => {
  const variables = transformToCreateMenuScrapingTemplateInput(
    menuScrapingTemplate,
  )
  return createMenuScrapingTemplate({ variables })
}

const MenuScrapingTemplatePage = () => {
  useDocumentTitle('Menu Scraping Template')
  const history = useHistory()

  const [createMenuScrapingTemplate] = useMutation(
    CREATE_MENU_SCRAPING_TEMPLATE,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Menu scraping template created.',
          description: `Menu scraping template has been successfully created.`,
        })
        history.push('/menu-scraping-templates/templates')
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping template creation failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    menuScrapingTemplate =>
      onSubmit(menuScrapingTemplate, createMenuScrapingTemplate),
    [createMenuScrapingTemplate],
  )

  return <CreateMenuScrapingTemplateForm onSubmit={handleSubmit} />
}

export default MenuScrapingTemplatePage
