import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { HiddenInputs, TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import scrapingTemplateScrapingIssueFormInitialValues from './initial-values/scraping-template-scraping-issue-form-initial-values'

const ScrapingTemplateScrapingIssueForm = ({
  scrapingTemplate,
  closeModal,
  isSettingScrapingTemplateScrapingIssue,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: scrapingTemplateScrapingIssueFormInitialValues(
      scrapingTemplate,
    ),
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row>
          <TextArea name="scrapingIssueComment" label="Comment" autoSize />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="small small no no">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSettingScrapingTemplateScrapingIssue}
            margin="small no no no"
          >
            Save
          </Button>
        </Row>
        <HiddenInputs names={['id']} />
      </form>
    </FormProvider>
  )
}

ScrapingTemplateScrapingIssueForm.propTypes = {
  scrapingTemplate: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSettingScrapingTemplateScrapingIssue: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ScrapingTemplateScrapingIssueForm
