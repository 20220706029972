import { gql } from 'apollo-boost'

const SIMILAR_MASTER_BRANDS_BY_NAME = gql`
  query similarMasterBrandsByName(
    $id: ID!
    $name: String!
    $page: Int
    $pageSize: Int
    $sortingCriterion: SimilarBrandsSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    similarMasterBrandsByName(
      id: $id
      name: $name
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      brands {
        id
        name
        locationCount
        website
        brandGatewayTask {
          id
        }
      }
      total
    }
  }
`

export default SIMILAR_MASTER_BRANDS_BY_NAME
