import React, { useMemo } from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

import { LockedStatus } from '../../../../common/components'
import { BRAND, LOCKABLE_ENTITIES } from '../../../../common/constants'

const TaskTableBrandColumn = ({ getUserLockingEntity, brand }) => {
  const userLockingEntity = useMemo(
    () => getUserLockingEntity(LOCKABLE_ENTITIES.BRAND, Number(brand.id)),
    [brand.id, getUserLockingEntity],
  )

  return (
    <Typography.Text>
      <LockedStatus
        userLockingEntity={userLockingEntity}
        entityType={BRAND}
        margin="no xxSmall no no"
      />
      {brand.name}
    </Typography.Text>
  )
}

TaskTableBrandColumn.propTypes = {
  getUserLockingEntity: PropTypes.func.isRequired,
  brand: PropTypes.object.isRequired,
}

export default TaskTableBrandColumn
