import { notification } from 'antd'

const showWarningNotification = ({ message, description }) =>
  notification.open({
    type: 'warning',
    placement: 'bottomRight',
    duration: 0,
    message,
    description,
  })

export default showWarningNotification
