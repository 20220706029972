import * as Yup from 'yup'

import { HTML_NOT_SCRAPEABLE } from '../../../constants'

const menuSchema = Yup.object().shape({
  format: Yup.string().required('Menu format is required'),

  notScrapeableReason: Yup.string().when('format', {
    is: format => format === HTML_NOT_SCRAPEABLE,
    then: Yup.string()
      .required('Menu not scrapeable reason is required')
      .nullable(),
  }),
})

export default menuSchema
