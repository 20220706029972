import { gql } from 'apollo-boost'

const LOCATION = gql`
  query location($id: ID!) {
    location(id: $id) {
      id
      name
      status
      businessStatus
      menuStatus
      website
      brand {
        id
        name
        businessStatus
        menu {
          id
          url
          format
          scrapingInstructions
          dishes {
            id
          }
        }
      }
      menu {
        id
        url
        format
        dishCount
        scrapingInstructions
        rescrapingComment
        modifiedDate
      }
      workingHours {
        dayOfWeek
        intervals {
          openTime
          closeTime
        }
      }
      fsaLocation {
        name
        formattedAddress
        rating
        ratingDate
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        postCode
        createdDate
      }
      googleLocation {
        name
        website
        street
        city
        postCode
        rating
        phone
        coordinates {
          latitude
          longitude
        }
        createdDate
      }
      modifiedDate
    }
  }
`

export default LOCATION
