import React from 'react'
import { Icon } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../core/components/styled'

const OpenLink = ({ onClick }) => (
  <Button size="small" shape="circle" onClick={onClick}>
    <Icon type="link" />
  </Button>
)

OpenLink.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default OpenLink
