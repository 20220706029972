import { map } from 'lodash-es'
import * as Yup from 'yup'

import { TASK_TYPES } from '../../../../tasks/constants'

const openTasksReportSchema = Yup.object().shape({
  taskTypes: Yup.array()
    .of(Yup.string().oneOf(map(TASK_TYPES, task => task.value)))
    .min(1),
})

export default openTasksReportSchema
