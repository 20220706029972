import { mapKeys } from 'lodash-es'

export const NOT_STARTED = 'NOT_STARTED'
export const STARTED = 'STARTED'
export const REOPENED = 'REOPENED'
export const RESOLVED = 'RESOLVED'

export const TASK_STATUSES = mapKeys(
  [
    { name: 'Not started', value: NOT_STARTED },
    { name: 'Started', value: STARTED },
    { name: 'Reopened', value: REOPENED },
    { name: 'Resolved', value: RESOLVED },
  ],
  'value',
)
