import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Input } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../constants'

const SearchMenuFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { menuSearchTerm: queryParamMenuSearchTerm } = getQueryParams(search)
  const [menuSearchTerm, setMenuSearchTerm] = useState('')

  const handleSearch = useCallback(
    menuSearchTerm =>
      history.push(
        createUrl(pathname, search, {
          menuSearchTerm,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  useEffect(() => setMenuSearchTerm(queryParamMenuSearchTerm), [
    queryParamMenuSearchTerm,
  ])

  return (
    <Input.Search
      placeholder="Search menu"
      value={menuSearchTerm}
      allowClear
      onChange={({ target: { value } }) => setMenuSearchTerm(value)}
      onSearch={handleSearch}
    />
  )
}

export default SearchMenuFilter
