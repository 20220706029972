import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Table, Typography } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import {
  BRAND_DEFINITION_TASKS_PERMISSIONS,
  DEFAULT_PAGE,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { usePaginationFixer } from '../../../common/hooks'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder, tableOnRowClick } from '../../../common/utils'

import { BRAND_DEFINITION_TASKS_SORTING_CRITERIA } from '../../../menus/constants'
import {
  ASSIGN_BRAND_DEFINITION_TASK,
  UNASSIGN_BRAND_DEFINITION_TASK,
} from '../../graphql/mutations'
import { BRAND_DEFINITION_TASKS } from '../../graphql/queries'

import TaskTableAssignedColumn from './sections/task-table-assigned-column'

const BrandDefinitionTasksPage = () => {
  useDocumentTitle('Tasks - Brand Definition')
  const history = useHistory()
  const { search, pathname } = useLocation()

  const {
    brandSearchTerm,
    statuses,
    reopenReasons,
    isAssigned,
    assignedToId,
    isQualityChecked,
    qualityCheckedById,
    isAwaiting,
    awaitingReason,
    page = DEFAULT_PAGE,
    pageSize = 10,
    sortingCriterion,
    sortingOrder,
  } = getQueryParams(search)

  const {
    data: { brandDefinitionTasks = {} } = {},
    loading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(BRAND_DEFINITION_TASKS, {
    variables: {
      filters: {
        brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
        statuses,
        reopenReasons,
        isAssigned,
        assignedToId,
        isQualityChecked,
        qualityCheckedById,
        isAwaiting,
        awaitingReason,
      },
      page,
      pageSize,
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  usePaginationFixer(
    isLoadingTasks,
    brandDefinitionTasks,
    'brandDefinitionTasks',
  )

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_BRAND_DEFINITION_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Brand definition task assigned.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Brand definition task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_BRAND_DEFINITION_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Brand definition task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand definition task unassign failed.',
          description: message,
        })
      },
    },
  )

  const tableColumns = useMemo(
    () => [
      {
        title: 'Brand ID',
        dataIndex: 'brand.id',
        width: '100px',
      },
      {
        title: 'Brand name',
        dataIndex: 'brand.name',
        width: '400px',
      },
      {
        title: 'Assigned',
        width: '250px',
        render: task => (
          <TaskTableAssignedColumn
            task={task}
            permissions={BRAND_DEFINITION_TASKS_PERMISSIONS}
            assignTask={assignTask}
            unassignTask={unassignTask}
            refetchTasks={refetchTasks}
          />
        ),
      },
      {
        title: 'Included by',
        width: '250px',
        dataIndex: 'brandIncludedBy.name',
      },
      {
        title: 'Included locations',
        dataIndex: 'brand.includedLocationCount',
        width: '120px',
      },
      {
        title: 'Total locations',
        dataIndex: 'brand.locationCount',
        width: '120px',
      },
      {
        title: 'Updated at',
        dataIndex: 'modifiedDate',
        width: '160px',
        render: date => <LondonDateTime date={date} />,
        sorter: true,
      },
    ],
    [assignTask, refetchTasks, unassignTask],
  )

  const redirectToBrandDefinitionTask = useCallback(
    task => history.push(`/tasks/brand-definition/${task.id}`),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event =>
        tableOnRowClick(event, row, redirectToBrandDefinitionTask),
    }),
    [redirectToBrandDefinitionTask],
  )

  const getSortingCriterion = (field, order) =>
    field === 'modifiedDate' && order
      ? BRAND_DEFINITION_TASKS_SORTING_CRITERIA.MODIFIED_DATE
      : ''

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          page: pagination.current,
          sortingCriterion,
          sortingOrder,
        }),
      )
    },
    [history, search, pathname],
  )

  return (
    <>
      <Typography.Text>
        {isLoadingTasks
          ? 'Loading records'
          : `${brandDefinitionTasks.total} matching records`}
      </Typography.Text>
      <Table
        loading={isLoadingTasks || isAssigningTask || isUnassigningTask}
        rowKey="id"
        columns={tableColumns}
        dataSource={brandDefinitionTasks.brandDefinitionTasks}
        pagination={{
          current: page,
          total: brandDefinitionTasks.total,
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
    </>
  )
}

export default BrandDefinitionTasksPage
