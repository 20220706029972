import React, { useCallback, useState } from 'react'
import ReactJson from 'react-json-view'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Row, Table, Typography } from 'antd'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { LondonDateTime } from '../../../../../../common/components'
import {
  ADMIN,
  DEFAULT_PAGE,
  MENU_PROBING_JOB_STATUSES,
} from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'
import {
  useIsLoggedInUser,
  useUserHasRoles,
} from '../../../../../../common/hooks'

import { MENU_PROBING_JOBS } from '../../../../../../menus/graphql/queries'
import {
  PARTIAL_SUCCESS,
  SCRAPING_STATUSES,
  SUCCESS,
} from '../../../../../constants'
import { APPLY_NEW_FINGERPRINTS_FROM_LEAD_TO_CLUSTER } from '../../../../../graphql/mutations'
import {
  getFingerprintsFile,
  getFingerprintsFromMenuProbingJob,
} from '../../../../../helpers'

const LeadMenuProbingJobsTable = ({
  menuScrapingCluster,
  onApplyNewFingerprints,
  isApplyFingerprintsButtonHidden,
}) => {
  const [menuProbingJobsPage, setMenuProbingJobsPage] = useState(DEFAULT_PAGE)

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const {
    data: {
      menuProbingJobs: {
        menuProbingJobs = [],
        total: menuProbingJobsCount,
      } = {},
    } = {},
    refetch: refetchMenuProbingJobs,
    loading: isLoadingMenuProbingSessions,
  } = useQuery(MENU_PROBING_JOBS, {
    variables: {
      menuScrapingClusterId: menuScrapingCluster.id,
      page: menuProbingJobsPage,
    },
    fetchPolicy: 'no-cache',
  })

  const [applyNewFingerprintsFromLeadToCluster] = useMutation(
    APPLY_NEW_FINGERPRINTS_FROM_LEAD_TO_CLUSTER,
    {
      onCompleted() {
        onApplyNewFingerprints()
        refetchMenuProbingJobs()
        showSuccessNotification({
          message: 'Fingerprints updated.',
          description:
            'Menu scraping fingerprints has been successfully updated.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping fingerprints update failed.',
          description: message,
        })
      },
    },
  )

  const handleApplyFingerprintsClick = useCallback(
    job =>
      applyNewFingerprintsFromLeadToCluster({
        variables: {
          menuProbingJobId: job.id,
        },
      }),
    [applyNewFingerprintsFromLeadToCluster],
  )

  const handleMenuProbingJobsChange = useCallback(
    page => {
      setMenuProbingJobsPage(page)
    },
    [setMenuProbingJobsPage],
  )

  const tableColumns = [
    {
      title: 'ID',
      width: '5%',
      dataIndex: 'id',
    },
    {
      title: 'Menu ID',
      width: '5%',
      dataIndex: 'menuId',
    },
    {
      title: 'Session ID',
      width: '5%',
      dataIndex: 'menuProbingSessionId',
    },
    {
      title: 'Job status',
      width: '10%',
      dataIndex: 'jobStatus',
      render: status => status && MENU_PROBING_JOB_STATUSES[status].name,
    },
    {
      title: 'Schedule date',
      width: '10%',
      dataIndex: 'scheduleDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Completed date',
      width: '10%',
      dataIndex: 'completedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Probing status',
      width: '10%',
      dataIndex: 'probingStatus',
      render: status => status && SCRAPING_STATUSES[status].name,
    },
    {
      title: 'Has changes',
      width: '5%',
      dataIndex: 'hasChanges',
      render: hasChanges => (hasChanges ? 'Yes' : 'No'),
    },
    {
      title: 'Fingerprints',
      width: '10%',
      render: job => (
        <>
          <Button
            margin="no small no no"
            disabled={
              job.probingStatus !== SUCCESS &&
              job.probingStatus !== PARTIAL_SUCCESS
            }
            onClick={() =>
              fileDownload(
                getFingerprintsFile(getFingerprintsFromMenuProbingJob(job)),
                `menu-probing-job-${job.id}.json`,
              )
            }
          >
            Download
          </Button>
          {!isApplyFingerprintsButtonHidden && (
            <Button
              disabled={
                (!isLoggedInUser && !isAdmin) ||
                (job.probingStatus !== SUCCESS &&
                  job.probingStatus !== PARTIAL_SUCCESS)
              }
              onClick={() => handleApplyFingerprintsClick(job)}
            >
              Apply
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Changes',
      width: '25%',
      render: job => {
        const changes = {
          addedIds: job.addedIds || [],
          removedIds: job.removedIds || [],
          addedClasses: job.addedClasses || [],
          removedClasses: job.removedClasses || [],
          addedXPathSignatures: job.addedXPathSignatures || [],
          removedXPathSignatures: job.removedXPathSignatures || [],
          updatedHashCodes: JSON.parse(job.updatedHashCodes),
        }

        return (
          <ReactJson
            src={changes}
            groupArraysAfterLength={null}
            collapsed
            name={false}
            enableClipboard={false}
            displayDataTypes={false}
          />
        )
      },
    },
  ]

  return (
    <Row>
      <Typography.Title level={3}>Lead menus probing jobs</Typography.Title>
      <Table
        rowKey="id"
        loading={isLoadingMenuProbingSessions}
        columns={tableColumns}
        dataSource={menuProbingJobs}
        pagination={{
          total: menuProbingJobsCount,
          current: menuProbingJobsPage,
          onChange: handleMenuProbingJobsChange,
        }}
      />
    </Row>
  )
}

LeadMenuProbingJobsTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  isApplyFingerprintsButtonHidden: PropTypes.bool,
  onApplyNewFingerprints: PropTypes.func,
}

LeadMenuProbingJobsTable.defaultProps = {
  onApplyNewFingerprints: () => {},
  isApplyFingerprintsButtonHidden: false,
}

export default LeadMenuProbingJobsTable
