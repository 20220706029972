import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { CLONE_LOCATION } from '../../../graphql/mutations'
import { transformToCloneLocationInput } from '../../../graphql/transformers'
import { CloneLocationForm } from '../../forms'

const CloneLocationModal = ({ location, isModalOpen, closeModal }) => {
  const [cloneLocation, { loading: isCloningLocation }] = useMutation(
    CLONE_LOCATION,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Location cloned.',
          description: 'Location has been successfully cloned.',
        })
        closeModal()
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Clone location failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    data => {
      cloneLocation({
        variables: { data: transformToCloneLocationInput(data) },
      })
    },
    [cloneLocation],
  )

  return (
    <Modal
      title="Clone Location"
      visible={isModalOpen}
      footer={null}
      onCancel={closeModal}
    >
      <CloneLocationForm
        location={location}
        onSubmit={handleSubmit}
        isLoading={isCloningLocation}
        closeModal={closeModal}
      />
    </Modal>
  )
}

CloneLocationModal.propTypes = {
  location: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default CloneLocationModal
