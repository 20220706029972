import { gql } from 'apollo-boost'

const SIMILAR_MASTER_BRANDS_BY_LOCATION_WEBSITE = gql`
  query similarMasterBrandsByLocationWebsite(
    $id: ID!
    $page: Int
    $pageSize: Int
    $sortingCriterion: SimilarBrandsSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    similarMasterBrandsByLocationWebsite(
      id: $id
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      brands {
        id
        name
        locationCount
        website
        brandGatewayTask {
          id
        }
      }
      total
    }
  }
`

export default SIMILAR_MASTER_BRANDS_BY_LOCATION_WEBSITE
