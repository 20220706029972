import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const MapSearchBoxControls = styled.div`
  display: flex;
`

export const MapSearchBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 360px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px ${transparentize(0.9, '#000')};
  z-index: 1000;
`

export const Map = styled.div`
  position: relative;
  width: 100%;
  height: 50vh;

  ${props =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 900;
    `}
`
