import { gql } from 'apollo-boost'

const MENU_SCRAPING_INSTRUCTIONS_VERSIONS = gql`
  query menuScrapingInstructionsVersions($menuId: ID!) {
    menuScrapingInstructionsVersions(menuId: $menuId) {
      id
      menuId
      number
      scrapingInstructions
      createdDate
      createdBy {
        id
        name
      }
    }
  }
`

export default MENU_SCRAPING_INSTRUCTIONS_VERSIONS
