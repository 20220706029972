import { gql } from 'apollo-boost'

const SAVE_BRAND_GATEWAY_TASK = gql`
  mutation saveBrandGatewayTask(
    $id: ID!
    $brandData: SaveBrandGatewayTaskBrandInput
    $locationsData: [SaveBrandGatewayTaskLocationInput!]!
    $newSlaveBrandIds: [ID!]!
    $newNotSameBrandIds: [ID!]!
    $removedSlaveBrandIds: [ID!]!
    $removedNotSameBrandIds: [ID!]!
  ) {
    saveBrandGatewayTask(
      id: $id
      brandData: $brandData
      locationsData: $locationsData
      newSlaveBrandIds: $newSlaveBrandIds
      newNotSameBrandIds: $newNotSameBrandIds
      removedSlaveBrandIds: $removedSlaveBrandIds
      removedNotSameBrandIds: $removedNotSameBrandIds
    ) {
      id
    }
  }
`

export default SAVE_BRAND_GATEWAY_TASK
