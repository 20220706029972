import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { SET_MENU_SCRAPING_ISSUE_COMMENT } from '../../../../menus/graphql/mutations'
import { ScrapingIssueCommentForm } from '../../forms'

const MenuScrapingIssueCommentModal = ({
  menu,
  isModalOpen,
  setIsModalOpen,
  onCommentChanged,
  disabled,
}) => {
  const [
    setMenuScrapingIssueComment,
    { loading: isSettingScrapingIssueComment },
  ] = useMutation(SET_MENU_SCRAPING_ISSUE_COMMENT, {
    onCompleted({
      setMenuScrapingIssueComment: { scrapingIssueComment, status },
    }) {
      const description = isNil(scrapingIssueComment)
        ? 'Scraping issue comment removed'
        : 'Scraping issue comment set'

      showSuccessNotification({
        message: 'Scraping issue',
        description,
      })

      setIsModalOpen(false)
      onCommentChanged(scrapingIssueComment, status)
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Scraping issue',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    menu => setMenuScrapingIssueComment({ variables: menu }),
    [setMenuScrapingIssueComment],
  )

  const handleClose = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  return (
    <Modal
      title="Scraping issue"
      visible={isModalOpen}
      onCancel={handleClose}
      footer={null}
    >
      <ScrapingIssueCommentForm
        menu={menu}
        closeModal={handleClose}
        isSettingScrapingIssueComment={isSettingScrapingIssueComment}
        disabled={disabled}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

MenuScrapingIssueCommentModal.propTypes = {
  menu: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onCommentChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

MenuScrapingIssueCommentModal.defaultProps = {
  disabled: false,
}

export default MenuScrapingIssueCommentModal
