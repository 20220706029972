import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'

import { AuthContext } from '../../contexts'
import { REFRESH_ACCESS_TOKEN, SET_USER } from '../../graphql/mutations'
import { GET_USER } from '../../graphql/queries'
import {
  checkIfTokenIsValid,
  createSession,
  decodeToken,
  getAccessToken,
  getRefreshToken,
  getUser,
} from '../../services'

const AuthProvider = ({ children }) => {
  const { data } = useQuery(GET_USER)
  const [setUser] = useMutation(SET_USER)

  const contextValue = useMemo(() => ({ user: data?.user || getUser() }), [
    data,
  ])

  const [isInitialized, setIsInitialized] = useState(false)
  const initialiseSession = useCallback(
    (accessToken, refreshToken) => {
      const userData = decodeToken(accessToken)
      const { permissions } = getUser()
      const user = { ...userData, permissions }

      createSession(user, accessToken, refreshToken)
      setUser({ variables: { user } })

      setIsInitialized(true)
    },
    [setUser],
  )

  const [refreshAccessToken] = useMutation(REFRESH_ACCESS_TOKEN, {
    onCompleted({ refreshAccessToken: accessToken }) {
      const refreshToken = getRefreshToken()
      initialiseSession(accessToken, refreshToken)
    },
  })

  useEffect(() => {
    if (isInitialized) return

    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()

    if (accessToken && checkIfTokenIsValid(accessToken)) {
      initialiseSession(accessToken, refreshToken)
    } else if (refreshToken && checkIfTokenIsValid(refreshToken)) {
      refreshAccessToken({ variables: { refreshToken } })
    }
  }, [isInitialized, refreshAccessToken, initialiseSession])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
