import { gql } from 'apollo-boost'

const START_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER = gql`
  mutation startTemplateValidationSessionForCluster(
    $id: ID!
    $data: StartTemplateValidationSessionForClusterInput!
  ) {
    startTemplateValidationSessionForCluster(id: $id, data: $data) {
      id
    }
  }
`

export default START_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER
