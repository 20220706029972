import { gql } from 'apollo-boost'

const CREATE_GOOGLE_LOCATION = gql`
  mutation createGoogleLocation($placeId: ID!) {
    createGoogleLocation(placeId: $placeId) {
      id
      placeId
      name
      website
      phone
      businessStatus
      formattedAddress
      streetNumber
      street
      postCode
      country
      coordinates {
        latitude
        longitude
      }
    }
  }
`

export default CREATE_GOOGLE_LOCATION
