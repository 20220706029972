import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { Spinner } from '../../../common/components'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { MENU_SCRAPING_CLUSTER } from '../../graphql/queries'
import { MenuScrapingClusterForm } from '../forms'

import {
  MenuScrapingClusterRerunFlowStepsIndicator,
  MenuScrapingClusterSteps,
} from './sections'

const MenuScrapingClusterPage = () => {
  const { id: menuScrapingClusterId } = useParams()
  useDocumentTitle('Menu Scraping Cluster')

  const {
    data: { menuScrapingCluster } = {},
    refetch: refetchMenuScrapingCluster,
    loading: isLoadingMenuScrapingCluster,
  } = useQuery(MENU_SCRAPING_CLUSTER, {
    variables: { id: menuScrapingClusterId },
    skip: !menuScrapingClusterId,
    fetchPolicy: 'no-cache',
  })

  if (isLoadingMenuScrapingCluster) {
    return <Spinner />
  }

  return (
    <>
      <MenuScrapingClusterForm
        menuScrapingCluster={menuScrapingCluster}
        refetchMenuScrapingCluster={refetchMenuScrapingCluster}
      />
      <MenuScrapingClusterRerunFlowStepsIndicator
        menuScrapingCluster={menuScrapingCluster}
        refetchMenuScrapingCluster={refetchMenuScrapingCluster}
      />
      <MenuScrapingClusterSteps
        menuScrapingCluster={menuScrapingCluster}
        refetchMenuScrapingCluster={refetchMenuScrapingCluster}
      />
    </>
  )
}

export default MenuScrapingClusterPage
