import { gql } from 'apollo-boost'

const UNMARK_BRAND_DEFINITION_TASK_AS_AWAITING = gql`
  mutation unmarkBrandDefinitionTaskAsAwaiting($id: ID!) {
    unmarkBrandDefinitionTaskAsAwaiting(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNMARK_BRAND_DEFINITION_TASK_AS_AWAITING
