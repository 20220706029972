import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { keys, size, some } from 'lodash-es'

import { getQueryParams } from '../../../core/utils/services/queryParams'

import { PAGES_FOR_CACHING_FILTERS } from '../../constants'
import { cacheFilterForPage } from '../../services'

const useCacheUrl = () => {
  const history = useHistory()

  useEffect(() => {
    history.listen(({ pathname, search }) => {
      const queryParams = getQueryParams(search)
      if (size(keys(queryParams)) === 1 && queryParams.brandSearchTerm) return

      if (some(PAGES_FOR_CACHING_FILTERS, page => pathname.startsWith(page))) {
        cacheFilterForPage(pathname, `${pathname}${search}`)
      }
    })
  }, [history])
}

export default useCacheUrl
