import { gql } from 'apollo-boost'

const SET_MENU_EXCLUDE_FROM_CURATION = gql`
  mutation setMenuExcludeFromCuration(
    $id: ID!
    $isExcludedFromCuration: Boolean!
  ) {
    setMenuExcludeFromCuration(
      id: $id
      isExcludedFromCuration: $isExcludedFromCuration
    ) {
      id
      isExcludedFromCuration
    }
  }
`

export default SET_MENU_EXCLUDE_FROM_CURATION
