import { gql } from 'apollo-boost'

const DELETE_MENU_SCRAPING_TEMPLATE = gql`
  mutation deleteMenuScrapingTemplate($id: ID!) {
    deleteMenuScrapingTemplate(id: $id) {
      id
      name
    }
  }
`

export default DELETE_MENU_SCRAPING_TEMPLATE
