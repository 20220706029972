import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  ExcludedFromSimilarMenusTable,
  LeadsManuallyRemovedFromClusterMenusTable,
  MenuScrapingClusterMenusTable,
  MenusManuallyRemovedFromClusterMenusTable,
  MenuStatusesTable,
  SimilarMenusByUrlSignatureTable,
} from './tables'

const MenuScrapingClusterPreparationStep = ({ menuScrapingCluster }) => {
  const [
    menuStatusesTableRefreshKey,
    setMenuStatusesTableRefreshKey,
  ] = useState(Math.random())

  const [menusTableRefreshKey, setMenusTableRefreshKey] = useState(
    Math.random(),
  )

  const [
    manuallyRemovedMenusTableRefreshKey,
    setManuallyRemovedMenusTableRefreshKey,
  ] = useState(Math.random())

  const [
    manuallyRemovedLeadsTableRefreshKey,
    setManuallyRemovedLeadsTableRefreshKey,
  ] = useState(Math.random())

  const [
    similarMenusByUrlSignatureTableRefreshKey,
    setSimilarMenusByUrlSignatureTableRefreshKey,
  ] = useState(Math.random())

  const [
    excludedFromSimilarMenusTableRefreshKey,
    setExcludedFromSimilarMenusTableRefreshKey,
  ] = useState(Math.random())

  const [
    isExcludedFromSimilarMenusTableHidden,
    setIsExcludedFromSimilarMenusTableHidden,
  ] = useState(true)

  const [
    isManuallyRemovedMenusTableHidden,
    setIsManuallyRemovedMenusTableHidden,
  ] = useState(true)

  const [
    isManuallyRemovedLeadsTableHidden,
    setIsManuallyRemovedLeadsTableHidden,
  ] = useState(true)

  const refreshMenuStatusesTable = useCallback(() => {
    setMenuStatusesTableRefreshKey(Math.random())
  }, [])

  const refreshMenusTable = useCallback(() => {
    setMenusTableRefreshKey(Math.random())
  }, [])

  const refreshManuallyRemovedMenusTable = useCallback(() => {
    setManuallyRemovedMenusTableRefreshKey(Math.random())
  }, [])

  const refreshManuallyRemovedLeadsTable = useCallback(() => {
    setManuallyRemovedLeadsTableRefreshKey(Math.random())
  }, [])

  const refreshSimilarMenusByUrlSignatureTable = useCallback(() => {
    setSimilarMenusByUrlSignatureTableRefreshKey(Math.random())
  }, [])

  const refreshExcludedFromSimilarMenusTable = useCallback(() => {
    setExcludedFromSimilarMenusTableRefreshKey(Math.random())
  }, [])

  const toggleIsManuallyRemovedMenusTableHidden = useCallback(() => {
    setIsManuallyRemovedMenusTableHidden(prev => !prev)
  }, [])

  const toggleIsManuallyRemovedLeadsTableHidden = useCallback(() => {
    setIsManuallyRemovedLeadsTableHidden(prev => !prev)
  }, [])

  const toggleIsExcludedFromSimilarMenusTableHidden = useCallback(() => {
    setIsExcludedFromSimilarMenusTableHidden(prev => !prev)
  }, [])

  return (
    <>
      <MenuStatusesTable
        key={menuStatusesTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        onMenusReset={() => refreshMenusTable()}
      />
      <MenuScrapingClusterMenusTable
        key={menusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isUpdateRoleButtonHidden
        isUpdateMenuScrapingClusterHidden
        onRemoveMenuFromMenuScrapingCluster={() => {
          refreshMenuStatusesTable()
          refreshManuallyRemovedMenusTable()
          refreshManuallyRemovedLeadsTable()
        }}
      />
      <MenusManuallyRemovedFromClusterMenusTable
        key={manuallyRemovedMenusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedMenusTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedMenusTableHidden()
        }}
        onAttachMenuBackToClusterMenus={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
      />
      <LeadsManuallyRemovedFromClusterMenusTable
        key={manuallyRemovedLeadsTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedLeadsTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedLeadsTableHidden()
        }}
      />
      <SimilarMenusByUrlSignatureTable
        key={similarMenusByUrlSignatureTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        onAttachMenuToCluster={() => {
          refreshMenusTable()
          refreshMenuStatusesTable()
        }}
        onExcludeMenuFromClusterSimilarMenus={() => {
          refreshExcludedFromSimilarMenusTable()
        }}
      />
      <ExcludedFromSimilarMenusTable
        key={excludedFromSimilarMenusTableRefreshKey}
        isHidden={isExcludedFromSimilarMenusTableHidden}
        toggleIsHidden={() => {
          toggleIsExcludedFromSimilarMenusTableHidden()
        }}
        menuScrapingCluster={menuScrapingCluster}
        onRemoveMenuFromClusterExcludedMenus={() => {
          refreshSimilarMenusByUrlSignatureTable()
        }}
      />
    </>
  )
}

MenuScrapingClusterPreparationStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterPreparationStep
