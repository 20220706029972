import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import { Spinner } from '../../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { getCachedFilterForPage } from '../../../../common/services'

import { MENU_RESCRAPING_COMMENT } from '../../../../menus/graphql/queries'
import { RESOLVE_DISH_REVIEW_TASK_BY_REJECT_MENU } from '../../../graphql/mutations'
import { RejectMenuForm } from '../../forms'

const onSubmit = (variables, setRescrapingComment) =>
  setRescrapingComment({ variables })

const RejectMenuModal = ({ taskId, menuId, isModalOpen, setIsModalOpen }) => {
  const history = useHistory()

  const {
    data: { menuRescrapingComment } = {},
    loading: isLoadingMenuRescrapingComment,
  } = useQuery(MENU_RESCRAPING_COMMENT, {
    variables: { id: menuId },
    fetchPolicy: 'no-cache',
  })

  const [
    resolveDishReviewTaskByRejectMenu,
    { loading: isRejectingMenu },
  ] = useMutation(RESOLVE_DISH_REVIEW_TASK_BY_REJECT_MENU, {
    onCompleted() {
      showSuccessNotification({
        message: 'Reject menu',
        description:
          'Menu rescraping comment has been successfully set and dish review task was resolved by rejecting menu',
      })
      setIsModalOpen(false)
      history.push(getCachedFilterForPage('/tasks/dish-review'))
    },

    onError({ message }) {
      showErrorNotification({
        message: 'Menu rescraping comment.',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    menu => onSubmit(menu, resolveDishReviewTaskByRejectMenu),
    [resolveDishReviewTaskByRejectMenu],
  )

  return isLoadingMenuRescrapingComment ? (
    <Spinner />
  ) : (
    <Modal
      title="Menu rescraping comment"
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <RejectMenuForm
        taskId={taskId}
        menuRescrapingComment={menuRescrapingComment}
        closeModal={() => setIsModalOpen(false)}
        isRejectingMenu={isRejectingMenu}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

RejectMenuModal.propTypes = {
  taskId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default RejectMenuModal
