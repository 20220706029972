import React, { useMemo } from 'react'
import { filter, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Select } from '../../../../core/components'

const DuplicateLocationSelect = ({
  locationIndex,
  currentLocationId,
  locations,
  onChange,
}) => {
  const locationOptions = useMemo(() => {
    const otherLocations = filter(
      locations,
      ({ id }) => id !== currentLocationId,
    )

    return map(otherLocations, location => ({
      name: `${location.position} - ${location.name}`,
      value: location.id,
    }))
  }, [currentLocationId, locations])

  return (
    <Select
      style={{ width: '100%' }}
      allowClear
      showSearch
      name={`locations.${locationIndex}.duplicateOfId`}
      options={locationOptions}
      onSelectChange={onChange}
    />
  )
}

DuplicateLocationSelect.propTypes = {
  locationIndex: PropTypes.number.isRequired,
  currentLocationId: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func,
}

DuplicateLocationSelect.defaultProps = {
  onChange: undefined,
}

export default DuplicateLocationSelect
