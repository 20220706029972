import { gql } from 'apollo-boost'

const COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU = gql`
  query completedMenuScrapingJobsForMenu($menuId: ID!) {
    completedMenuScrapingJobsForMenu(menuId: $menuId) {
      menuScrapingJobId
      completedDate
      responseType
      scrapingStatus
    }
  }
`

export default COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU
