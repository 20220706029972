import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Form, Typography } from 'antd'
import { first, map, without } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

const LocationsList = ({ name, label }) => {
  const { control } = useFormContext()
  const [showMore, setShowMore] = useState(false)

  const { fields: locations } = useFieldArray({
    control,
    name,
  })

  const firstLocation = first(locations)

  const handleShowMoreLocations = useCallback(
    () =>
      showMore ? (
        <>
          <Typography.Paragraph style={{ lineHeight: '1.3em' }}>
            {`${firstLocation.name}`}
            <Button type="link" onClick={() => setShowMore(!showMore)}>
              (Show less)
            </Button>
          </Typography.Paragraph>
          {map(without(locations, firstLocation), ({ name }, index) => (
            <p key={index} className="paragraph-locations">{`${name}`}</p>
          ))}
        </>
      ) : (
        <Typography.Paragraph style={{ lineHeight: '1.3em' }}>
          {`${firstLocation.name}`}
          <Button type="link" onClick={() => setShowMore(!showMore)}>
            {` (${locations.length - 1} more)`}
          </Button>
        </Typography.Paragraph>
      ),
    [showMore, locations, firstLocation],
  )

  return (
    <Form.Item label={label}>
      {locations.length > 1
        ? handleShowMoreLocations()
        : map(locations, ({ name }) => `${name}`)}
    </Form.Item>
  )
}

LocationsList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

LocationsList.defaultProps = {
  label: '',
}

export default LocationsList
