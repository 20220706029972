import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { intersectionWith, isEmpty, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Select } from '../../../../core/components'

import { GET_ROLES } from '../../../../account/graphql/queries'
import { USERS } from '../../../graphql/queries'

const UsersDropdown = ({
  name,
  label,
  disableInctiveUsers,
  withRoles,
  ...props
}) => {
  const { data: { roles } = {} } = useQuery(GET_ROLES, { skip: !withRoles })

  const roleIds = map(
    intersectionWith(
      roles,
      withRoles,
      (role, withRole) => role.name === withRole,
    ),
    'id',
  )

  const { data: { users } = {}, loading: isLoadingUsers } = useQuery(USERS, {
    variables: {
      filters: {
        roleIds: isEmpty(roleIds) ? undefined : roleIds,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const usersOptions = map(users, ({ name, id, isActive }) => ({
    name,
    value: id,
    disabled: disableInctiveUsers && !isActive,
  }))

  return (
    <Select
      name={name}
      label={label}
      options={usersOptions}
      loading={isLoadingUsers}
      {...props}
    />
  )
}

UsersDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disableInctiveUsers: PropTypes.bool,
  withRoles: PropTypes.array,
}

UsersDropdown.defaultProps = {
  label: 'Users',
  disableInctiveUsers: true,
  withRoles: undefined,
}

export default UsersDropdown
