import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { showErrorNotification } from '../../../../common/helpers'

import { generateScrapingClusterMenusReport } from '../../../../reports/rest'

const GenerateScrapingClusterMenusReportButton = ({
  scrapingClusterId,
  style,
}) => {
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(async () => {
    try {
      setIsGeneratingReport(true)
      const reportResponse = await generateScrapingClusterMenusReport({
        scrapingClusterId,
      })

      fileDownload(reportResponse.data, 'ScrapingClusterMenusReport.csv')
    } catch ({ response }) {
      showErrorNotification({
        message: 'Failed to generate report.',
        description: response && response.statusText,
      })
    } finally {
      setIsGeneratingReport(false)
    }
  }, [scrapingClusterId, setIsGeneratingReport])

  return (
    <Button style={style} onClick={generateReport} loading={isGeneratingReport}>
      Generate menus report
    </Button>
  )
}

GenerateScrapingClusterMenusReportButton.propTypes = {
  scrapingClusterId: PropTypes.string.isRequired,
  style: PropTypes.object,
}

GenerateScrapingClusterMenusReportButton.defaultProps = {
  style: {},
}

export default GenerateScrapingClusterMenusReportButton
