import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { TASK_AWAITING_REASONS } from '../../tasks/constants'
import { DEFAULT_PAGE } from '../constants'

const TaskAwaitingReasonFilter = ({ disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { awaitingReason } = getQueryParams(search)

  const handleChange = useCallback(
    awaitingReason =>
      history.push(
        createUrl(pathname, search, {
          awaitingReason,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  return (
    <Form.Item label="Awaiting reason">
      <Select
        style={{ width: '100%' }}
        value={awaitingReason}
        onChange={handleChange}
        disabled={disabled}
        dropdownStyle={{ minWidth: 'fit-content' }}
        allowClear
      >
        {map(TASK_AWAITING_REASONS, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

TaskAwaitingReasonFilter.propTypes = {
  disabled: PropTypes.bool,
}

TaskAwaitingReasonFilter.defaultProps = {
  disabled: false,
}

export default TaskAwaitingReasonFilter
