import { Button as AntButton } from 'antd'
import styled from 'styled-components'

import { sizeMapper } from '../../utils/styles'

const Button = styled(AntButton)`
  margin: ${props => sizeMapper(props.margin, 'no')};
`

export default Button
