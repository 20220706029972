import { gql } from 'apollo-boost'

const LOGIN_WITH_GOOGLE_ID_TOKEN = gql`
  mutation loginWithGoogleIdToken($googleIdToken: String!) {
    loginWithGoogleIdToken(googleIdToken: $googleIdToken) {
      accessToken
      refreshToken
      permissions
    }
  }
`

export default LOGIN_WITH_GOOGLE_ID_TOKEN
