import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input } from 'antd'
import { trim } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../constants'

const MenuIdInput = ({ disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { menuId } = getQueryParams(search)

  const handleChange = useCallback(
    ({ target: { value } }) =>
      history.push(
        createUrl(pathname, search, {
          menuId: trim(value),
          menusPage: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  return (
    <Form.Item label="Menu ID">
      <Input
        placeholder="Menu ID"
        value={menuId}
        onChange={handleChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}

MenuIdInput.propTypes = {
  disabled: PropTypes.bool,
}

MenuIdInput.defaultProps = {
  disabled: false,
}

export default MenuIdInput
