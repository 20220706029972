import { gql } from 'apollo-boost'

const ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER = gql`
  mutation attachMenuToMenuScrapingCluster($id: ID!, $menuId: ID!) {
    attachMenuToMenuScrapingCluster(id: $id, menuId: $menuId) {
      id
    }
  }
`

export default ATTACH_MENU_TO_MENU_SCRAPING_CLUSTER
