import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row, Typography } from 'antd'
import { isNull } from 'lodash-es'
import PropTypes from 'prop-types'

import { FormDate, FormText, Input } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import {
  HistoryDrawer,
  LondonDateTime,
  Spinner,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  EMPTY_VALUE,
  LOCATION,
  LOCATIONS_PERMISSIONS,
} from '../../../common/constants'
import { useUserHasPermissions } from '../../../common/hooks'

import { PermissionGuard } from '../../../account/components/routes'
import { LocationMenuTable, LocationWorkingHours } from '../pages/sections'

import { locationFormInitialValues } from './initial-values'
import { locationSchema } from './schemas'

const LocationForm = ({
  location,
  isUpdatingLocation,
  isRefetchingLocation,
  onSubmit,
}) => {
  const history = useHistory()

  const defaultValues = useMemo(() => locationFormInitialValues(location), [
    location,
  ])

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(locationSchema),
  })

  const { reset, handleSubmit } = formMethods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const canUserUpdateLocation = useUserHasPermissions(
    LOCATIONS_PERMISSIONS.UPDATE,
  )

  return (
    <>
      {(isUpdatingLocation || isRefetchingLocation) && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageHeader sticky>
            <PageTitle>
              Location
              <HistoryDrawer entityType={LOCATION} entityId={location.id} />
            </PageTitle>
            <PageActions>
              <Button
                onClick={() => history.push(`/brands/${location.brand.id}`)}
                margin="no small"
              >
                Go back
              </Button>
              <PermissionGuard permissions={LOCATIONS_PERMISSIONS.UPDATE}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </PermissionGuard>
            </PageActions>
          </PageHeader>
          <Panel withBorderBottom>
            <Row gutter={[16, 16]}>
              <Col span={2}>
                <FormText name="id" label="Location ID" />
              </Col>
              <Col span={5}>
                <Input
                  name="name"
                  label="Location"
                  disabled={!canUserUpdateLocation}
                />
              </Col>
              <Col span={5}>
                <Input
                  name="website"
                  label="Website"
                  disabled={!canUserUpdateLocation}
                />
              </Col>
              <Col span={2}>
                <FormText name="status" label="Status" />
              </Col>
              <Col span={3}>
                <FormText name="businessStatus" label="Business status" />
              </Col>
              <Col span={3}>
                <FormText label="Menu status" name="menuStatus" />
              </Col>
              <Col span={3}>
                <FormDate name="modifiedDate" label="Updated date" />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={2}>
                <FormText name="brand.id" label="Brand ID" />
              </Col>
              <Col span={5}>
                <FormText name="brand.name" label="Brand name" />
              </Col>
            </Row>
          </Panel>
          <Panel margin="small no no" withBorderBottom>
            <Typography.Text strong>
              Health & Safety data (
              <LondonDateTime date={location.fsaLocation.createdDate} />)
            </Typography.Text>
            <Row gutter={16}>
              <Col span={4}>
                <FormText name="fsaLocation.name" label="Name" />
              </Col>
              <Col span={9}>
                <FormText name="fsaLocation.formattedAddress" label="Address" />
              </Col>
              <Col span={2}>
                <FormText name="fsaLocation.rating" label="Rating" />
              </Col>
              <Col span={3}>
                <FormDate name="fsaLocation.ratingDate" label="Rating date" />
              </Col>
            </Row>
          </Panel>
          <Panel margin="small no no" withBorderBottom>
            <Typography.Text strong>
              Google data (
              {isNull(location.googleLocation) ? (
                EMPTY_VALUE
              ) : (
                <LondonDateTime date={location.googleLocation.createdDate} />
              )}
              )
            </Typography.Text>
            <Row gutter={16}>
              <Col span={4}>
                <FormText name="googleLocation.name" label="Name" />
              </Col>
              <Col span={4}>
                <FormText name="googleLocation.street" label="Street address" />
              </Col>
              <Col span={3}>
                <FormText name="googleLocation.city" label="City" />
              </Col>
              <Col span={3}>
                <FormText name="googleLocation.postCode" label="Post code" />
              </Col>
              <Col span={2}>
                <FormText
                  name="googleLocation.coordinates.latitude"
                  label="Latitude"
                />
              </Col>
              <Col span={2}>
                <FormText
                  name="googleLocation.coordinates.longitude"
                  label="Longitude"
                />
              </Col>
              <Col span={3}>
                <FormText name="googleLocation.phone" label="Phone" />
              </Col>
              <Col span={1}>
                <FormText name="googleLocation.rating" label="Rating" />
              </Col>
            </Row>
          </Panel>
        </form>
        <LocationWorkingHours location={location} />
        <LocationMenuTable location={location} />
      </FormProvider>
    </>
  )
}

LocationForm.propTypes = {
  location: PropTypes.object.isRequired,
  isUpdatingLocation: PropTypes.bool.isRequired,
  isRefetchingLocation: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default LocationForm
