import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTER_MENUS = gql`
  query menuScrapingClusterMenus(
    $id: ID!
    $page: Int
    $pageSize: Int
    $filters: MenuScrapingClusterMenusFiltersInput
    $sortingCriterion: MenuScrapingClusterMenusSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    menuScrapingClusterMenus(
      id: $id
      page: $page
      pageSize: $pageSize
      filters: $filters
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      menus {
        id
        url
        status
        format
        scrapedDate
        scrapingStatus
        notScrapeableReason
        notes
        brand {
          id
          name
        }
        menuScrapingAvailability {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          isSharedByMultipleLocations
          isAlwaysAvailable
          isDefaultAvailability
        }
        menuScrapingClusterId
        scrapingClusterRole
        attachedToScrapingClusterDate
      }
      total
    }
  }
`

export default MENU_SCRAPING_CLUSTER_MENUS
