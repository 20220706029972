import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Form, Typography } from 'antd'
import { first, map, size, without } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

const PlatformsList = ({ name, label }) => {
  const { control } = useFormContext()
  const [showMore, setShowMore] = useState(false)

  const { fields: platforms } = useFieldArray({
    control,
    name,
  })

  const firstPlatform = first(platforms)

  const handleShowMorePlatforms = useCallback(
    () =>
      showMore ? (
        <>
          <Typography.Paragraph style={{ lineHeight: '1.3em' }}>
            {`${firstPlatform.name}`}
            <Button type="link" onClick={() => setShowMore(!showMore)}>
              (Show less)
            </Button>
          </Typography.Paragraph>
          {map(without(platforms, firstPlatform), ({ name }, index) => (
            <p key={index} className="paragraph-platforms">{`${name}`}</p>
          ))}
        </>
      ) : (
        <Typography.Paragraph style={{ lineHeight: '1.3em' }}>
          {`${firstPlatform.name}`}
          <Button type="link" onClick={() => setShowMore(!showMore)}>
            {` (${size(platforms) - 1} more)`}
          </Button>
        </Typography.Paragraph>
      ),
    [showMore, platforms, firstPlatform],
  )

  return (
    <Form.Item label={label}>
      {size(platforms) > 1
        ? handleShowMorePlatforms()
        : map(platforms, ({ name }) => `${name}`)}
    </Form.Item>
  )
}

PlatformsList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

PlatformsList.defaultProps = {
  label: '',
}

export default PlatformsList
