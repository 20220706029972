const markTaskAsAwaitingFormInitialValues = () => {
  const initialValues = {
    awaitingReason: '',
    awaitingReasonComment: '',
  }

  return initialValues
}

export default markTaskAsAwaitingFormInitialValues
