import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Input, Row, Select, Table, Typography } from 'antd'
import { map } from 'lodash-es'

import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import { PageActions, PageHeader } from '../../../common/components/styled'
import { DEFAULT_PAGE, SORTING_ORDERS_MAP } from '../../../common/constants'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder } from '../../../common/utils'

import {
  MENU_SCRAPING_TEMPLATE_STATUSES,
  MENU_SCRAPING_TEMPLATES_SORTING_CRITERIA,
} from '../../constants'
import { MENU_SCRAPING_TEMPLATES } from '../../graphql/queries'

import { MenuScrapingTemplatesTableActionsColumn } from './sections'

const getSortingCriterion = (field, order) => {
  if (order) {
    if (field === 'name') {
      return MENU_SCRAPING_TEMPLATES_SORTING_CRITERIA.NAME
    }
    if (field === 'status') {
      return MENU_SCRAPING_TEMPLATES_SORTING_CRITERIA.STATUS
    }
  }
  return null
}

const MenuScrapingTemplatesPage = () => {
  useDocumentTitle('Menu Scraping Templates')
  const history = useHistory()
  const { search, pathname } = useLocation()
  const {
    page = DEFAULT_PAGE,
    templateSearchTerm: queryParamTemplateSearchTerm,
    templateStatus,
    sortingCriterion,
    sortingOrder,
  } = getQueryParams(search)

  const [templateSearchTerm, setTemplateSearchTerm] = useState()

  const handleSearch = useCallback(
    templateSearchTerm =>
      history.push(
        createUrl(pathname, search, {
          templateStatus,
          templateSearchTerm,
          sortingCriterion,
          sortingOrder,
          page: DEFAULT_PAGE,
        }),
      ),
    [templateStatus, history, pathname, search, sortingCriterion, sortingOrder],
  )

  const handleTemplateStatusChange = useCallback(
    templateStatus =>
      history.push(
        createUrl(pathname, search, {
          templateStatus,
          templateSearchTerm,
          sortingCriterion,
          sortingOrder,
          page: DEFAULT_PAGE,
        }),
      ),
    [
      templateSearchTerm,
      history,
      pathname,
      search,
      sortingCriterion,
      sortingOrder,
    ],
  )

  useEffect(() => setTemplateSearchTerm(queryParamTemplateSearchTerm), [
    queryParamTemplateSearchTerm,
  ])

  const {
    data: { menuScrapingTemplates = {} } = {},
    loading: isLoadingMenuScrapingTemplates,
    refetch: refetchMenuScrapingTemplates,
  } = useQuery(MENU_SCRAPING_TEMPLATES, {
    variables: {
      page,
      filters: {
        templateStatus,
        templateSearchTerm,
      },
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  const tableColumns = [
    {
      title: 'Id',
      width: '5%',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      width: '15%',
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder:
        sortingCriterion === MENU_SCRAPING_TEMPLATES_SORTING_CRITERIA.NAME
          ? SORTING_ORDERS_MAP[sortingOrder].antd
          : null,
    },
    {
      title: 'Menu count',
      width: '15%',
      dataIndex: 'menuCount',
    },
    {
      title: 'Status',
      width: '10%',
      dataIndex: 'status',
      render: status => MENU_SCRAPING_TEMPLATE_STATUSES[status].name,
      sorter: true,
      defaultSortOrder:
        sortingCriterion === MENU_SCRAPING_TEMPLATES_SORTING_CRITERIA.STATUS
          ? SORTING_ORDERS_MAP[sortingOrder].antd
          : null,
    },
    {
      title: 'Version',
      width: '5%',
      dataIndex: 'scrapingInstructionsVersionNumber',
    },
    {
      title: 'Created date',
      width: '10%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Created by',
      width: '10%',
      dataIndex: 'createdBy.name',
    },
    {
      title: 'Modified date',
      width: '10%',
      dataIndex: 'modifiedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Modified by',
      width: '10%',
      dataIndex: 'modifiedBy.name',
    },
    {
      align: 'right',
      render: menuScrapingTemplate => (
        <MenuScrapingTemplatesTableActionsColumn
          menuScrapingTemplate={menuScrapingTemplate}
          refetchMenuScrapingTemplates={refetchMenuScrapingTemplates}
        />
      ),
    },
  ]

  const handlePageChange = useCallback(
    page =>
      history.push(
        createUrl(pathname, search, {
          page,
          templateStatus,
          templateSearchTerm,
          sortingCriterion,
          sortingOrder,
        }),
      ),
    [
      history,
      pathname,
      search,
      templateStatus,
      templateSearchTerm,
      sortingCriterion,
      sortingOrder,
    ],
  )

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          templateStatus,
          templateSearchTerm,
          sortingCriterion,
          sortingOrder,
          page: pagination.current,
        }),
      )
    },
    [history, pathname, search, templateSearchTerm, templateStatus],
  )

  return (
    <>
      <PageHeader sticky>
        <Typography.Title level={4}>Templates</Typography.Title>
        <PageActions>
          <Button
            onClick={() =>
              history.push('/menu-scraping-templates/templates/create')
            }
          >
            Add template
          </Button>
        </PageActions>
      </PageHeader>
      <Panel margin="small no">
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Input.Search
              placeholder="Search template"
              value={templateSearchTerm}
              allowClear
              onChange={({ target: { value } }) => setTemplateSearchTerm(value)}
              onSearch={handleSearch}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              value={templateStatus}
              onChange={handleTemplateStatusChange}
              allowClear
              placeholder="Template status"
            >
              {map(MENU_SCRAPING_TEMPLATE_STATUSES, ({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Panel>
      <Table
        loading={isLoadingMenuScrapingTemplates}
        rowKey="id"
        columns={tableColumns}
        dataSource={menuScrapingTemplates.menuScrapingTemplates}
        pagination={{
          current: page,
          total: menuScrapingTemplates.total,
          onChange: handlePageChange,
        }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default MenuScrapingTemplatesPage
