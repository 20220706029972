import { gql } from 'apollo-boost'

const MENU_SCRAPING_RESPONSE = gql`
  query menuScrapingResponse($id: ID!) {
    menuScrapingResponse(id: $id) {
      id
      menuId
      data
    }
  }
`

export default MENU_SCRAPING_RESPONSE
