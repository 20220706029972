import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTER_MENU_COUNT_BY_STATUSES = gql`
  query menuScrapingClusterMenuCountByStatuses($id: ID!) {
    menuScrapingClusterMenuCountByStatuses(id: $id) {
      menuStatus
      scrapingStatus
      count
    }
  }
`

export default MENU_SCRAPING_CLUSTER_MENU_COUNT_BY_STATUSES
