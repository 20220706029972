import { gql } from 'apollo-boost'

const MARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED = gql`
  mutation markBrandGatewayTaskAsQualityChecked($id: ID!) {
    markBrandGatewayTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default MARK_BRAND_GATEWAY_TASK_AS_QUALITY_CHECKED
