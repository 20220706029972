import { isEmpty } from 'lodash-es'
import * as Yup from 'yup'

const menuIdsAsCsv = Yup.string().test(
  'validateMenuIds',
  'Menu ids must be a comma separated list of integers',
  function validateMenuIds(menuIds) {
    if (!isEmpty(menuIds)) {
      const menuIdsArray = menuIds.split(',').map(Number)
      if (menuIdsArray.some(Number.isNaN)) {
        return this.createError({
          path: 'menuIds',
          message: 'Menu ids must be a comma separated list of integers',
        })
      }
    }
    return true
  },
)

export default menuIdsAsCsv
