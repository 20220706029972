import { gql } from 'apollo-boost'

const COMMON_WEBSITE_DOMAINS = gql`
  query COMMON_WEBSITE_DOMAINS(
    $filters: CommonWebsiteDomainFiltersInput!
    $page: Int
    $pageSize: Int
  ) {
    commonWebsiteDomains(filters: $filters, page: $page, pageSize: $pageSize) {
      commonWebsiteDomains {
        id
        websiteDomain
      }
      total
    }
  }
`

export default COMMON_WEBSITE_DOMAINS
