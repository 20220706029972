import http from '../../core/services/http'

export const generateUsersReport = ({
  userIds,
  includeSystemEvents,
  startDate,
  endDate,
  taskTypes,
}) =>
  http.get(
    `/reports/user?userIds=${userIds}&startDate=${startDate}&endDate=${endDate}&taskTypes=${taskTypes}&includeSystemEvents=${includeSystemEvents}`,
    { responseType: 'blob' },
  )
