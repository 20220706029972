import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Icon, Table } from 'antd'
import { includes, without } from 'lodash-es'

import { PopoverReactJson } from '../../../core/components'
import { Button } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import { DEFAULT_PAGE } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { START_MENU_SCRAPING_RERUN } from '../../../menu-scraping-templates/graphql/mutations'
import { transformToStartMenuScrapingRerunInput } from '../../../menu-scraping-templates/graphql/transformers'
import { IN_PROGRESS, MENU_SCRAPING_RERUN_STATUSES } from '../../constants'
import { ABORT_MENU_SCRAPING_SESSION } from '../../graphql/mutations'
import {
  MENU_SCRAPING_RERUN_REPORT_URL,
  MENU_SCRAPING_RERUNS,
} from '../../graphql/queries'
import { StartMenuScrapingRerunForm } from '../forms'

const MenuScrapingRerunsPage = () => {
  useDocumentTitle('Menu Scraping Reruns')
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { page = DEFAULT_PAGE } = getQueryParams(search)
  const [menuScrapingRerunSessionId, setMenuScrapingRerunSessionId] = useState()

  const [abortRerunIds, setAbortRerunIds] = useState([])
  const [isStartingScrapingRerun, setIsStartingScrapingRerun] = useState(false)

  const {
    data: { menuScrapingReruns = {} } = {},
    loading: isLoadingMenuScrapingReruns,
    refetch: refetchMenuScrapingReruns,
  } = useQuery(MENU_SCRAPING_RERUNS, {
    variables: {
      page,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    fetchMenuScrapingRerunReportUrl,
    { loading: isLoadingMenuScrapingRerunReportUrl },
  ] = useLazyQuery(MENU_SCRAPING_RERUN_REPORT_URL, {
    onCompleted(data) {
      window.open(data.menuScrapingRerunReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download menu scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const handleClickDownloadReport = menuScrapingRerun => {
    setMenuScrapingRerunSessionId(menuScrapingRerun.id)

    fetchMenuScrapingRerunReportUrl({
      variables: { id: menuScrapingRerun.id },
    })
  }

  const [abortMenuScrapingRerun] = useMutation(ABORT_MENU_SCRAPING_SESSION, {
    onCompleted({ abortMenuScrapingSession: { id } }) {
      setAbortRerunIds(without(abortRerunIds, id))

      refetchMenuScrapingReruns()

      showSuccessNotification({
        message: 'Menu scraping rerun aborted.',
        description: `Menu scraping rerun ${id} has been successfully aborted.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping rerun abort failed.',
        description: message,
      })
    },
  })

  const handleClickAbort = menuScrapingRerun => {
    setAbortRerunIds([...abortRerunIds, menuScrapingRerun.id])

    abortMenuScrapingRerun({
      variables: { id: menuScrapingRerun.id },
    })
  }

  const [startMenuScrapingRerun] = useMutation(START_MENU_SCRAPING_RERUN, {
    onCompleted() {
      setIsStartingScrapingRerun(false)
      refetchMenuScrapingReruns()
      showSuccessNotification({
        message: 'Menu scraping rerun started.',
        description: 'Menu scraping rerun has been successfully started.',
      })
    },

    onError({ message }) {
      setIsStartingScrapingRerun(false)
      showErrorNotification({
        message: 'Menu scraping rerun start failed.',
        description: message,
      })
    },
  })

  const handleStartMenuScrapingRerun = useCallback(
    values => {
      setIsStartingScrapingRerun(true)
      const variables = transformToStartMenuScrapingRerunInput(values)
      return startMenuScrapingRerun({ variables })
    },
    [startMenuScrapingRerun],
  )

  const tableColumns = [
    {
      title: 'Id',
      width: '4%',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      width: '7%',
      dataIndex: 'status',
      render: status => MENU_SCRAPING_RERUN_STATUSES[status].name,
    },
    {
      title: 'Created date',
      width: '10%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Created by',
      width: '10%',
      dataIndex: 'createdBy.name',
    },
    {
      title: 'Time slot count',
      width: '10%',
      dataIndex: 'timeSlotCount',
    },
    {
      title: 'Start time',
      width: '10%',
      dataIndex: 'startTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Estimated completion time',
      width: '10%',
      dataIndex: 'estimatedCompletionTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Completed date',
      width: '10%',
      dataIndex: 'completedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Total menu count',
      width: '10%',
      dataIndex: 'totalMenuCount',
    },
    {
      title: 'Scraped menu count',
      width: '10%',
      dataIndex: 'scrapedMenuCount',
    },
    {
      title: 'Job counts',
      width: '120px',
      dataIndex: 'jobCountByScrapingStatus',
      render: jobCountByScrapingStatus => (
        <PopoverReactJson source={JSON.parse(jobCountByScrapingStatus)} />
      ),
    },
    {
      title: 'Report',
      width: '5%',
      render: menuScrapingRerun => (
        <Button
          margin="no small no no"
          loading={
            isLoadingMenuScrapingRerunReportUrl &&
            menuScrapingRerun.id === menuScrapingRerunSessionId
          }
          onClick={() => handleClickDownloadReport(menuScrapingRerun)}
        >
          Download
          <Icon type="file" />
        </Button>
      ),
    },
    {
      title: 'Abort',
      width: '4%',
      render: menuScrapingRerun => (
        <Button
          margin="no small no no"
          disabled={menuScrapingRerun.status !== IN_PROGRESS}
          loading={includes(abortRerunIds, menuScrapingRerun.id)}
          onClick={() => handleClickAbort(menuScrapingRerun)}
        >
          Abort
          <Icon type="stop" />
        </Button>
      ),
    },
  ]

  const handlePageChange = useCallback(
    page => history.push(createUrl(pathname, search, { page })),
    [history, search, pathname],
  )

  return (
    <>
      <StartMenuScrapingRerunForm
        isStartingScrapingRerun={isStartingScrapingRerun}
        onSubmit={handleStartMenuScrapingRerun}
      />
      <Table
        loading={isLoadingMenuScrapingReruns}
        rowKey="id"
        columns={tableColumns}
        dataSource={menuScrapingReruns.menuScrapingReruns}
        pagination={{
          current: page,
          total: menuScrapingReruns.total,
          onChange: handlePageChange,
        }}
      />
    </>
  )
}

export default MenuScrapingRerunsPage
