import React, { useMemo } from 'react'
import { Layout } from 'antd'
import PropTypes from 'prop-types'

import { SCRAPED_ITEM_TYPES } from '../../../constants'

import ScrapingDebugInformationDish from './scraping-debug-information-dish'
import ScrapingDebugInformationMenu from './scraping-debug-information-menu'
import ScrapingDebugInformationMenuTitle from './scraping-debug-information-menu-title'

const { Sider } = Layout

const ScrapingDebugInformation = ({ scrapedItem }) => {
  const renderedScrapedItem = useMemo(() => {
    switch (scrapedItem.type) {
      case SCRAPED_ITEM_TYPES.DISH:
        return <ScrapingDebugInformationDish dish={scrapedItem} />
      case SCRAPED_ITEM_TYPES.MENU_TITLE:
        return <ScrapingDebugInformationMenuTitle menuTitle={scrapedItem} />
      default:
        return <ScrapingDebugInformationMenu menu={scrapedItem} />
    }
  }, [scrapedItem])

  return (
    <Sider width="30%" style={{ background: '#fff' }}>
      {renderedScrapedItem}
    </Sider>
  )
}

ScrapingDebugInformation.propTypes = {
  scrapedItem: PropTypes.object.isRequired,
}

export default ScrapingDebugInformation
