import { gql } from 'apollo-boost'

const UNASSIGN_DISH_REVIEW_TASK = gql`
  mutation unassignDishReviewTask($id: ID!) {
    unassignDishReviewTask(id: $id) {
      id
    }
  }
`

export default UNASSIGN_DISH_REVIEW_TASK
