import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  MapPinPopover,
  MapPinPopoverDescription,
  MapPinPopoverTitle,
} from '../../styled'

import MapPin from './map-pin'

const FsaMapPin = ({ index, title, description, disabled }) => (
  <MapPin type={disabled ? 'fsaDisabled' : 'fsa'} text={index}>
    <MapPinPopover>
      <MapPinPopoverTitle>{title}</MapPinPopoverTitle>
      <MapPinPopoverDescription>{description}</MapPinPopoverDescription>
    </MapPinPopover>
  </MapPin>
)

FsaMapPin.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

FsaMapPin.defaultProps = {
  disabled: false,
}

export default memo(FsaMapPin)
