import { gql } from 'apollo-boost'

const MENU_SCRAPING_TEMPLATE = gql`
  query menuScrapingTemplate($id: ID!) {
    menuScrapingTemplate(id: $id) {
      id
      name
      comment
      scrapingIssueComment
      status
      scrapingInstructions
      scrapingInstructionsVersionNumber
      createdDate
      createdBy {
        id
        name
      }
      modifiedDate
      modifiedBy {
        id
        name
      }
      deletedDate
      deletedBy {
        id
        name
      }
    }
  }
`

export default MENU_SCRAPING_TEMPLATE
