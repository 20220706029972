import styled, { css } from 'styled-components'

export const Page = styled.div`
  background-color: white;
`

export const PageContent = styled.div`
  padding: 0 30px;
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 0;
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 999;
    `};
`

export const PageTitle = styled.h3`
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 40px;
  font-size: 28px;
`

export const PageActions = styled.div`
  display: flex;
  min-width: fit-content;
  justify-content: flex-end;
`

export const PageOverlay = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
`
