import * as Yup from 'yup'

const additionalMenuUrlsSchema = Yup.object().shape({
  additionalUrls: Yup.array().of(
    Yup.string()
      .url('Website is not valid')
      .required('Website is required'),
  ),
})

export default additionalMenuUrlsSchema
