import React from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

const ScrapingInstructionsDiffModal = ({
  scrapingInstructions,
  otherScrapingInstructions,
  isModalOpen,
  onCancel,
}) => (
  <Modal
    title="Scraping instructions differences"
    visible={isModalOpen}
    onCancel={onCancel}
    footer={null}
    width="100%"
  >
    <ReactDiffViewer
      newValue={scrapingInstructions?.instructions}
      oldValue={otherScrapingInstructions?.instructions}
      splitView
      leftTitle={`V.${otherScrapingInstructions?.version}`}
      rightTitle={`V.${scrapingInstructions?.version}`}
    />
  </Modal>
)

ScrapingInstructionsDiffModal.propTypes = {
  scrapingInstructions: PropTypes.object,
  otherScrapingInstructions: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ScrapingInstructionsDiffModal.defaultProps = {
  scrapingInstructions: undefined,
  otherScrapingInstructions: undefined,
}

export default ScrapingInstructionsDiffModal
