import { gql } from 'apollo-boost'

const REOPEN_MENU_SCRAPING_TASK = gql`
  mutation reopenMenuScrapingTask($id: ID!) {
    reopenMenuScrapingTask(id: $id) {
      id
      modifiedDate
      reopenedDate
    }
  }
`

export default REOPEN_MENU_SCRAPING_TASK
