import { gql } from 'apollo-boost'

const START_NEW_RERUN_FLOW_FOR_MENU_SCRAPING_CLUSTER = gql`
  mutation startNewRerunFlowForMenuScrapingCluster($id: ID!) {
    startNewRerunFlowForMenuScrapingCluster(id: $id) {
      id
    }
  }
`

export default START_NEW_RERUN_FLOW_FOR_MENU_SCRAPING_CLUSTER
