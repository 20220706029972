import { gql } from 'apollo-boost'

const MENU_DEFINITION_TASKS = gql`
  query menuDefinitionTasks(
    $filters: MenuDefinitionTaskFiltersInput!
    $page: Int
    $pageSize: Int
    $sortingCriterion: MenuDefinitionTaskSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    menuDefinitionTasks(
      filters: $filters
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      menuDefinitionTasks {
        id
        status
        brand {
          id
          name
          locationCount
          includedLocationCount
        }
        assignedTo {
          id
          name
        }
        resolvedBy {
          id
          name
        }
        modifiedBy {
          id
          name
        }
        brandDefinedBy {
          id
          name
        }
        createdDate
        modifiedDate
        resolvedDate
      }
      total
    }
  }
`

export default MENU_DEFINITION_TASKS
