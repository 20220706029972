import React from 'react'
import { Switch } from 'react-router'

import {
  BRAND_DEFINITION_TASKS_PERMISSIONS,
  BRAND_GATEWAY_TASKS_PERMISSIONS,
  DISH_REVIEW_TASKS_PERMISSIONS,
  MENU_DEFINITION_TASKS_PERMISSIONS,
  MENU_SCRAPING_TASKS_PERMISSIONS,
} from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import TasksPage from './pages/tasks-page'
import {
  BrandDefinitionTaskPage,
  BrandGatewayTaskPage,
  DishReviewTaskByMenuIdPage,
  DishReviewTaskPage,
  MenuDefinitionTaskPage,
  MenuScrapingTaskPage,
} from './pages'

const TasksRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={BRAND_GATEWAY_TASKS_PERMISSIONS.GET}
      path="/tasks/brand-gateway/:taskId"
      component={BrandGatewayTaskPage}
    />
    <PermissionRoute
      permissions={BRAND_DEFINITION_TASKS_PERMISSIONS.GET}
      path="/tasks/brand-definition/:taskId"
      component={BrandDefinitionTaskPage}
    />
    <PermissionRoute
      permissions={MENU_DEFINITION_TASKS_PERMISSIONS.GET}
      path="/tasks/menu-definition/:taskId"
      component={MenuDefinitionTaskPage}
    />
    <PermissionRoute
      permissions={MENU_SCRAPING_TASKS_PERMISSIONS.GET}
      path="/tasks/menu-scraping/:taskId"
      component={MenuScrapingTaskPage}
    />
    <PermissionRoute
      permissions={DISH_REVIEW_TASKS_PERMISSIONS.GET}
      path="/tasks/dish-review/from-menu-id/:menuId"
      component={DishReviewTaskByMenuIdPage}
    />
    <PermissionRoute
      permissions={DISH_REVIEW_TASKS_PERMISSIONS.GET}
      path="/tasks/dish-review/:taskId"
      component={DishReviewTaskPage}
    />
    <PermissionRoute
      permissions={[
        BRAND_GATEWAY_TASKS_PERMISSIONS.LIST,
        BRAND_DEFINITION_TASKS_PERMISSIONS.LIST,
        MENU_DEFINITION_TASKS_PERMISSIONS.LIST,
        MENU_SCRAPING_TASKS_PERMISSIONS.LIST,
        DISH_REVIEW_TASKS_PERMISSIONS.LIST,
      ]}
      path="/tasks"
      component={TasksPage}
    />
  </Switch>
)

export default TasksRouter
