import { gql } from 'apollo-boost'

const CREATE_PLATFORM = gql`
  mutation createPlatform($data: PlatformInput!) {
    createPlatform(data: $data) {
      id
      name
      urlIdentificationRegex
      urlNormalizationRegex
    }
  }
`

export default CREATE_PLATFORM
