import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { Checkbox, DateRangePicker } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import {
  ADMIN,
  DEFINER_JUNIOR_SCRAPER,
  DEFINER_MIDDLE_SCRAPER,
  DEFINER_MIDDLE_SCRAPER_REVIEWER,
  DEFINER_SENIOR_SCRAPER_REVIEWER,
  JUNIOR_SCRAPER,
  MIDDLE_SCRAPER,
  MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_MIDDLE_SCRAPER,
  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
  SENIOR_SCRAPER,
  SENIOR_SCRAPER_REVIEWER,
  TEAM_LEADER,
} from '../../../common/constants'

import { UsersDropdown } from '../../../users/components/pages/sections'

import { scrapingAttemptsReportSchema } from './schemas'

const ScrapingAttemptsReportForm = ({ isGeneratingReport, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      userIds: [],
      dateRange: '',
      includeAllAttempts: false,
    },
    resolver: yupResolver(scrapingAttemptsReportSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom">
            <Col span={6}>
              <UsersDropdown
                name="userIds"
                mode="multiple"
                allowClear
                disableInctiveUsers={false}
                withRoles={[
                  ADMIN,
                  TEAM_LEADER,
                  JUNIOR_SCRAPER,
                  MIDDLE_SCRAPER,
                  SENIOR_SCRAPER,
                  DEFINER_JUNIOR_SCRAPER,
                  DEFINER_MIDDLE_SCRAPER,
                  DEFINER_MIDDLE_SCRAPER_REVIEWER,
                  DEFINER_SENIOR_SCRAPER_REVIEWER,
                  MIDDLE_SCRAPER_REVIEWER,
                  SENIOR_SCRAPER_REVIEWER,
                  QUALITY_CHECKER_MIDDLE_SCRAPER,
                  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
                  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
                ]}
              />
            </Col>
            <Col span={5} offset={1}>
              <DateRangePicker name="dateRange" label="Date range" />
            </Col>
            <Col span={2} offset={1}>
              <Checkbox
                name="includeAllAttempts"
                label="Include all attempts"
              />
            </Col>
            <Col span={1} offset={1}>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isGeneratingReport}
              >
                Generate scraping attempts report
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

ScrapingAttemptsReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isGeneratingReport: PropTypes.bool.isRequired,
}

export default ScrapingAttemptsReportForm
