import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import {
  MapPinPopover,
  MapPinPopoverDescription,
  MapPinPopoverTitle,
} from '../../styled'

import MapPin from './map-pin'

const GooglePlaceMapPin = ({
  index,
  locationId,
  title,
  description,
  onDetachGooglePlaceFromLocation,
  disabled,
}) => (
  <MapPin type={disabled ? 'googlePlaceDisabled' : 'googlePlace'} text={index}>
    <MapPinPopover>
      <MapPinPopoverTitle>{title}</MapPinPopoverTitle>
      <MapPinPopoverDescription>{description}</MapPinPopoverDescription>
      {!disabled && (
        <Button
          block
          type="primary"
          size="small"
          className="u-marginTopSmall"
          onClick={() => onDetachGooglePlaceFromLocation(locationId)}
        >
          Detach Google info
        </Button>
      )}
    </MapPinPopover>
  </MapPin>
)

GooglePlaceMapPin.propTypes = {
  index: PropTypes.number.isRequired,
  locationId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onDetachGooglePlaceFromLocation: PropTypes.func,
  disabled: PropTypes.bool,
}

GooglePlaceMapPin.defaultProps = {
  disabled: false,
  onDetachGooglePlaceFromLocation: undefined,
}

export default memo(GooglePlaceMapPin)
