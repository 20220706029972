import { gql } from 'apollo-boost'

const GET_USER = gql`
  {
    user @client {
      id
      email
      name
      roles {
        id
        name
      }
      permissions
    }
  }
`

export default GET_USER
