import { gql } from 'apollo-boost'

const DISH_REVIEW_TASK = gql`
  query dishReviewTask($id: ID!) {
    dishReviewTask(id: $id) {
      id
      status
      isAwaiting
      awaitingReason
      awaitingReasonComment
      isAutoResolved
      isRerunFlow
      createdDate
      modifiedDate
      resolvedDate
      reopenedDate
      reopenReason
      menu {
        id
        url
        scrapingInstructions
        scrapingInstructionsVersionNumber
        rejectionReason
        status
        format
        availabilityComment
        scrapingIssueComment
        isScrapedInDebugMode
        scrapingResponseId
        isScrapedAutomatically
        menuScrapingClusterId
        menuScrapingTemplateId
        isDeleted
        scrapingSummary {
          menuAnnotations {
            fullMenu
            menuType
            menu
            menuList
            sectionList
            dishList
            global
          }
          duration
          status
          analysis
          analysisSlim
          status
          messages
          exceptions
          flags
          scrapingReport {
            fileKey
          }
        }
        scrapedBy {
          id
          name
        }
        scrapedDate
        brand {
          id
          name
        }
        locations {
          id
          name
        }
        menuTitles {
          id
          parentId
          title
          description
          calories
          diets
          addons
          miscInfo
          allergens
          nutritions
          isEverywhereAvailable
          dishCount
          isDeleted
          hash
        }
        dishes {
          id
          menuId
          menuTitleId
          name
          description
          price
          calories
          ingredients
          createdDate
          modifiedDate
          isDeleted
          diets
          addons
          miscInfo
          allergens
          nutritions
          hash
        }
      }
      assignedTo {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      createdDate
      modifiedDate
      resolvedDate

      miscTaskVerifiedBy {
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment

      miscTaskQCVerifiedBy {
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
        menuScrapingTask {
          id
          status
        }
        dishReviewTask {
          id
          status
        }
      }
    }
  }
`

export default DISH_REVIEW_TASK
