import removeUser from './remove-user'
import setRoles from './set-roles'
import setUser from './set-user'

const resolvers = {
  Mutation: {
    setUser,
    removeUser,
    setRoles,
  },
}

export default resolvers
