import { gql } from 'apollo-boost'

const SET_MENU_SCRAPING_ISSUE_COMMENT = gql`
  mutation setMenuScrapingIssueComment(
    $id: ID!
    $scrapingIssueComment: String
  ) {
    setMenuScrapingIssueComment(
      id: $id
      scrapingIssueComment: $scrapingIssueComment
    ) {
      id
      scrapingIssueComment
      status
    }
  }
`

export default SET_MENU_SCRAPING_ISSUE_COMMENT
