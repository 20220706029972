import { isEmpty } from 'lodash-es'

function validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount(value) {
  const { menuIds, menuRoles, randomlySelectedMenuCount } = value

  if (isEmpty(menuIds) && isEmpty(menuRoles) && !randomlySelectedMenuCount) {
    return this.createError({
      path: 'menuRoles',
      message:
        'At least one of menuIds, menuStatuses or scrapingStatuses is required',
    })
  }
  return true
}

export default validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount
