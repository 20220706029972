import React, { useCallback, useState } from 'react'
import ReactJson from 'react-json-view'
import { useQuery } from '@apollo/react-hooks'
import { Button, Drawer, Icon, List, Popover } from 'antd'
import PropTypes from 'prop-types'

import { EVENTS } from '../../event/graphql/queries'
import { APP_NAME } from '../constants'

import LondonDateTime from './london-date-time'
import Spinner from './spinner'

const HistoryItem = ({ user, createdDate, message, data = {} }) => (
  <List.Item>
    At <LondonDateTime date={createdDate} /> {user ? user.name : APP_NAME} :{' '}
    {message}
    <Popover
      title="Data"
      placement="left"
      content={
        <ReactJson
          src={data}
          style={{ minWidth: '500px' }}
          enableClipboard={false}
          displayDataTypes={false}
          name={false}
          collapsed
        />
      }
    >
      <Button size="small" shape="circle" style={{ float: 'right' }}>
        <Icon type="eye" />
      </Button>
    </Popover>
  </List.Item>
)

HistoryItem.propTypes = {
  createdDate: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

const HistoryDrawer = ({ entityId, entityType }) => {
  const [isVisible, setIsVisible] = useState(false)

  const {
    data: { events } = {},
    loading: isLoadingEvents,
    refetch: refetchEvents,
  } = useQuery(EVENTS, {
    variables: {
      filters: {
        entityTypes: [entityType],
        entityIds: [entityId],
      },
    },
    fetchPolicy: 'no-cache',
  })

  const onButtonClick = useCallback(() => {
    refetchEvents()
    setIsVisible(true)
  }, [refetchEvents])

  const onClose = useCallback(() => setIsVisible(false), [])

  return (
    <>
      <Button
        style={{ marginBottom: '10px', marginRight: '5px', marginLeft: '5px' }}
        size="small"
        shape="circle"
        onClick={onButtonClick}
      >
        <Icon type="history" />
      </Button>
      {isLoadingEvents ? (
        <Spinner />
      ) : (
        <Drawer
          title="History"
          placement="right"
          onClose={onClose}
          visible={isVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={events}
            pagination
            renderItem={HistoryItem}
          />
        </Drawer>
      )}
    </>
  )
}

HistoryDrawer.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
}

export default HistoryDrawer
