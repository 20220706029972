import { isEmpty } from 'lodash-es'

import { splitCsvToNumberArray } from '../utils'

const transformToStartMenuScrapingRerunInput = values => {
  const data = {
    ignoreWorkingHours: values.ignoreWorkingHours,
    rerunTimeSlotCount: values.rerunTimeSlotCount,
  }

  if (!isEmpty(values.menuIds)) {
    data.menuIds = splitCsvToNumberArray(values.menuIds)
  } else {
    data.menuStatuses = values.menuStatuses
    data.scrapedBeforeDate = values.scrapedBeforeDate
    data.includeAllAvailableMenus = values.includeAllAvailableMenus

    if (!data.includeAllAvailableMenus) {
      data.rerunMenuCount = values.rerunMenuCount
    }
  }

  return { data }
}

export default transformToStartMenuScrapingRerunInput
