import { gql } from 'apollo-boost'

const RESOLVE_BRAND_DEFINITION_TASK = gql`
  mutation resolveBrandDefinitionTask(
    $id: ID!
    $brandData: SaveBrandDefinitionTaskBrandInput
    $locationsData: [SaveBrandDefinitionTaskLocationInput!]!
  ) {
    resolveBrandDefinitionTask(
      id: $id
      brandData: $brandData
      locationsData: $locationsData
    ) {
      brandDefinitionTask {
        id
      }
      menuDefinitionTaskId
    }
  }
`

export default RESOLVE_BRAND_DEFINITION_TASK
