import { mapKeys } from 'lodash-es'

export const CAN_NOT_SELECT_ON_PAGE = 'CAN_NOT_SELECT_ON_PAGE'
export const CAPTCHA = 'CAPTCHA'
export const CAROUSEL = 'CAROUSEL'
export const DELIVERY_PLATFORM = 'DELIVERY_PLATFORM'
export const DYNAMIC_SELECTOR = 'DYNAMIC_SELECTOR'
export const EXPIRED_LINK = 'EXPIRED_LINK'
export const MENU_IN_A_DIFFERENT_LANGUAGE = 'MENU_IN_A_DIFFERENT_LANGUAGE'
export const OTHER = 'OTHER'
export const PAGINATION = 'PAGINATION'
export const POORLY_BUILT_WEBSITE = 'POORLY_BUILT_WEBSITE'
export const REQUIRES_LOG_IN = 'REQUIRES_LOG_IN'
export const SLIDER_NAVIGATION = 'SLIDER_NAVIGATION'
export const WORKING_HOURS = 'WORKING_HOURS'

export const MENU_NOT_SCRAPEABLE_REASONS = mapKeys(
  [
    { name: 'Cannot select anything on page', value: CAN_NOT_SELECT_ON_PAGE },
    { name: 'CAPTCHA', value: CAPTCHA },
    { name: 'Carousel', value: CAROUSEL },
    { name: 'Delivery platform', value: DELIVERY_PLATFORM },
    { name: 'Dynamic selector', value: DYNAMIC_SELECTOR },
    { name: 'Expired link', value: EXPIRED_LINK },
    {
      name: 'Menu in a different language',
      value: MENU_IN_A_DIFFERENT_LANGUAGE,
    },
    { name: 'Other', value: OTHER },
    { name: 'Pagination', value: PAGINATION },
    { name: 'Poorly built website', value: POORLY_BUILT_WEBSITE },
    { name: 'Requires log in', value: REQUIRES_LOG_IN },
    { name: 'Slider navigation', value: SLIDER_NAVIGATION },
    { name: 'Working hours', value: WORKING_HOURS },
  ],
  'value',
)
