import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { SET_MENU_SCRAPING_TEMPLATE_COMMENT } from '../../../../menus/graphql/mutations'
import { ScrapingTemplateCommentForm } from '../../forms'

const MenuScrapingTemplateCommentModal = ({
  scrapingTemplate,
  isModalOpen,
  setIsModalOpen,
}) => {
  const handleClose = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const [
    setMenuScrapingTemplateComment,
    { loading: isSettingScrapingTemplateComment },
  ] = useMutation(SET_MENU_SCRAPING_TEMPLATE_COMMENT, {
    onCompleted({ setMenuScrapingTemplateComment: { comment } }) {
      showSuccessNotification({
        message: 'Comment',
        description: isNil(comment) ? 'Comment removed' : 'Comment saved',
      })

      setIsModalOpen(false)
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Comment',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    scrapingTemplate =>
      setMenuScrapingTemplateComment({ variables: scrapingTemplate }),
    [setMenuScrapingTemplateComment],
  )

  return (
    <Modal
      title="Comment"
      visible={isModalOpen}
      onCancel={handleClose}
      footer={null}
    >
      <ScrapingTemplateCommentForm
        scrapingTemplate={scrapingTemplate}
        closeModal={handleClose}
        isSettingScrapingTemplateComment={isSettingScrapingTemplateComment}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

MenuScrapingTemplateCommentModal.propTypes = {
  scrapingTemplate: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default MenuScrapingTemplateCommentModal
