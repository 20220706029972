import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'
import useSupercluster from 'use-supercluster'

import { MapSearchBox } from '../../../core/components'
import { Map as MapContainer } from '../../../core/components/styled'
import { UK_COORDINATES } from '../../../core/constants'
import { getEnvironmentVariable } from '../../../core/helpers'

import {
  createPinDataFromGooglePlace,
  createPinDataFromLocations,
} from '../../utils'

import {
  FsaMapPin,
  GooglePlaceMapPin,
  MapClusterMarker,
  SearchedMapPin,
} from './sections'

const BrandDefinitionMap = ({
  center,
  onMapInitialized,
  onGooglePlaceAttachedToLocation,
  onDetachGooglePlaceFromLocation,
  mapSearchInputValue,
  locations,
  disabledLocations,
}) => {
  const mapRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(6)
  const [searchedGooglePlace, setSearchedGooglePlace] = useState()
  const [mapSearchValue, setMapSearchValue] = useState(mapSearchInputValue)

  const enabledPins = useMemo(
    () => createPinDataFromLocations(locations, false),
    [locations],
  )

  const disabledPins = useMemo(
    () => createPinDataFromLocations(disabledLocations, true),
    [disabledLocations],
  )

  useEffect(() => {
    if (isInitialized) {
      mapRef.current.map.setZoom(6)
      mapRef.current.map.panTo(UK_COORDINATES)
    }
  }, [isInitialized, locations])

  const { clusters, supercluster } = useSupercluster({
    points: disabledPins,
    bounds,
    zoom,
    options: { radius: 60, maxZoom: 20 },
  })

  const searchedPin = useMemo(() => {
    return (
      searchedGooglePlace && createPinDataFromGooglePlace(searchedGooglePlace)
    )
  }, [searchedGooglePlace])

  const initialize = useCallback(
    ({ map, maps: googleMaps }) => {
      mapRef.current = { map, googleMaps }
      setIsInitialized(true)
      onMapInitialized({ map, googleMaps })
    },
    [onMapInitialized],
  )

  const onPlaceSelect = useCallback(
    googlePlace => setSearchedGooglePlace(googlePlace),
    [setSearchedGooglePlace],
  )

  const attachSearchedGooglePlaceToLocation = useCallback(
    locationId => {
      onGooglePlaceAttachedToLocation(locationId, searchedGooglePlace)
      setSearchedGooglePlace(undefined)
      setMapSearchValue(mapSearchInputValue)
    },
    [
      onGooglePlaceAttachedToLocation,
      searchedGooglePlace,
      setSearchedGooglePlace,
      mapSearchInputValue,
    ],
  )

  const generatePinKey = useCallback(({ index, id }) => `${index}-${id}`, [])

  return (
    <MapContainer sticky>
      {isInitialized && (
        <MapSearchBox
          map={mapRef.current.map}
          onPlaceSelect={onPlaceSelect}
          inputValue={mapSearchValue}
          setInputValue={setMapSearchValue}
        />
      )}

      <GoogleMapReact
        bootstrapURLKeys={{
          key: getEnvironmentVariable('REACT_APP_GOOGLE_API_KEY'),
          libraries: 'places',
        }}
        defaultCenter={center}
        defaultZoom={6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={initialize}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
      >
        {map(enabledPins, enabledPin => {
          const [longitude, latitude] = enabledPin.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pinsCount,
            ...pinProps
          } = enabledPin.properties

          return enabledPin.type === 'fsa' ? (
            <FsaMapPin
              key={generatePinKey(pinProps)}
              lat={latitude}
              lng={longitude}
              {...pinProps}
            />
          ) : (
            <GooglePlaceMapPin
              key={generatePinKey(pinProps)}
              lat={latitude}
              lng={longitude}
              onDetachGooglePlaceFromLocation={onDetachGooglePlaceFromLocation}
              {...pinProps}
            />
          )
        })}

        {map(clusters, cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pinsCount,
            ...pinProps
          } = cluster.properties

          if (cluster.type === 'fsaDisabled') {
            return (
              <FsaMapPin
                key={generatePinKey(pinProps)}
                lat={latitude}
                lng={longitude}
                disabled
                {...pinProps}
              />
            )
          }

          if (cluster.type === 'googlePlaceDisabled') {
            return (
              <GooglePlaceMapPin
                key={generatePinKey(pinProps)}
                lat={latitude}
                lng={longitude}
                disabled
                {...pinProps}
              />
            )
          }

          return (
            <MapClusterMarker
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
              cluster={cluster}
              pinsCount={pinsCount}
              totalPinsCount={size(enabledPins)}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(cluster.id),
                  20,
                )
                mapRef.current.map.setZoom(expansionZoom)
                mapRef.current.map.panTo({ lat: latitude, lng: longitude })
              }}
            />
          )
        })}

        {!!searchedPin && (
          <SearchedMapPin
            title={searchedPin.title}
            description={searchedPin.description}
            locations={locations}
            lat={searchedPin.coordinates.latitude}
            lng={searchedPin.coordinates.longitude}
            onLocationSelect={attachSearchedGooglePlaceToLocation}
          />
        )}
      </GoogleMapReact>
    </MapContainer>
  )
}

BrandDefinitionMap.propTypes = {
  center: PropTypes.object,
  locations: PropTypes.array.isRequired,
  disabledLocations: PropTypes.array.isRequired,
  onMapInitialized: PropTypes.func.isRequired,
  mapSearchInputValue: PropTypes.string.isRequired,
  onGooglePlaceAttachedToLocation: PropTypes.func.isRequired,
  onDetachGooglePlaceFromLocation: PropTypes.func.isRequired,
}

BrandDefinitionMap.defaultProps = {
  center: UK_COORDINATES,
}

export default BrandDefinitionMap
