import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { TEMPLATE_VALIDATION } from '../../../../../menus/constants'

import {
  MenuScrapingClusterMenusTable,
  MenuScrapingSessionsTable,
  MenuStatusesTable,
} from './tables'

const MenuScrapingClusterTemplateValidationStep = ({ menuScrapingCluster }) => {
  const [
    menuStatusesTableRefreshKey,
    setMenuStatusesTableRefreshKey,
  ] = useState(Math.random())

  const refreshMenuStatusesTable = useCallback(() => {
    setMenuStatusesTableRefreshKey(Math.random())
  }, [])

  const [menusTableRefreshKey, setMenusTableRefreshKey] = useState(
    Math.random(),
  )

  const refreshMenusTable = useCallback(() => {
    setMenusTableRefreshKey(Math.random())
  }, [])

  return (
    <>
      <MenuStatusesTable
        key={menuStatusesTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        onMenusReset={() => refreshMenusTable()}
      />
      <MenuScrapingClusterMenusTable
        key={menusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isRemoveButtonHidden
        isUpdateMenuScrapingClusterHidden
        isMenuNotesDisabled={false}
      />
      <MenuScrapingSessionsTable
        menuScrapingCluster={menuScrapingCluster}
        rerunStepType={TEMPLATE_VALIDATION}
        onStartScrapingSession={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
        onAbortScrapingSession={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
      />
    </>
  )
}

MenuScrapingClusterTemplateValidationStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterTemplateValidationStep
