import { gql } from 'apollo-boost'

const MARK_BRAND_GATEWAY_TASK_AS_AWAITING = gql`
  mutation markBrandGatewayTaskAsAwaiting(
    $id: ID!
    $awaitingReason: TaskAwaitingReason!
    $awaitingReasonComment: String
  ) {
    markBrandGatewayTaskAsAwaiting(
      id: $id
      awaitingReason: $awaitingReason
      awaitingReasonComment: $awaitingReasonComment
    ) {
      id
      awaitingReason
      awaitingReasonComment
      modifiedDate
    }
  }
`

export default MARK_BRAND_GATEWAY_TASK_AS_AWAITING
