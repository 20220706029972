import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { MENU_STATUSES } from '../../menus/constants'
import { DEFAULT_PAGE } from '../constants'

const MenuStatusFilter = ({ mode, disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { menuStatuses } = getQueryParams(search)

  const handleChange = useCallback(
    menuStatuses =>
      history.push(
        createUrl(pathname, search, {
          menuStatuses,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Menu status">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={menuStatuses}
        mode={mode}
        onChange={handleChange}
        dropdownStyle={{ minWidth: 'fit-content' }}
        maxTagCount={1}
        maxTagPlaceholder={getMaxTagPlaceholder}
        disabled={disabled}
      >
        {map(MENU_STATUSES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

MenuStatusFilter.propTypes = {
  mode: PropTypes.string,
  disabled: PropTypes.bool,
}

MenuStatusFilter.defaultProps = {
  mode: 'default',
  disabled: false,
}

export default MenuStatusFilter
