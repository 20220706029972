import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { UnconnectedUsersDropdown } from '../../users/components/pages/sections'
import { DEFAULT_PAGE } from '../constants'

const TaskAssignedToFilter = ({ disabled, roles }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { assignedToId } = getQueryParams(search)

  const handleChange = useCallback(
    assignedToId =>
      history.push(
        createUrl(pathname, search, {
          assignedToId,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  return (
    <UnconnectedUsersDropdown
      label="Task assigned to"
      value={assignedToId}
      onChange={handleChange}
      disabled={disabled}
      width="100%"
      dropdownStyle={{ minWidth: 'fit-content' }}
      allowClear
      withRoles={roles}
    />
  )
}

TaskAssignedToFilter.propTypes = {
  disabled: PropTypes.bool,
  roles: PropTypes.array,
}

TaskAssignedToFilter.defaultProps = {
  disabled: false,
  roles: undefined,
}

export default TaskAssignedToFilter
