import { gql } from 'apollo-boost'

const RESOLVE_DISH_REVIEW_TASK = gql`
  mutation resolveDishReviewTask(
    $id: ID!
    $menuId: ID!
    $menuTitlesData: [SaveDishReviewTaskMenuTitleInput!]!
  ) {
    resolveDishReviewTask(
      id: $id
      menuId: $menuId
      menuTitlesData: $menuTitlesData
    ) {
      id
    }
  }
`

export default RESOLVE_DISH_REVIEW_TASK
