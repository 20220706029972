import { isNil, reduce } from 'lodash-es'

const getAncestorIdsForTreeNode = (nodeId, allNodes, accumulator = []) => {
  const ancestors = [...accumulator, nodeId]
  const node = allNodes.find(node => node.id === nodeId)

  if (isNil(node?.parentId)) return ancestors
  return getAncestorIdsForTreeNode(node.parentId, allNodes, ancestors)
}

const getAncestorIdsForTreeNodes = (nodeIds, allNodes) =>
  reduce(
    nodeIds,
    (result, nodeId) => [
      ...new Set([...result, ...getAncestorIdsForTreeNode(nodeId, allNodes)]),
    ],
    [],
  )

export default getAncestorIdsForTreeNodes
