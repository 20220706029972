import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { HiddenInputs, TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { scrapingIssueCommentFormInitialValues } from './initial-values'

const ScrapingIssueCommentForm = ({
  menu,
  closeModal,
  isSettingScrapingIssueComment,
  disabled,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: scrapingIssueCommentFormInitialValues(menu),
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row>
          <TextArea
            name="scrapingIssueComment"
            label="Comment"
            autoSize
            disabled={disabled}
          />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="small small no no">
            Cancel
          </Button>
          {!disabled && (
            <Button
              type="primary"
              htmlType="submit"
              loading={isSettingScrapingIssueComment}
              margin="small no no no"
            >
              Save
            </Button>
          )}
        </Row>
        <HiddenInputs names={['id']} />
      </form>
    </FormProvider>
  )
}

ScrapingIssueCommentForm.propTypes = {
  menu: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSettingScrapingIssueComment: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

ScrapingIssueCommentForm.defaultProps = {
  disabled: false,
}

export default ScrapingIssueCommentForm
