import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker, Form } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

const DateRangePicker = ({ name, label }) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <Controller control={control} name={name} as={DatePicker.RangePicker} />
    </Form.Item>
  )
}

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

DateRangePicker.defaultProps = {
  label: '',
}

export default DateRangePicker
