import { mapKeys } from 'lodash-es'

export const NO_GOOGLE_MATCH = 'NO'
export const LOW_GOOGLE_MATCH = 'LOW'
export const MEDIUM_GOOGLE_MATCH = 'MEDIUM'
export const HIGH_GOOGLE_MATCH = 'HIGH'
export const HIGHEST_GOOGLE_MATCH = 'HIGHEST'
export const MANUAL_GOOGLE_MATCH = 'MANUAL'

export const LOCATION_GOOGLE_MATCH_RANKS = mapKeys(
  [
    { name: 'None', value: NO_GOOGLE_MATCH },
    { name: 'Low', value: LOW_GOOGLE_MATCH },
    { name: 'Medium', value: MEDIUM_GOOGLE_MATCH },
    { name: 'High', value: HIGH_GOOGLE_MATCH },
    { name: 'Highest', value: HIGHEST_GOOGLE_MATCH },
    { name: 'Manual', value: MANUAL_GOOGLE_MATCH },
  ],
  'value',
)

export const LOCATION_GOOGLE_MATCH_RANKS_SORT_VALUES = mapKeys(
  [
    { sortValue: 0, value: NO_GOOGLE_MATCH },
    { sortValue: 1, value: LOW_GOOGLE_MATCH },
    { sortValue: 2, value: MEDIUM_GOOGLE_MATCH },
    { sortValue: 3, value: HIGH_GOOGLE_MATCH },
    { sortValue: 4, value: HIGHEST_GOOGLE_MATCH },
    { sortValue: 5, value: MANUAL_GOOGLE_MATCH },
  ],
  'value',
)
