import { mapKeys } from 'lodash-es'

export const LOCATION_WITH_OWN_MENU = 'OWN_MENU'
export const LOCATION_WITH_BRAND_MENU = 'SAME_AS_BRAND'
export const LOCATION_WITH_NO_MENU = 'NO_MENU'

export const LOCATION_MENU_STATUSES = mapKeys(
  [
    { name: 'Own menu', value: LOCATION_WITH_OWN_MENU },
    { name: 'Same as brand', value: LOCATION_WITH_BRAND_MENU },
    { name: 'No menu', value: LOCATION_WITH_NO_MENU },
  ],
  'value',
)

export const LOCATION_MENU_STATUSES_BY_NAME = mapKeys(
  [
    { name: 'Own menu', value: LOCATION_WITH_OWN_MENU },
    { name: 'Same as brand', value: LOCATION_WITH_BRAND_MENU },
    { name: 'No menu', value: LOCATION_WITH_NO_MENU },
  ],
  'name',
)

export const LOCATION_MENU_STATUSES_SORT_VALUES = mapKeys(
  [
    { sortValue: 0, value: LOCATION_WITH_OWN_MENU },
    { sortValue: 1, value: LOCATION_WITH_BRAND_MENU },
    { sortValue: 2, value: LOCATION_WITH_NO_MENU },
  ],
  'value',
)
