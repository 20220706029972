import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Typography } from 'antd'
import PropTypes from 'prop-types'

import { EMPTY_VALUE } from '../../common/constants'

import isFalsy from '../utils/services/isFalsy'

const formatValue = (value, valuePostfix) =>
  isFalsy(value, { allowZero: true }) ? EMPTY_VALUE : `${value}${valuePostfix}`

const FormText = ({
  name,
  label,
  ellipsis,
  copyable,
  style,
  valuePostfix,
  transform,
}) => {
  const { control } = useFormContext()
  return (
    <Form.Item label={label}>
      <Controller
        control={control}
        name={name}
        render={({ value }) => (
          <Typography.Paragraph
            copyable={copyable}
            ellipsis={ellipsis}
            style={style}
          >
            {formatValue(transform(value), valuePostfix)}
          </Typography.Paragraph>
        )}
      />
    </Form.Item>
  )
}

FormText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ellipsis: PropTypes.bool,
  copyable: PropTypes.object,
  style: PropTypes.object,
  valuePostfix: PropTypes.string,
  transform: PropTypes.func,
}

FormText.defaultProps = {
  label: '',
  ellipsis: true,
  copyable: null,
  style: {},
  valuePostfix: '',
  transform: value => value,
}

export default FormText
