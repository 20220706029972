import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Icon, Row, Table, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { DEFAULT_PAGE } from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'

import { REMOVE_MENU_FROM_CLUSTER_EXCLUDED_MENUS } from '../../../../../graphql/mutations'
import { MENUS_EXCLUDED_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS } from '../../../../../graphql/queries'

const ExcludedFromSimilarMenusTable = ({
  menuScrapingCluster,
  onRemoveMenuFromClusterExcludedMenus,
  isHidden,
  toggleIsHidden,
  isRemoveButtonHidden,
}) => {
  const [
    menusExcludedFromSimilarMenusPage,
    setMenusExcludedFromSimilarMenusPage,
  ] = useState(DEFAULT_PAGE)

  const [
    refetchMenusExcludedFromSimilarMenus,
    {
      data: {
        menusExcludedFromMenuScrapingClusterSimilarMenus: {
          menus: menusExcludedFromSimilarMenus = [],
          total: menusExcludedFromSimilarMenusTotal = 0,
        } = {},
      } = {},
      loading: isLoadingMenusExcludedFromSimilarMenus,
    },
  ] = useLazyQuery(MENUS_EXCLUDED_FROM_MENU_SCRAPING_CLUSTER_SIMILAR_MENUS, {
    variables: {
      id: menuScrapingCluster.id,
      page: menusExcludedFromSimilarMenusPage,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!isHidden) {
      refetchMenusExcludedFromSimilarMenus({
        id: menuScrapingCluster.id,
        page: menusExcludedFromSimilarMenusPage,
      })
    }
  }, [
    isHidden,
    menuScrapingCluster.id,
    menusExcludedFromSimilarMenusPage,
    refetchMenusExcludedFromSimilarMenus,
  ])

  const [removeMenuFromClusterExcludedMenus] = useMutation(
    REMOVE_MENU_FROM_CLUSTER_EXCLUDED_MENUS,
    {
      onCompleted() {
        refetchMenusExcludedFromSimilarMenus({
          id: menuScrapingCluster.id,
          page: menusExcludedFromSimilarMenusPage,
        })
        onRemoveMenuFromClusterExcludedMenus()
        showSuccessNotification({
          message: 'Menu removed from excluded menus.',
          description:
            'Menu has been successfully removed from excluded menus.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu removal from excluded menus failed.',
          description: message,
        })
      },
    },
  )

  const menusExcludedFromSimilarMenusTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '40px',
      render: id => <Link to={`/menus/${id}`}>{id}</Link>,
    },
    {
      title: 'Brand',
      dataIndex: 'brand.name',
      width: '150px',
    },
    {
      title: 'Website',
      dataIndex: 'url',
      width: '200px',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    ...(isRemoveButtonHidden
      ? []
      : [
          {
            title: 'Actions',
            width: '55px',
            dataIndex: 'id',
            render: id => (
              <Button
                type="primary"
                onClick={() =>
                  removeMenuFromClusterExcludedMenus({
                    variables: {
                      menuId: id,
                      id: menuScrapingCluster.id,
                    },
                  })
                }
                margin="no small no no"
              >
                Remove
              </Button>
            ),
          },
        ]),
  ]

  return (
    <Row>
      <div style={{ display: 'flex' }}>
        <Typography.Title level={3}>
          {`Menus excluded from similar menus`}
          {!isHidden && ` (${menusExcludedFromSimilarMenusTotal})`}
        </Typography.Title>
        <Button onClick={toggleIsHidden} style={{ marginLeft: 'auto' }}>
          <Icon type={!isHidden ? 'up' : 'down'} />
        </Button>
      </div>
      {!isHidden && (
        <Table
          rowKey="id"
          loading={isLoadingMenusExcludedFromSimilarMenus}
          columns={menusExcludedFromSimilarMenusTableColumns}
          dataSource={menusExcludedFromSimilarMenus}
          pagination={{
            current: menusExcludedFromSimilarMenusPage,
            pageSize: 10,
            total: menusExcludedFromSimilarMenusTotal,
            onChange: setMenusExcludedFromSimilarMenusPage,
          }}
        />
      )}
    </Row>
  )
}

ExcludedFromSimilarMenusTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onRemoveMenuFromClusterExcludedMenus: PropTypes.func,
  isHidden: PropTypes.bool,
  toggleIsHidden: PropTypes.func,
  isRemoveButtonHidden: PropTypes.bool,
}

ExcludedFromSimilarMenusTable.defaultProps = {
  onRemoveMenuFromClusterExcludedMenus: () => {},
  isHidden: false,
  toggleIsHidden: () => {},
  isRemoveButtonHidden: false,
}

export default ExcludedFromSimilarMenusTable
