import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Table } from 'antd'

import { Button } from '../../../core/components/styled'

import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { PLATFORM_PERMISSIONS } from '../../../common/constants'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { PermissionGuard } from '../../../account/components/routes'
import { PLATFORMS } from '../../graphql/queries'

import { PlatformsTableActionColumn } from './sections'

const PlatformsPage = () => {
  useDocumentTitle('Settings - Platforms')

  const {
    data: { platforms } = {},
    loading: isLoadingPlatforms,
    refetch: refetchPlatforms,
  } = useQuery(PLATFORMS, { fetchPolicy: 'no-cache' })

  const history = useHistory()

  const tableColumns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Identification Regex',
        dataIndex: 'urlIdentificationRegex',
      },
      {
        title: 'Normalization Regex',
        dataIndex: 'urlNormalizationRegex',
      },
      {
        align: 'right',
        render: platform => (
          <PlatformsTableActionColumn
            platform={platform}
            refetchPlatforms={refetchPlatforms}
          />
        ),
      },
    ],
    [refetchPlatforms],
  )

  return (
    <>
      <PageHeader sticky>
        <PageTitle>Platforms</PageTitle>
        <PageActions>
          <PermissionGuard permissions={PLATFORM_PERMISSIONS.CREATE}>
            <Button onClick={() => history.push('/settings/platforms/create')}>
              Add platform
            </Button>
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Table
        loading={isLoadingPlatforms}
        rowKey="id"
        columns={tableColumns}
        dataSource={platforms}
        pagination={false}
      />
    </>
  )
}

export default PlatformsPage
