import { gql } from 'apollo-boost'

const MENU_SCRAPING_TEMPLATES = gql`
  query menuScrapingTemplates(
    $page: Int
    $pageSize: Int
    $filters: MenuScrapingTemplateFiltersInput
    $sortingCriterion: MenuScrapingTemplateSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    menuScrapingTemplates(
      page: $page
      pageSize: $pageSize
      filters: $filters
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      menuScrapingTemplates {
        id
        name
        status
        scrapingInstructionsVersionNumber
        createdDate
        createdBy {
          id
          name
        }
        modifiedDate
        modifiedBy {
          id
          name
        }
        menuCount
      }
      total
    }
  }
`

export default MENU_SCRAPING_TEMPLATES
