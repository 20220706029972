import * as Yup from 'yup'

const updateMenuScrapingTemplateSchema = Yup.object().shape({
  name: Yup.string().required(`Template name is required`),
  status: Yup.string().required('Status is required'),
  scrapingInstructions: Yup.string().required(
    'Scraping instructions are required',
  ),
})

export default updateMenuScrapingTemplateSchema
