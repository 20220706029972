import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Form, Icon, Row, Select, Spin, Typography } from 'antd'
import { filter, get, join, map, uniq } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { MENU_SCRAPING_CLUSTERS_BASIC } from '../../../graphql/queries'

const MenuScrapingClustersDropdown = ({
  name,
  label,
  disabled,
  currentMenuScrapingClusterId,
  allowClear,
  onSelect,
}) => {
  const { control, errors } = useFormContext()
  const formErrors = get(errors, name)

  const messages = formErrors
    ? join(uniq(map(formErrors, 'message')), '. ')
    : undefined

  const getSelectedValue = useCallback(
    value =>
      value?.id
        ? { key: value.id, label: `Cluster ${value.id}` }
        : { key: '', label: '' },
    [],
  )

  const {
    data: { menuScrapingClustersBasic: menuScrapingClusters } = {},
    loading: isLoadingMenuScrapingClusters,
  } = useQuery(MENU_SCRAPING_CLUSTERS_BASIC)

  const options = map(
    filter(
      menuScrapingClusters,
      ({ id }) => id !== currentMenuScrapingClusterId,
    ),
    ({ id }) => ({
      label: `Cluster ${id}`,
      value: id,
    }),
  )

  const handleOnChange = onChange => value => {
    const v = { id: value?.key, name: value?.label }
    onChange(v)
    if (onSelect) {
      onSelect(v)
    }
  }

  return (
    <Form.Item
      label={label}
      help={messages}
      validateStatus={messages ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        render={({ onChange, value, ...props }) => (
          <Select
            showSearch
            allowClear={allowClear}
            labelInValue
            value={getSelectedValue(value)}
            filterOption
            onChange={handleOnChange(onChange)}
            notFoundContent={
              isLoadingMenuScrapingClusters ? <Spin size="small" /> : null
            }
            dropdownStyle={{ minWidth: 'fit-content' }}
            optionLabelProp="label"
            optionFilterProp="label"
            {...props}
            disabled={disabled}
          >
            {map(options, ({ label, value }) => (
              <Select.Option key={value} value={value} label={label}>
                <Row type="flex" justify="space-between">
                  <Typography.Text>{label}</Typography.Text>
                  <Link
                    to={`/menu-scraping-templates/clusters/${value}`}
                    target="_blank"
                  >
                    <Button size="small" type="link">
                      <Icon type="link" />
                    </Button>
                  </Link>
                </Row>
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </Form.Item>
  )
}

MenuScrapingClustersDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  currentMenuScrapingClusterId: PropTypes.number.isRequired,
  allowClear: PropTypes.bool,
  onSelect: PropTypes.func,
}

MenuScrapingClustersDropdown.defaultProps = {
  disabled: false,
  label: undefined,
  allowClear: true,
  onSelect: undefined,
}

export default MenuScrapingClustersDropdown
