import { isEmpty, map, pick } from 'lodash-es'

import { LOCATION_WITH_OWN_MENU } from '../../../brands/constants'

const transformMenu = values => {
  const enhancedValues = {
    ...values,
    additionalUrls: !isEmpty(values.additionalUrls)
      ? values.additionalUrls
      : null,
  }

  return pick(
    enhancedValues,
    'id',
    'format',
    'url',
    'additionalUrls',
    'availabilityComment',
    'isExample',
    'notScrapeableReason',
    'notScrapeableComment',
  )
}

const transformBrand = brand =>
  brand.hasMenu
    ? { id: brand.id, menu: transformMenu(brand.menu) }
    : { id: brand.id }

const transformLocation = location =>
  location.menuStatus === LOCATION_WITH_OWN_MENU
    ? {
        id: location.id,
        menuStatus: location.menuStatus,
        menu: transformMenu(location.menu),
      }
    : {
        id: location.id,
        menuStatus: location.menuStatus,
      }

const transformToMenuDefinitionTaskInput = values => {
  const { task } = values
  const brandData = transformBrand(values.brand)
  const locationsData = map(values.locations, transformLocation)

  return {
    id: task.id,
    brandData,
    locationsData,
  }
}

export default transformToMenuDefinitionTaskInput
