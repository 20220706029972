import React from 'react'
import { Icon, Table } from 'antd'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { LondonDateTime } from '../../../../common/components'

import { isMenuScrapeable } from '../../../../menus/helpers'
import {
  LOCATION_WITH_BRAND_MENU,
  LOCATION_WITH_OWN_MENU,
} from '../../../constants'

const getLocationMenu = location => {
  const { menuStatus } = location
  switch (menuStatus) {
    case LOCATION_WITH_OWN_MENU:
      return location.menu
    case LOCATION_WITH_BRAND_MENU:
      return location.brand.menu
    default:
      return null
  }
}

const LocationMenuTable = ({ location }) => {
  const menu = getLocationMenu(location)

  const tableColumns = [
    { title: 'Menu ID', dataIndex: 'id' },
    {
      title: 'Menu URL',
      dataIndex: 'url',
      render: menuURL =>
        menuURL && (
          <a href={menuURL} target="_blank" rel="noopener noreferrer">
            {menuURL}
          </a>
        ),
    },
    {
      title: 'Dishes',
      dataIndex: 'dishCount',
    },
    {
      title: 'Last update',
      dataIndex: 'modifiedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'JSON',
      render: menu => (
        <Button
          margin="no small no no"
          disabled={
            !isMenuScrapeable(menu.format) || !menu.scrapingInstructions
          }
          onClick={() => {
            fileDownload(menu.scrapingInstructions, `${menu.id}.json`)
          }}
        >
          Download
          <Icon type="download" />
        </Button>
      ),
    },
  ]
  return (
    menu && (
      <>
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={[menu]}
          pagination={false}
        />
      </>
    )
  )
}

LocationMenuTable.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LocationMenuTable
