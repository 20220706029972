import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form as AntForm, Icon, Upload as AntUpload } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

import { showErrorNotification } from '../../common/helpers'

import { Button } from './styled'

const readScrapingInstructions = scrapingInstructionsFile => {
  const reader = new FileReader()
  reader.readAsText(scrapingInstructionsFile)
  return new Promise((resolve, reject) => {
    reader.onloadend = ({ target }) => {
      if (target.error) return reject(target.error)
      return resolve(target.result)
    }
  })
}

const isValidJson = file => {
  try {
    JSON.parse(file)
  } catch (e) {
    return false
  }
  return true
}

const Upload = ({ name, label, accept, disabled }) => {
  const { control, errors, setValue } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <AntForm.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        render={() => (
          <AntUpload
            accept={accept}
            transformFile={readScrapingInstructions}
            showUploadList={false}
            customRequest={({ file }) =>
              isValidJson(file)
                ? setValue(name, file, { shouldValidate: true })
                : showErrorNotification({
                    message: 'File does not contain a valid JSON format',
                  })
            }
          >
            <Button disabled={disabled} margin="no small no no">
              Upload
              <Icon type="upload" />
            </Button>
          </AntUpload>
        )}
      />
    </AntForm.Item>
  )
}

Upload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
}

Upload.defaultProps = {
  label: '',
  accept: '',
  disabled: false,
}

export default Upload
