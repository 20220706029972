import React from 'react'
import { Switch } from 'react-router'

import {
  BRANDS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
} from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import { BrandPage, BrandsPage, LocationPage } from './pages'

const BrandsRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={BRANDS_PERMISSIONS.LIST}
      exact
      path="/brands"
      component={BrandsPage}
    />

    <PermissionRoute
      permissions={LOCATIONS_PERMISSIONS.GET}
      exact
      path="/brands/locations/:locationId"
      component={LocationPage}
    />

    <PermissionRoute
      permissions={BRANDS_PERMISSIONS.GET}
      exact
      path="/brands/:brandId"
      component={BrandPage}
    />
  </Switch>
)

export default BrandsRouter
