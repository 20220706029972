import { concat, includes, isNull, map, pick } from 'lodash-es'

import { APP_NAME, NO, YES } from '../../../../common/constants'

import {
  EXCLUDED,
  INCLUDED,
  MANUAL_GOOGLE_MATCH,
  MASTER,
  NO_GOOGLE_MATCH,
  NOT_SAME,
  SLAVE,
} from '../../../../brands/constants'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'
import { sortLocationsForBrandGatewayTask } from '../../../helpers'

const isIncludedOrExcludedStatus = status =>
  includes([INCLUDED, EXCLUDED], status)

const getBrandValues = values => {
  const brand = pick(
    values,
    'id',
    'name',
    'website',
    'isHasNoWebsiteConfirmed',
    'rank',
    'messinessScore',
    'exclusionReason',
    'exclusionReasonComment',
    'modifiedDate',
    'slaveBrands',
    'notSameBrands',
  )

  return {
    ...brand,
    status: isIncludedOrExcludedStatus(values.status) ? values.status : '',
    isMasterBrand: values.rank === MASTER,
    masterBrand: isNull(values.masterBrand)
      ? { id: '', name: '', brandGatewayTaskId: '' }
      : {
          id: values.masterBrand.id,
          name: values.masterBrand.name,
          brandGatewayTaskId: values.masterBrand.brandGatewayTask.id,
        },
    hasOnlyUnsupportedBusinessTypes: values.hasOnlyUnsupportedBusinessTypes
      ? YES
      : NO,
  }
}

const getLocationValues = values => {
  const location = pick(
    values,
    'id',
    'brandId',
    'name',
    'status',
    'originalBrand',
    'duplicateOf',
    'newBrand',
    'fsaLocation',
    'googleLocation',
    'website',
    'formattedAddress',
    'exclusionReason',
    'exclusionReasonComment',
    'requiresReview',
  )

  if (values.googleMatchRank)
    return {
      ...location,
      googleMatchRank: values.googleMatchRank,
    }

  return {
    ...location,
    googleMatchRank: values.googleLocationId
      ? MANUAL_GOOGLE_MATCH
      : NO_GOOGLE_MATCH,
  }
}

const getRelatedBrandsValues = ({ slaveBrands, notSameBrands }) => {
  const relatedSlaveBrands = map(slaveBrands, brand => ({
    ...brand,
    relationship: SLAVE,
  }))

  const relatedNotSameBrands = map(notSameBrands, brand => ({
    ...brand,
    relationship: NOT_SAME,
  }))

  return concat(relatedSlaveBrands, relatedNotSameBrands)
}

const getTaskValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'qualityCheckedBy',
    'qualityCheckedDate',
    'modifiedDate',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isDone: false,
  }
}

const brandGatewayTaskState = (values, similarBrands) => {
  const task = getTaskValues(values)
  const brand = getBrandValues(values.brand)

  const sortedLocations = sortLocationsForBrandGatewayTask(
    values.brand.locations,
  )

  const locations = map(sortedLocations, location =>
    getLocationValues({
      ...location,
      brand: {
        id: brand.id,
        name: brand.name,
      },
      newBrand: { id: '', name: '', status: '' },
    }),
  )

  const relatedBrands = getRelatedBrandsValues(values.brand)

  const taskState = {
    task,
    brand,
    locations,
    relatedBrands,
    similarBrands,
  }

  return taskState
}

export default brandGatewayTaskState
