import { isEmpty } from 'lodash-es'

const brandImagesFormInitialValues = values => ({
  areAssetsActive: values.areAssetsActive,
  icon: isEmpty(values.iconFileKey)
    ? []
    : [{ uid: '0', status: 'done', url: values.iconFileKey }],
  image: isEmpty(values.imageFileKey)
    ? []
    : [{ uid: '0', status: 'done', url: values.imageFileKey }],
})

export default brandImagesFormInitialValues
