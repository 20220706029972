import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox as AntCheckbox, Form, Icon, Popover } from 'antd'
import PropTypes from 'prop-types'

const Checkbox = ({
  name,
  label,
  text,
  infoMessage,
  onCheckboxChange,
  ...rest
}) => {
  const { control } = useFormContext()
  return (
    <Form.Item label={label}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Controller
          control={control}
          name={name}
          render={({ onChange, value }) => (
            <AntCheckbox
              onChange={e => {
                onChange(e.target.checked)
                if (onCheckboxChange) onCheckboxChange(e.target.checked)
              }}
              checked={value}
              name={name}
              {...rest}
            >
              {text}
            </AntCheckbox>
          )}
        />
        {infoMessage && (
          <div style={{ marginLeft: '10px' }}>
            <Popover content={infoMessage} placement="left">
              <Icon type="info-circle" />
            </Popover>
          </div>
        )}
      </div>
    </Form.Item>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
  infoMessage: PropTypes.string,
  onCheckboxChange: PropTypes.func,
}

Checkbox.defaultProps = {
  label: '',
  text: '',
  infoMessage: '',
  onCheckboxChange: undefined,
}

export default Checkbox
