import { pick } from 'lodash-es'

const transformToUpdateBrandInput = values => ({
  ...pick(
    values,
    'name',
    'website',
    'isForTesting',
    'useBrandNameAsLocationName',
    'useDishTypeIcon',
  ),
})

export default transformToUpdateBrandInput
