import { pick } from 'lodash-es'

const transformMenu = menu =>
  pick(
    menu,
    'id',
    'format',
    'scrapingInstructions',
    'notScrapeableReason',
    'notScrapeableComment',
  )

const transformToMenuScrapingTaskInput = values => {
  const { task, isResolveWithDebugModeScraping = false } = values

  const menuData = transformMenu(values.menu)

  return {
    id: task.id,
    menuData,
    isDebugModeScraping: isResolveWithDebugModeScraping,
  }
}

export default transformToMenuScrapingTaskInput
