import { map, pick } from 'lodash-es'

const transformMenuTitle = values => pick(values, 'id', 'isEverywhereAvailable')

const transformToDishReviewTaskInput = values => {
  const { task, menu, menuTitles } = values

  const menuId = menu.id
  const menuTitlesData = map(menuTitles, transformMenuTitle)

  return {
    id: task.id,
    menuId,
    menuTitlesData,
  }
}

export default transformToDishReviewTaskInput
