import { useLocation } from 'react-router-dom'
import { first, last, split } from 'lodash-es'

export const useFirstPathnameSegment = () => {
  const { pathname } = useLocation()
  return pathname.split('/')[1]
}

export const useLastPathnameSegment = () => {
  const { pathname } = useLocation()
  const url = first(split(pathname, '?'))
  return last(split(url, '/'))
}
