import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTER_SIMILAR_MENUS = gql`
  query menuScrapingClusterSimilarMenus($id: ID!, $page: Int, $pageSize: Int) {
    menuScrapingClusterSimilarMenus(id: $id, page: $page, pageSize: $pageSize) {
      menus {
        id
        url
        brand {
          id
          name
        }
      }
      total
    }
  }
`

export default MENU_SCRAPING_CLUSTER_SIMILAR_MENUS
