import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Row, Tabs } from 'antd'

import { Panel } from '../../../core/components/styled'
import { createUrl } from '../../../core/utils/services/queryParams'

import { SearchBrandFilter, SearchMenuFilter } from '../../../common/components'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { MENU_TYPES } from '../../constants'

import { AllMenusPage } from '.'

const MenusPage = () => {
  useDocumentTitle('Menus')
  const history = useHistory()
  const { pathname, search } = useLocation()

  const handleOnChangeTabs = useCallback(
    () => history.push(createUrl(pathname, search, { page: '' })),
    [history, pathname, search],
  )

  return (
    <>
      <Panel margin="small no">
        <Row gutter={16}>
          <Col span={8}>
            <SearchBrandFilter />
          </Col>
          <Col span={9}>
            <SearchMenuFilter />
          </Col>
        </Row>
      </Panel>

      <Panel margin="small no">
        <Tabs onChange={handleOnChangeTabs} type="card">
          <Tabs.TabPane tab={MENU_TYPES.ALL.name} key={MENU_TYPES.ALL.key}>
            <AllMenusPage />
          </Tabs.TabPane>
        </Tabs>
      </Panel>
    </>
  )
}

export default MenusPage
