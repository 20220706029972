import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'

import {
  RESOLVE_MENU_DEFINITION_TASK,
  SAVE_MENU_DEFINTION_TASK,
} from '../../graphql/mutations'
import { MENU_DEFINITION_TASK } from '../../graphql/queries'
import { transformToMenuDefinitionTaskInput } from '../../graphql/transformers'
import { MenuDefinitionTaskForm } from '../forms'

const onSubmit = (values, saveTask, resolveTask) => {
  const variables = transformToMenuDefinitionTaskInput(values)

  return values.task.isDone
    ? resolveTask({
        variables,
      })
    : saveTask({
        variables,
      })
}

const MenuDefinitionTaskPage = () => {
  useDocumentTitle('Tasks - Menu Definition')
  const { taskId } = useParams()
  const history = useHistory()

  const {
    data: { menuDefinitionTask } = {},
    loading: isLoadingTask,
    refetch: refetchMenuDefinitionTask,
    networkStatus: taskQueryNetworkStatus,
  } = useQuery(MENU_DEFINITION_TASK, {
    variables: { id: taskId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [saveTask, { loading: isSavingTask }] = useMutation(
    SAVE_MENU_DEFINTION_TASK,
    {
      onCompleted() {
        refetchMenuDefinitionTask()
        showSuccessNotification({
          message: 'Menu definition task saved.',
          description: 'Task has been successfully saved.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Save menu definition task failed.',
          description: message,
        })
      },
    },
  )

  const [resolveTask, { loading: isResolvingTask }] = useMutation(
    RESOLVE_MENU_DEFINITION_TASK,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Menu definition task resolved.',
          description: 'Task has been successfully resolved.',
        })
        history.push(getCachedFilterForPage('/tasks/brand-definition'))
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Resolve menu definition task failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    task => onSubmit(task, saveTask, resolveTask),
    [saveTask, resolveTask],
  )

  return isLoadingTask ? (
    <Spinner />
  ) : (
    <MenuDefinitionTaskForm
      task={menuDefinitionTask}
      isSavingTask={isSavingTask}
      isResolvingTask={isResolvingTask}
      isRefetchingTask={taskQueryNetworkStatus === NetworkStatus.refetch}
      onSubmit={handleSubmit}
    />
  )
}

export default MenuDefinitionTaskPage
