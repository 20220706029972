import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { Button, Panel } from '../../../core/components/styled'

import { UsersDropdown } from '../../../users/components/pages/sections'

import { userCountsReportSchema } from './schemas'

const UserCountsReportForm = ({ isGeneratingReport, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      userId: '',
    },
    resolver: yupResolver(userCountsReportSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom">
            <Col span={5} offset={0}>
              <UsersDropdown
                name="userId"
                label="User"
                showSearch
                allowClear
                disableInctiveUsers={false}
              />
            </Col>
            <Col span={1} offset={1}>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isGeneratingReport}
              >
                Generate user counts report
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

UserCountsReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isGeneratingReport: PropTypes.bool.isRequired,
}

export default UserCountsReportForm
