import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { isNull, map, mapKeys } from 'lodash-es'
import PropTypes from 'prop-types'

import { Panel } from '../../../../core/components/styled'

import { DAYS_OF_WEEK, EMPTY_VALUE } from '../../../../common/constants'
import { formatTime } from '../../../../common/services/formatter'

const LocationWorkingHours = ({ location }) => {
  const workingHours = location.workingHours
    ? mapKeys(location.workingHours, 'dayOfWeek')
    : []

  return (
    <Panel margin="small no" withBorderBottom>
      <Row>
        <Card title="Working hours" bordered={false}>
          {map(DAYS_OF_WEEK, ({ name, value }, index) => (
            <Row key={index}>
              <Col span={6}>
                <Typography.Text strong>{name}</Typography.Text>
              </Col>
              {workingHours[value] &&
                map(
                  workingHours[value].intervals,
                  ({ openTime, closeTime }, index) => (
                    <Col span={6} key={index}>
                      {`${
                        isNull(openTime) ? EMPTY_VALUE : formatTime(openTime)
                      } /
                      ${
                        isNull(closeTime) ? EMPTY_VALUE : formatTime(closeTime)
                      }`}
                    </Col>
                  ),
                )}
            </Row>
          ))}
        </Card>
      </Row>
    </Panel>
  )
}

LocationWorkingHours.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LocationWorkingHours
