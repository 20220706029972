import http from '../../core/services/http'

export const generateScrapingVerificationReport = ({
  userIds,
  startDate,
  endDate,
}) =>
  http.get(
    `/reports/scraping-verification?userIds=${userIds}&startDate=${startDate}&endDate=${endDate}`,
    { responseType: 'blob' },
  )
