import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { THEME } from '../../../../core/constants'

import { MapPin as MapPinContainer } from '../../styled'

const TRANSFORMS_BY_TYPE = {
  fsa: 'translate(-24 -38) rotate(-30)',
  fsaDisabled: 'translate(-24 -2) rotate(-150)',
  googlePlace: 'translate(-8 -38) rotate(30)',
  googlePlaceDisabled: 'translate(-5 0) rotate(150)',
  searched: 'translate(-16 -38) ',
}

const TRANSFORMS_BY_TYPE_FOR_TEXT = {
  fsa: '',
  fsaDisabled: 'translate(32 35) rotate(180)',
  googlePlace: '',
  googlePlaceDisabled: 'translate(33 33) rotate(175)',
  searched: '',
}

const COLOR_NAMES_BY_TYPE = {
  fsa: 'red',
  fsaDisabled: 'greyRed',
  googlePlace: 'blue',
  googlePlaceDisabled: 'greyBlue',
  searched: 'blue',
}

const MapPin = ({ type, text, children }) => {
  const colorName = COLOR_NAMES_BY_TYPE[type]
  const color = THEME.colors[colorName]

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const togglePopover = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [
    isPopoverOpen,
    setIsPopoverOpen,
  ])

  return (
    <MapPinContainer color={colorName}>
      <svg
        width="32"
        height="43"
        xmlns="http://www.w3.org/2000/svg"
        transform={TRANSFORMS_BY_TYPE[type]}
        onClick={togglePopover}
      >
        <path
          d="M16.114 41.62c-2.245 0-14.876-17.832-14.876-26.048C1.238 7.357 7.898.697 16.114.697c8.215 0 14.875 6.66 14.875 14.875 0 8.216-12.63 26.048-14.875 26.048z"
          stroke={color}
          fill={color}
          fillRule="evenodd"
          fillOpacity=".3"
        />

        {!!text && (
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            fill={color}
            transform={TRANSFORMS_BY_TYPE_FOR_TEXT[type]}
          >
            {text}
          </text>
        )}
      </svg>

      {isPopoverOpen && children}
    </MapPinContainer>
  )
}

MapPin.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
}

MapPin.defaultProps = {
  text: '',
}

export default memo(MapPin)
