import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Icon, Popconfirm, Spin } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { USERS_PERMISSIONS } from '../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { useIsLoggedInUser } from '../../../../common/hooks'

import { PermissionGuard } from '../../../../account/components/routes'
import { DELETE_USER } from '../../../graphql/mutations'

const UsersTableActionColumn = ({ user, refetchUsers }) => {
  const isLoggedInUser = useIsLoggedInUser(user)

  const [deleteUser, { loading: isDeletingUser }] = useMutation(DELETE_USER, {
    onCompleted({ deleteUser: { name } }) {
      refetchUsers()
      showSuccessNotification({
        message: 'User deleted.',
        description: `${name} has been successfully deleted.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'User deletion failed.',
        description: message,
      })
    },
  })

  const history = useHistory()

  return isDeletingUser ? (
    <Spin />
  ) : (
    <>
      <PermissionGuard permissions={USERS_PERMISSIONS.UPDATE}>
        <Button
          onClick={() => history.push(`/users/${user.id}`)}
          margin="no small"
        >
          Edit
        </Button>
      </PermissionGuard>
      <PermissionGuard permissions={USERS_PERMISSIONS.DELETE}>
        <Popconfirm
          title="Are you sure you want to delete this user?"
          disabled={isLoggedInUser}
          onConfirm={() => deleteUser({ variables: { id: user.id } })}
          placement="left"
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={isLoggedInUser}>
            <Icon type="delete" />
          </Button>
        </Popconfirm>
      </PermissionGuard>
    </>
  )
}

UsersTableActionColumn.propTypes = {
  user: PropTypes.object.isRequired,
  refetchUsers: PropTypes.func.isRequired,
}

export default UsersTableActionColumn
