import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { markMiscTaskAsVerifiedFormInitialValues } from './initial-values'

const MarkMiscTaskAsVerifiedForm = ({ onSubmit, onClose }) => {
  const formMethods = useForm({
    defaultValues: markMiscTaskAsVerifiedFormInitialValues(),
  })

  const handleSave = data => {
    onSubmit(data)
    formMethods.reset(markMiscTaskAsVerifiedFormInitialValues())
  }

  const handleCancel = () => {
    onClose()
    formMethods.reset(markMiscTaskAsVerifiedFormInitialValues())
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSave)}>
        <Row>
          <TextArea name="comment" label="Comment" autoSize />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={handleCancel} margin="small small no no">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" margin="small no no no">
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

MarkMiscTaskAsVerifiedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MarkMiscTaskAsVerifiedForm
