import { useUserHasPermissions } from '../../common/hooks'

const useCanUserAssignCluster = (
  menuScrapingCluster,
  permissions,
  adminPermission,
) => {
  const hasPermission = useUserHasPermissions(permissions)
  const hasAdminPermission = useUserHasPermissions(adminPermission)
  return (
    (!menuScrapingCluster.assignedTo && hasPermission) || hasAdminPermission
  )
}

export default useCanUserAssignCluster
