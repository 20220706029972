import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import { MarkMiscTaskAsVerifiedForm } from '../../forms'

const MarkMiscTaskAsVerifiedModal = ({
  isModalOpen,
  isQCVerified,
  onSubmit,
  closeModal,
}) => {
  const title = isQCVerified
    ? 'Mark miscellaneous task as QC verified'
    : 'Mark miscellaneous task as verified'

  return (
    <Modal title={title} visible={isModalOpen} footer={null}>
      <MarkMiscTaskAsVerifiedForm
        isModalOpen={isModalOpen}
        onSubmit={onSubmit}
        onClose={closeModal}
      />
    </Modal>
  )
}

MarkMiscTaskAsVerifiedModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isQCVerified: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default MarkMiscTaskAsVerifiedModal
