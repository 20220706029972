import { mapKeys } from 'lodash-es'

export const LOCATIONS_CHANGED = 'LOCATIONS_CHANGED'
export const LOCATIONS_MOVED_FROM_ANOTHER_BRAND =
  'LOCATIONS_MOVED_FROM_ANOTHER_BRAND'
export const MANUAL_CHANGE = 'MANUAL_CHANGE'
export const MENU_SCRAPING_BY_USER = 'MENU_SCRAPING'
export const MENU_SCRAPING_ERROR = 'MENU_SCRAPING_ERROR'
export const MENU_SCRAPING_SIGNIFICANT_CHANGES =
  'MENU_SCRAPING_SIGNIFICANT_CHANGES'
export const REJECT_DISH_REVIEW_TASK = 'REJECT_DISH_REVIEW_TASK'
export const RESOLVE_BRAND_DEFINITION_TASK = 'RESOLVE_BRAND_DEFINITION_TASK'
export const RESOLVE_BRAND_GATEWAY_TASK = 'RESOLVE_BRAND_GATEWAY_TASK'
export const SYSTEM_CHANGE = 'SYSTEM_CHANGE'
export const UNMARK_BRAND_AS_MASTER = 'UNMARK_BRAND_AS_MASTER'
export const UNMARK_BRAND_AS_SLAVE = 'UNMARK_BRAND_AS_SLAVE'

export const TASK_REOPEN_REASONS = mapKeys(
  [
    { name: 'Locations changed', value: LOCATIONS_CHANGED },
    {
      name: 'Locations moved from another brand',
      value: LOCATIONS_MOVED_FROM_ANOTHER_BRAND,
    },
    { name: 'Manual change', value: MANUAL_CHANGE },
    { name: 'Menu scraping', value: MENU_SCRAPING_BY_USER },
    { name: 'Menu scraping error', value: MENU_SCRAPING_ERROR },
    {
      name: 'Menu scraping significant changes',
      value: MENU_SCRAPING_SIGNIFICANT_CHANGES,
    },
    { name: 'Reject Dish Review task', value: REJECT_DISH_REVIEW_TASK },
    {
      name: 'Resolve Brand Definition task',
      value: RESOLVE_BRAND_DEFINITION_TASK,
    },
    { name: 'Resolve Brand Gateway task', value: RESOLVE_BRAND_GATEWAY_TASK },
    { name: 'System change', value: SYSTEM_CHANGE },
    { name: 'Unmark brand as master', value: UNMARK_BRAND_AS_MASTER },
    { name: 'Unmark brand as slave', value: UNMARK_BRAND_AS_SLAVE },
  ],
  'value',
)
