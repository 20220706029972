import styled, { css } from 'styled-components'

import { sizeMapper } from '../../utils/styles'

const Panel = styled.div`
  position: relative;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.withBorderBottom &&
    css`
      border-bottom: 1px solid gray;
    `};
`

export default Panel
