import { filter, map, orderBy } from 'lodash-es'

import { aggregateChildDishCountForMenuTitle } from '../../aggregators'
import { attachMenuTitlePathToDishes } from '../../helpers'

const getMenuTitles = menuTitles => {
  const menuTitlesWithChildDishCount = map(menuTitles, menuTitle => {
    const childDishCount = aggregateChildDishCountForMenuTitle(
      menuTitle,
      menuTitles,
    )

    const { diets, addons, miscInfo, allergens } = menuTitle

    return {
      ...menuTitle,
      diets: diets && map(diets, diet => JSON.parse(diet)),
      addons: addons && map(addons, addon => JSON.parse(addon)),
      miscInfo: miscInfo && map(miscInfo, misc => JSON.parse(misc)),
      allergens: allergens && map(allergens, allergen => JSON.parse(allergen)),
      childDishCount,
    }
  })

  return orderBy(menuTitlesWithChildDishCount, 'isDeleted')
}

const getDishes = allDishes => {
  const dishes = filter(allDishes, dish => !dish.isDeleted)

  return map(dishes, dish => {
    const { diets, addons, miscInfo, allergens, nutritions } = dish

    return {
      ...dish,
      diets: diets && map(diets, diet => JSON.parse(diet)),
      addons: addons && map(addons, addon => JSON.parse(addon)),
      miscInfo: miscInfo && map(miscInfo, misc => JSON.parse(misc)),
      allergens: allergens && map(allergens, allergen => JSON.parse(allergen)),
      nutritions:
        nutritions && map(nutritions, nutrition => JSON.parse(nutrition)),
    }
  })
}

const transformToMenu = menu => {
  const { menuTitles, dishes } = menu

  const transformedMenuTitles = getMenuTitles(menuTitles)
  const transformedDishes = getDishes(dishes)

  return {
    ...menu,
    menuTitles: transformedMenuTitles,
    dishes: attachMenuTitlePathToDishes(
      transformedDishes,
      transformedMenuTitles,
    ),
  }
}

export default transformToMenu
