import React from 'react'
import { Route, Switch } from 'react-router'

import {
  COMMON_WEBSITE_DOMAIN_PERMISSIONS,
  PLATFORM_PERMISSIONS,
} from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import {
  CommonWebsiteDomainPage,
  PlatformEditorPage,
  PlatformsPage,
  SettingsMainPage,
} from './pages'

const SettingsRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={PLATFORM_PERMISSIONS.CREATE}
      path="/settings/platforms/create"
      component={PlatformEditorPage}
    />
    <PermissionRoute
      permissions={PLATFORM_PERMISSIONS.UPDATE}
      path="/settings/platforms/:id"
      component={PlatformEditorPage}
    />
    <>
      <Route path="/settings" component={SettingsMainPage} />
      <PermissionRoute
        permissions={COMMON_WEBSITE_DOMAIN_PERMISSIONS.LIST}
        path="/settings/common-websites"
        component={CommonWebsiteDomainPage}
      />
      <PermissionRoute
        permissions={PLATFORM_PERMISSIONS.LIST}
        path="/settings/platforms"
        component={PlatformsPage}
      />
    </>
  </Switch>
)

export default SettingsRouter
