import { gql } from 'apollo-boost'

const REOPEN_DISH_REVIEW_TASK = gql`
  mutation reopenDishReviewTask($id: ID!) {
    reopenDishReviewTask(id: $id) {
      id
      modifiedDate
      reopenedDate
    }
  }
`

export default REOPEN_DISH_REVIEW_TASK
