import { gql } from 'apollo-boost'

const SET_MENU_SCRAPING_TEMPLATE_SCRAPING_ISSUE = gql`
  mutation setMenuScrapingTemplateScrapingIssue(
    $id: ID!
    $scrapingIssueComment: String
  ) {
    setMenuScrapingTemplateScrapingIssue(
      id: $id
      scrapingIssueComment: $scrapingIssueComment
    ) {
      id
      scrapingIssueComment
    }
  }
`

export default SET_MENU_SCRAPING_TEMPLATE_SCRAPING_ISSUE
