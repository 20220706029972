import { gql } from 'apollo-boost'

const MENU_SCRAPING_RERUNS = gql`
  query menuScrapingReruns($page: Int, $pageSize: Int) {
    menuScrapingReruns(page: $page, pageSize: $pageSize) {
      menuScrapingReruns {
        id
        status
        createdDate
        createdBy {
          id
          name
        }
        timeSlotCount
        startTime
        estimatedCompletionTime
        completedDate
        totalMenuCount
        scrapedMenuCount
        jobCountByScrapingStatus
      }
      total
    }
  }
`

export default MENU_SCRAPING_RERUNS
