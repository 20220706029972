import { mapKeys } from 'lodash-es'

export const NEW = 'NEW'
export const NOT_SCRAPEABLE = 'NOT_SCRAPEABLE'
export const CURRENTLY_UNSCRAPEABLE = 'CURRENTLY_UNSCRAPEABLE'
export const SCRAPE_SCHEDULED = 'SCRAPE_SCHEDULED'
export const PARTIALLY_SCRAPED = 'PARTIALLY_SCRAPED'
export const SCRAPED = 'SCRAPED'
export const APPROVED = 'APPROVED'
export const AUTO_APPROVED = 'AUTO_APPROVED'
export const AUTO_REJECTED = 'AUTO_REJECTED'
export const SCRAPING_RERUN_ERROR = 'SCRAPING_RERUN_ERROR'
export const SCRAPING_RERUN_SIGNIFICANT_CHANGES =
  'SCRAPING_RERUN_SIGNIFICANT_CHANGES'
export const REJECTED_RESCRAPE = 'REJECTED_RESCRAPE'
export const REJECTED_NEW = 'REJECTED_NEW'

export const RESCRAPING_MENU_STATUSES = mapKeys(
  [
    { label: 'Rescrape', value: REJECTED_RESCRAPE },
    { label: 'New', value: REJECTED_NEW },
  ],
  'value',
)

export const MENU_STATUSES = mapKeys(
  [
    { name: 'New', value: NEW },
    { name: 'Not scrapeable', value: NOT_SCRAPEABLE },
    {
      name: 'Currently unscrapeable',
      value: CURRENTLY_UNSCRAPEABLE,
    },
    { name: 'Scrape scheduled', value: SCRAPE_SCHEDULED },
    { name: 'Partially scraped', value: PARTIALLY_SCRAPED },
    { name: 'Scraped', value: SCRAPED },
    { name: 'Approved', value: APPROVED },
    { name: 'Auto approved', value: AUTO_APPROVED },
    { name: 'Rejected - Rescrape', value: REJECTED_RESCRAPE },
    { name: 'Rejected - New', value: REJECTED_NEW },
    { name: 'Auto rejected', value: AUTO_REJECTED },
    { name: 'Scraping rerun error', value: SCRAPING_RERUN_ERROR },
    {
      name: 'Scraping rerun significant changes',
      value: SCRAPING_RERUN_SIGNIFICANT_CHANGES,
    },
  ],
  'value',
)
