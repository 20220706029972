import React from 'react'
import { Switch } from 'react-router'

import { USERS_PERMISSIONS } from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import { UserEditorPage, UsersPage } from './pages'

const UsersRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={USERS_PERMISSIONS.LIST}
      exact
      path="/users"
      component={UsersPage}
    />

    <PermissionRoute
      permissions={USERS_PERMISSIONS.CREATE}
      exact
      path="/users/create"
      component={UserEditorPage}
    />

    <PermissionRoute
      permissions={USERS_PERMISSIONS.GET}
      exact
      path="/users/:id"
      component={UserEditorPage}
    />
  </Switch>
)

export default UsersRouter
