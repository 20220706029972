import React, { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Col,
  Form as AntForm,
  Modal,
  Pagination,
  Row,
  Select as AntSelect,
  Table,
  Typography,
} from 'antd'
import { forEach, map, values } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  FormInternalLink,
  HiddenInputs,
  Input,
  Select,
} from '../../../../core/components'
import { Button } from '../../../../core/components/styled'
import { THEME } from '../../../../core/constants'

import {
  GoogleMapSearchByAddress,
  GoogleSearch,
  OpenLink,
  VisibilityContainer,
} from '../../../../common/components'
import { PAGE_SIZE_OPTIONS } from '../../../../common/constants/pagination'
import { useCanUserClone } from '../../../../common/hooks'

import { LocationGoogleMatchRank } from '../../../../brands/components/pages/sections'
import CloneLocationModal from '../../../../brands/components/pages/sections/clone-location-modal'
import {
  EXCLUDED,
  EXCLUDED_LOCATION,
  FSA_BUSINESS_TYPES,
  INCLUDED_LOCATION,
  LOCATION_EXCLUSION_REASONS,
  LOCATION_STATUSES,
  MISC,
  NEW_LOCATION,
} from '../../../../brands/constants'

import SearchBrandSelect from './search-brand-select'

const inclusionOptions = [
  {
    name: LOCATION_STATUSES.INCLUDED.name,
    value: LOCATION_STATUSES.INCLUDED.value,
  },
  {
    name: LOCATION_STATUSES.EXCLUDED.name,
    value: LOCATION_STATUSES.EXCLUDED.value,
  },
]

const BrandGatewayLocations = ({
  currentBrandId,
  originalBrandId,
  originalBrands,
  onChange,
  totalLocationCount,
  filteredLocationCount,
  currentPage,
  currentPageSize,
  onPageChange,
  allLocationsStatus,
  setAllLocationsStatus,
}) => {
  const [isCloneLocationModalOpen, setIsCloneLocationModalOpen] = useState(
    false,
  )

  const [locationToClone, setLocationToClone] = useState(null)

  const { control, getValues, setValue, watch } = useFormContext()

  const canUserClone = useCanUserClone()

  const originalBrandsOptions = useMemo(
    () =>
      map(originalBrands, ({ id, name }) => ({
        name: `${name} - ${id}`,
        value: id,
      })),
    [originalBrands],
  )

  const { fields: locations } = useFieldArray({
    control,
    name: 'locations',
  })

  const onSetAllLocationsStatus = useCallback(
    status => {
      setAllLocationsStatus(status)
      const { locations } = getValues()

      forEach(locations, (location, index) => {
        setValue(`locations.${index}.status`, status, {
          shouldDirty: true,
          shouldValidate: true,
        })
      })
    },
    [getValues, setValue, setAllLocationsStatus],
  )

  const onSearchBrandSelectChange = useCallback(
    (values, index) => {
      if (!values) {
        setValue(`locations.${index}.newBrand.id`, null)
        setValue(`locations.${index}.newBrand.name`, null)
      } else {
        const { key: brandId, label: brandName } = values
        setValue(`locations.${index}.newBrand.id`, brandId)
        setValue(`locations.${index}.newBrand.name`, brandName)
      }
    },
    [setValue],
  )

  const setRecordStyle = ({ requiresReview }) => {
    if (requiresReview) return 'requiresReviewRow'
    return undefined
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '80px',
      render: (id, location, index) => (
        <FormInternalLink
          name={`locations.${index}.id`}
          transform={id => `/brands/locations/${id}`}
        />
      ),
    },
    {
      title: 'Original brand ID',
      dataIndex: 'originalBrand.id',
      width: '100px',
    },
    {
      title: 'Original brand name',
      dataIndex: 'originalBrand.name',
      width: '160px',
    },
    {
      title: 'Location name',
      dataIndex: 'name',
      width: '160px',
    },
    {
      title: 'H&S location',
      width: '160px',
      dataIndex: 'fsaLocation',
      render: (fsaLocation, location) => (
        <GoogleSearch
          text={fsaLocation.name}
          queryText={`${fsaLocation.name} ${location.formattedAddress}`}
        />
      ),
    },
    {
      title: 'H&S business type',
      width: '160px',
      dataIndex: 'fsaLocation.businessType',
      render: businessTypeId => FSA_BUSINESS_TYPES[businessTypeId].name,
    },
    {
      title: 'Google name',
      dataIndex: 'googleLocation.name',
      width: '160px',
      render: (googleName, location) => (
        <GoogleSearch
          text={googleName}
          queryText={`${googleName} ${location.formattedAddress}`}
        />
      ),
    },
    {
      title: 'Google match',
      dataIndex: 'googleMatchRank',
      width: '100px',
      render: googleMatchRank =>
        googleMatchRank && (
          <LocationGoogleMatchRank matchRank={googleMatchRank} />
        ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '300px',
      render: (website, location, index) => {
        const locationStatus = watch(`locations.${index}.status`)

        return (
          <Input
            name={`locations.${index}.website`}
            disabled={locationStatus === NEW_LOCATION}
            addonAfter={
              <OpenLink
                onClick={() =>
                  window.open(getValues(`locations.${index}.website`))
                }
              />
            }
          />
        )
      },
    },
    {
      title: 'Address',
      render: location => <GoogleMapSearchByAddress location={location} />,
    },
    {
      title: 'Inclusion',
      width: '120px',
      render: (row, location, index) => {
        const newBrandStatus = watch(`locations.${index}.newBrand.status`)
        return (
          <Select
            disabled={newBrandStatus === EXCLUDED}
            name={`locations.${index}.status`}
            options={inclusionOptions}
            onSelectChange={status => {
              if (
                status === INCLUDED_LOCATION &&
                location.status === EXCLUDED_LOCATION
              ) {
                Modal.confirm({
                  title: 'Are you sure?',
                  content:
                    'Including an excluded location has side effects. Doing this you will remove location as duplicate, remove the has no Google flag, remove connection with a permanently closed Google location. Do you want to continue?',
                  onOk() {
                    setValue(`locations.${index}.exclusionReason`, '')
                    setValue(`locations.${index}.exclusionReasonComment`, '')
                  },
                  onCancel() {
                    setValue(`locations.${index}.status`, EXCLUDED_LOCATION)
                  },
                })
              }
            }}
          />
        )
      },
    },
    {
      title: 'Exclusion reason',
      width: '180px',
      render: (row, location, index) => (
        <VisibilityContainer
          isHidden={watch(`locations.${index}.status`) !== EXCLUDED_LOCATION}
        >
          <Select
            name={`locations.${index}.exclusionReason`}
            options={values(LOCATION_EXCLUSION_REASONS)}
            onSelectChange={exclusionReason => {
              if (exclusionReason !== MISC) {
                setValue(`locations.${index}.exclusionReasonComment`, '')
              }
            }}
          />
        </VisibilityContainer>
      ),
    },
    {
      title: 'Exclusion comment',
      width: '150px',
      render: (row, location, index) => {
        const locationExclusionReason = watch(
          `locations.${index}.exclusionReason`,
        )

        return (
          <VisibilityContainer isHidden={locationExclusionReason !== MISC}>
            <Input name={`locations.${index}.exclusionReasonComment`} />
          </VisibilityContainer>
        )
      },
    },
    {
      title: 'Change brand',
      width: '270px',
      render: (row, location, index) => (
        <SearchBrandSelect
          locationIndex={index}
          onChange={values => onSearchBrandSelectChange(values, index)}
          width="100%"
          excludeBrandId={Number(currentBrandId)}
        />
      ),
    },
    {
      title: 'Duplicate of',
      dataIndex: 'duplicateOf',
      width: '100px',
      render: duplicateLocation =>
        duplicateLocation && (
          <Link to={`/brands/locations/${duplicateLocation.id}`}>
            {duplicateLocation.name}
          </Link>
        ),
    },
    {
      align: 'right',
      render: cloneLocation =>
        canUserClone && (
          <Button
            onClick={() => {
              setLocationToClone(cloneLocation)
              setIsCloneLocationModalOpen(true)
            }}
          >
            Clone
          </Button>
        ),
    },
    {
      width: '0px',
      render: (row, location, index) => (
        <HiddenInputs
          names={[
            `locations.${index}.brandId`,
            `locations.${index}.originalBrand`,
            `locations.${index}.formattedAddress`,
            `locations.${index}.fsaLocation`,
            `locations.${index}.googleLocation.name`,
            `locations.${index}.newBrand.id`,
            `locations.${index}.newBrand.name`,
            `locations.${index}.duplicateOf.id`,
            `locations.${index}.duplicateOf.name`,
          ]}
        />
      ),
    },
  ]

  return (
    <>
      <Typography.Title level={4}>
        {`Total locations (${totalLocationCount}) - Filtered locations: (${filteredLocationCount})`}
      </Typography.Title>
      <Row gutter={16}>
        <Col span={5}>
          <AntForm.Item label="Original brand">
            <AntSelect
              onChange={onChange}
              style={{ width: '100%' }}
              allowClear
              value={originalBrandId}
            >
              {map(originalBrandsOptions, ({ name, value }) => (
                <AntSelect.Option
                  key={value}
                  value={value}
                  style={{
                    color: value === currentBrandId ? THEME.colors.blue : '',
                  }}
                >
                  {name}
                </AntSelect.Option>
              ))}
            </AntSelect>
          </AntForm.Item>
        </Col>
        <Col span={2}>
          <AntForm.Item label="All locations inclusions">
            <AntSelect
              onChange={status => {
                onSetAllLocationsStatus(status)
                if (status === INCLUDED_LOCATION) {
                  forEach(locations, (location, idex) => {
                    setValue(`locations.${idex}.exclusionReason`, '')
                    setValue(`locations.${idex}.exclusionReasonComment`, '')
                  })
                }
              }}
              value={allLocationsStatus}
            >
              {map(inclusionOptions, ({ name, value }) => (
                <AntSelect.Option key={value} value={value}>
                  {name}
                </AntSelect.Option>
              ))}
            </AntSelect>
          </AntForm.Item>
        </Col>
      </Row>
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        columns={tableColumns}
        dataSource={locations}
        rowClassName={setRecordStyle}
        pagination={false}
      />
      <Row type="flex" justify="end">
        <Pagination
          showSizeChanger
          size="small"
          current={currentPage}
          style={{ marginTop: '15px' }}
          pageSize={currentPageSize}
          total={filteredLocationCount}
          onChange={onPageChange}
          onShowSizeChange={onPageChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
        />
      </Row>
      {locationToClone && (
        <CloneLocationModal
          location={locationToClone}
          isModalOpen={isCloneLocationModalOpen}
          website={getValues('brand.website')}
          originalName="test"
          closeModal={() => setIsCloneLocationModalOpen(false)}
        />
      )}
    </>
  )
}

BrandGatewayLocations.propTypes = {
  currentBrandId: PropTypes.string.isRequired,
  originalBrandId: PropTypes.string,
  originalBrands: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  totalLocationCount: PropTypes.number.isRequired,
  filteredLocationCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentPageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  allLocationsStatus: PropTypes.string,
  setAllLocationsStatus: PropTypes.func.isRequired,
}

BrandGatewayLocations.defaultProps = {
  originalBrandId: '',
  allLocationsStatus: '',
}

export default BrandGatewayLocations
