import { partition, sortBy } from 'lodash-es'

import { LOCATION_MENU_STATUSES_SORT_VALUES } from '../constants'

const sortLocationByMenuStatusComparator = location =>
  LOCATION_MENU_STATUSES_SORT_VALUES[location.menuStatus].sortValue

const sortLocationsByMenuStatus = locations => {
  const locationsSortedById = sortBy(locations, 'id')

  const [locationsWithMenuStatus, locationsWithoutMenuStatus] = partition(
    locationsSortedById,
    location => location.menuStatus,
  )

  const locationsWithMenuStatusSortedByMenuStatus = sortBy(
    locationsWithMenuStatus,
    sortLocationByMenuStatusComparator,
  )

  return [
    ...locationsWithoutMenuStatus,
    ...locationsWithMenuStatusSortedByMenuStatus,
  ]
}

export default sortLocationsByMenuStatus
