import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const MenuScrapingJobStatsPanel = ({
  title,
  existingItemCount,
  scrapedItemCount,
  deletedItemCount,
  restoredItemCount,
  significantlyUpdatedItemCount,
  updatedItemCount,
  createdItemCount,
}) => {
  return (
    <>
      <Typography.Text strong>{title}</Typography.Text>
      <Row>
        <Typography.Text>Existing: {existingItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Scraped: {scrapedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Deleted: {deletedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Restored: {restoredItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>
          Updated (significantly): {significantlyUpdatedItemCount}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Updated: {updatedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Created: {createdItemCount}</Typography.Text>
      </Row>
    </>
  )
}

MenuScrapingJobStatsPanel.propTypes = {
  title: PropTypes.string.isRequired,
  existingItemCount: PropTypes.number.isRequired,
  scrapedItemCount: PropTypes.number.isRequired,
  deletedItemCount: PropTypes.number.isRequired,
  restoredItemCount: PropTypes.number.isRequired,
  significantlyUpdatedItemCount: PropTypes.number.isRequired,
  updatedItemCount: PropTypes.number.isRequired,
  createdItemCount: PropTypes.number.isRequired,
}

const MenuScrapingJobStats = ({ menuScrapingJob = {} }) => (
  <>
    <Col span={5}>
      <MenuScrapingJobStatsPanel
        title="Menu Titles Changes"
        existingItemCount={menuScrapingJob.existingMenuTitleCount}
        scrapedItemCount={menuScrapingJob.scrapedMenuTitleCount}
        deletedItemCount={menuScrapingJob.deletedMenuTitleCount}
        restoredItemCount={menuScrapingJob.restoredMenuTitleCount}
        significantlyUpdatedItemCount={
          menuScrapingJob.significantlyUpdatedMenuTitleCount
        }
        updatedItemCount={menuScrapingJob.updatedMenuTitleCount}
        createdItemCount={menuScrapingJob.createdMenuTitleCount}
      />
    </Col>
    <Col span={5}>
      <MenuScrapingJobStatsPanel
        title="Dishes Changes"
        existingItemCount={menuScrapingJob.existingDishCount}
        scrapedItemCount={menuScrapingJob.scrapedDishCount}
        deletedItemCount={menuScrapingJob.deletedDishCount}
        restoredItemCount={menuScrapingJob.restoredDishCount}
        significantlyUpdatedItemCount={
          menuScrapingJob.significantlyUpdatedDishCount
        }
        updatedItemCount={menuScrapingJob.updatedDishCount}
        createdItemCount={menuScrapingJob.createdDishCount}
      />
    </Col>
  </>
)

MenuScrapingJobStats.propTypes = {
  menuScrapingJob: PropTypes.object.isRequired,
}

export default MenuScrapingJobStats
