import * as Yup from 'yup'

import {
  EXCLUDED,
  EXCLUDED_LOCATION,
  INCLUDED,
  INCLUDED_LOCATION,
  MASTER,
  MISC,
  SLAVE,
} from '../../../../brands/constants'

const brandGatewayTaskSchema = Yup.object().shape({
  brand: Yup.object().shape({
    name: Yup.string()
      .required('Brand name is required')
      .nullable(),
    website: Yup.string()
      .when(['status', 'rank', 'isHasNoWebsiteConfirmed'], {
        is: (status, rank, isHasNoWebsiteConfirmed) =>
          !isHasNoWebsiteConfirmed && rank === MASTER && status === INCLUDED,
        then: Yup.string()
          .url('Website is not valid')
          .required('Brand website is required')
          .nullable(),
      })
      .nullable(),
    rank: Yup.string()
      .required('Brand rank is required')
      .nullable(),
    status: Yup.string().when('rank', {
      is: rank => rank === MASTER,
      then: Yup.string().required('Brand inclusion is required'),
    }),
    masterBrand: Yup.object().when('rank', {
      is: rank => rank === SLAVE,
      then: Yup.object().shape({
        id: Yup.string().required('Master brand is required'),
        name: Yup.string().required('Master brand is required'),
      }),
    }),
    exclusionReason: Yup.string()
      .when('status', {
        is: status => status === EXCLUDED,
        then: Yup.string()
          .required('Excluded reason is required')
          .nullable(),
      })
      .nullable(),
    exclusionReasonComment: Yup.string()
      .when('exclusionReason', {
        is: exclusionReason => exclusionReason === MISC,
        then: Yup.string()
          .required('Comment is required')
          .nullable(),
      })
      .nullable(),
  }),
  locations: Yup.array().of(
    Yup.object().shape({
      website: Yup.string()
        .url('Website is not valid')
        .nullable(),
      newBrand: Yup.object().shape({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
      }),
      status: Yup.string().oneOf(
        [INCLUDED_LOCATION, EXCLUDED_LOCATION],
        'Please choose a valid option',
      ),
      exclusionReason: Yup.string()
        .when('status', {
          is: status => status === EXCLUDED_LOCATION,
          then: Yup.string()
            .required('Excluded reason is required')
            .nullable(),
        })
        .nullable(),
      exclusionReasonComment: Yup.string()
        .when('exclusionReason', {
          is: exclusionReason => exclusionReason === MISC,
          then: Yup.string()
            .required('Comment is required')
            .nullable(),
        })
        .nullable(),
    }),
  ),
})

export default brandGatewayTaskSchema
