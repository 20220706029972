import React from 'react'
import PropTypes from 'prop-types'

import { MapClusterMarker as MapClusterMarkerContainer } from '../../styled'

const MapClusterMarker = ({ pinsCount, totalPinsCount, ...props }) => (
  <MapClusterMarkerContainer
    color="blue"
    count={pinsCount}
    totalCount={totalPinsCount}
    {...props}
  >
    {pinsCount}
  </MapClusterMarkerContainer>
)

MapClusterMarker.propTypes = {
  pinsCount: PropTypes.number.isRequired,
  totalPinsCount: PropTypes.number.isRequired,
}
export default MapClusterMarker
