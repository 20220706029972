import styled, { css } from 'styled-components'

import { sizeMapper } from '../../../core/utils/styles'

const LockedStatus = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.green};

  ${props =>
    props.isLocked &&
    !props.isLoggedInUser &&
    css`
      background-color: ${props.theme.colors.red};
    `};

  ${props =>
    props.isLocked &&
    props.isLoggedInUser &&
    css`
      background-color: ${props.theme.colors.yellow};
    `};
`

export default LockedStatus
