import { push } from '../../common/utils'

import { RESOLVED } from '../constants'

import {
  getBrandDefinitionBreadcrumbItemByTaskId,
  getBrandGatewayBreadcrumbItemByTaskId,
  getDishReviewBreadcrumbItemByTaskId,
  getMenuDefinitionBreadcrumbItemByTaskId,
  getMenuScrapingBreadcrumbItemByTaskId,
} from './get-breadcrumb-item-paths'

const getBreadcrumbItemsForMenuScrapingTaskPage = ({ relatedTasks }) => {
  const {
    brandGatewayTask,
    brandDefinitionTask,
    menuDefinitionTask,
    menuScrapingTask,
    dishReviewTask,
  } = relatedTasks

  const items = []

  push(items, getBrandGatewayBreadcrumbItemByTaskId(brandGatewayTask.id))
  push(items, getBrandDefinitionBreadcrumbItemByTaskId(brandDefinitionTask.id))
  push(items, getMenuDefinitionBreadcrumbItemByTaskId(menuDefinitionTask.id))
  push(items, getMenuScrapingBreadcrumbItemByTaskId(menuScrapingTask.id))

  if (menuScrapingTask.status !== RESOLVED || !dishReviewTask?.id) return items

  push(items, getDishReviewBreadcrumbItemByTaskId(dishReviewTask.id))

  return items
}

export default getBreadcrumbItemsForMenuScrapingTaskPage
