import { gql } from 'apollo-boost'

const SAVE_DISH_REVIEW_TASK = gql`
  mutation saveDishReviewTask(
    $id: ID!
    $menuId: ID!
    $menuTitlesData: [SaveDishReviewTaskMenuTitleInput!]!
  ) {
    saveDishReviewTask(
      id: $id
      menuId: $menuId
      menuTitlesData: $menuTitlesData
    ) {
      id
    }
  }
`

export default SAVE_DISH_REVIEW_TASK
