import { gql } from 'apollo-boost'

const UNMARK_MENU_SCRAPING_TASK_AS_VERIFIED = gql`
  mutation unmarkMenuScrapingTaskAsVerified($id: ID!) {
    unmarkMenuScrapingTaskAsVerified(id: $id) {
      id
    }
  }
`

export default UNMARK_MENU_SCRAPING_TASK_AS_VERIFIED
