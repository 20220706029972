import { gql } from 'apollo-boost'

const UNASSIGN_MENU_SCRAPING_TASK = gql`
  mutation unassignMenuScrapingTask($id: ID!) {
    unassignMenuScrapingTask(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNASSIGN_MENU_SCRAPING_TASK
