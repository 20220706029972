import { gql } from 'apollo-boost'

const BRAND_DEFINITION_TASK = gql`
  query brandDefinitionTask($id: ID!) {
    brandDefinitionTask(id: $id) {
      id
      status
      isAwaiting
      awaitingReason
      awaitingReasonComment
      qualityCheckedDate
      modifiedDate
      reopenedDate
      reopenReason
      brand {
        id
        name
        website
        isHasNoWebsiteConfirmed
        useBrandNameAsLocationName
        status
        businessStatus
        modifiedDate
        locations {
          id
          name
          website
          status
          exclusionReason
          businessStatus
          fsaLocationId
          googleLocationId
          googleMatchRank
          isHasNoGoogleMatchConfirmed
          phone
          coordinates {
            latitude
            longitude
          }
          fsaLocation {
            name
            formattedAddress
            addressLine1
            addressLine2
            addressLine3
            addressLine4
            postCode
            coordinates {
              latitude
              longitude
            }
            geocodeNorthEastCoordinates {
              latitude
              longitude
            }
            geocodeSouthWestCoordinates {
              latitude
              longitude
            }
          }
          googleLocation {
            name
            streetNumber
            street
            city
            formattedAddress
            postCode
            phone
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
      assignedTo {
        id
        name
      }
      qualityCheckedBy {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      qualityCheckedDate
      modifiedDate

      miscTaskVerifiedBy {
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment

      miscTaskQCVerifiedBy {
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
      }
    }
  }
`

export default BRAND_DEFINITION_TASK
