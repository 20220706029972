import { gql } from 'apollo-boost'

const SAVE_COMMON_WEBSITE_DOMAINS = gql`
  mutation saveCommonWebsiteDomains(
    $newBulkData: [URLDomain!]!
    $updatedBulkData: [CommonWebsiteDomainInput!]!
    $removedIds: [ID!]!
  ) {
    saveCommonWebsiteDomains(
      newBulkData: $newBulkData
      updatedBulkData: $updatedBulkData
      removedIds: $removedIds
    ) {
      newCommonWebsiteDomains {
        id
        websiteDomain
      }
      updatedCommonWebsiteDomains {
        id
        websiteDomain
      }
    }
  }
`

export default SAVE_COMMON_WEBSITE_DOMAINS
