import styled from 'styled-components'

import { colorMapper } from '../../../core/utils/styles'

export const MapClusterMarker = styled.div`
  width: 20px;
  height: 20px;
  /* width: ${props => 10 + props.count / props.totalCount}px;
  height: ${props => 10 + props.count / props.totalCount}px; */
  color: #fff;
  border-radius: 50%;
  font-size: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => colorMapper(props)};
`
