import { isNull, map, pick, sortBy } from 'lodash-es'

import { APP_NAME, EMPTY_VALUE } from '../../../../common/constants'

import {
  BUSINESS_STATUSES,
  LOCATION_GOOGLE_MATCH_RANKS_SORT_VALUES,
  MANUAL_GOOGLE_MATCH,
  NO_GOOGLE_MATCH,
} from '../../../../brands/constants'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'

const sortLocationByGoogleMatchComparator = location =>
  LOCATION_GOOGLE_MATCH_RANKS_SORT_VALUES[location.googleMatchRank].sortValue

const getBrandValues = values => {
  const brand = pick(
    values,
    'id',
    'name',
    'website',
    'isHasNoWebsiteConfirmed',
    'useBrandNameAsLocationName',
    'modifiedDate',
  )

  return {
    ...brand,
    businessStatus:
      BUSINESS_STATUSES[values.businessStatus]?.name || EMPTY_VALUE,
  }
}

const getLocationInitialValues = (values, index) => {
  const location = pick(
    values,
    'id',
    'name',
    'status',
    'businessStatus',
    'phone',
    'exclusionReason',
    'website',
    'fsaLocationId',
    'googleMatchRank',
    'fsaLocation',
    'googleLocationId',
    'googleLocation',
    'isHasNoGoogleMatchConfirmed',
  )

  return {
    ...location,
    duplicateOfId: location.duplicateOfId || '',
    position: index + 1,
  }
}

const setGoogleMatchRankForLocation = location => {
  if (location.googleMatchRank)
    return {
      ...location,
      googleMatchRank: location.googleMatchRank,
    }

  return {
    ...location,
    googleMatchRank: location.googleLocationId
      ? MANUAL_GOOGLE_MATCH
      : NO_GOOGLE_MATCH,
  }
}

const getTaskValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'qualityCheckedBy',
    'qualityCheckedDate',
    'modifiedDate',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isDone: false,
  }
}

const brandDefinitionTaskState = values => {
  const task = getTaskValues(values)
  const brand = getBrandValues(values.brand)

  const locationsWithGoogleRankMatch = map(
    values.brand.locations,
    setGoogleMatchRankForLocation,
  )

  const locations = map(
    sortBy(locationsWithGoogleRankMatch, sortLocationByGoogleMatchComparator),
    (location, index) => getLocationInitialValues(location, index),
  )

  const taskState = {
    task,
    brand,
    locations,
  }

  return taskState
}

export default brandDefinitionTaskState
