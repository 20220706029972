import * as Yup from 'yup'

const brandSchema = Yup.object().shape({
  name: Yup.string().required(`Brand's name is required`),
  website: Yup.string()
    .url('Website is not valid')
    .required(`Brand's website is required`)
    .nullable(),
})

export default brandSchema
