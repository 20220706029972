import { useContext } from 'react'
import { castArray, intersection, isArray, isEmpty } from 'lodash-es'

import { AuthContext } from '../../account/contexts'

const useUserHasPermissions = permissions => {
  const permissionsArray = isArray(permissions)
    ? permissions
    : castArray(permissions)

  const { user: loggedInUser } = useContext(AuthContext)
  return !isEmpty(intersection(loggedInUser.permissions, permissionsArray))
}

export default useUserHasPermissions
