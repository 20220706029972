import { gql } from 'apollo-boost'

const UNASSIGN_BRAND_DEFINITION_TASK = gql`
  mutation unassignBrandDefinitionTask($id: ID!) {
    unassignBrandDefinitionTask(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNASSIGN_BRAND_DEFINITION_TASK
