import { isEmpty, isNil, map } from 'lodash-es'

import getDataUrlFromBlob from './get-data-url-from-blob'

const convertFileListToDataUrls = async fileList => {
  if (isEmpty(fileList)) {
    return []
  }

  return Promise.all(
    map(fileList, async file => {
      if (isNil(file.originFileObj)) {
        return file.url
      }

      const imagePath = await getDataUrlFromBlob(file.originFileObj)
      return imagePath
    }),
  )
}

export default convertFileListToDataUrls
