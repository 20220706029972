import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import { Input, Select } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { NEED_ASSISTANCE, TASK_AWAITING_REASONS } from '../../constants'

import { markTaskAsAwaitingFormInitialValues } from './initial-values'
import { markTaskAsAwaitingSchema } from './schemas'

const MarkTaskAsAwaitingForm = ({ onSubmit, closeModal }) => {
  const formMethods = useForm({
    defaultValues: markTaskAsAwaitingFormInitialValues(),
    resolver: yupResolver(markTaskAsAwaitingSchema),
  })

  const { handleSubmit, watch } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Select
              name="awaitingReason"
              label="Awaiting reason"
              options={values(TASK_AWAITING_REASONS)}
            />
          </Col>

          <Col span={12}>
            {watch('awaitingReason') === NEED_ASSISTANCE && (
              <Input
                name="awaitingReasonComment"
                label="Awaiting reason comment"
              />
            )}
          </Col>
        </Row>

        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="no small">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

MarkTaskAsAwaitingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default MarkTaskAsAwaitingForm
