import { useUserHasPermissions } from '../../common/hooks'

import { RESOLVED } from '../constants'

const useCanUserReopenTask = (taskStatus, permissions) => {
  const isTaskResolved = taskStatus === RESOLVED
  const hasPermission = useUserHasPermissions(permissions)
  return isTaskResolved && hasPermission
}

export default useCanUserReopenTask
