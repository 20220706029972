import { get, isNil } from 'lodash-es'

const additionalMenuUrlsFormInitialValues = menu =>
  isNil(get(menu, 'additionalUrls'))
    ? {
        additionalUrls: [],
      }
    : {
        additionalUrls: menu.additionalUrls,
      }

export default additionalMenuUrlsFormInitialValues
