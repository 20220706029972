import { gql } from 'apollo-boost'

const UNMARK_BRAND_AS_MASTER = gql`
  mutation unmarkBrandAsMaster($id: ID!) {
    unmarkBrandAsMaster(id: $id) {
      id
    }
  }
`

export default UNMARK_BRAND_AS_MASTER
