import React from 'react'
import { Switch } from 'react-router'

import { MENU_SCRAPING_TEMPLATE_PERMISSIONS } from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import MenuScrapingTemplatesMainPage from './pages/menu-scraping-templates-main-page'
import {
  CreateMenuScrapingTemplatePage,
  MenuScrapingClusterPage,
  UpdateMenuScrapingTemplatePage,
} from './pages'

const MenuScrapingTemplatesRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN}
      exact
      path="/menu-scraping-templates/templates/create"
      component={CreateMenuScrapingTemplatePage}
    />
    <PermissionRoute
      permissions={MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN}
      exact
      path="/menu-scraping-templates/templates/:id"
      component={UpdateMenuScrapingTemplatePage}
    />
    <PermissionRoute
      permissions={MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN}
      exact
      path="/menu-scraping-templates/clusters/:id"
      component={MenuScrapingClusterPage}
    />
    <PermissionRoute
      permissions={MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN}
      path="/menu-scraping-templates"
      component={MenuScrapingTemplatesMainPage}
    />
  </Switch>
)

export default MenuScrapingTemplatesRouter
