import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { FormText } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { BrandsDropdown } from '../pages/sections'

import { cloneLocationFormInitialValues } from './initial-values'
import { cloneLocationSchema } from './schemas'

const CloneLocationForm = ({ location, onSubmit, closeModal, isLoading }) => {
  const defaultValues = useMemo(
    () => cloneLocationFormInitialValues(location),
    [location],
  )

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(cloneLocationSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row type="flex" justify="space-between">
          <Col span={4}>
            <FormText name="location.id" label="Location ID" />
          </Col>
          <Col span={16}>
            <FormText name="location.name" label="Location Name" />
          </Col>
          <Col span={24}>
            <BrandsDropdown
              name="brand"
              label="To Brand"
              includeOnlyMasterBrands={false}
            />
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Button loading={isLoading} onClick={closeModal} margin="small small">
            Cancel
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            margin="small no no"
          >
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

CloneLocationForm.propTypes = {
  location: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default CloneLocationForm
