import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import { DEFAULT_PAGE_SIZE, LOCKABLE_ENTITIES } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useLockedEntities } from '../../../common/hooks'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'

import {
  SIMILAR_BRANDS,
  SIMILAR_MASTER_BRANDS_BY_LOCATION_WEBSITE,
  SIMILAR_MASTER_BRANDS_BY_NAME,
} from '../../../brands/graphql/queries'
import {
  RESOLVE_BRAND_GATEWAY_TASK,
  SAVE_BRAND_GATEWAY_TASK,
} from '../../graphql/mutations'
import { BRAND_GATEWAY_TASK } from '../../graphql/queries'
import { transformToBrandGatewayTaskInput } from '../../graphql/transformers'
import { BrandGatewayTaskForm } from '../forms'

const onSubmit = async (values, initialValues, saveTask, resolveTask) => {
  const variables = transformToBrandGatewayTaskInput(values, initialValues)

  return values.task.isDone
    ? resolveTask({ variables })
    : saveTask({ variables })
}

const BrandGatewayTaskPage = () => {
  useDocumentTitle('Tasks - Brand Gateway')
  const { taskId } = useParams()
  const history = useHistory()

  const {
    lockedEntities,
    getUserLockingEntity,
    lockEntities,
  } = useLockedEntities()

  const {
    data: { brandGatewayTask } = {},
    loading: isLoadingTask,
    refetch: refetchBrandGatewayTask,
    networkStatus: taskQueryNetworkStatus,
  } = useQuery(BRAND_GATEWAY_TASK, {
    variables: { id: taskId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const userLockingBrand = useMemo(() => {
    return getUserLockingEntity(
      LOCKABLE_ENTITIES.BRAND,
      Number(brandGatewayTask?.brand?.id),
    )
  }, [brandGatewayTask, getUserLockingEntity])

  useEffect(() => {
    if (!brandGatewayTask || !lockedEntities || userLockingBrand.user) return
    lockEntities(LOCKABLE_ENTITIES.BRAND, [brandGatewayTask.brand.id])
  }, [brandGatewayTask, userLockingBrand, lockedEntities, lockEntities])

  const {
    data: { similarBrands } = {},
    loading: isLoadingSimilarBrands,
    refetch: refetchSimilarBrands,
  } = useQuery(SIMILAR_BRANDS, {
    skip: !brandGatewayTask?.brand?.id,
    variables: {
      id: brandGatewayTask?.brand?.id,
      name: brandGatewayTask?.brand?.name,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: { similarMasterBrandsByName = {} } = {},
    loading: isLoadingSimilarMasterBrandsByName,
    refetch: refetchSimilarMasterBrandsByName,
  } = useQuery(SIMILAR_MASTER_BRANDS_BY_NAME, {
    skip: !brandGatewayTask?.brand?.id,
    variables: {
      id: brandGatewayTask?.brand?.id,
      name: brandGatewayTask?.brand?.name,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: { similarMasterBrandsByLocationWebsite = {} } = {},
    loading: isLoadingSimilarMasterBrandsByLocationWebsite,
    refetch: refetchSimilarMasterBrandsByLocationWebsite,
  } = useQuery(SIMILAR_MASTER_BRANDS_BY_LOCATION_WEBSITE, {
    skip: !brandGatewayTask?.brand?.id,
    variables: {
      id: brandGatewayTask?.brand?.id,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [resolveTask, { loading: isResolvingTask }] = useMutation(
    RESOLVE_BRAND_GATEWAY_TASK,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Brand gateway task resolved.',
          description: 'Task has been sucesfully resolved.',
        })

        history.push(getCachedFilterForPage('/tasks/brand-gateway'))
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Resolve brand gateway task failed.',
          description: message,
        })
      },
    },
  )

  const [saveTask, { loading: isSavingTask }] = useMutation(
    SAVE_BRAND_GATEWAY_TASK,
    {
      onCompleted() {
        refetchBrandGatewayTask()
        refetchSimilarBrands()
        showSuccessNotification({
          message: 'Brand gateway task saved.',
          description: 'Task has been successfully saved.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Save brand gateway task failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    values => onSubmit(values, brandGatewayTask, saveTask, resolveTask),
    [brandGatewayTask, saveTask, resolveTask],
  )

  return isLoadingTask ? (
    <Spinner />
  ) : (
    <BrandGatewayTaskForm
      task={brandGatewayTask}
      isRefetchingTask={taskQueryNetworkStatus === NetworkStatus.refetch}
      isLoadingSimilarBrands={isLoadingSimilarBrands}
      similarBrands={similarBrands}
      refetchSimilarBrands={refetchSimilarBrands}
      isLoadingSimilarMasterBrandsByName={isLoadingSimilarMasterBrandsByName}
      similarMasterBrandsByName={similarMasterBrandsByName}
      refetchSimilarMasterBrandsByName={refetchSimilarMasterBrandsByName}
      isLoadingSimilarMasterBrandsByLocationWebsite={
        isLoadingSimilarMasterBrandsByLocationWebsite
      }
      similarMasterBrandsByLocationWebsite={
        similarMasterBrandsByLocationWebsite
      }
      refetchSimilarMasterBrandsByLocationWebsite={
        refetchSimilarMasterBrandsByLocationWebsite
      }
      isSavingTask={isSavingTask}
      isResolvingTask={isResolvingTask}
      lockedEntities={lockedEntities}
      userLockingBrand={userLockingBrand}
      onSubmit={handleSubmit}
    />
  )
}

export default BrandGatewayTaskPage
