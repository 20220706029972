import { gql } from 'apollo-boost'

const CREATE_BRAND = gql`
  mutation createBrand($data: CreateBrandInput!) {
    createBrand(data: $data) {
      id
      name
      status
    }
  }
`

export default CREATE_BRAND
