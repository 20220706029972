import { gql } from 'apollo-boost'

const MARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED = gql`
  mutation markBrandDefinitionTaskAsQualityChecked($id: ID!) {
    markBrandDefinitionTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default MARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED
