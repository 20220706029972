import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Checkbox, DateRangePicker, Select } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { TASK_TYPES } from '../../../tasks/constants'
import { UsersDropdown } from '../../../users/components/pages/sections'

import { usersReportSchema } from './schemas'

const taskTypeOptions = map(TASK_TYPES, ({ name, value }) => ({
  name,
  value,
}))

const UsersReportForm = ({ isGeneratingReport, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      userIds: [],
      dateRange: '',
      taskTypes: [],
    },
    resolver: yupResolver(usersReportSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom">
            <Col span={5}>
              <UsersDropdown
                name="userIds"
                mode="multiple"
                allowClear
                disableInctiveUsers={false}
              />
            </Col>
            <Col span={2} offset={1}>
              <Checkbox
                name="includeSystemEvents"
                label="Include system events"
              />
            </Col>
            <Col span={5} offset={1}>
              <DateRangePicker name="dateRange" label="Date range" />
            </Col>
            <Col span={5} offset={1}>
              <Select
                name="taskTypes"
                label="Task types"
                options={taskTypeOptions}
                mode="multiple"
                allowClear
              />
            </Col>
            <Col span={2} offset={1}>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isGeneratingReport}
              >
                Generate users report
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

UsersReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isGeneratingReport: PropTypes.bool.isRequired,
}

export default UsersReportForm
