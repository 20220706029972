import { mapKeys } from 'lodash-es'

export const SCRAPING_ERROR = 'SCRAPING_ERROR'
export const UNPROCESSABLE_SCRAPING_RESPONSE = 'UNPROCESSABLE_SCRAPING_RESPONSE'

export const MENU_REJECTION_REASONS = mapKeys(
  [
    { name: 'Scraping error', value: SCRAPING_ERROR },
    {
      name: 'Unprocessable scraping response',
      value: UNPROCESSABLE_SCRAPING_RESPONSE,
    },
  ],
  'value',
)
