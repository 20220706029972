import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Select, Spin } from 'antd'
import { intersectionWith, isEmpty, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { GET_ROLES } from '../../../../account/graphql/queries'
import { USERS } from '../../../graphql/queries'

const UnconnectedUsersDropdown = ({
  onChange,
  value,
  label,
  width,
  withRoles,
  ...props
}) => {
  const { data: { roles } = {} } = useQuery(GET_ROLES, { skip: !withRoles })

  const roleIds = map(
    intersectionWith(
      roles,
      withRoles,
      (role, withRole) => role.name === withRole,
    ),
    'id',
  )

  const { data: { users } = {}, loading: isLoadingUsers } = useQuery(USERS, {
    variables: {
      filters: {
        roleIds: isEmpty(roleIds) ? undefined : roleIds,
      },
    },
  })

  const usersOptions = map(users, ({ name, id }) => ({
    name,
    value: String(id),
  }))

  return (
    <Form.Item label={label}>
      {isLoadingUsers ? (
        <Spin />
      ) : (
        <Select
          style={{ width }}
          loading={isLoadingUsers}
          onChange={onChange}
          value={value && String(value)}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          {...props}
        >
          {map(usersOptions, user => (
            <Select.Option key={user.value} value={user.value}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  )
}

UnconnectedUsersDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.string,
  withRoles: PropTypes.array,
}

UnconnectedUsersDropdown.defaultProps = {
  value: undefined,
  label: '',
  width: '200px',
  withRoles: undefined,
}

export default UnconnectedUsersDropdown
