import { filter, includes, map, reduce } from 'lodash-es'

import { MENU_ITEM_STATUSES } from '../constants'

import getAncestorIdsForTreeNodes from './get-ancestor-ids-for-tree-nodes'

const getAllMenuTitles = (scrapedMenuTitles, changedMenuTitles) => {
  const deletedMenuTitles = filter(
    changedMenuTitles,
    menuTitle => menuTitle.status === MENU_ITEM_STATUSES.DELETED,
  )

  return [...scrapedMenuTitles, ...deletedMenuTitles]
}

const getAffectedMenuTitles = (
  scrapedMenuTitles,
  changedMenuTitles,
  changedDishes,
) => {
  const menuTitlesWithContentChanges = filter(
    changedMenuTitles,
    menuTitle => menuTitle.hasContentChanges,
  )

  const changedMenuTitleIds = map(menuTitlesWithContentChanges, 'id')

  const menuTitleIdsForChangedDishes = map(changedDishes, 'menuTitleId')
  const uniqueMenuTitleIdsForChangedDishes = [
    ...new Set(menuTitleIdsForChangedDishes),
  ]

  const allMenuTitles = getAllMenuTitles(scrapedMenuTitles, changedMenuTitles)

  const menuTitleIdsForChangedItems = getAncestorIdsForTreeNodes(
    [...changedMenuTitleIds, ...uniqueMenuTitleIdsForChangedDishes],
    allMenuTitles,
  )

  return filter(allMenuTitles, menuTitle =>
    includes(menuTitleIdsForChangedItems, menuTitle.id),
  )
}

const prepareMenuTitles = (menuTitles, status) =>
  map(menuTitles, menuTitle => ({ ...JSON.parse(menuTitle), status }))

const prepareUpdatedMenuTitles = menuTitles => {
  const parsedMenuTitles = map(menuTitles, menuTitle => JSON.parse(menuTitle))

  const withSignificantContentChanges = filter(
    parsedMenuTitles,
    menuTitle => menuTitle.hasSignificantContentChanges,
  )

  const withoutSignificantContentChanges = filter(
    parsedMenuTitles,
    menuTitle =>
      menuTitle.hasContentChanges && !menuTitle.hasSignificantContentChanges,
  )

  const withoutContentChanges = filter(
    parsedMenuTitles,
    menuTitle => !menuTitle.hasContentChanges,
  )

  return [
    ...map(withSignificantContentChanges, menuTitle => ({
      ...menuTitle,
      status: MENU_ITEM_STATUSES.UPDATED_SIGNIFICANTLY,
    })),
    ...map(withoutSignificantContentChanges, menuTitle => ({
      ...menuTitle,
      status: MENU_ITEM_STATUSES.UPDATED,
    })),
    ...withoutContentChanges,
  ]
}

const getMenuScrapingVersionMenuTitlesChanges = (
  menuScrapingJob,
  changedDishes,
) => {
  const {
    menuScrapingResponse: {
      data: {
        menuTree: { menuTitles: scrapedMenuTitles },
      },
    },
  } = menuScrapingJob

  const {
    deletedMenuTitles,
    restoredMenuTitles,
    updatedMenuTitles,
    createdMenuTitles,
  } = menuScrapingJob

  const changedMenuTitles = [
    ...prepareMenuTitles(deletedMenuTitles, MENU_ITEM_STATUSES.DELETED),
    ...prepareMenuTitles(restoredMenuTitles, MENU_ITEM_STATUSES.RESTORED),
    ...prepareUpdatedMenuTitles(updatedMenuTitles),
    ...prepareMenuTitles(createdMenuTitles, MENU_ITEM_STATUSES.CREATED),
  ]

  const affectedMenuTitles = getAffectedMenuTitles(
    scrapedMenuTitles,
    changedMenuTitles,
    changedDishes,
  )

  const changedMenuTitlesIdStatusMap = reduce(
    changedMenuTitles,
    (hashStatusMap, menuTitle) => ({
      ...hashStatusMap,
      [menuTitle.id]: menuTitle.status,
    }),
    {},
  )

  return map(affectedMenuTitles, menuTitle => ({
    ...menuTitle,
    status: changedMenuTitlesIdStatusMap[menuTitle.id],
  }))
}

export default getMenuScrapingVersionMenuTitlesChanges
