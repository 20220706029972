import { useContext } from 'react'
import { castArray, intersection, isArray, isEmpty, map } from 'lodash-es'

import { AuthContext } from '../../account/contexts'

const useUserHasRoles = roles => {
  const rolesArray = isArray(roles) ? roles : castArray(roles)

  const { user: loggedInUser } = useContext(AuthContext)
  const userRoles = map(loggedInUser.roles, 'name')
  return !isEmpty(intersection(userRoles, rolesArray))
}

export default useUserHasRoles
