import { gql } from 'apollo-boost'

const UNMARK_BRAND_GATEWAY_TASK_AS_AWAITING = gql`
  mutation unmarkBrandGatewayTaskAsAwaiting($id: ID!) {
    unmarkBrandGatewayTaskAsAwaiting(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNMARK_BRAND_GATEWAY_TASK_AS_AWAITING
