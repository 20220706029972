import React from 'react'
import { Redirect, Route, withRouter } from 'react-router'
import PropTypes from 'prop-types'

import { useUserHasPermissions } from '../../../common/hooks'

const PermissionRoute = ({ permissions, component: Component, ...rest }) => {
  const hasPermission = useUserHasPermissions(permissions)

  const renderComponent = props =>
    hasPermission ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/users' }} />
    )

  return <Route {...rest} render={renderComponent} />
}

PermissionRoute.propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  component: PropTypes.elementType.isRequired,
}

export default withRouter(PermissionRoute)
