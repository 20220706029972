import { isEmpty } from 'lodash-es'

function validateMenuIdsOrMenuStatusesAndScrapedBeforeDate(value) {
  const { menuIds, menuStatuses, scrapedBeforeDate } = value
  if (isEmpty(menuIds) && (isEmpty(menuStatuses) || !scrapedBeforeDate)) {
    return this.createError({
      path: 'menuStatuses',
      message: 'Provide either menuIds or menuStatuses and scrapedBeforeDate',
    })
  }
  return true
}

export default validateMenuIdsOrMenuStatusesAndScrapedBeforeDate
