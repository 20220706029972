import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Form } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '../../../../core/components'

import { OpenLink } from '../../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { UPDATE_CLUSTER_MENU } from '../../../graphql/mutations'
import { menuUrlSchema } from '../../forms/schemas'

const MenuUrlForm = ({ id, url, disabled, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      url,
    },
    resolver: yupResolver(menuUrlSchema),
  })
  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods

  const [updateMenuUrl, { loading }] = useMutation(UPDATE_CLUSTER_MENU, {
    onCompleted() {
      onSubmit()
      showSuccessNotification({
        message: `Successfully updated URL for menu ${id}.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: `Failed to update URL for menu ${id}.`,
        description: message,
      })
    },
  })

  const handleOnSubmit = data => {
    if (!isDirty) return
    updateMenuUrl({
      variables: {
        id,
        data: {
          url: data.url,
        },
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        onSubmit={handleSubmit(handleOnSubmit)}
        onBlur={handleSubmit(handleOnSubmit)}
      >
        <Input
          name="url"
          disabled={disabled || loading}
          addonAfter={<OpenLink onClick={() => window.open(url)} />}
        />
      </Form>
    </FormProvider>
  )
}

MenuUrlForm.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  disabled: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default MenuUrlForm
