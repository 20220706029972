import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Icon, Row, Table } from 'antd'
import fileDownload from 'js-file-download'

import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  LondonDateTime,
  MenuIsAwaitingScrapingFilter,
  MenuScrapedByFilter,
  MenuStatusFilter,
} from '../../../common/components'
import { DEFAULT_PAGE } from '../../../common/constants'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'
import { tableOnRowClick } from '../../../common/utils'

import { MENUS } from '../../graphql/queries'
import { isOnlyHTMLFormat } from '../../helpers'

import {
  MenuAnnotationsFilter,
  MenuFormatFilter,
  MenuIsExampleFilter,
  MenuStructureFilter,
} from './sections'

const AllMenusPage = () => {
  useDocumentTitle('Menus - All')
  const history = useHistory()
  const { pathname, search } = useLocation()

  const {
    brandSearchTerm,
    menuSearchTerm,
    formats,
    isExample,
    structureSearchTerm,
    fullAnnotationsSearchTerm,
    isAwaitingScraping,
    scrapedById,
    menuStatuses,
    page = DEFAULT_PAGE,
  } = getQueryParams(search)

  const { data: { menus = {} } = {}, loading: isLoadingMenus } = useQuery(
    MENUS,
    {
      variables: {
        filters: {
          brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
          menuSearchTerm: menuSearchTerm && String(menuSearchTerm),
          formats,
          isExample,
          structureSearchTerm,
          fullAnnotationsSearchTerm,
          isAwaitingScraping,
          scrapedById,
          status: menuStatuses,
        },
        page,
      },
      fetchPolicy: 'no-cache',
    },
  )

  const tableColumns = [
    {
      title: 'Menu ID',
      dataIndex: 'id',
      width: '100px',
    },
    {
      title: 'Brand',
      width: '200px',
      dataIndex: 'brand.name',
    },
    {
      title: 'Website',
      width: '250px',
      dataIndex: 'url',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      title: 'Structure',
      width: '120px',
      dataIndex: 'structure',
    },
    {
      title: 'Annotations',
      width: '200px',
      dataIndex: 'fullAnnotations',
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '120px',
    },
    {
      title: 'Dishes',
      width: '80px',
      dataIndex: 'dishCount',
    },
    {
      title: 'JSON file',
      dataIndex: 'scrapingInstructions',
      width: '100px',
      render: (scrapingInstructions, menu) => {
        return (
          <Button
            disabled={!scrapingInstructions}
            onClick={() => {
              fileDownload(scrapingInstructions, `${menu.id}.json`)
            }}
          >
            <Icon type="download" />
          </Button>
        )
      },
    },
    {
      title: 'Last update',
      width: '160px',
      dataIndex: 'modifiedDate',
      render: date => <LondonDateTime date={date} />,
    },
  ]

  const redirectToMenu = useCallback(
    menu => history.push(getCachedFilterForPage(`/menus/${menu.id}`)),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event => tableOnRowClick(event, row, redirectToMenu),
    }),
    [redirectToMenu],
  )

  return (
    <>
      <Panel margin="small no">
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <MenuFormatFilter mode="multiple" width="100%" />
          </Col>
          <Col span={6}>
            <MenuStatusFilter mode="multiple" width="100%" />
          </Col>
          <Col span={4}>
            <MenuIsExampleFilter />
          </Col>
        </Row>

        {isOnlyHTMLFormat(formats) && (
          <Row gutter={16}>
            <Col span={6}>
              <MenuStructureFilter />
            </Col>
            <Col span={6}>
              <MenuAnnotationsFilter />
            </Col>
            <Col span={4}>
              <MenuIsAwaitingScrapingFilter queryParamName="isAwaitingScraping" />
            </Col>
            <Col span={4}>
              <MenuScrapedByFilter
                queryParamName="scrapedById"
                label="Scraped by"
              />
            </Col>
          </Row>
        )}
      </Panel>
      <Table
        loading={isLoadingMenus}
        rowKey="id"
        columns={tableColumns}
        dataSource={menus.menus}
        pagination={{
          current: page,
          total: menus.total,
          onChange: page => history.push(createUrl(pathname, search, { page })),
        }}
        onRow={onRow}
      />
    </>
  )
}

export default AllMenusPage
