import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import ScrapingAnalysis from './scraping-analysis'
import ScrapingDebugJsonPanel from './scraping-debug-json-panel'

const ScrapingDebugInformationDish = ({ dish }) => (
  <Collapse
    defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
    style={{
      overflow: 'auto',
      height: '85vh',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Dish
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Name: </strong>
            {dish.name}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Price: </strong>
            {dish.price}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Description: </strong>
            {dish.description}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Ingredients: </strong>
            {dish.ingredients}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Start: </strong>
            {dish.timestampBegin}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping End: </strong>
            {dish.timestampEnd}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Duration: </strong>
            {dish.duration}
          </Typography.Text>
        </List.Item>
      </List>
    </Collapse.Panel>
    <Collapse.Panel header="Analysis" key={1}>
      <ScrapingAnalysis analysis={dish.analysis} />
    </Collapse.Panel>
    <ScrapingDebugJsonPanel
      header="Messages"
      panelId={2}
      items={dish.messages}
    />
    <ScrapingDebugJsonPanel header="Diets" panelId={3} items={dish.diets} />
    <ScrapingDebugJsonPanel header="Addons" panelId={4} items={dish.addons} />
    <ScrapingDebugJsonPanel
      header="Misc Info"
      panelId={5}
      items={dish.miscInfo}
    />
    <ScrapingDebugJsonPanel
      header="Allergens"
      panelId={6}
      items={dish.allergens}
    />
    <ScrapingDebugJsonPanel
      header="Nutritions"
      panelId={7}
      items={dish.nutritions}
    />
    <ScrapingDebugJsonPanel header="Flags" panelId={8} items={dish.flags} />
    <ScrapingDebugJsonPanel
      header="Exceptions"
      panelId={9}
      items={dish.exceptions}
    />
  </Collapse>
)

ScrapingDebugInformationDish.propTypes = {
  dish: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationDish
