import { mapKeys } from 'lodash-es'

export const BRAND_GATEWAY = 'BRAND_GATEWAY'
export const BRAND_DEFINITION = 'BRAND_DEFINITION'
export const MENU_DEFINITION = 'MENU_DEFINITION'
export const MENU_SCRAPING = 'MENU_SCRAPING'
export const DISH_REVIEW = 'DISH_REVIEW'

export const TASK_TYPES = mapKeys(
  [
    {
      name: 'Brand gateway',
      value: BRAND_GATEWAY,
      key: 'brand-gateway',
    },
    {
      name: 'Brand definition',
      value: BRAND_DEFINITION,
      key: 'brand-definition',
    },
    {
      name: 'Menu definition',
      value: MENU_DEFINITION,
      key: 'menu-definition',
    },
    {
      name: 'Menu scraping',
      value: MENU_SCRAPING,
      key: 'menu-scraping',
    },
    {
      name: 'Dish review',
      value: DISH_REVIEW,
      key: 'dish-review',
    },
  ],
  'value',
)
