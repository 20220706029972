import * as Yup from 'yup'

const menuScrapingTemplateSchema = Yup.object().shape({
  name: Yup.string().required(`Template name is required`),
  scrapingInstructions: Yup.string().required(
    'Scraping instructions are required',
  ),
})

export default menuScrapingTemplateSchema
