import { mapKeys } from 'lodash-es'

export const RESTAURANT_CAFE_CANTEEN = 'Restaurant/Cafe/Canteen'
export const PUB_BAR_NIGHTCLUB = 'Pub/Bar/Nightclub'
export const DISTRIBUTOR_TRANSPORTER = 'Distributor/Transporter'
export const IMPORTER_EXPORTER = 'Importer/Exporter'
export const RETAILER_OTHER = 'Retailer/Other'
export const FARMER_GROWER = 'Farmer/Grower'
export const MANUFACTURER_PACKER = 'Manufacturer/Packer'
export const TAKEAWAY_SANDWICH_SHOP = 'Takeaway/SandwichShop'
export const SCHOOL_COLLEGE_UNIVERSITY = 'School/College/University'
export const MOBILE_CATERERER = 'MobileCaterer'
export const RETAILER_SUPERMARKET_HYPERMARKET =
  'Retailer/Supermarket/Hypermarket'
export const OTHER_CATERING_PREMISES = 'OtherCateringPremises'
export const HOTEL_BED_AND_BREAKFAST_GUEST_HOUSE =
  'Hotel/BedAndBreakfast/GuestHouse'
export const HOSPITAL_CHILDCARE_CARING_PREMISE =
  'Hospital/Childcare/CaringPremise'

export const FSA_BUSINESS_TYPES = mapKeys(
  [
    { name: 'Restaurant / Cafe / Canteen', value: RESTAURANT_CAFE_CANTEEN },
    {
      name: 'Pub / Bar / Nightclub',
      value: PUB_BAR_NIGHTCLUB,
    },
    {
      name: 'Hospitals / Childcare / Caring Premises',
      value: HOSPITAL_CHILDCARE_CARING_PREMISE,
    },
    {
      name: 'Distributors/Transporters',
      value: DISTRIBUTOR_TRANSPORTER,
    },
    {
      name: 'Importers / Exporters',
      value: IMPORTER_EXPORTER,
    },
    {
      name: 'Retailers / Other',
      value: RETAILER_OTHER,
    },
    {
      name: 'Farmers / Growers',
      value: FARMER_GROWER,
    },
    {
      name: 'Manufacturers / Packers',
      value: MANUFACTURER_PACKER,
    },
    {
      name: 'Takeaway / Sandwich shop',
      value: TAKEAWAY_SANDWICH_SHOP,
    },
    {
      name: 'School / College / University',
      value: SCHOOL_COLLEGE_UNIVERSITY,
    },
    {
      name: 'Mobile caterer',
      value: MOBILE_CATERERER,
    },
    {
      name: 'Retailers / Supermarkets / Hypermarkets',
      value: RETAILER_SUPERMARKET_HYPERMARKET,
    },
    {
      name: 'Other Catering Premises',
      value: OTHER_CATERING_PREMISES,
    },
    {
      name: 'Hotel / Bed & Breakfast / Guest House',
      value: HOTEL_BED_AND_BREAKFAST_GUEST_HOUSE,
    },
  ],
  'value',
)
