import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Select as AntSelect } from 'antd'
import { get, includes, map, toLower } from 'lodash-es'
import PropTypes from 'prop-types'

const ALL_OPTION = { name: 'All', value: 'ALL' }

const Select = ({
  name,
  label,
  options,
  onSelectChange,
  mode,
  isHidden,
  maxTagCount,
  ...rest
}) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
      className={isHidden ? 'is-hidden' : ''}
    >
      <Controller
        control={control}
        name={name}
        render={({ onChange, ...props }) => (
          <AntSelect
            {...props}
            onChange={value => {
              if (includes(value, ALL_OPTION.value)) {
                const allValues = map(options, 'value')
                onChange(allValues)
                if (onSelectChange) onSelectChange(allValues)
              } else {
                onChange(value)
                if (onSelectChange) onSelectChange(value)
              }
            }}
            mode={mode}
            maxTagCount={maxTagCount}
            maxTagPlaceholder={omittedValues =>
              `and ${omittedValues.length} more selected`
            }
            filterOption={(input, option) =>
              toLower(option.props.children).indexOf(toLower(input)) >= 0
            }
            dropdownStyle={{ minWidth: 'fit-content' }}
            {...rest}
          >
            {mode === 'multiple' && (
              <AntSelect.Option key={ALL_OPTION.value} value={ALL_OPTION.value}>
                {ALL_OPTION.name}
              </AntSelect.Option>
            )}
            {map(options, ({ name, value, disabled = false }) => (
              <AntSelect.Option key={value} value={value} disabled={disabled}>
                {name}
              </AntSelect.Option>
            ))}
          </AntSelect>
        )}
      />
    </Form.Item>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  mode: PropTypes.string,
  onSelectChange: PropTypes.func,
  maxTagCount: PropTypes.number,
}

Select.defaultProps = {
  isHidden: false,
  label: '',
  mode: 'default',
  onSelectChange: undefined,
  maxTagCount: 2,
}

export default Select
