import { isEmpty } from 'lodash-es'

import { splitCsvToNumberArray } from '../utils'

const transformToStartMenuScrapingSessionForClusterInput = (
  menuScrapingClusterId,
  values,
) => {
  const data = {
    useMinimumTimeSlotCount: values.useMinimumTimeSlotCount,
    sessionTimeSlotCount: values.sessionTimeSlotCount,
    ignoreWorkingHours: values.ignoreWorkingHours,
  }

  if (!isEmpty(values.menuIds)) {
    data.menuIds = splitCsvToNumberArray(values.menuIds)
  } else {
    data.menuStatuses = values.menuStatuses
    data.scrapingStatuses = values.scrapingStatuses
  }

  return {
    id: menuScrapingClusterId,
    data,
  }
}

export default transformToStartMenuScrapingSessionForClusterInput
