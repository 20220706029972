import { gql } from 'apollo-boost'

const PLATFORM = gql`
  query platform($id: ID!) {
    platform(id: $id) {
      id
      name
      urlIdentificationRegex
      urlNormalizationRegex
    }
  }
`

export default PLATFORM
