import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { Button } from '../../../core/components/styled'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateScrapingClustersReport } from '../../rest'

const ScrapingClustersReportPage = () => {
  useDocumentTitle('Reports - Scraping clusters')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(async () => {
    try {
      setIsGeneratingReport(true)
      const reportResponse = await generateScrapingClustersReport()

      fileDownload(reportResponse.data, 'ScrapingClustersReport.csv')
      setIsGeneratingReport(false)
    } catch ({ response }) {
      showErrorNotification({
        message: 'Failed to generate report.',
        description: response && response.statusText,
      })
    }
  }, [setIsGeneratingReport])

  return (
    <Button
      type="primary"
      onClick={generateReport}
      loading={isGeneratingReport}
    >
      Generate scraping clusters report
    </Button>
  )
}

export default ScrapingClustersReportPage
