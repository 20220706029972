import { gql } from 'apollo-boost'

const UNMARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED = gql`
  mutation unmarkMenuDefinitionTaskAsQualityChecked($id: ID!) {
    unmarkMenuDefinitionTaskAsQualityChecked(id: $id) {
      id
    }
  }
`

export default UNMARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED
