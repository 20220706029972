import { gql } from 'apollo-boost'

const ASSIGN_MENU_SCRAPING_TASK = gql`
  mutation assignMenuScrapingTask($id: ID!, $toUserId: ID!) {
    assignMenuScrapingTask(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_MENU_SCRAPING_TASK
