import { filter, forEach, isEmpty, sumBy } from 'lodash-es'

const aggregateChildDishCountForMenuTitle = (menuTitle, allMenuTitles) => {
  let sum = 0

  const children = filter(
    allMenuTitles,
    ({ parentId }) => parentId === menuTitle.id,
  )

  if (isEmpty(children)) return 0

  const nonDeletedChildren = filter(children, ({ isDeleted }) => !isDeleted)

  sum += sumBy(nonDeletedChildren, 'dishCount')

  forEach(nonDeletedChildren, childMenuTitle => {
    sum += aggregateChildDishCountForMenuTitle(childMenuTitle, allMenuTitles)
  })

  return sum
}

export default aggregateChildDishCountForMenuTitle
