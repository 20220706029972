import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Col, Modal, Row } from 'antd'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import { Checkbox, NumberInput, Select } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { ADMIN } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../common/hooks'
import { formatDate } from '../../../common/services/formatter'

import { MENU_STATUSES, NEW } from '../../../menus/constants'
import { SCRAPING_STATUSES } from '../../constants'
import { START_MENU_SCRAPING_SESSION_FOR_CLUSTER } from '../../graphql/mutations'
import { transformToStartMenuScrapingSessionForClusterInput } from '../../graphql/transformers'

import { startMenuScrapingSessionForClusterSchema } from './schemas'

const StartMenuScrapingSessionForClusterForm = ({
  menuScrapingCluster,
  onStartMenuScrapingSessionForCluster,
}) => {
  const [isStartingScrapingSession, setIsStartingScrapingSession] = useState(
    false,
  )
  const [lastUsedVariables, setLastUsedVariables] = useState(null)

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const showConfirmationForNoMenusAvailable = () => {
    Modal.info({
      title: 'Information',
      content: (
        <div>
          <p>
            There are no menus available for the current session configuration.
          </p>
          <p>Please review your configuration and try again.</p>
        </div>
      ),
      width: 600,
      onOk() {},
    })
  }

  const showConfirmationForMinTimeSlotCount = (
    data,
    startSessionAfterConfirmation,
  ) => {
    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <p style={{ marginBottom: '4px' }}>
            The minimum number of time slots is {data.minTimeSlotCount}.
          </p>
          <p style={{ marginBottom: '20px' }}>
            The session is estimated to complete around{' '}
            {formatDate(data.estimatedCompletionTime)}.
          </p>
          <p>Are you sure you want to start the session?</p>
        </div>
      ),
      width: 600,
      onOk: () => startSessionAfterConfirmation(),
    })
  }

  const showConfirmationForNotAllMenusAvailable = (
    data,
    startSessionAfterConfirmation,
  ) => {
    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <p style={{ marginBottom: '20px' }}>
            {data.unavailableMenuCount} out of {data.totalMenuCount} menus are
            not available during the session duration.
          </p>
          <p>Do you want to start the session only for the available menus?</p>
        </div>
      ),
      width: 600,
      onOk: () => startSessionAfterConfirmation(),
    })
  }

  const [startMenuScrapingSessionForCluster] = useMutation(
    START_MENU_SCRAPING_SESSION_FOR_CLUSTER,
    {
      onCompleted() {
        setIsStartingScrapingSession(false)
        onStartMenuScrapingSessionForCluster()
        showSuccessNotification({
          message: 'Menu scraping session started.',
          description: 'Menu scraping session has been successfully started.',
        })
      },

      onError(error) {
        setIsStartingScrapingSession(false)

        const startSessionAfterConfirmation = () => {
          const variables = {
            ...lastUsedVariables,
            data: {
              ...lastUsedVariables.data,
              isStartSessionConfirmed: true,
            },
          }
          startMenuScrapingSessionForCluster({ variables })
        }

        const { type, data } =
          error?.graphQLErrors?.[0]?.extensions?.exception || {}

        if (type === 'SCRAPING_SESSION_NO_MENUS_AVAILABLE') {
          showConfirmationForNoMenusAvailable()
        } else if (
          type === 'SCRAPING_SESSION_MIN_TIME_SLOT_COUNT_NOT_CONFIRMED'
        ) {
          showConfirmationForMinTimeSlotCount(
            data,
            startSessionAfterConfirmation,
          )
        } else if (type === 'SCRAPING_SESSION_NOT_ALL_MENUS_ARE_AVAILABLE') {
          showConfirmationForNotAllMenusAvailable(
            data,
            startSessionAfterConfirmation,
          )
        } else {
          showErrorNotification({
            message: 'Menu scraping session start failed.',
            description: error.message,
          })
        }
      },
    },
  )

  const handleStartMenuScrapingSession = useCallback(
    values => {
      setIsStartingScrapingSession(true)

      const variables = transformToStartMenuScrapingSessionForClusterInput(
        menuScrapingCluster.id,
        values,
      )

      setLastUsedVariables(variables)
      return startMenuScrapingSessionForCluster({ variables })
    },
    [menuScrapingCluster, startMenuScrapingSessionForCluster],
  )

  const formMethods = useForm({
    defaultValues: {
      sessionTimeSlotCount: 2,
      ignoreWorkingHours:
        menuScrapingCluster.areWorkingHoursIrrelevant || false,
      menuStatuses: [NEW],
    },
    resolver: yupResolver(startMenuScrapingSessionForClusterSchema),
    mode: 'onChange',
  })

  const { handleSubmit, setValue, watch, trigger } = formMethods

  const menuStatuses = watch('menuStatuses')
  const scrapingStatuses = watch('scrapingStatuses')
  const sessionTimeSlotCount = watch('sessionTimeSlotCount')
  const useMinimumTimeSlotCount = watch('useMinimumTimeSlotCount')

  // We need this for validateMenuIdsOrMenuStatusesOrScrapingStatuses validation to work properly
  useEffect(() => {
    trigger()
  }, [menuStatuses, scrapingStatuses, trigger])

  useEffect(() => {
    setValue(
      'ignoreWorkingHours',
      menuScrapingCluster.areWorkingHoursIrrelevant || false,
    )
  }, [menuScrapingCluster.areWorkingHoursIrrelevant, setValue])

  useEffect(() => {
    if (useMinimumTimeSlotCount) {
      setValue('sessionTimeSlotCount', undefined)
    } else {
      setValue('sessionTimeSlotCount', 2)
    }
    trigger()
  }, [setValue, useMinimumTimeSlotCount, trigger])

  const menuStatusOptions = useMemo(() => values(MENU_STATUSES), [])
  const scrapingStatusOptions = useMemo(() => values(SCRAPING_STATUSES), [])

  const handleStartScrapingSession = useCallback(
    event => {
      event.preventDefault()

      const actualSubmit = () => {
        handleSubmit(handleStartMenuScrapingSession)()
      }

      if (sessionTimeSlotCount === 0) {
        Modal.confirm({
          title: 'Are you sure?',
          content:
            'Are you sure you want to send all the menus for scraping at once (without time slots)?',
          onOk: actualSubmit,
        })
      } else {
        actualSubmit()
      }
    },
    [handleSubmit, handleStartMenuScrapingSession, sessionTimeSlotCount],
  )

  return (
    <FormProvider {...formMethods}>
      <Panel margin="no no small no">
        <form onSubmit={handleStartScrapingSession}>
          <Row type="flex" align="bottom" justify="space-between">
            <Col span={2}>
              <Checkbox
                label="Min time slot count"
                name="useMinimumTimeSlotCount"
                infoMessage="This will include maximum 50 menus per time slot"
              />
            </Col>
            <Col span={2}>
              <NumberInput
                label="Time slot count"
                placeholder="Time slot count"
                name="sessionTimeSlotCount"
                min={0}
                max={672}
                disabled={useMinimumTimeSlotCount}
                infoMessage="The slot duration is 15 min"
              />
            </Col>
            <Col span={1}>
              <Checkbox label="Ignore WH" name="ignoreWorkingHours" />
            </Col>
            <Col span={6}>
              <Select
                name="menuStatuses"
                label="Menu statuses"
                placeholder="Select menu statuses"
                options={menuStatusOptions}
                mode="multiple"
                allowClear
                maxTagCount={1}
              />
            </Col>
            <Col span={5}>
              <Select
                name="scrapingStatuses"
                label="Scraping statuses"
                placeholder="Select scraping statuses"
                options={scrapingStatusOptions}
                mode="multiple"
                allowClear
                maxTagCount={1}
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  !menuScrapingCluster.menuScrapingTemplate
                }
                htmlType="submit"
                loading={isStartingScrapingSession}
              >
                Start session
              </Button>
            </Col>
          </Row>
        </form>
      </Panel>
    </FormProvider>
  )
}

StartMenuScrapingSessionForClusterForm.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onStartMenuScrapingSessionForCluster: PropTypes.func,
}

StartMenuScrapingSessionForClusterForm.defaultProps = {
  onStartMenuScrapingSessionForCluster: () => {},
}

export default StartMenuScrapingSessionForClusterForm
