import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Col,
  Drawer,
  Icon,
  List,
  Popover,
  Select,
  Typography,
} from 'antd'
import fileDownload from 'js-file-download'
import { filter, map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  LondonDateTime,
  ScrapingInstructionsDiffModal,
  Spinner,
} from '../../../../common/components'

import { MENU_SCRAPING_TEMPLATE_INSTRUCTIONS_VERSIONS } from '../../../graphql/queries'

const VersionDetails = ({ createdDate, createdBy, number }) => (
  <Typography.Text>
    At <LondonDateTime date={createdDate} /> ${createdBy.name} uploaded version
    ${number}
  </Typography.Text>
)

VersionDetails.propTypes = {
  createdDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
}

const MenuScrapingTemplateInstructionsVersionsDrawer = ({
  menuScrapingTemplate,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const [
    scrapingInstructionsDiffModalSettings,
    setScrapingInstructionsDiffModalSettings,
  ] = useState({ isOpen: false })

  const {
    data: { menuScrapingTemplateInstructionsVersions } = {},
    loading: isLoadingScrapingInstructions,
    refetch: refetchScrapingInstructions,
  } = useQuery(MENU_SCRAPING_TEMPLATE_INSTRUCTIONS_VERSIONS, {
    variables: { menuScrapingTemplateId: menuScrapingTemplate.id },
    fetchPolicy: 'no-cache',
  })

  const onButtonClick = useCallback(() => {
    refetchScrapingInstructions()
    setIsDrawerVisible(true)
  }, [refetchScrapingInstructions])

  const onClose = useCallback(() => setIsDrawerVisible(false), [])

  return (
    <>
      <Button size="small" style={{ margin: '5px' }} onClick={onButtonClick}>
        Version history
        <Icon type="eye" />
      </Button>
      {isLoadingScrapingInstructions ? (
        <Spinner />
      ) : (
        <Drawer
          title="Scraping instructions versions"
          placement="right"
          onClose={onClose}
          visible={isDrawerVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={menuScrapingTemplateInstructionsVersions}
            renderItem={menuScrapingTemplateInstructionsVersion => (
              <List.Item>
                <Col span={14}>
                  <VersionDetails
                    {...menuScrapingTemplateInstructionsVersion}
                  />
                </Col>
                <Col span={2} offset={1}>
                  {menuScrapingTemplateInstructionsVersion.comment && (
                    <Popover
                      title="Comment"
                      placement="left"
                      content={menuScrapingTemplateInstructionsVersion.comment}
                    >
                      <Button size="medium">
                        <Icon type="eye" />
                      </Button>
                    </Popover>
                  )}
                </Col>
                <Col span={2}>
                  <Button
                    size="medium"
                    onClick={() => {
                      const version =
                        menuScrapingTemplate.scrapingInstructionsVersionNumber !==
                        menuScrapingTemplateInstructionsVersion.number
                          ? `v${menuScrapingTemplateInstructionsVersion.number}`
                          : 'current'

                      fileDownload(
                        menuScrapingTemplateInstructionsVersion?.scrapingInstructions,
                        `${menuScrapingTemplateInstructionsVersion?.menuScrapingTemplateId}-menu-scraping-template-${version}.json`,
                      )
                    }}
                  >
                    <Icon type="download" />
                  </Button>
                </Col>
                <Col span={5}>
                  <Select
                    style={{ width: '100%' }}
                    value="Compare with..."
                    onChange={number =>
                      setScrapingInstructionsDiffModalSettings({
                        isOpen: true,
                        currentScrapingInstructions: {
                          instructions: menuScrapingTemplateInstructionsVersions.find(
                            v => v.number === number,
                          ).scrapingInstructions,
                          version: number,
                        },
                        otherScrapingInstructions: {
                          instructions:
                            menuScrapingTemplateInstructionsVersion.scrapingInstructions,
                          version:
                            menuScrapingTemplateInstructionsVersion.number,
                        },
                      })
                    }
                  >
                    {map(
                      filter(
                        menuScrapingTemplateInstructionsVersions,
                        v =>
                          v.number !==
                          menuScrapingTemplateInstructionsVersion.number,
                      ),
                      ({ number }) => (
                        <Select.Option key={number} value={number}>
                          V.{number}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Col>
              </List.Item>
            )}
          />
        </Drawer>
      )}

      <ScrapingInstructionsDiffModal
        isModalOpen={scrapingInstructionsDiffModalSettings.isOpen}
        scrapingInstructions={
          scrapingInstructionsDiffModalSettings.currentScrapingInstructions
        }
        otherScrapingInstructions={
          scrapingInstructionsDiffModalSettings.otherScrapingInstructions
        }
        onCancel={() =>
          setScrapingInstructionsDiffModalSettings({
            isOpen: false,
            otherScrapingInstructions: undefined,
            currentScrapingInstructions: undefined,
          })
        }
      />
    </>
  )
}

MenuScrapingTemplateInstructionsVersionsDrawer.propTypes = {
  menuScrapingTemplate: PropTypes.object.isRequired,
}

export default MenuScrapingTemplateInstructionsVersionsDrawer
