import * as Yup from 'yup'

const userEditorSchema = Yup.object().shape({
  name: Yup.string().required(`User's name is required`),
  roleId: Yup.string().required(`User role is required`),
  email: Yup.string()
    .email('Email is not valid')
    .required(`User's email address is required`),
})

export default userEditorSchema
