import { gql } from 'apollo-boost'

const COMPLETE_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP = gql`
  mutation completeMenuScrapingClusterRerunFlowStep(
    $rerunFlowId: ID!
    $stepType: MenuScrapingClusterRerunFlowStepType!
  ) {
    completeMenuScrapingClusterRerunFlowStep(
      rerunFlowId: $rerunFlowId
      stepType: $stepType
    ) {
      id
    }
  }
`

export default COMPLETE_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP
