import { gql } from 'apollo-boost'

const SET_MENU_SCRAPING_TEMPLATE_COMMENT = gql`
  mutation setMenuScrapingTemplateComment($id: ID!, $comment: String) {
    setMenuScrapingTemplateComment(id: $id, comment: $comment) {
      id
      comment
    }
  }
`

export default SET_MENU_SCRAPING_TEMPLATE_COMMENT
