import { gql } from 'apollo-boost'

const PLATFORMS = gql`
  query platforms {
    platforms {
      id
      name
      urlIdentificationRegex
      urlNormalizationRegex
    }
  }
`

export default PLATFORMS
