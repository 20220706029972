import { useIsLoggedInUser, useUserHasPermissions } from '../../common/hooks'

const useCanUserResolveTask = (assignedTo, permissions, adminPermission) => {
  const isAssignedToUser = useIsLoggedInUser(assignedTo)
  const hasPermission = useUserHasPermissions(permissions)
  const hasAdminPermission = useUserHasPermissions(adminPermission)
  return (isAssignedToUser && hasPermission) || hasAdminPermission
}

export default useCanUserResolveTask
