import { mapKeys } from 'lodash-es'

const MONDAY = 1
const TUESDAY = 2
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5
const SATURDAY = 6
const SUNDAY = 0

export const DAYS_OF_WEEK = mapKeys(
  [
    { name: 'Monday', value: MONDAY },
    { name: 'Tuesday', value: TUESDAY },
    { name: 'Wednesday', value: WEDNESDAY },
    { name: 'Thursday', value: THURSDAY },
    { name: 'Friday', value: FRIDAY },
    { name: 'Saturday', value: SATURDAY },
    { name: 'Sunday', value: SUNDAY },
  ],
  'value',
)
