import React from 'react'
import { Switch } from 'react-router'

import { MENUS_PERMISSIONS } from '../../common/constants'

import { PermissionRoute } from '../../account/components/routes'

import MenusPage from './pages/menus-page'
import { MenuPage } from './pages'

const MenusRouter = () => (
  <Switch>
    <PermissionRoute
      permissions={MENUS_PERMISSIONS.GET}
      exact
      path="/menus/:menuId"
      component={MenuPage}
    />

    <PermissionRoute
      permissions={MENUS_PERMISSIONS.LIST}
      path="/menus"
      component={MenusPage}
    />
  </Switch>
)

export default MenusRouter
