export const LOCK = 'lock'
export const UNLOCK = 'unlock'
export const REQUEST_LOCKS = 'requestLocks'
export const RECEIVE_LOCKS = 'receiveLocks'
export const UNLOCK_ALL = 'unlockAll'

export const SOCKET_EVENTS = {
  LOCK,
  UNLOCK,
  REQUEST_LOCKS,
  RECEIVE_LOCKS,
  UNLOCK_ALL,
}
