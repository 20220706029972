import React from 'react'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { getDateTooltipTitle } from '../helpers'
import { formatDate } from '../services/formatter'

const LondonDateTime = ({ date, dateDetails }) => {
  if (!date) return null

  return (
    <Tooltip placement="topLeft" title={getDateTooltipTitle(date, dateDetails)}>
      {formatDate(date)}
    </Tooltip>
  )
}

LondonDateTime.propTypes = {
  date: PropTypes.string,
  dateDetails: PropTypes.string,
}

LondonDateTime.defaultProps = {
  date: undefined,
  dateDetails: undefined,
}

export default LondonDateTime
