import React from 'react'
import { Popconfirm, Popover } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { MAX_UNMARK_AS_MASTER_LIMIT } from '../../../constants'

const SimilarMasterBrandsUnmarkAsMasterColumn = ({
  locationCount,
  onConfirm,
}) => {
  if (locationCount >= MAX_UNMARK_AS_MASTER_LIMIT) {
    return (
      <Popover
        title="Unmark as master is not allowed."
        content={`This brand has more than ${MAX_UNMARK_AS_MASTER_LIMIT} locations attached to it.`}
        placement="left"
      >
        <Button disabled>Unmark as master</Button>
      </Popover>
    )
  }

  return (
    <Popconfirm
      title="Are you sure you want to unmark this brand as master?"
      onConfirm={onConfirm}
      placement="left"
      okText="Yes"
      cancelText="No"
    >
      <Button>Unmark as master</Button>
    </Popconfirm>
  )
}

SimilarMasterBrandsUnmarkAsMasterColumn.propTypes = {
  locationCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default SimilarMasterBrandsUnmarkAsMasterColumn
