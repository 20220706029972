import { Checkbox as AntCheckbox } from 'antd'
import styled from 'styled-components'

import { sizeMapper } from '../../utils/styles'

const Checkbox = styled(AntCheckbox)`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
`

export default Checkbox
