import { gql } from 'apollo-boost'

const BRANDS = gql`
  query brands(
    $filters: BrandFiltersInput
    $sortingCriterion: BrandSortingCriterion
    $page: Int
    $pageSize: Int
  ) {
    brands(
      filters: $filters
      sortingCriterion: $sortingCriterion
      page: $page
      pageSize: $pageSize
    ) {
      brands {
        id
        name
        website
        status
        businessStatus
        locationCount
        messinessScore
        hasOnlyUnsupportedBusinessTypes
        menuId
        modifiedDate
        brandGatewayTask {
          id
        }
      }
      total
    }
  }
`

export default BRANDS
