import { gql } from 'apollo-boost'

const MENU = gql`
  query menu($id: ID!) {
    menu(id: $id) {
      id
      url
      rescrapingComment
      scrapingInstructions
      scrapingInstructionsVersionNumber
      format
      notScrapeableReason
      notScrapeableComment
      isExample
      isExcludedFromCuration
      isScrapedInDebugMode
      scrapingResponseId
      menuScrapingClusterId
      menuScrapingAvailability {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        isSharedByMultipleLocations
        isAlwaysAvailable
        isDefaultAvailability
      }
      brand {
        id
        name
      }
      locations {
        id
        name
      }
      dishReviewTask {
        id
      }
      dishes {
        id
        menuId
        menuTitleId
        name
        description
        price
        calories
        ingredients
        diets
        addons
        miscInfo
        allergens
        nutritions
        createdDate
        isDeleted
        modifiedDate
        hash
      }
      menuTitles {
        id
        parentId
        title
        description
        calories
        diets
        addons
        miscInfo
        allergens
        nutritions
        isEverywhereAvailable
        dishCount
        isDeleted
        hash
      }
      createdDate
      modifiedDate
      scrapingSummary {
        menuAnnotations {
          fullMenu
        }
        messages
        status
        scrapingReport {
          fileKey
        }
      }
    }
  }
`

export default MENU
