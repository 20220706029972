import React from 'react'
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { Spinner } from '../../../common/components'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { MENU } from '../../../menus/graphql/queries'

const DishReviewTaskByMenuIdPage = () => {
  useDocumentTitle('Tasks - Dish review')
  const { menuId } = useParams()

  const { data, loading: isLoadingMenu } = useQuery(MENU, {
    variables: { id: menuId },
    fetchPolicy: 'no-cache',
  })

  const dishReviewTaskId = data && data.menu.dishReviewTask.id

  return isLoadingMenu ? (
    <Spinner />
  ) : (
    <Redirect to={{ pathname: `/tasks/dish-review/${dishReviewTaskId}` }} />
  )
}

export default DishReviewTaskByMenuIdPage
