import ApolloClient, { Observable } from 'apollo-boost'

import { refreshAccessToken } from '../../account/helpers'
import { getEnvironmentVariable } from '../helpers'

const createRequest = ({ getAccessToken }) => async operation => {
  operation.setContext({
    headers: {
      Accept: 'application/json',
      accessToken: getAccessToken(),
    },
  })
}

const createApolloClient = ({ getAccessToken, resolvers }) => {
  const apolloClient = new ApolloClient({
    uri: `${getEnvironmentVariable('REACT_APP_AUTOEAT_API_URL')}/graphql`,
    request: createRequest({ getAccessToken }),
    resolvers,
    // eslint-disable-next-line consistent-return
    onError: ({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors?.some(err => err.message === 'Unauthenticated.')) {
        return new Observable(observer =>
          refreshAccessToken(apolloClient)
            .then(accessToken => {
              operation.setContext(({ headers }) => ({
                headers: {
                  ...headers,
                  accessToken,
                },
              }))

              forward(operation).subscribe({
                next: res => {
                  observer.next(res)
                  observer.complete()
                },
                error: err => observer.error(err),
              })
            })
            .catch(err => observer.error(err)),
        )
      }
    },
  })
  return apolloClient
}

export default createApolloClient
