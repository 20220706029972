import { MENU_SCRAPING_TASKS_PERMISSIONS } from '../../common/constants'
import { useUserHasPermissions } from '../../common/hooks'
import { areSameValue } from '../../common/utils'

import { RESOLVED } from '../constants'

const useCanUserReopenMenuScrapingTask = (user, task) => {
  const isTaskResolved = task.status === RESOLVED
  const hasPermission = useUserHasPermissions(
    MENU_SCRAPING_TASKS_PERMISSIONS.REOPEN,
  )
  const isTaskOwner = areSameValue(task.assignedTo?.id, user.id)
  return isTaskResolved && (hasPermission || isTaskOwner)
}

export default useCanUserReopenMenuScrapingTask
