import { gql } from 'apollo-boost'

const UNMARK_MENU_SCRAPING_TASK_AS_AWAITING = gql`
  mutation unmarkMenuScrapingTaskAsAwaiting($id: ID!) {
    unmarkMenuScrapingTaskAsAwaiting(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNMARK_MENU_SCRAPING_TASK_AS_AWAITING
