import { isEmpty, isNaN, map } from 'lodash-es'
import * as Yup from 'yup'

import { menuIdsAsCsv } from '../../../../common/schemas'

import { MENU_SCRAPING_CLUSTER_ROLES } from '../../../../menus/constants'

import { validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount } from './validators'

const startTemplateValidationSessionForClusterSchema = Yup.object()
  .shape({
    useMinimumTimeSlotCount: Yup.boolean(),
    sessionTimeSlotCount: Yup.mixed().when('useMinimumTimeSlotCount', {
      is: true,
      then: Yup.number()
        .notRequired()
        .oneOf(
          [undefined],
          'Session time slot count must be empty when Minimum Time Slot Count is selected',
        ),
      otherwise: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .required('Session time slot count is required')
        .integer('Session Time Slot Count must be an integer'),
    }),
    ignoreWorkingHours: Yup.boolean(),
    menuRoles: Yup.array().of(
      Yup.string().oneOf(map(MENU_SCRAPING_CLUSTER_ROLES, s => s.value)),
    ),
    randomlySelectedMenuCount: Yup.number()
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .when('menuIds', {
        is: menuIds => isEmpty(menuIds),
        then: Yup.number()
          .nullable()
          .transform(value => (isNaN(value) ? null : value))
          .required(`Randomly selected menu count is required`),
      }),
    menuIds: menuIdsAsCsv,
  })
  .test(
    'validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount',
    'At least one of menuIds, menuRoles or randomlySelectedMenuCount is required',
    validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount,
  )

export default startTemplateValidationSessionForClusterSchema
