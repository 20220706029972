import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

import { MENU_FORMATS } from '../../../constants'
import { isOnlyHTMLFormat } from '../../../helpers'

const MenuFormatFilter = ({ disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { formats, isAwaitingScraping, scrapedById } = getQueryParams(search)

  const handleChangeMenuFormat = useCallback(
    formats =>
      history.push(
        createUrl(pathname, search, {
          formats,
          scrapedById: isOnlyHTMLFormat(formats) ? scrapedById : '',
          isAwaitingScraping: isOnlyHTMLFormat(formats)
            ? isAwaitingScraping
            : '',
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, scrapedById, isAwaitingScraping],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Formats">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={formats}
        mode="multiple"
        onChange={handleChangeMenuFormat}
        maxTagCount={1}
        maxTagPlaceholder={getMaxTagPlaceholder}
        disabled={disabled}
      >
        {map(MENU_FORMATS, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

MenuFormatFilter.propTypes = {
  disabled: PropTypes.bool,
}

MenuFormatFilter.defaultProps = {
  disabled: false,
}

export default MenuFormatFilter
