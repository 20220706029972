import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { BOOLEAN_TYPES, DEFAULT_PAGE } from '../constants'

const TaskIsQualityCheckedFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { isQualityChecked, qualityCheckedById } = getQueryParams(search)

  const handleChange = useCallback(
    isQualityChecked =>
      history.push(
        createUrl(pathname, search, {
          isQualityChecked,
          qualityCheckedById:
            qualityCheckedById === 'true' ? qualityCheckedById : '',
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, qualityCheckedById],
  )

  return (
    <Form.Item label="Task QC'ed">
      <Select
        style={{ width: '100%' }}
        value={isQualityChecked?.toString()}
        onChange={handleChange}
        allowClear
      >
        {map(BOOLEAN_TYPES, ({ name, value }) => (
          <Select.Option key={value} value={value?.toString()}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default TaskIsQualityCheckedFilter
