import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { Button, Panel } from '../../../core/components/styled'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateMenuAllergensReport } from '../../rest'

const MenuReportPage = () => {
  useDocumentTitle('Reports - Menu')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(async () => {
    try {
      setIsGeneratingReport(true)
      const reportReponse = await generateMenuAllergensReport()
      fileDownload(reportReponse.data, 'Menu_allergens_nutritions.csv')
      setIsGeneratingReport(false)
    } catch (e) {
      showErrorNotification({
        message: 'Failed to generate report.',
        description: e,
      })
    }
  }, [setIsGeneratingReport])

  const handleSubmit = useCallback(() => generateReport(), [generateReport])

  return (
    <Panel withBorderBottom>
      <Button
        margin="no no medium"
        type="primary"
        onClick={handleSubmit}
        loading={isGeneratingReport}
      >
        Generate menu allergens & nutritions report
      </Button>
    </Panel>
  )
}

export default MenuReportPage
