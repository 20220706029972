import { gql } from 'apollo-boost'

const USERS = gql`
  query users($filters: UserFiltersInput) {
    users(filters: $filters) {
      id
      name
      email
      roles {
        id
        name
      }
      isActive
      isDeleted
      loginDate
      modifiedDate
    }
  }
`

export default USERS
