import Cookie from 'js-cookie'
import lscache from 'lscache'
import moment from 'moment'

import setTasksDefaultFilters from '../../common/services/set-tasks-default-filters'

import {
  ACCESS_TOKEN_EXPIRATION_TIME_MIN,
  GOOGLE_ACCESS_TOKEN_EXPIRATION_TIME_MIN,
  REFRESH_TOKEN_EXPIRATION_TIME_MIN,
} from '../constants'

const COOKIE_KEY_ACCESS_TOKEN = 'accessToken'
const COOKIE_KEY_REFRESH_TOKEN = 'refreshToken'

const LS_KEY_USER = 'user'
const LS_KEY_ACCESS_TOKEN = 'accessToken'
const LS_KEY_REFRESH_TOKEN = 'refreshToken'
const LS_KEY_GOOGLE_ACCESS_TOKEN = 'googleAccessToken'

export const createSession = (
  user,
  accessToken,
  refreshToken,
  googleAccessToken,
) => {
  lscache.set(LS_KEY_USER, user, REFRESH_TOKEN_EXPIRATION_TIME_MIN)

  const accessTokenExpires = moment()
    .add(ACCESS_TOKEN_EXPIRATION_TIME_MIN, 'minutes')
    .toDate()
  Cookie.set(COOKIE_KEY_ACCESS_TOKEN, accessToken, {
    expires: accessTokenExpires,
    sameSite: 'strict',
  })
  lscache.set(
    LS_KEY_ACCESS_TOKEN,
    accessToken,
    ACCESS_TOKEN_EXPIRATION_TIME_MIN,
  )

  const refreshTokenExpires = moment()
    .add(REFRESH_TOKEN_EXPIRATION_TIME_MIN, 'minutes')
    .toDate()
  Cookie.set(COOKIE_KEY_REFRESH_TOKEN, refreshToken, {
    expires: refreshTokenExpires,
    sameSite: 'strict',
  })
  lscache.set(
    LS_KEY_REFRESH_TOKEN,
    refreshToken,
    REFRESH_TOKEN_EXPIRATION_TIME_MIN,
  )

  if (googleAccessToken) {
    lscache.set(
      LS_KEY_GOOGLE_ACCESS_TOKEN,
      googleAccessToken,
      GOOGLE_ACCESS_TOKEN_EXPIRATION_TIME_MIN,
    )
  }

  setTasksDefaultFilters()
}

export const getAccessToken = () => lscache.get(LS_KEY_ACCESS_TOKEN)
export const getRefreshToken = () => lscache.get(LS_KEY_REFRESH_TOKEN)

export const getUser = () => lscache.get(LS_KEY_USER)

export const destroySession = () => {
  Cookie.remove(COOKIE_KEY_ACCESS_TOKEN)
  lscache.flush()
}
