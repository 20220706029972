import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Icon, Row, Select, Spin, Typography } from 'antd'
import { debounce, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { NAME_SIMILARITY, SLAVE } from '../../../../brands/constants'
import { BRANDS } from '../../../../brands/graphql/queries'

const SearchBrandSelect = ({
  locationIndex,
  excludeBrandId,
  onChange,
  width,
}) => {
  const { watch } = useFormContext()
  const brand = watch(`locations.${locationIndex}.newBrand`)
  const [brandSearchTerm, setBrandSearchTerm] = useState()

  const { data: { brands = [] } = {}, loading: isLoadingBrands } = useQuery(
    BRANDS,
    {
      variables: {
        filters: {
          notRank: SLAVE,
          brandSearchTerm,
        },
        sortingCriterion: NAME_SIMILARITY,
      },
      skip: !brandSearchTerm,
      fetchPolicy: 'no-cache',
    },
  )

  const renderedOptions = useMemo(
    () =>
      map(brands?.brands, brand => (
        <Select.Option
          key={brand.id}
          value={brand.id}
          label={brand.name}
          disabled={brand.id === excludeBrandId}
        >
          <Row type="flex" justify="space-between">
            <Typography.Text>{`${brand.name} - ${brand.id}`}</Typography.Text>
            <Link
              to={`/tasks/brand-gateway/${brand.brandGatewayTask.id}`}
              target="_blank"
            >
              <Button size="small" type="link">
                <Icon type="link" />
              </Button>
            </Link>
          </Row>
        </Select.Option>
      )),
    [brands, excludeBrandId],
  )

  return (
    <Row>
      <Select
        showSearch
        allowClear
        labelInValue
        value={brand.id ? { key: brand.id, label: brand.name } : undefined}
        style={{ width }}
        showArrow={false}
        filterOption={false}
        onSearch={debounce(value => setBrandSearchTerm(value), 1000)}
        onChange={onChange}
        notFoundContent={isLoadingBrands ? <Spin size="small" /> : null}
        dropdownStyle={{ minWidth: 'fit-content' }}
        optionLabelProp="label"
      >
        {renderedOptions}
      </Select>
    </Row>
  )
}

SearchBrandSelect.propTypes = {
  locationIndex: PropTypes.number.isRequired,
  excludeBrandId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
}

SearchBrandSelect.defaultProps = {
  width: '200px',
}

export default SearchBrandSelect
