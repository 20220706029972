import { gql } from 'apollo-boost'

const UNMARK_MENU_DEFINITION_TASK_AS_AWAITING = gql`
  mutation unmarkMenuDefinitionTaskAsAwaiting($id: ID!) {
    unmarkMenuDefinitionTaskAsAwaiting(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNMARK_MENU_DEFINITION_TASK_AS_AWAITING
