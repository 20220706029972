import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Drawer, Icon, List, notification, Typography } from 'antd'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'

import {
  COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU,
  MENU_SCRAPING_JOB,
} from '../../menus/graphql/queries'
import { formatDate } from '../services/formatter'

import LondonDateTime from './london-date-time'
import Spinner from './spinner'

const ScrapingResponse = ({ completedDate, responseType, scrapingStatus }) => (
  <Typography.Text>
    At <LondonDateTime date={completedDate} /> the scraping engine returned a{' '}
    {responseType} event with
    <Typography.Text strong> {scrapingStatus}</Typography.Text>
    {' status'}
  </Typography.Text>
)

ScrapingResponse.propTypes = {
  completedDate: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
  scrapingStatus: PropTypes.string.isRequired,
}

const ScrapingHistoryDrawer = ({ menuId, buttonName, buttonSize }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [menuScrapingJobId, setMenuScrapingJobId] = useState()

  const {
    data: { completedMenuScrapingJobsForMenu } = {},
    loading: isLoadingCompletedMenuScrapingJobs,
    refetch: refetchCompletedMenuScrapingJobs,
  } = useQuery(COMPLETED_MENU_SCRAPING_JOBS_FOR_MENU, {
    variables: { menuId },
    fetchPolicy: 'no-cache',
  })

  const {
    data: { menuScrapingJob = {} } = {},
    loading: isLoadingMenuScrapingJob,
    refetch: refetchMenuScrapingJob,
  } = useQuery(MENU_SCRAPING_JOB, {
    skip: !menuScrapingJobId,
    variables: { id: menuScrapingJobId },
    onCompleted() {
      if (!menuScrapingJobId) return

      fileDownload(
        JSON.stringify(menuScrapingJob.menuScrapingResponse.data, null, 2),
        `${menuId}-${formatDate(menuScrapingJob.completedDate)}.json`,
      )
    },
    onError({ message }) {
      notification.open({
        message: 'Download scraping response failed.',
        description: message,
        type: 'error',
        duration: 0,
      })
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!menuScrapingJobId) return
    refetchMenuScrapingJob()
  }, [menuScrapingJobId, refetchMenuScrapingJob])

  const onButtonClick = useCallback(() => {
    refetchCompletedMenuScrapingJobs()
    setIsDrawerVisible(true)
  }, [refetchCompletedMenuScrapingJobs])

  const onClose = useCallback(() => setIsDrawerVisible(false), [])

  return (
    <>
      <Button size={buttonSize} onClick={onButtonClick}>
        {buttonName}
        <Icon type="eye" />
      </Button>
      {isLoadingCompletedMenuScrapingJobs ? (
        <Spinner />
      ) : (
        <Drawer
          title="Scraping response history"
          placement="right"
          onClose={onClose}
          visible={isDrawerVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={completedMenuScrapingJobsForMenu}
            renderItem={completedMenuScrapingJob => (
              <List.Item>
                <ScrapingResponse {...completedMenuScrapingJob} />
                <Button
                  size="small"
                  loading={
                    isLoadingMenuScrapingJob &&
                    completedMenuScrapingJob.menuScrapingJobId ===
                      menuScrapingJobId
                  }
                  onClick={() =>
                    setMenuScrapingJobId(
                      completedMenuScrapingJob.menuScrapingJobId,
                    )
                  }
                >
                  Download
                  <Icon type="download" />
                </Button>
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </>
  )
}

ScrapingHistoryDrawer.propTypes = {
  menuId: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
}

ScrapingHistoryDrawer.defaultProps = {
  buttonSize: 'small',
}

export default ScrapingHistoryDrawer
