import React, { useMemo } from 'react'
import { groupBy, keys, map } from 'lodash-es'
import PropTypes from 'prop-types'

import ScrapingAnalysisSection from './scraping-analysis-section'

const ScrapingAnalysis = ({ analysis }) => {
  const analysisGroupedByType = useMemo(() => groupBy(analysis, 'type'), [
    analysis,
  ])

  return (
    <>
      {map(keys(analysisGroupedByType), (type, index) => (
        <ScrapingAnalysisSection
          key={index}
          type={type}
          messages={analysisGroupedByType[type]}
        />
      ))}
    </>
  )
}

ScrapingAnalysis.propTypes = {
  analysis: PropTypes.array,
}

ScrapingAnalysis.defaultProps = {
  analysis: undefined,
}

export default ScrapingAnalysis
