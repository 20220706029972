import { isEmpty } from 'lodash-es'

import {
  SCRAPED_ITEM_PLACEHOLDER_TITLES,
  SCRAPED_ITEM_STATUSES,
} from '../constants'

const getMenuTitleName = menuTitle => {
  if (
    menuTitle.status === SCRAPED_ITEM_STATUSES.ERROR &&
    isEmpty(menuTitle.title)
  ) {
    return SCRAPED_ITEM_PLACEHOLDER_TITLES.CORRUPT_MENU_TITLE
  }
  if (isEmpty(menuTitle.title)) {
    return SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_MENU_TITLE
  }
  return menuTitle.title
}

export default getMenuTitleName
