import { gql } from 'apollo-boost'

const SET_MENU_RESCRAPING_COMMENT = gql`
  mutation setMenuRescrapingComment(
    $id: ID!
    $rescrapingComment: String!
    $assignedToUserId: ID
  ) {
    setMenuRescrapingComment(
      id: $id
      rescrapingComment: $rescrapingComment
      assignedToUserId: $assignedToUserId
    ) {
      id
    }
  }
`

export default SET_MENU_RESCRAPING_COMMENT
