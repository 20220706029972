import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

const HiddenCheckboxes = ({ names }) => {
  const { control } = useFormContext()
  return map(names, (name, index) => (
    <Controller
      key={index}
      control={control}
      name={name}
      defaultValue={false}
      render={props => <input type="hidden" {...props} />}
    />
  ))
}

HiddenCheckboxes.propTypes = {
  names: PropTypes.array.isRequired,
}

export default HiddenCheckboxes
