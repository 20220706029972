import { gql } from 'apollo-boost'

const UNMARK_MENU_AS_SCRAPING_CUSTER_CORE = gql`
  mutation unmarkMenuAsScrapingClusterCore($id: ID!, $menuId: ID!) {
    unmarkMenuAsScrapingClusterCore(id: $id, menuId: $menuId) {
      id
      scrapingClusterRole
    }
  }
`

export default UNMARK_MENU_AS_SCRAPING_CUSTER_CORE
