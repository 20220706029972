import arrayToTree from 'array-to-tree'
import { map } from 'lodash-es'

import { SCRAPED_ITEM_TYPES } from '../constants'

const transformScrapedItemToTreeItem = (scrapedItem, menuId) => {
  switch (scrapedItem.type) {
    case SCRAPED_ITEM_TYPES.DISH:
      return {
        parentId: scrapedItem.menuTitleId,
        title: scrapedItem.name,
        value: scrapedItem.id,
        id: scrapedItem.id,
        status: scrapedItem.status,
      }
    case SCRAPED_ITEM_TYPES.MENU_TITLE:
      return {
        parentId: !scrapedItem.parentId ? menuId : scrapedItem.parentId,
        title: scrapedItem.title,
        value: scrapedItem.id,
        id: scrapedItem.id,
        status: scrapedItem.status,
      }
    default:
      return {
        parentId: null,
        title: 'Menu',
        value: menuId,
        id: menuId,
        status: scrapedItem.status,
      }
  }
}

const transformScrapedItemsToTree = (scrapedItems, menuId) => {
  const treeItems = map(scrapedItems, item =>
    transformScrapedItemToTreeItem(item, menuId),
  )

  return arrayToTree(treeItems, {
    parentProperty: 'parentId',
  })
}

export default transformScrapedItemsToTree
