import { gql } from 'apollo-boost'

const MARK_MENU_DEFINITION_TASK_MISC_AS_QC_VERIFIED = gql`
  mutation markMenuDefinitionTaskMiscAsQCVerified($id: ID!, $comment: String) {
    markMenuDefinitionTaskMiscAsQCVerified(id: $id, comment: $comment) {
      id
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment
    }
  }
`

export default MARK_MENU_DEFINITION_TASK_MISC_AS_QC_VERIFIED
