import React from 'react'
import { Route, Switch } from 'react-router'

import ReportsPage from './pages/reports-page'

const ReportsRouter = () => (
  <Switch>
    <Route path="/reports" component={ReportsPage} />
  </Switch>
)

export default ReportsRouter
