import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { Form } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '../../../../core/components'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'

import { UPDATE_CLUSTER_MENU } from '../../../graphql/mutations'

const MenuNotesForm = ({ id, notes, disabled, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      notes,
    },
  })
  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods

  const [updateMenuUrl, { loading }] = useMutation(UPDATE_CLUSTER_MENU, {
    onCompleted() {
      onSubmit()
      showSuccessNotification({
        message: `Successfully updated notes for menu ${id}.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: `Failed to update notes for menu ${id}.`,
        description: message,
      })
    },
  })

  const handleOnSubmit = data => {
    if (!isDirty) return
    updateMenuUrl({
      variables: {
        id,
        data: {
          notes: data.notes,
        },
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        onSubmit={handleSubmit(handleOnSubmit)}
        onBlur={handleSubmit(handleOnSubmit)}
      >
        <Input name="notes" disabled={disabled || loading} maxLength={2000} />
      </Form>
    </FormProvider>
  )
}

MenuNotesForm.propTypes = {
  id: PropTypes.string.isRequired,
  notes: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

MenuNotesForm.defaultProps = {
  notes: undefined,
}

export default MenuNotesForm
