import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { Icon, Row, Table, Typography } from 'antd'
import { isEmpty, join } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../../../core/components/styled'

import { LondonDateTime } from '../../../../../../common/components'
import { DEFAULT_PAGE } from '../../../../../../common/constants'

import { LEADS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER } from '../../../../../graphql/queries'

const LeadsManuallyRemovedFromClusterMenusTable = ({
  menuScrapingCluster,
  isHidden,
  toggleIsHidden,
}) => {
  const [
    manuallyRemovedFromClusterMenusPage,
    setManuallyRemovedFromClusterMenusPage,
  ] = useState(DEFAULT_PAGE)

  const [
    refetchManuallyRemovedFromClusterMenus,
    {
      data: {
        leadsManuallyRemovedFromScrapingCluster: {
          leads: manuallyRemovedFromClusterMenus = [],
          total: manuallyRemovedFromClusterMenusTotal = 0,
        } = {},
      } = {},
      loading: isLoadingManuallyRemovedFromClusterMenus,
    },
  ] = useLazyQuery(LEADS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER, {
    variables: {
      id: menuScrapingCluster.id,
      page: manuallyRemovedFromClusterMenusPage,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!isHidden) {
      refetchManuallyRemovedFromClusterMenus({
        id: menuScrapingCluster.id,
        page: manuallyRemovedFromClusterMenusPage,
      })
    }
  }, [
    isHidden,
    menuScrapingCluster.id,
    manuallyRemovedFromClusterMenusPage,
    refetchManuallyRemovedFromClusterMenus,
  ])

  const manuallyRemovedFromClusterMenusTableColumns = [
    {
      title: 'ID',
      dataIndex: 'menuId',
      width: '20%',
      render: menuId => <Link to={`/menus/${menuId}`}>{menuId}</Link>,
    },
    {
      title: 'Removed date',
      width: '30%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Processed date',
      width: '30%',
      dataIndex: 'processedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'New lead menu IDs',
      width: '20%',
      dataIndex: 'newMenuIds',
      render: (newMenuIds, row) => {
        if (row.processedDate && isEmpty(newMenuIds)) {
          return '-'
        }
        return join(newMenuIds, ', ')
      },
    },
  ]

  return (
    <Row>
      <div style={{ display: 'flex' }}>
        <Typography.Title level={3}>
          {`Manually removed leads`}
          {!isHidden && ` (${manuallyRemovedFromClusterMenusTotal})`}
        </Typography.Title>
        <Button onClick={toggleIsHidden} style={{ marginLeft: 'auto' }}>
          <Icon type={!isHidden ? 'up' : 'down'} />
        </Button>
      </div>
      {!isHidden && (
        <Table
          rowKey="id"
          loading={isLoadingManuallyRemovedFromClusterMenus}
          columns={manuallyRemovedFromClusterMenusTableColumns}
          dataSource={manuallyRemovedFromClusterMenus}
          pagination={{
            current: manuallyRemovedFromClusterMenusPage,
            pageSize: 10,
            total: manuallyRemovedFromClusterMenusTotal,
            onChange: setManuallyRemovedFromClusterMenusPage,
          }}
        />
      )}
    </Row>
  )
}

LeadsManuallyRemovedFromClusterMenusTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  toggleIsHidden: PropTypes.func,
}

LeadsManuallyRemovedFromClusterMenusTable.defaultProps = {
  isHidden: false,
  toggleIsHidden: () => {},
}

export default LeadsManuallyRemovedFromClusterMenusTable
