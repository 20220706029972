import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTERS = gql`
  query menuScrapingClusters(
    $page: Int
    $pageSize: Int
    $filters: MenuScrapingClustersFiltersInput
    $sortingCriterion: MenuScrapingClusterSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    menuScrapingClusters(
      page: $page
      pageSize: $pageSize
      filters: $filters
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      menuScrapingClusters {
        id
        menuScrapingTemplate {
          id
          name
          status
        }
        menuCount
        approvedMenuCount
        createdDate
        modifiedDate
        hasUpdatedFingerprints
        assignedTo {
          id
          name
        }
        modifiedBy {
          id
          name
        }
        rerunFlow {
          currentStep {
            type
          }
        }
      }
      total
    }
  }
`

export default MENU_SCRAPING_CLUSTERS
