import React from 'react'
import { Badge, Switch, Tooltip } from 'antd'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'

import { THEME } from '../../../../core/constants'

import { useUserHasPermissions } from '../../../../common/hooks'
import { formatDate } from '../../../../common/services/formatter'

import { RESOLVED } from '../../../constants'

const QUALITY_CHECHED = 'Quality checked'
const NOT_QUALITY_CHECKED = 'Not quality checked'

const QualityCheckedSwitch = ({ task, permissions, onChange, loading }) => {
  const isQualityChecked = !isEmpty(task.qualityCheckedBy?.name)

  const hasUserPermission = useUserHasPermissions([
    permissions.MARK_AS_QUALITY_CHECKED,
    permissions.UNMARK_AS_QUALITY_CHECKED,
  ])

  const qualityCheckedInfo = isQualityChecked
    ? `QC passed by ${task.qualityCheckedBy.name} at ${formatDate(
        task.qualityCheckedDate,
        false,
      )}`
    : `QC not passed`

  return hasUserPermission ? (
    <Tooltip placement="bottom" title={qualityCheckedInfo} trigger="hover">
      <Switch
        style={{
          marginTop: '5px',
        }}
        checkedChildren={QUALITY_CHECHED}
        unCheckedChildren={NOT_QUALITY_CHECKED}
        checked={isQualityChecked}
        onChange={onChange}
        loading={loading}
        disabled={task.status !== RESOLVED}
      />
    </Tooltip>
  ) : (
    <Tooltip placement="bottom" title={qualityCheckedInfo} trigger="hover">
      <Badge
        count={isQualityChecked ? QUALITY_CHECHED : NOT_QUALITY_CHECKED}
        style={{
          marginTop: '5px',
          backgroundColor: isQualityChecked
            ? THEME.colors.green
            : THEME.colors.grey,
        }}
      />
    </Tooltip>
  )
}

QualityCheckedSwitch.propTypes = {
  task: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default QualityCheckedSwitch
