import React from 'react'
import { Icon } from 'antd'
import PropTypes from 'prop-types'

const FinishedRerunFlowStepIcon = () => (
  <Icon
    type="check"
    style={{
      fontSize: '16px',
      color: '#1777ff',
    }}
  />
)

const RerunFlowStepIcon = ({ isCurrentStep, isFinishedStep, order }) => {
  let color = '#8a8a8a'
  let backgroundColor = isFinishedStep ? `#e6f4ff` : '#f0f0f0'
  if (isCurrentStep && !isFinishedStep) {
    color = '#ffffff'
    backgroundColor = '#1777ff'
  }

  return (
    <div style={{ backgroundColor: '#fff', padding: '0 12px' }}>
      <div
        style={{
          width: '32px',
          height: '32px',
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          color,
          backgroundColor,
        }}
      >
        {isFinishedStep ? <FinishedRerunFlowStepIcon /> : order + 1}
      </div>
    </div>
  )
}

RerunFlowStepIcon.propTypes = {
  isCurrentStep: PropTypes.bool.isRequired,
  isFinishedStep: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
}

export default RerunFlowStepIcon
