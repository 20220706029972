import { gql } from 'apollo-boost'

const BRAND_GATEWAY_TASK = gql`
  query brandGatewayTask($id: ID!) {
    brandGatewayTask(id: $id) {
      id
      status
      isAwaiting
      awaitingReason
      awaitingReasonComment
      qualityCheckedDate
      modifiedDate
      reopenedDate
      reopenReason
      brand {
        id
        name
        website
        isHasNoWebsiteConfirmed
        messinessScore
        hasOnlyUnsupportedBusinessTypes
        rank
        status
        exclusionReason
        exclusionReasonComment
        masterBrand {
          id
          name
          brandGatewayTask {
            id
          }
        }
        slaveBrands {
          id
          name
          locationCount
          brandGatewayTask {
            id
          }
        }
        notSameBrands {
          id
          name
          locationCount
          brandGatewayTask {
            id
          }
        }
        locations {
          id
          brandId
          name
          website
          status
          exclusionReason
          exclusionReasonComment
          formattedAddress
          googleMatchRank
          requiresReview
          originalBrand {
            id
            name
          }
          duplicateOf {
            id
            name
          }
          fsaLocation {
            name
            businessType
            formattedAddress
            coordinates {
              latitude
              longitude
            }
          }
          googleLocationId
          googleLocation {
            name
          }
        }
        modifiedDate
      }
      assignedTo {
        id
        name
      }
      qualityCheckedBy {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      qualityCheckedDate
      modifiedDate

      miscTaskVerifiedBy {
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment
      miscTaskQCVerifiedBy {
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
      }
    }
  }
`

export default BRAND_GATEWAY_TASK
