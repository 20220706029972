import { find, isArray, isEmpty, isString, map } from 'lodash-es'

const mergeFormValues = (taskValues, formValues) => {
  if (isArray(formValues)) {
    const isArrayOfStrings = !find(
      formValues,
      formValue => !isString(formValue),
    )

    if (isArrayOfStrings) return formValues

    const updatedValuesMappedById = map(taskValues, value => {
      const matchedValue = find(
        formValues,
        formValue => value.id === formValue?.id,
      )

      return matchedValue ? { ...value, ...matchedValue } : value
    })

    return isEmpty(updatedValuesMappedById)
      ? formValues
      : updatedValuesMappedById
  }

  return undefined
}

export default mergeFormValues
