import React, { useCallback, useState } from 'react'
import fileDownload from 'js-file-download'

import { showErrorNotification } from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { generateScrapingAttemptsReport } from '../../rest'
import { ScrapingAttemptsReportForm } from '../forms'

const ScrapingAttemptsReportPage = () => {
  useDocumentTitle('Reports - Scraping Attempts')
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const generateReport = useCallback(
    async filters => {
      const { dateRange, userIds, includeAllAttempts = false } = filters

      try {
        setIsGeneratingReport(true)
        const [startDateMoment, endDateMoment] = dateRange
        const reportReponse = await generateScrapingAttemptsReport({
          startDate: startDateMoment.format('YYYY-MM-DD'),
          endDate: endDateMoment.format('YYYY-MM-DD'),
          userIds,
          includeAllAttempts,
        })

        fileDownload(reportReponse.data, 'ScrapingAttemptsReport.csv')
        setIsGeneratingReport(false)
      } catch ({ response }) {
        showErrorNotification({
          message: 'Failed to generate report.',
          description: response.statusText,
        })
      }
    },
    [setIsGeneratingReport],
  )

  const handleSubmit = useCallback(filters => generateReport(filters), [
    generateReport,
  ])

  return (
    <ScrapingAttemptsReportForm
      onSubmit={handleSubmit}
      isGeneratingReport={isGeneratingReport}
    />
  )
}

export default ScrapingAttemptsReportPage
