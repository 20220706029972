import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Table, Typography } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import {
  DEFAULT_PAGE,
  MENU_DEFINITION_TASKS_PERMISSIONS,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { usePaginationFixer } from '../../../common/hooks'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder, tableOnRowClick } from '../../../common/utils'

import {
  CLOSED_PERMANENTLY,
  CLOSED_TEMPORARILY,
} from '../../../brands/constants'
import { MENU_DEFINITION_TASKS_SORTING_CRITERIA } from '../../../menus/constants'
import {
  ASSIGN_MENU_DEFINITION_TASK,
  UNASSIGN_MENU_DEFINITION_TASK,
} from '../../graphql/mutations'
import { MENU_DEFINITION_TASKS } from '../../graphql/queries'

import TaskTableAssignedColumn from './sections/task-table-assigned-column'

const MenuDefinitionTasksPage = () => {
  useDocumentTitle('Tasks - Menu Definition')
  const history = useHistory()
  const { search, pathname } = useLocation()

  const {
    brandSearchTerm,
    statuses,
    reopenReasons,
    isAssigned,
    assignedToId,
    isQualityChecked,
    qualityCheckedById,
    isAwaiting,
    awaitingReason,
    page = DEFAULT_PAGE,
    pageSize = 10,
    sortingCriterion,
    sortingOrder,
  } = getQueryParams(search)

  const {
    data: { menuDefinitionTasks = {} } = {},
    loading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(MENU_DEFINITION_TASKS, {
    variables: {
      filters: {
        brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
        statuses,
        reopenReasons,
        isAssigned,
        assignedToId,
        isQualityChecked,
        qualityCheckedById,
        isAwaiting,
        awaitingReason,
      },
      page,
      pageSize,
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  usePaginationFixer(isLoadingTasks, menuDefinitionTasks, 'menuDefinitionTasks')

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_MENU_DEFINITION_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Menu definition task assigned.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu definition task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_MENU_DEFINITION_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Menu definition task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu definition task unassign failed.',
          description: message,
        })
      },
    },
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'brand.id',
      width: '100px',
    },
    {
      title: 'Brand name',
      dataIndex: 'brand.name',
      width: '500px',
    },
    {
      title: 'Assigned',
      width: '250px',
      render: task => (
        <TaskTableAssignedColumn
          task={task}
          permissions={MENU_DEFINITION_TASKS_PERMISSIONS}
          assignTask={assignTask}
          unassignTask={unassignTask}
          refetchTasks={refetchTasks}
        />
      ),
    },
    {
      title: 'Brand defined by',
      width: '250px',
      dataIndex: 'brandDefinedBy.name',
    },
    {
      title: 'Included locations',
      dataIndex: 'brand.includedLocationCount',
      width: '120px',
    },
    {
      title: 'Total locations',
      dataIndex: 'brand.locationCount',
      width: '120px',
    },
    {
      title: 'Updated at',
      dataIndex: 'modifiedDate',
      width: '160px',
      render: date => <LondonDateTime date={date} />,
      sorter: true,
    },
  ]

  const redirectToMenuDefinitionTask = useCallback(
    task => history.push(`/tasks/menu-definition/${task.id}`),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event =>
        tableOnRowClick(event, row, redirectToMenuDefinitionTask),
    }),
    [redirectToMenuDefinitionTask],
  )

  const getSortingCriterion = (field, order) =>
    field === 'modifiedDate' && order
      ? MENU_DEFINITION_TASKS_SORTING_CRITERIA.MODIFIED_DATE
      : ''

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          page: pagination.current,
          sortingCriterion,
          sortingOrder,
        }),
      )
    },
    [history, search, pathname],
  )

  return (
    <>
      <Typography.Text>
        {isLoadingTasks
          ? 'Loading records'
          : `${menuDefinitionTasks.total} matching records`}
      </Typography.Text>
      <Table
        loading={isLoadingTasks || isAssigningTask || isUnassigningTask}
        rowKey="id"
        columns={tableColumns}
        dataSource={menuDefinitionTasks.menuDefinitionTasks}
        pagination={{
          current: page,
          total: menuDefinitionTasks.total,
        }}
        rowClassName={record =>
          record.businessStatus === CLOSED_TEMPORARILY ||
          record.businessStatus === CLOSED_PERMANENTLY
            ? 'closed'
            : undefined
        }
        onChange={handleTableChange}
        onRow={onRow}
      />
    </>
  )
}
export default MenuDefinitionTasksPage
