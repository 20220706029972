import React from 'react'
import PropTypes from 'prop-types'

import { ENTITY_TYPES } from '../constants'
import { useIsLoggedInUser } from '../hooks'

import { LockedStatus as LockedStatusContainer } from './styled'

const getTitle = (lockingUser, isLoggedInUser, entityType) => {
  if (!lockingUser.user) return ''

  return isLoggedInUser
    ? `${ENTITY_TYPES[entityType].name} locked by me`
    : `${ENTITY_TYPES[entityType].name} locked by ${lockingUser.user.name}`
}

const LockedStatus = ({ userLockingEntity, entityType, margin }) => {
  const isLoggedInUser = useIsLoggedInUser(userLockingEntity.user)
  const title = getTitle(userLockingEntity, isLoggedInUser, entityType)

  const isLocked = userLockingEntity.user
    ? !userLockingEntity.isCurrentSession
    : false

  return (
    <LockedStatusContainer
      isLocked={isLocked}
      isLoggedInUser={isLoggedInUser}
      title={title}
      margin={margin}
    />
  )
}

LockedStatus.propTypes = {
  margin: PropTypes.string,
  userLockingEntity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
}

LockedStatus.defaultProps = {
  margin: 'no',
}

export default LockedStatus
