import { isEmpty, isNull, map, pick } from 'lodash-es'

import { APP_NAME } from '../../../../common/constants'

import { sortLocationsByMenuStatus } from '../../../../brands/helpers'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'

const getBrandValues = values => {
  const brand = pick(values, 'id', 'name', 'website', 'menu', 'modifiedDate')
  const menu = brand.menu || {}

  return {
    ...brand,
    hasMenu: !isEmpty(brand.menu),
    menu,
  }
}

const getLocationInitialValues = values =>
  pick(
    values,
    'id',
    'name',
    'formattedAddress',
    'menuStatus',
    'website',
    'menu',
  )

const getTaskValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'qualityCheckedBy',
    'qualityCheckedDate',
    'modifiedDate',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isDone: false,
  }
}

const menuDefinitionTaskState = values => {
  const task = getTaskValues(values)
  const brand = getBrandValues(values.brand || {})
  const locations = map(values.brand?.locations, getLocationInitialValues)

  const locationsSortedByMenuStatus = sortLocationsByMenuStatus(locations)

  return {
    task,
    brand,
    locations: locationsSortedByMenuStatus,
  }
}

export default menuDefinitionTaskState
