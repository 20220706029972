import { pick } from 'lodash-es'

const getLocationInitialValues = location => pick(location, 'id', 'name')

const getBrandInitialValues = () => ({
  id: undefined,
  name: undefined,
})

const locationFormInitialValues = values => {
  if (!values) return {}
  const location = getLocationInitialValues(values)
  const brand = getBrandInitialValues()

  const initialValues = {
    location,
    brand,
  }

  return initialValues
}

export default locationFormInitialValues
