import { useContext } from 'react'
import {
  filter,
  first,
  groupBy,
  intersection,
  isEmpty,
  map,
  values,
} from 'lodash-es'

import { AuthContext } from '../../account/contexts'

const useFilterOnPermission = elements => {
  const { user: loggedInUser } = useContext(AuthContext)

  const filteredElements = groupBy(
    filter(
      elements,
      ({ permissions }) =>
        !isEmpty(intersection(loggedInUser.permissions, permissions)),
    ),
    'key',
  )

  return map(values(filteredElements), element => first(element))
}

export default useFilterOnPermission
