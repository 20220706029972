import React, { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Col, Form, Input, Row, Select, Table, Typography } from 'antd'
import {
  differenceBy,
  filter,
  forEach,
  isEmpty,
  map,
  size,
  values,
} from 'lodash-es'
import PropTypes from 'prop-types'

import { HiddenInputs, RadioGroupButtons } from '../../../../core/components'

import {
  BRAND,
  DEFAULT_PAGE_SIZE,
  LOCKABLE_ENTITIES,
  SORTING_ORDERS_MAP,
} from '../../../../common/constants'
import { useLockedEntities } from '../../../../common/hooks'

import { BRAND_RELATIONSHIPS, UNSET } from '../../../../brands/constants'
import { getSimilarBrandGatewayTableSortingData } from '../../../helpers'

import TaskTableBrandLinkColumn from './task-table-brand-link-column'

const BrandGatewaySimilarBrandsByName = ({
  brand,
  isLoading,
  refetchSimilarBrands,
}) => {
  const [name, setName] = useState(brand.name)
  const [sortingOrder, setSortingOrder] = useState()
  const [lockedSimilarBrandIds, setLockedSimilarBrandIds] = useState()
  const [allRelationships, setAllRelationships] = useState()
  const { control, setValue } = useFormContext()

  const { fields: brandsSimilarByName } = useFieldArray({
    control,
    name: 'similarBrands.brandsSimilarByName',
  })

  useEffect(() => setName(brand.name), [brand.name])
  useEffect(() => setAllRelationships(), [brandsSimilarByName])

  const {
    lockedEntities,
    lockEntities,
    unlockEntities,
    getUserLockingEntity,
  } = useLockedEntities()

  useEffect(() => {
    if (isEmpty(brandsSimilarByName) || isEmpty(lockedEntities)) return

    const lockedBrands = filter(
      lockedEntities,
      ({ entityType }) => entityType === BRAND,
    )

    const brandsToLock = differenceBy(
      brandsSimilarByName,
      lockedBrands,
      brand => Number(brand.id) || brand.entityId,
    )

    if (isEmpty(brandsToLock)) return

    const brandsToLockIds = map(brandsToLock, 'id')
    lockEntities(LOCKABLE_ENTITIES.BRAND, brandsToLockIds)
    setLockedSimilarBrandIds(brandsToLockIds)
  }, [brandsSimilarByName, lockedEntities, lockEntities])

  const onSetAllRelationshipsChange = useCallback(
    relationship => {
      setAllRelationships(relationship)

      forEach(brandsSimilarByName, (brand, index) =>
        setValue(
          `similarBrands.brandsSimilarByName.${index}.relationship`,
          relationship,
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        ),
      )
    },
    [brandsSimilarByName, setValue],
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'id',
      width: '110px',
      sorter: true,
      sortOrder: sortingOrder && SORTING_ORDERS_MAP[sortingOrder].antd,
    },
    {
      title: 'Brand name',
      dataIndex: 'brand.name',
      width: '400px',
      render: (brandName, brand) => (
        <TaskTableBrandLinkColumn
          getUserLockingEntity={getUserLockingEntity}
          to={`/tasks/brand-gateway/${brand.brandGatewayTask.id}`}
          brand={brand}
        />
      ),
    },
    {
      title: () => (
        <Row gutter={16}>
          <Col span={12}>
            <span className="ant-table-column-title">Relationship</span>
          </Col>
          <Col span={12}>
            <Select
              value={allRelationships}
              onChange={onSetAllRelationshipsChange}
              style={{ width: '120px' }}
            >
              {map(BRAND_RELATIONSHIPS, ({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      ),
      width: '400px',
      render: (id, similarBrand, index) => (
        <RadioGroupButtons
          name={`similarBrands.brandsSimilarByName.${index}.relationship`}
          options={values(BRAND_RELATIONSHIPS)}
          defaultValue={UNSET}
          onRadioGroupChange={() => setAllRelationships()}
        />
      ),
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '110px',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '400px',
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      width: '0px',
      render: (row, brand, index) => (
        <HiddenInputs
          names={[
            `similarBrands.brandsSimilarByName.${index}.id`,
            `similarBrands.brandsSimilarByName.${index}.name`,
            `similarBrands.brandsSimilarByName.${index}.website`,
            `similarBrands.brandsSimilarByName.${index}.brandGatewayTask`,
            `similarBrands.brandsSimilarByName.${index}.locationCount`,
          ]}
        />
      ),
    },
  ]

  const getSortingData = useCallback(
    sorter => {
      const { order, criterion } = getSimilarBrandGatewayTableSortingData(
        sorter,
        sortingOrder,
      )

      setSortingOrder(order)

      return {
        sortingCriterion: criterion,
        sortingOrder: order,
      }
    },
    [sortingOrder],
  )
  const handleTableChange = useCallback(
    (page, filters, sorter) => {
      const sortingData = getSortingData(sorter)

      refetchSimilarBrands({
        id: brand.id,
        name,
        pageSize: DEFAULT_PAGE_SIZE,
        sortingCriterion: sortingData.sortingCriterion,
        sortingOrder: sortingData.sortingOrder,
      })
    },
    [brand, name, refetchSimilarBrands, getSortingData],
  )

  return (
    <>
      <Typography.Title level={4}>
        Similar brands by name ({size(brandsSimilarByName)})
      </Typography.Title>
      <Form.Item
        style={{ margin: '0px' }}
        validateStatus={isEmpty(name) ? 'error' : 'success'}
        help={isEmpty(name) ? 'Please provide a brand name' : ''}
      >
        <Input.Search
          style={{ width: 'auto' }}
          placeholder="Brand name"
          value={name}
          required
          onChange={({ target: { value } }) => setName(value)}
          onSearch={brandName => {
            if (!brandName) return
            if (!isEmpty(lockedSimilarBrandIds)) {
              unlockEntities(BRAND, lockedSimilarBrandIds)
            }

            setLockedSimilarBrandIds()
            refetchSimilarBrands({
              id: brand.id,
              name: brandName,
              pageSize: DEFAULT_PAGE_SIZE,
              sortingOrder: null,
              sortingCriterion: null,
            })
            setSortingOrder(undefined)
          }}
        />
      </Form.Item>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={brandsSimilarByName}
        pagination={false}
        onChange={handleTableChange}
      />
    </>
  )
}

BrandGatewaySimilarBrandsByName.propTypes = {
  brand: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refetchSimilarBrands: PropTypes.func.isRequired,
}

export default BrandGatewaySimilarBrandsByName
