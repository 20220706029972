import React, { useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory } from 'react-router-dom'
import { Checkbox, Dropdown, Icon, Menu, Popconfirm } from 'antd'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { ADMIN, TEAM_LEADER } from '../../../../common/constants'
import { useUserHasRoles } from '../../../../common/hooks'
import { getCachedFilterForPage } from '../../../../common/services'

import { isMenuScrapeable } from '../../../../menus/helpers'
import {
  useCanUserAssignTask,
  useCanUserReopenTask,
  useCanUserResolveTask,
  useCanUserUnassignTask,
} from '../../../hooks'

import MarkTaskAsAwaitingModal from './mark-task-as-awaiting-modal'

const { RESOLVED } = require('../../../constants')

const { Group: ButtonGroup } = Button

const UNASSIGNED_PLACEHOLDER = 'Assign to me'

const TaskActionSection = ({
  task,
  permissions,
  onAssignTask,
  onUnassignTask,
  onSave,
  onDone,
  onDoneWithDebugModeScraping,
  onMarkTaskAsAwaiting,
  onUnmarkTaskAsAwaiting,
  onReopenTask,
  cancelUrl,
  canReopenTask,
  menu,
  isSaveDisabled,
}) => {
  const { isAssigned, assignedTo, isAwaiting, status } = task
  const history = useHistory()
  const [isMarkAwaitingModalOpen, setIsMarkAwaitingModalOpen] = useState(false)

  const canUserAssignTask = useCanUserAssignTask(task, permissions.ASSIGN)
  const canUserUnassignTask = useCanUserUnassignTask(permissions.UNASSIGN)

  const hasPermissionToReopenTask = useCanUserReopenTask(status, [
    permissions.REOPEN,
  ])

  const canUserReopenTask = useMemo(
    () => canReopenTask ?? hasPermissionToReopenTask,
    [canReopenTask, hasPermissionToReopenTask],
  )

  const isAttachedToCluster = useMemo(() => !!menu?.menuScrapingClusterId, [
    menu,
  ])

  const canUserResolveTask = useCanUserResolveTask(
    assignedTo,
    permissions.RESOLVE,
    permissions.ADMIN,
  )

  const isAdmin = useUserHasRoles(ADMIN)
  const isTeamLeader = useUserHasRoles(TEAM_LEADER)

  useHotkeys(
    'command+shift+s, ctrl+shift+s',
    () =>
      onSave &&
      canUserResolveTask &&
      task.status !== RESOLVED &&
      onSave()[(onSave, canUserResolveTask, task.status)],
  )

  const assignedToCheckboxText = useMemo(
    () => (isAssigned ? assignedTo.name : UNASSIGNED_PLACEHOLDER),
    [isAssigned, assignedTo],
  )

  const onAssignedCheckboxChange = useCallback(
    ({ target: { checked } }) => (checked ? onAssignTask() : onUnassignTask()),
    [onAssignTask, onUnassignTask],
  )

  const handleMarkTaskAsAwaiting = useCallback(
    awaitingReasonData => {
      onMarkTaskAsAwaiting(awaitingReasonData)
      setIsMarkAwaitingModalOpen(false)
    },
    [onMarkTaskAsAwaiting],
  )

  const handleUnmarkTaskAsAwaiting = useCallback(() => {
    onUnmarkTaskAsAwaiting({ id: task.id })
  }, [onUnmarkTaskAsAwaiting, task.id])

  const canResolveTask = useMemo(
    () => isAssigned && canUserResolveTask && task.status !== RESOLVED,
    [isAssigned, canUserResolveTask, task.status],
  )

  const doneButtonMargin = useMemo(
    () => (onDoneWithDebugModeScraping ? 'no no no no' : 'no small no no'),
    [onDoneWithDebugModeScraping],
  )

  const doneButtonMenu = useMemo(
    () => (
      <Menu onClick={onDoneWithDebugModeScraping}>
        <Menu.Item key="1" disabled={!isMenuScrapeable(menu?.format)}>
          Resolve with debug mode scraping
        </Menu.Item>
      </Menu>
    ),
    [onDoneWithDebugModeScraping, menu],
  )

  return (
    <>
      <Checkbox
        style={{ marginTop: '5px' }}
        checked={isAssigned}
        onChange={onAssignedCheckboxChange}
        disabled={
          isAssigned
            ? !canUserUnassignTask
            : !canUserAssignTask ||
              (!!isAttachedToCluster && !isAdmin && !isTeamLeader)
        }
      >
        {assignedToCheckboxText}
      </Checkbox>
      {onSave && (
        <Button
          onClick={onSave}
          margin="no small no no"
          type="primary"
          style={{ width: '80px' }}
          text={assignedToCheckboxText}
          disabled={!canResolveTask || isAttachedToCluster || isSaveDisabled}
        >
          Save
        </Button>
      )}

      <ButtonGroup margin="no small no no">
        <Popconfirm
          title="Are you sure you want to resolve this task?"
          onConfirm={onDone}
          placement="bottom"
          okText="Yes"
          cancelText="No"
          disabled={
            !canResolveTask ||
            (!!isAttachedToCluster && !isAdmin && !isTeamLeader)
          }
        >
          <Button
            margin={doneButtonMargin}
            type="primary"
            style={{ width: '80px' }}
            disabled={
              !canResolveTask ||
              (!!isAttachedToCluster && !isAdmin && !isTeamLeader)
            }
          >
            Done
          </Button>
        </Popconfirm>

        {onDoneWithDebugModeScraping && (
          <Dropdown
            overlay={doneButtonMenu}
            placement="bottomLeft"
            disabled={!canResolveTask}
          >
            <Button margin="no small no no" type="primary">
              <Icon type="down" />
            </Button>
          </Dropdown>
        )}
      </ButtonGroup>

      {isAwaiting ? (
        <Button margin="no small no no" onClick={handleUnmarkTaskAsAwaiting}>
          Unmark as awaiting
        </Button>
      ) : (
        <Button
          margin="no small no no"
          onClick={() => setIsMarkAwaitingModalOpen(true)}
        >
          Mark as awaiting
        </Button>
      )}

      {onReopenTask && canUserReopenTask && (
        <Button margin="no small no no" onClick={onReopenTask}>
          Reopen
        </Button>
      )}

      <Button
        margin="no small no no"
        onClick={() => history.push(getCachedFilterForPage(cancelUrl))}
      >
        Go back
      </Button>

      <MarkTaskAsAwaitingModal
        markTaskAsAwaiting={handleMarkTaskAsAwaiting}
        isModalOpen={isMarkAwaitingModalOpen}
        closeModal={() => setIsMarkAwaitingModalOpen(false)}
      />
    </>
  )
}

TaskActionSection.propTypes = {
  task: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  onAssignTask: PropTypes.func.isRequired,
  onUnassignTask: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  onDone: PropTypes.func,
  onDoneWithDebugModeScraping: PropTypes.func,
  onMarkTaskAsAwaiting: PropTypes.func.isRequired,
  onUnmarkTaskAsAwaiting: PropTypes.func.isRequired,
  onReopenTask: PropTypes.func,
  cancelUrl: PropTypes.string.isRequired,
  canReopenTask: PropTypes.bool,
  menu: PropTypes.object,
}

TaskActionSection.defaultProps = {
  onSave: undefined,
  isSaveDisabled: false,
  onDone: undefined,
  onDoneWithDebugModeScraping: undefined,
  onReopenTask: undefined,
  canReopenTask: undefined,
  menu: undefined,
}

export default TaskActionSection
