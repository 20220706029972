import React, { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Col, Row, Select, Table, Typography } from 'antd'
import {
  differenceBy,
  filter,
  forEach,
  isEmpty,
  map,
  size,
  values,
} from 'lodash-es'
import PropTypes from 'prop-types'

import { HiddenInputs, RadioGroupButtons } from '../../../../core/components'

import { BRAND, LOCKABLE_ENTITIES } from '../../../../common/constants'
import { useLockedEntities } from '../../../../common/hooks'

import { BRAND_RELATIONSHIPS, UNSET } from '../../../../brands/constants'

import TaskTableBrandLinkColumn from './task-table-brand-link-column'

const BrandGatewaySimilarBrandsByLocationWebsite = ({ isLoading }) => {
  const [allRelationships, setAllRelationships] = useState()
  const { control, setValue } = useFormContext()

  const { fields: brandsSimilarByLocationWebsite } = useFieldArray({
    control,
    name: 'similarBrands.brandsSimilarByLocationWebsite',
  })

  useEffect(() => setAllRelationships(), [brandsSimilarByLocationWebsite])

  const {
    lockedEntities,
    lockEntities,
    getUserLockingEntity,
  } = useLockedEntities()

  useEffect(() => {
    if (isEmpty(brandsSimilarByLocationWebsite) || isEmpty(lockedEntities))
      return

    const lockedBrands = filter(
      lockedEntities,
      ({ entityType }) => entityType === BRAND,
    )

    const brandsToLock = differenceBy(
      brandsSimilarByLocationWebsite,
      lockedBrands,

      brand => Number(brand.id) || brand.entityId,
    )

    if (isEmpty(brandsToLock)) return

    const brandsToLockIds = map(brandsToLock, 'id')
    lockEntities(LOCKABLE_ENTITIES.BRAND, brandsToLockIds)
  }, [brandsSimilarByLocationWebsite, lockedEntities, lockEntities])

  const onSetAllRelationshipsChange = useCallback(
    relationship => {
      setAllRelationships(relationship)

      forEach(brandsSimilarByLocationWebsite, (brand, index) =>
        setValue(
          `similarBrands.brandsSimilarByLocationWebsite.${index}.relationship`,
          relationship,
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        ),
      )
    },
    [brandsSimilarByLocationWebsite, setValue],
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'id',
      width: '110px',
    },
    {
      title: 'Brand name',
      dataIndex: 'brand.name',
      width: '400px',
      render: (brandName, brand) => (
        <TaskTableBrandLinkColumn
          getUserLockingEntity={getUserLockingEntity}
          to={`/tasks/brand-gateway/${brand.brandGatewayTask.id}`}
          brand={brand}
        />
      ),
    },
    {
      title: () => (
        <Row gutter={16}>
          <Col span={12}>
            <span className="ant-table-column-title">Relationship</span>
          </Col>
          <Col span={12}>
            <Select
              value={allRelationships}
              onChange={onSetAllRelationshipsChange}
              style={{ width: '120px' }}
            >
              {map(BRAND_RELATIONSHIPS, ({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      ),
      width: '400px',
      render: (id, similarBrand, index) => (
        <RadioGroupButtons
          name={`similarBrands.brandsSimilarByLocationWebsite.${index}.relationship`}
          options={values(BRAND_RELATIONSHIPS)}
          defaultValue={UNSET}
          onRadioGroupChange={() => setAllRelationships()}
        />
      ),
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '110px',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '400px',
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      width: '0px',
      render: (row, brand, index) => (
        <HiddenInputs
          names={[
            `similarBrands.brandsSimilarByLocationWebsite.${index}.id`,
            `similarBrands.brandsSimilarByLocationWebsite.${index}.name`,
            `similarBrands.brandsSimilarByLocationWebsite.${index}.website`,
            `similarBrands.brandsSimilarByLocationWebsite.${index}.brandGatewayTask`,
            `similarBrands.brandsSimilarByLocationWebsite.${index}.locationCount`,
          ]}
        />
      ),
    },
  ]

  return (
    <>
      <Typography.Title level={4}>
        Similar brands by location website (
        {size(brandsSimilarByLocationWebsite)})
      </Typography.Title>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={brandsSimilarByLocationWebsite}
        pagination={false}
      />
    </>
  )
}

BrandGatewaySimilarBrandsByLocationWebsite.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default BrandGatewaySimilarBrandsByLocationWebsite
