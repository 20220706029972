import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactJson from 'react-json-view'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Input, Upload } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'

import { createMenuScrapingTemplateSchema } from './schemas'

const CreateMenuScrapingTemplateForm = ({ onSubmit }) => {
  const history = useHistory()

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(createMenuScrapingTemplateSchema),
  })

  const { watch, handleSubmit } = formMethods

  const scrapingInstructions = watch('scrapingInstructions')

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageHeader sticky>
            <PageTitle>Create Menu Scraping Template</PageTitle>
            <PageActions>
              <Button
                onClick={() =>
                  history.push('/menu-scraping-templates/templates')
                }
                margin="no small"
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </PageActions>
          </PageHeader>
          <Panel>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Input name="name" label="Name" />
              </Col>
              <Col span={3}>
                <Upload
                  label="Scraping instructions"
                  name="scrapingInstructions"
                  accept=".json"
                />
              </Col>
            </Row>
          </Panel>
          <Panel margin="small no">
            <Typography.Title level={4} style={{ margin: '5px' }}>
              Scraping instructions
            </Typography.Title>

            {scrapingInstructions && (
              <ReactJson
                src={JSON.parse(scrapingInstructions)}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
              />
            )}
          </Panel>
        </form>
      </FormProvider>
    </>
  )
}

CreateMenuScrapingTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default CreateMenuScrapingTemplateForm
