import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { ContentWithOverlay, Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'

import {
  RESOLVE_DISH_REVIEW_TASK,
  SAVE_DISH_REVIEW_TASK,
} from '../../graphql/mutations'
import { DISH_REVIEW_TASK } from '../../graphql/queries'
import {
  transformToDishReviewTask,
  transformToDishReviewTaskInput,
} from '../../graphql/transformers'
import { DishReviewTaskForm } from '../forms'

const onSubmit = (values, saveTask, resolveTask) => {
  const variables = transformToDishReviewTaskInput(values)

  return values.task.isDone
    ? resolveTask({
        variables,
      })
    : saveTask({
        variables,
      })
}

const DishReviewTaskPage = () => {
  useDocumentTitle('Tasks - Dish review')
  const { taskId } = useParams()
  const history = useHistory()

  const { data, loading: isLoadingTask } = useQuery(DISH_REVIEW_TASK, {
    variables: { id: taskId },
    fetchPolicy: 'no-cache',
  })

  const [saveTask, { loading: isSavingTask }] = useMutation(
    SAVE_DISH_REVIEW_TASK,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Dish review task saved.',
          description: 'Task has been successfully saved.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Save dish review task failed.',
          description: message,
        })
      },
    },
  )

  const [resolveTask, { loading: isResolvingTask }] = useMutation(
    RESOLVE_DISH_REVIEW_TASK,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Dish review task resolved.',
          description: 'Task has been successfully resolved.',
        })
        history.push(getCachedFilterForPage('/tasks/dish-review'))
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Resolve dish review task failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    task => onSubmit(task, saveTask, resolveTask),
    [saveTask, resolveTask],
  )

  const dishReviewTask = useMemo(
    () =>
      (data &&
        data.dishReviewTask &&
        transformToDishReviewTask(data.dishReviewTask)) ||
      {},
    [data],
  )

  if (isLoadingTask) return <Spinner />

  if (dishReviewTask.menu.isDeleted)
    return (
      <ContentWithOverlay message="This menu is deleted">
        <DishReviewTaskForm
          task={dishReviewTask}
          isSavingTask={isSavingTask}
          isResolvingTask={isResolvingTask}
          onSubmit={handleSubmit}
        />
      </ContentWithOverlay>
    )

  return (
    <DishReviewTaskForm
      task={dishReviewTask}
      isSavingTask={isSavingTask}
      isResolvingTask={isResolvingTask}
      onSubmit={handleSubmit}
    />
  )
}

export default DishReviewTaskPage
