import * as Yup from 'yup'

import { NEED_ASSISTANCE } from '../../../constants'

const markMenuAsAwaitingSchema = Yup.object().shape({
  awaitingReason: Yup.string().required('Awaiting reason is required'),
  awaitingReasonComment: Yup.string()
    .when('awaitingReason', {
      is: awaitingReason => awaitingReason === NEED_ASSISTANCE,
      then: Yup.string().required('Awaiting reason commment is required'),
    })
    .nullable(),
})

export default markMenuAsAwaitingSchema
