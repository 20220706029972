import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { clearAllFilters } from '../../../common/services'

import { REMOVE_USER } from '../../graphql/mutations'
import { destroySession } from '../../services'

const LogoutPage = () => {
  const history = useHistory()

  useEffect(() => {
    clearAllFilters()
    destroySession()
  }, [])

  const [removeUser, { called }] = useMutation(REMOVE_USER, {
    onCompleted() {
      history.push('/account/login')
    },
  })

  if (!called) removeUser()
  return null
}

export default LogoutPage
