import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import ScrapingAnalysis from './scraping-analysis'
import ScrapingDebugJsonPanel from './scraping-debug-json-panel'

const ScrapingDebugInformationMenuTitle = ({ menuTitle }) => (
  <Collapse
    defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
    style={{
      overflow: 'auto',
      height: '85vh',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Menu Title
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Title: </strong>
            {menuTitle.title}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Description: </strong>
            {menuTitle.description}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Start: </strong>
            {menuTitle.timestampBegin}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping End: </strong>
            {menuTitle.timestampEnd}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Duration: </strong>
            {menuTitle.duration}
          </Typography.Text>
        </List.Item>
      </List>
    </Collapse.Panel>
    <Collapse.Panel header="Analysis" key={1}>
      <ScrapingAnalysis analysis={menuTitle.analysis} />
    </Collapse.Panel>
    <ScrapingDebugJsonPanel
      header="Messages"
      panelId={2}
      items={menuTitle.messages}
    />
    <ScrapingDebugJsonPanel
      header="Diets"
      panelId={3}
      items={menuTitle.diets}
    />
    <ScrapingDebugJsonPanel
      header="Addons"
      panelId={4}
      items={menuTitle.addons}
    />
    <ScrapingDebugJsonPanel
      header="Misc Info"
      panelId={5}
      items={menuTitle.miscInfo}
    />
    <ScrapingDebugJsonPanel
      header="Allergens"
      panelId={6}
      items={menuTitle.allergens}
    />
    <ScrapingDebugJsonPanel
      header="Nutritions"
      panelId={7}
      items={menuTitle.nutritions}
    />
    <ScrapingDebugJsonPanel
      header="Flags"
      panelId={8}
      items={menuTitle.flags}
    />
    <ScrapingDebugJsonPanel
      header="Exceptions"
      panelId={9}
      items={menuTitle.exceptions}
    />
  </Collapse>
)

ScrapingDebugInformationMenuTitle.propTypes = {
  menuTitle: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationMenuTitle
