const isFalsy = (
  value,
  { allowEmptyString = false, allowZero = false, allowBoolean = true } = {},
) => {
  if (value === '') return !allowEmptyString
  if (value === 0) return !allowZero
  if (value === false) return !allowBoolean
  return !value
}

export default isFalsy
