import { gql } from 'apollo-boost'

const MENU_SCRAPING_TEMPLATES_BASIC = gql`
  query menuScrapingTemplatesBasic {
    menuScrapingTemplatesBasic {
      id
      name
      status
      scrapingInstructionsVersionNumber
    }
  }
`

export default MENU_SCRAPING_TEMPLATES_BASIC
