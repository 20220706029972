import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  LeadsManuallyRemovedFromClusterMenusTable,
  MenuScrapingClusterMenusTable,
  MenusManuallyRemovedFromClusterMenusTable,
  MenuStatusesTable,
} from './tables'

const MenuScrapingClusterCleanupStep = ({ menuScrapingCluster }) => {
  const [
    menuStatusesTableRefreshKey,
    setMenuStatusesTableRefreshKey,
  ] = useState(Math.random())

  const [menusTableRefreshKey, setMenusTableRefreshKey] = useState(
    Math.random(),
  )

  const [
    manuallyRemovedMenusTableRefreshKey,
    setManuallyRemovedMenusTableRefreshKey,
  ] = useState(Math.random())

  const [
    manuallyRemovedLeadsTableRefreshKey,
    setManuallyRemovedLeadsTableRefreshKey,
  ] = useState(Math.random())

  const [
    isManuallyRemovedMenusTableHidden,
    setIsManuallyRemovedMenusTableHidden,
  ] = useState(true)

  const [
    isManuallyRemovedLeadsTableHidden,
    setIsManuallyRemovedLeadsTableHidden,
  ] = useState(true)

  const refreshMenuStatusesTable = useCallback(() => {
    setMenuStatusesTableRefreshKey(Math.random())
  }, [])

  const refreshMenusTable = useCallback(() => {
    setMenusTableRefreshKey(Math.random())
  }, [])

  const refreshManuallyRemovedMenusTable = useCallback(() => {
    setManuallyRemovedMenusTableRefreshKey(Math.random())
  }, [])

  const refreshManuallyRemovedLeadsTable = useCallback(() => {
    setManuallyRemovedLeadsTableRefreshKey(Math.random())
  }, [])

  const toggleIsManuallyRemovedMenusTableHidden = useCallback(() => {
    setIsManuallyRemovedMenusTableHidden(prev => !prev)
  }, [])

  const toggleIsManuallyRemovedLeadsTableHidden = useCallback(() => {
    setIsManuallyRemovedLeadsTableHidden(prev => !prev)
  }, [])

  return (
    <>
      <MenuStatusesTable
        key={menuStatusesTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isResetMenusButtonHidden
      />
      <MenuScrapingClusterMenusTable
        key={menusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isUpdateRoleButtonHidden
        onRemoveMenuFromMenuScrapingCluster={() => {
          refreshMenuStatusesTable()
          refreshManuallyRemovedMenusTable()
          refreshManuallyRemovedLeadsTable()
        }}
      />
      <MenusManuallyRemovedFromClusterMenusTable
        key={manuallyRemovedMenusTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedMenusTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedMenusTableHidden()
        }}
        onAttachMenuBackToClusterMenus={() => {
          refreshMenuStatusesTable()
          refreshMenusTable()
        }}
      />
      <LeadsManuallyRemovedFromClusterMenusTable
        key={manuallyRemovedLeadsTableRefreshKey}
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedLeadsTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedLeadsTableHidden()
        }}
      />
    </>
  )
}

MenuScrapingClusterCleanupStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterCleanupStep
