import React from 'react'
import ReactJson from 'react-json-view'
import { Popover } from 'antd'
import PropTypes from 'prop-types'

const PopoverReactJson = ({
  source,
  name,
  collapsed,
  displayDataTypes,
  enableClipboard,
  enableClipboardInPopover,
}) => {
  return (
    <Popover
      content={
        <ReactJson
          src={source}
          enableClipboard={enableClipboardInPopover}
          displayDataTypes={displayDataTypes}
          name={false}
        />
      }
    >
      <div>
        <ReactJson
          name={name}
          src={source}
          collapsed={collapsed}
          enableClipboard={enableClipboard}
          displayDataTypes={displayDataTypes}
        />
      </div>
    </Popover>
  )
}

PopoverReactJson.propTypes = {
  name: PropTypes.bool,
  source: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  displayDataTypes: PropTypes.bool,
  enableClipboard: PropTypes.bool,
  enableClipboardInPopover: PropTypes.bool,
}

PopoverReactJson.defaultProps = {
  name: false,
  collapsed: true,
  displayDataTypes: false,
  enableClipboard: false,
  enableClipboardInPopover: false,
}

export default PopoverReactJson
