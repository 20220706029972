import { gql } from 'apollo-boost'

const BRAND_DEFINITION_TASKS = gql`
  query brandDefinitionTasks(
    $filters: BrandDefinitionTaskFiltersInput!
    $page: Int
    $pageSize: Int
    $sortingCriterion: BrandDefinitionTaskSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    brandDefinitionTasks(
      filters: $filters
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      brandDefinitionTasks {
        id
        status
        brand {
          id
          name
          locationCount
          includedLocationCount
        }
        brandIncludedBy {
          id
          name
        }
        assignedTo {
          id
          name
        }
        resolvedBy {
          id
          name
        }
        modifiedBy {
          id
          name
        }
        createdDate
        modifiedDate
        resolvedDate
      }
      total
    }
  }
`

export default BRAND_DEFINITION_TASKS
