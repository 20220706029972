import { gql } from 'apollo-boost'

const BRAND = gql`
  query brand($id: ID!) {
    brand(id: $id) {
      id
      name
      website
      status
      businessStatus
      exclusionReasonComment
      modifiedDate
      hasOnlyUnsupportedBusinessTypes
      useBrandNameAsLocationName
      useDishTypeIcon
      isForTesting
      iconFileKey
      imageFileKey
      areAssetsActive
      menu {
        id
        url
        format
        dishCount
        scrapingInstructions
      }
      locations {
        id
        name
        status
        businessStatus
        menuStatus
        menu {
          url
        }
        website
        modifiedDate
      }
    }
  }
`

export default BRAND
