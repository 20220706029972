import { gql } from 'apollo-boost'

const EVENTS = gql`
  query events($filters: EventFiltersInput!) {
    events(filters: $filters) {
      id
      userId
      type
      subType
      entityType
      entityId
      message
      data
      createdDate
      user {
        id
        name
      }
    }
  }
`

export default EVENTS
