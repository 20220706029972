import React, { useContext, useMemo } from 'react'
import { Checkbox } from 'antd'
import PropTypes from 'prop-types'

import { AuthContext } from '../../../../account/contexts'
import { useCanUserAssignTask, useCanUserUnassignTask } from '../../../hooks'

const onChange = (isChecked, task, loggedInUser, assignTask, unassignTask) =>
  isChecked
    ? assignTask({ variables: { id: task.id, toUserId: loggedInUser.id } })
    : unassignTask({ variables: { id: task.id } })

const TaskTableAssignedColumn = ({
  task,
  permissions,
  assignTask,
  unassignTask,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)
  const { assignedTo } = task

  const canUserAssignTask = useCanUserAssignTask(task, permissions.ASSIGN)
  const canUserUnassignTask = useCanUserUnassignTask(permissions.UNASSIGN)

  const isMenuAttachedToCluster = useMemo(
    () => task.menu?.menuScrapingClusterId,
    [task.menu],
  )

  return (
    <Checkbox
      checked={!!assignedTo}
      disabled={
        assignedTo
          ? !canUserUnassignTask
          : !canUserAssignTask || !!isMenuAttachedToCluster
      }
      onChange={event =>
        onChange(
          event.target.checked,
          task,
          loggedInUser,
          assignTask,
          unassignTask,
        )
      }
      onClick={event => event.stopPropagation()}
    >
      {assignedTo ? assignedTo.name : 'Assign to me'}
    </Checkbox>
  )
}

TaskTableAssignedColumn.propTypes = {
  task: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  assignTask: PropTypes.func.isRequired,
  unassignTask: PropTypes.func.isRequired,
}

export default TaskTableAssignedColumn
