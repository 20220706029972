import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Checkbox, Form, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

const MenuShowSimilarFilter = ({ queryParamName, disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const showSimilarMenus = getQueryParams(search)[queryParamName] === true

  const handleChange = useCallback(
    event => {
      const isChecked = event.target.checked

      history.push(
        createUrl(pathname, search, {
          [queryParamName]: isChecked ? 'true' : undefined,
        }),
      )
    },
    [history, pathname, search, queryParamName],
  )

  useEffect(() => {
    if (disabled) {
      const newUrl = createUrl(pathname, search, {
        [queryParamName]: undefined,
      })
      history.push(newUrl)
    }
  }, [disabled, history, pathname, search, queryParamName])

  const checkboxElement = (
    <Checkbox
      checked={!disabled && showSimilarMenus}
      onChange={handleChange}
      disabled={disabled}
    />
  )

  return (
    <Form.Item label="Show similar menus">
      {disabled ? (
        <Tooltip title="This is available only for lead menus.">
          {checkboxElement}
        </Tooltip>
      ) : (
        checkboxElement
      )}
    </Form.Item>
  )
}

MenuShowSimilarFilter.propTypes = {
  queryParamName: PropTypes.string,
  disabled: PropTypes.bool,
}

MenuShowSimilarFilter.defaultProps = {
  queryParamName: 'showSimilarMenus',
  disabled: true,
}

export default MenuShowSimilarFilter
