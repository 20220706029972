import { gql } from 'apollo-boost'

const ASSIGN_BRAND_DEFINITION_TASK = gql`
  mutation assignBrandDefinitionTask($id: ID!, $toUserId: ID!) {
    assignBrandDefinitionTask(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_BRAND_DEFINITION_TASK
