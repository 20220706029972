export const ASC = 'asc'
export const DESC = 'desc'

export const SORTING_ORDERS = {
  ASC,
  DESC,
}

export const SORTING_ORDERS_MAP = {
  [SORTING_ORDERS.ASC]: {
    name: 'Ascending',
    antd: 'ascend',
    api: ASC,
  },
  [SORTING_ORDERS.DESC]: {
    name: 'Descending',
    antd: 'descend',
    api: DESC,
  },
}
