import { useCallback, useContext, useEffect, useState } from 'react'
import { every, find } from 'lodash-es'

import { SOCKET_EVENTS } from '../constants'
import { SocketContext } from '../contexts'

const useLockedEntities = () => {
  const [lockedEntities, setLockedEntities] = useState()
  const { sessionId, socket } = useContext(SocketContext)

  const isEntityLocked = useCallback(
    (entityType, entityId) => !!find(lockedEntities, { entityType, entityId }),
    [lockedEntities],
  )

  const getUserLockingEntity = useCallback(
    (entityType, entityId) => {
      const lockedEntity = find(lockedEntities, { entityType, entityId })

      const user = lockedEntity?.userId
        ? {
            id: lockedEntity.userId,
            name: lockedEntity.userName,
          }
        : null

      const isCurrentSession = lockedEntity?.sessionId === sessionId
      return { user, isCurrentSession }
    },
    [sessionId, lockedEntities],
  )

  const canLockEntities = useCallback(
    (entityType, entityIds) => {
      return every(entityIds, entityId => !isEntityLocked(entityType, entityId))
    },
    [isEntityLocked],
  )

  const lockEntities = useCallback(
    (entityType, entityIds) => {
      socket.emit(SOCKET_EVENTS.LOCK, { entityType, entityIds })
    },
    [socket],
  )

  const unlockEntities = useCallback(
    (entityType, entityIds) => {
      socket.emit(SOCKET_EVENTS.UNLOCK, { entityType, entityIds })
    },
    [socket],
  )

  const unlockEntitiesWithSessionId = useCallback(
    sessionId => {
      socket.emit(SOCKET_EVENTS.UNLOCK_ALL, { sessionId })
    },
    [socket],
  )

  const cleanUp = useCallback(() => {
    socket.removeEventListener(SOCKET_EVENTS.RECEIVE_LOCKS, setLockedEntities)
    unlockEntitiesWithSessionId(sessionId)
  }, [socket, unlockEntitiesWithSessionId, sessionId])

  useEffect(() => {
    if (!socket.connected) return

    socket.on(SOCKET_EVENTS.RECEIVE_LOCKS, entities =>
      setLockedEntities(entities),
    )

    socket.emit(SOCKET_EVENTS.REQUEST_LOCKS)
  }, [socket, socket.connected])

  useEffect(() => cleanUp, [cleanUp])

  return {
    lockedEntities,
    isEntityLocked,
    getUserLockingEntity,
    canLockEntities,
    lockEntities,
    unlockEntities,
  }
}

export default useLockedEntities
