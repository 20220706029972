import React from 'react'
import PropTypes from 'prop-types'

const DuplicateLocationErrorDescription = ({
  brandGatewayTaskLink,
  locationId,
}) => (
  <div>
    This location is already in use by{' '}
    <a href={brandGatewayTaskLink} target="_blank" rel="noopener noreferrer">
      brand
    </a>{' '}
    for location {locationId}.
  </div>
)

DuplicateLocationErrorDescription.propTypes = {
  brandGatewayTaskLink: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
}

export default DuplicateLocationErrorDescription
