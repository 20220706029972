import { forEach, get, includes, map } from 'lodash-es'

import { DEFAULT_PAGE_SIZE } from '../../../../common/constants'
import { showErrorNotification } from '../../../../common/helpers'

import { menuDefinitionTaskSchema } from '../schemas'

const transformError = ({ path, message }, value) => {
  if (includes(path, 'locations')) {
    const locationIndex = path.match(/(\d+)/)[0]
    const locationPage = Math.floor(locationIndex / DEFAULT_PAGE_SIZE) + 1
    const location = get(value, `locations.${locationIndex}`)

    return `Location with id ${location.id} on page ${locationPage} has the following error: ${message}`
  }

  return message
}

const validateState = async state => {
  try {
    await menuDefinitionTaskSchema.validate(state, { abortEarly: false })
    return true
  } catch ({ value, inner }) {
    const errors = map(inner, error => transformError(error, value))

    forEach(errors, error =>
      showErrorNotification({
        message: error,
      }),
    )

    return false
  }
}

export default validateState
