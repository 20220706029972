import { pick } from 'lodash-es'

import { BRAND_STATUSES, BUSINESS_STATUSES } from '../../../constants'

const getBrandInitialValues = values => {
  const brand = pick(
    values,
    'id',
    'name',
    'hasOnlyUnsupportedBusinessTypes',
    'useBrandNameAsLocationName',
    'useDishTypeIcon',
    'website',
    'isForTesting',
    'modifiedDate',
  )

  return {
    ...brand,
    status: BRAND_STATUSES[values.status].name,
    businessStatus: BUSINESS_STATUSES[values.businessStatus]?.name || '',
  }
}

const getMenuInitialValues = values => {
  const { id, url, format, dishCount } = values || {}

  return {
    id: id || '',
    url: url || '',
    format: format || '',
    dishCount: dishCount || '',
  }
}

const brandFormInitialValues = values => {
  if (!values) return {}
  const brand = getBrandInitialValues(values)
  const menu = getMenuInitialValues(values.menu)

  const initialValues = {
    ...brand,
    menu,
  }

  return initialValues
}

export default brandFormInitialValues
