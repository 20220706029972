import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Table, Tooltip, Typography } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import {
  DEFAULT_PAGE,
  MENU_SCRAPING_TASKS_PERMISSIONS,
  SORTING_ORDERS,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { usePaginationFixer } from '../../../common/hooks'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder, tableOnRowClick } from '../../../common/utils'

import {
  MENU_FORMATS,
  MENU_SCRAPING_TASKS_SORTING_CRITERIA,
  MENU_STATUSES,
} from '../../../menus/constants'
import {
  ASSIGN_MENU_SCRAPING_TASK,
  UNASSIGN_MENU_SCRAPING_TASK,
} from '../../graphql/mutations'
import { MENU_SCRAPING_TASKS } from '../../graphql/queries'

import TaskTableAssignedColumn from './sections/task-table-assigned-column'

const MenuScrapingTasksPage = () => {
  useDocumentTitle('Tasks - Menu Scraping')
  const history = useHistory()
  const { search, pathname } = useLocation()

  const {
    brandSearchTerm,
    menuSearchTerm,
    statuses,
    reopenReasons,
    isAssigned,
    assignedToId,
    isAwaiting,
    awaitingReason,
    menuFormats,
    menuStatuses,
    menuIsAwaitingScraping,
    menuIsAwaitingProbing,
    isVerified,
    hasScrapingIssueComment,
    page = DEFAULT_PAGE,
    pageSize = 10,
    sortingCriterion,
    sortingOrder,
    brandId,
  } = getQueryParams(search)

  const {
    data: { menuScrapingTasks = {} } = {},
    loading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(MENU_SCRAPING_TASKS, {
    variables: {
      filters: {
        brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
        menuSearchTerm: menuSearchTerm && String(menuSearchTerm),
        statuses,
        reopenReasons,
        isAssigned,
        assignedToId,
        isAwaiting,
        awaitingReason,
        menuFormats,
        menuStatuses,
        menuIsAwaitingScraping,
        menuIsAwaitingProbing,
        isVerified,
        hasScrapingIssueComment,
        brandId,
      },
      page,
      pageSize,
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  usePaginationFixer(isLoadingTasks, menuScrapingTasks, 'menuScrapingTasks')

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Menu scraping task assigned.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Menu scraping task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task unassign failed.',
          description: message,
        })
      },
    },
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'menu.brand.id',
      width: '100px',
    },
    {
      title: 'Menu ID',
      width: '80px',
      dataIndex: 'menu.id',
    },
    {
      title: 'Assigned',
      width: '200px',
      render: task => (
        <TaskTableAssignedColumn
          task={task}
          permissions={MENU_SCRAPING_TASKS_PERMISSIONS}
          assignTask={assignTask}
          unassignTask={unassignTask}
        />
      ),
    },
    {
      title: 'Brand Name',
      width: '220px',
      dataIndex: 'menu.brand.name',
    },
    {
      title: 'Locations',
      width: '100px',
      dataIndex: 'menu.locationCount',
    },
    {
      title: 'Menu URL',
      width: '250px',
      dataIndex: 'menu.url',
      ellipsis: true,
      render: menuURL =>
        menuURL && (
          <a href={menuURL} target="_blank" rel="noopener noreferrer">
            {menuURL}
          </a>
        ),
    },
    {
      title: 'Menu status',
      width: '130px',
      dataIndex: 'menu.status',
      render: menuStatus => menuStatus && MENU_STATUSES[menuStatus].name,
    },
    {
      title: 'Menu format',
      width: '150px',
      dataIndex: 'menu.format',
      render: menuFormat => menuFormat && MENU_FORMATS[menuFormat].name,
    },
    {
      title: 'Comment',
      width: '260px',
      dataIndex: 'menu.scrapingIssueComment',
      ellipsis: true,
      render: comment => (
        <Tooltip
          title={comment}
          placement="left"
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0}
        >
          {comment}
        </Tooltip>
      ),
    },
    {
      title: 'Updated at',
      width: '150px',
      dataIndex: 'modifiedDate',
      render: date => <LondonDateTime date={date} />,
      sorter: true,
    },
    {
      title: 'Scraped at',
      dataIndex: 'menu.scrapedDate',
      width: '150px',
      render: date => <LondonDateTime date={date} />,
      sorter: true,
      defaultSortOrder: SORTING_ORDERS.DESC.antd,
    },
  ]

  const redirectToMenuScrapingTask = useCallback(
    task => history.push(`/tasks/menu-scraping/${task.id}`),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event => tableOnRowClick(event, row, redirectToMenuScrapingTask),
    }),
    [redirectToMenuScrapingTask],
  )

  const getSortingCriterion = (field, order) => {
    if (order) {
      if (field === 'modifiedDate') {
        return MENU_SCRAPING_TASKS_SORTING_CRITERIA.MODIFIED_DATE
      }
      if (field === 'menu.scrapedDate') {
        return MENU_SCRAPING_TASKS_SORTING_CRITERIA.SCRAPED_DATE
      }
    }
    return ''
  }

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          page: pagination.current,
          sortingCriterion,
          sortingOrder,
        }),
      )
    },
    [history, search, pathname],
  )

  return (
    <>
      <Typography.Text>
        {isLoadingTasks
          ? 'Loading records'
          : `${menuScrapingTasks.total} matching records`}
      </Typography.Text>
      <Table
        loading={isLoadingTasks || isAssigningTask || isUnassigningTask}
        rowKey="id"
        columns={tableColumns}
        dataSource={menuScrapingTasks.menuScrapingTasks}
        scroll={{ x: 1500 }}
        pagination={{
          current: page,
          total: menuScrapingTasks.total,
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
    </>
  )
}

export default MenuScrapingTasksPage
