import { gql } from 'apollo-boost'

const SIMILAR_BRANDS = gql`
  query similarBrands(
    $id: ID!
    $name: String!
    $pageSize: Int
    $sortingCriterion: SimilarBrandsSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    similarBrands(
      id: $id
      name: $name
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      brandsSimilarByName {
        id
        name
        locationCount
        website
        brandGatewayTask {
          id
        }
      }
      brandsSimilarByLocationWebsite {
        id
        name
        locationCount
        website
        brandGatewayTask {
          id
        }
      }
    }
  }
`

export default SIMILAR_BRANDS
