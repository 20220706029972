import { SCRAPED_ITEM_ANALYSIS_TYPES } from '../constants'

const getAnalysisSectionTitle = analysisType => {
  switch (analysisType) {
    case SCRAPED_ITEM_ANALYSIS_TYPES.SUMMARY:
      return 'Summary'
    case SCRAPED_ITEM_ANALYSIS_TYPES.MENU_TITLE:
      return 'Menu Title'
    case SCRAPED_ITEM_ANALYSIS_TYPES.DISH:
      return 'Dish'
    default:
      return analysisType
  }
}

export default getAnalysisSectionTitle
