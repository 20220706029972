import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const ScrapingDebugScreenshotStatsPanel = ({ screenshot }) => (
  <>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Website URL:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.url}</Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Title:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.title}</Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Selector Name:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.selectorName}</Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Selector CSS:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.selectorCss}</Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Element Count:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.elementCount}</Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Typography.Text strong>Elapsed Time:</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{screenshot.elapsedTime}</Typography.Text>
      </Col>
    </Row>
  </>
)

ScrapingDebugScreenshotStatsPanel.propTypes = {
  screenshot: PropTypes.object.isRequired,
}

export default ScrapingDebugScreenshotStatsPanel
