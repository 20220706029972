import * as Yup from 'yup'

const createBrandSchema = Yup.object().shape({
  name: Yup.string().required(`Brand's name is required`),
  website: Yup.string()
    .url('Website is not valid')
    .nullable(),
})

export default createBrandSchema
