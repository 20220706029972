import { isArray } from 'lodash-es'

const normalizeFileForUpload = file => {
  if (isArray(file)) {
    return file
  }

  return file?.fileList
}

export default normalizeFileForUpload
