import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Button, Form, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '../../../core/components'

import { loginWithEmailSchema } from '../schemas'
import { LoginPanel } from '../styled'

const LoginWithEmailForm = ({ onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      email: '',
      code: '',
    },
    resolver: yupResolver(loginWithEmailSchema),
  })

  const { handleSubmit, formState } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LoginPanel>
          <Typography.Title>AutoEat - FoodStyles</Typography.Title>
          <Input
            style={{ width: '300px' }}
            name="email"
            label="Email Address"
            placeholder="Email Address..."
            required
          />
          <Input
            style={{ width: '300px' }}
            name="code"
            label="Security Code"
            placeholder="Security Code..."
            required
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formState.isDirty}
          >
            Login
          </Button>
        </LoginPanel>
      </Form>
    </FormProvider>
  )
}

LoginWithEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginWithEmailForm
