import { gql } from 'apollo-boost'

const MARK_MENU_SCRAPING_TASK_AS_VERIFIED = gql`
  mutation markMenuScrapingTaskAsVerified(
    $id: ID!
    $isVerifiedInDebugMode: Boolean!
  ) {
    markMenuScrapingTaskAsVerified(
      id: $id
      isVerifiedInDebugMode: $isVerifiedInDebugMode
    ) {
      id
      isVerifiedInDebugMode
    }
  }
`

export default MARK_MENU_SCRAPING_TASK_AS_VERIFIED
