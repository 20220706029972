import { gql } from 'apollo-boost'

const REOPEN_BRAND_DEFINITION_TASK = gql`
  mutation reopenBrandDefinitionTask($id: ID!) {
    reopenBrandDefinitionTask(id: $id) {
      id
      modifiedDate
      reopenedDate
    }
  }
`

export default REOPEN_BRAND_DEFINITION_TASK
