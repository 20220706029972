import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import { map, values } from 'lodash-es'
import PropTypes from 'prop-types'

import { Select } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { TASK_TYPES } from '../../../tasks/constants'

import { openTasksReportSchema } from './schemas'

const taskTypeOptions = map(values(TASK_TYPES), ({ name, value, key }) => ({
  name,
  value,
  key,
}))

const OpenTasksReportForm = ({ isGeneratingReport, onSubmit }) => {
  const formMethods = useForm({
    defaultValues: {
      taskTypes: [],
    },
    resolver: yupResolver(openTasksReportSchema),
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom">
            <Col span={5}>
              <Select
                name="taskTypes"
                label="Task types"
                options={taskTypeOptions}
                mode="multiple"
                allowClear
              />
            </Col>
            <Col span={2} offset={1}>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isGeneratingReport}
              >
                Generate open tasks report
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

OpenTasksReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isGeneratingReport: PropTypes.bool.isRequired,
}

export default OpenTasksReportForm
