import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { MENU_SCRAPING_CLUSTER_ROLES } from '../../menus/constants'

const MenuScrapingClusterRolesFilter = ({ disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { scrapingClusterRoles } = getQueryParams(search)

  const handleChange = useCallback(
    scrapingClusterRoles =>
      history.push(
        createUrl(pathname, search, {
          scrapingClusterRoles,
        }),
      ),
    [history, pathname, search],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Menu role">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={scrapingClusterRoles}
        mode="multiple"
        onChange={handleChange}
        dropdownStyle={{ minWidth: 'fit-content' }}
        maxTagCount={3}
        maxTagPlaceholder={getMaxTagPlaceholder}
        disabled={disabled}
      >
        {map(MENU_SCRAPING_CLUSTER_ROLES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

MenuScrapingClusterRolesFilter.propTypes = {
  disabled: PropTypes.bool,
}

MenuScrapingClusterRolesFilter.defaultProps = {
  disabled: false,
}

export default MenuScrapingClusterRolesFilter
