import { gql } from 'apollo-boost'

const RESOLVE_MENU_DEFINITION_TASK = gql`
  mutation resolveMenuDefinitionTask(
    $id: ID!
    $brandData: SaveMenuDefinitionTaskBrandInput
    $locationsData: [SaveMenuDefinitionTaskLocationInput!]!
  ) {
    resolveMenuDefinitionTask(
      id: $id
      brandData: $brandData
      locationsData: $locationsData
    ) {
      id
    }
  }
`

export default RESOLVE_MENU_DEFINITION_TASK
