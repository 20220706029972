const transformToUpdateMenuScrapingClusterInput = ({ id, ...data }) => {
  const {
    menuScrapingTemplate,
    masterScrapingCluster,
    expectedMenuFormat,
    areWorkingHoursIrrelevant,
  } = data

  return {
    id,
    data: {
      expectedMenuFormat: expectedMenuFormat || null,
      menuScrapingTemplateId: menuScrapingTemplate?.id || null,
      masterScrapingClusterId: masterScrapingCluster?.id,
      areWorkingHoursIrrelevant: areWorkingHoursIrrelevant || false,
    },
  }
}

export default transformToUpdateMenuScrapingClusterInput
