export default Object.freeze({
  LEFT: {
    hotkey: 'left',
    description: 'Go to previous screenshot',
  },
  RIGHT: {
    hotkey: 'right',
    description: 'Go to next screenshot',
  },
  UP: {
    hotkey: 'up',
    description: 'Go to the first screenshot of the previous scraped item',
  },
  DOWN: {
    hotkey: 'down',
    description: 'Go to the first screenshot of the next scraped item',
  },
})
