import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Spin, Typography } from 'antd'

import { PageOverlay } from '../../../common/components/styled'
import { showErrorNotification } from '../../../common/helpers/notification'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { LOGIN_WITH_GOOGLE_ID_TOKEN, SET_USER } from '../../graphql/mutations'
import { createSession, decodeToken } from '../../services'
import { GoogleLoginButton } from '../sections'
import { LoginPanel } from '../styled'

const LoginPage = () => {
  useDocumentTitle('Login')
  const [googleAccessToken, setGoogleAccessToken] = useState()
  const [setUser] = useMutation(SET_USER)

  const [loginWithGoogleIdToken, { loading }] = useMutation(
    LOGIN_WITH_GOOGLE_ID_TOKEN,
    {
      onCompleted({
        loginWithGoogleIdToken: { accessToken, refreshToken, permissions },
      }) {
        const userData = decodeToken(accessToken)
        const user = { ...userData, permissions }
        createSession(user, accessToken, refreshToken, googleAccessToken)
        setUser({ variables: { user } })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Login with google failed.',
          description: message,
        })
      },
    },
  )

  const onGoogleLoginSuccess = useCallback(
    ({ tokenId, accessToken }) => {
      setGoogleAccessToken(accessToken)
      loginWithGoogleIdToken({ variables: { googleIdToken: tokenId } })
    },
    [loginWithGoogleIdToken],
  )

  return (
    <>
      {loading && (
        <PageOverlay>
          <Spin tip="Loading..." />
        </PageOverlay>
      )}
      <LoginPanel>
        <Typography.Title>AutoEat - FoodStyles</Typography.Title>
        <GoogleLoginButton onSuccess={onGoogleLoginSuccess} />
      </LoginPanel>
    </>
  )
}

export default LoginPage
