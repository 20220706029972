import { gql } from 'apollo-boost'

const UPDATE_BRAND = gql`
  mutation updateBrand($id: ID!, $data: UpdateBrandInput!) {
    updateBrand(id: $id, data: $data) {
      id
      name
      status
    }
  }
`

export default UPDATE_BRAND
