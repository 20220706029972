import { pick } from 'lodash-es'

const transformToUserInput = data => {
  const userInput = pick(data, ['name', 'email', 'isActive'])

  return {
    ...userInput,
    roleIds: [data.roleId],
  }
}

export default transformToUserInput
