import { partition, sortBy } from 'lodash-es'

import { LOCATION_STATUSES_SORT_VALUES } from '../../brands/constants'

const sortLocationByStatusComparator = location =>
  LOCATION_STATUSES_SORT_VALUES[location.status].sortValue

const sortLocationsForBrandGatewayTask = locations => {
  const locationsSortedById = sortBy(locations, 'id')

  const [
    locationsMarkedAsRequiringReview,
    locationsNotMarkedAsRequiringReview,
  ] = partition(locationsSortedById, location => location.requiresReview)

  const locationsNotMarkedAsRequiringReviewSortedByStatus = sortBy(
    locationsNotMarkedAsRequiringReview,
    sortLocationByStatusComparator,
  )

  return [
    ...locationsMarkedAsRequiringReview,
    ...locationsNotMarkedAsRequiringReviewSortedByStatus,
  ]
}

export default sortLocationsForBrandGatewayTask
