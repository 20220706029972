import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'
import { difference, differenceBy, filter } from 'lodash-es'

import { getQueryParams } from '../../../core/utils/services/queryParams'

import { Spinner } from '../../../common/components'
import { DEFAULT_PAGE } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { SAVE_COMMON_WEBSITE_DOMAINS } from '../../graphql/mutations'
import { COMMON_WEBSITE_DOMAINS } from '../../graphql/queries'
import { transformToSaveCommonWebsitesInput } from '../../graphql/transformers'
import { CommonWebsiteDomainForm } from '../forms'

const onSubmit = (formValues, initialFormValues, saveCommonWebsitesDomains) => {
  const newDomainWebsites = filter(formValues, value => !value.id)
  const removedDomainWebsites = differenceBy(
    initialFormValues,
    formValues,
    'id',
  )

  const updatedOrUntouchedWebsite = difference(formValues, [
    ...newDomainWebsites,
    ...removedDomainWebsites,
  ])

  const updatedDomainsWebsites = differenceBy(
    updatedOrUntouchedWebsite,
    initialFormValues,
    'websiteDomain',
  )

  const variables = transformToSaveCommonWebsitesInput(
    newDomainWebsites,
    updatedDomainsWebsites,
    removedDomainWebsites,
  )

  return saveCommonWebsitesDomains({ variables })
}

const CommonWebsiteDomainPage = () => {
  useDocumentTitle('Settings - Common websites')
  const { search } = useLocation()

  const { websiteDomain, page = DEFAULT_PAGE } = getQueryParams(search)

  const {
    data: { commonWebsiteDomains } = {},
    refetch: refetchCommonWebsitesDomains,
    networkStatus,
  } = useQuery(
    COMMON_WEBSITE_DOMAINS,
    {
      variables: {
        filters: { websiteDomain },
        page,
      },
    },
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  )

  const [saveCommonWebsitesDomains, { loading: isSaving }] = useMutation(
    SAVE_COMMON_WEBSITE_DOMAINS,
    {
      onCompleted() {
        refetchCommonWebsitesDomains()
        showSuccessNotification({
          message: 'Common website domains updated.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Save common website domains failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    (formformValues, formInitialFormValues) =>
      onSubmit(
        formformValues,
        formInitialFormValues,
        saveCommonWebsitesDomains,
      ),
    [saveCommonWebsitesDomains],
  )

  return networkStatus === NetworkStatus.loading ? (
    <Spinner />
  ) : (
    <>
      <CommonWebsiteDomainForm
        commonWebsiteDomains={commonWebsiteDomains.commonWebsiteDomains}
        total={commonWebsiteDomains.total}
        onSubmit={handleSubmit}
        isSaving={isSaving}
        isRefetching={networkStatus === NetworkStatus.refetch}
      />
    </>
  )
}

export default CommonWebsiteDomainPage
