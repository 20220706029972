import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Form, Icon, Row, Select, Spin, Typography } from 'antd'
import { get, join, map, uniq } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button, Panel } from '../../../../core/components/styled'

import { MENU_SCRAPING_TEMPLATES_BASIC } from '../../../graphql/queries'

const MenuScrapingTemplatesDropdown = ({ name, disabled }) => {
  const { control, errors, getValues } = useFormContext()
  const formErrors = get(errors, name)

  const [selectedValue, setSelectedValue] = useState(getValues(name))
  useEffect(() => {
    const newSelectedValue = getValues(name)
    setSelectedValue(newSelectedValue)
  }, [getValues, name, selectedValue])

  const messages = formErrors
    ? join(uniq(map(formErrors, 'message')), '. ')
    : undefined

  const getSelectedValue = useCallback(
    value =>
      value?.id
        ? { key: value.id, label: `${value.name}` }
        : { key: '', label: '' },
    [],
  )

  const {
    data: { menuScrapingTemplatesBasic: menuScrapingTemplates } = {},
    loading: isLoadingMenuScrapingTemplates,
  } = useQuery(MENU_SCRAPING_TEMPLATES_BASIC)

  const options = map(
    menuScrapingTemplates,
    ({ id, name, scrapingInstructionsVersionNumber }) => ({
      label: `${name} (v.${scrapingInstructionsVersionNumber})`,
      value: id,
    }),
  )

  return (
    <Form.Item
      label="Scraping template"
      help={messages}
      validateStatus={messages ? 'error' : 'success'}
    >
      <Row type="flex">
        <Col span={21}>
          <Controller
            control={control}
            name={name}
            render={({ onChange, value, ...props }) => (
              <Select
                disabled={disabled}
                showSearch
                allowClear
                labelInValue
                value={getSelectedValue(value)}
                filterOption
                onChange={value => {
                  const newValue = { id: value?.key, name: value?.label }
                  setSelectedValue(newValue)
                  onChange(newValue)
                }}
                notFoundContent={
                  isLoadingMenuScrapingTemplates ? <Spin size="small" /> : null
                }
                dropdownStyle={{ minWidth: 'fit-content' }}
                optionLabelProp="label"
                optionFilterProp="label"
                {...props}
              >
                {map(options, ({ label, value }) => (
                  <Select.Option key={value} value={value} label={label}>
                    <Row type="flex" justify="space-between">
                      <Typography.Text>{label}</Typography.Text>
                      <Link
                        to={`/menu-scraping-templates/templates/${value}`}
                        target="_blank"
                      >
                        <Button size="small" type="link">
                          <Icon type="link" />
                        </Button>
                      </Link>
                    </Row>
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        {selectedValue?.id && (
          <Col span={2}>
            <Panel margin="no xxSmall">
              <Link
                to={`/menu-scraping-templates/templates/${selectedValue.id}`}
                target="_blank"
              >
                <Button size="small" type="circle">
                  <Icon type="link" />
                </Button>
              </Link>
            </Panel>
          </Col>
        )}
      </Row>
    </Form.Item>
  )
}

MenuScrapingTemplatesDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default MenuScrapingTemplatesDropdown
