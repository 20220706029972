const THEME = {
  colors: {
    red: '#ff0000',
    lightRed: '#f8e3e3',
    greyRed: '#8c5a51',
    blue: '#0000FF',
    lightBlue: '#aeddf6',
    darkBlue: '#74caf9',
    greyBlue: '#556b87',
    green: '#45d16a',
    lightGreen: '#bde7a6',
    lime: '#90ee90',
    yellow: '#f5bd1f',
    lightYellow: '#f9e7a1',
    orange: '#f99245',
    grey: '#b6b0b0',
    darkGrey: '#656262',
    white: '#ffffff',
    black: '#000000',
  },
}

export default THEME
