import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'antd'
import { includes } from 'lodash-es'

import { Panel } from '../../../../core/components/styled'
import { getQueryParams } from '../../../../core/utils/services/queryParams'

import {
  SearchBrandFilter,
  TaskAssignedToFilter,
  TaskAwaitingReasonFilter,
  TaskIsAssignedFilter,
  TaskIsAwaitingFilter,
  TaskIsQualityCheckedFilter,
  TaskQualityCheckedByFilter,
  TaskReopenReasonFilter,
  TaskStatusFilter,
} from '../../../../common/components'
import {
  ADMIN,
  DEFINER,
  DEFINER_JUNIOR_SCRAPER,
  DEFINER_MIDDLE_SCRAPER,
  DEFINER_MIDDLE_SCRAPER_REVIEWER,
  DEFINER_SENIOR_SCRAPER_REVIEWER,
  QUALITY_CHECKER,
  QUALITY_CHECKER_MIDDLE_SCRAPER,
  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
  TEAM_LEADER,
} from '../../../../common/constants'

import { REOPENED } from '../../../constants'

const MenuDefinitionTasksFilters = () => {
  const { search } = useLocation()
  const { isAssigned, isAwaiting, isQualityChecked, statuses } = getQueryParams(
    search,
  )

  const isReopenStatusSelected = useMemo(() => includes(statuses, REOPENED), [
    statuses,
  ])

  return (
    <Panel margin="small no">
      <Row gutter={16}>
        <Col span={10}>
          <SearchBrandFilter />
        </Col>
      </Row>
      <Panel margin="small no">
        <Row gutter={16}>
          <Col span={5}>
            <TaskStatusFilter />
          </Col>
          <Col span={5}>
            <TaskReopenReasonFilter disabled={!isReopenStatusSelected} />
          </Col>
          <Col span={2}>
            <TaskIsAssignedFilter />
          </Col>
          <Col span={3}>
            <TaskAssignedToFilter
              disabled={!isAssigned}
              roles={[
                ADMIN,
                TEAM_LEADER,
                DEFINER,
                DEFINER_JUNIOR_SCRAPER,
                DEFINER_MIDDLE_SCRAPER,
                DEFINER_MIDDLE_SCRAPER_REVIEWER,
                DEFINER_SENIOR_SCRAPER_REVIEWER,
                QUALITY_CHECKER,
                QUALITY_CHECKER_MIDDLE_SCRAPER,
                QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
                QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
              ]}
            />
          </Col>
          <Col span={2}>
            <TaskIsQualityCheckedFilter />
          </Col>
          <Col span={3}>
            <TaskQualityCheckedByFilter
              disabled={!isQualityChecked}
              roles={[
                ADMIN,
                TEAM_LEADER,
                QUALITY_CHECKER,
                QUALITY_CHECKER_MIDDLE_SCRAPER,
                QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
                QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
              ]}
            />
          </Col>
          <Col span={2}>
            <TaskIsAwaitingFilter />
          </Col>
          <Col span={2}>
            <TaskAwaitingReasonFilter disabled={!isAwaiting} />
          </Col>
        </Row>
      </Panel>
    </Panel>
  )
}

export default MenuDefinitionTasksFilters
