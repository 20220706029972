import { filter, findIndex, map } from 'lodash-es'
import moment from 'moment-timezone'

import { MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER } from '../../menus/constants'

const findLatestRerunFlowStep = (steps = []) =>
  steps.reduce((acc, step) => {
    if (
      step.startedDate &&
      (!acc?.startedDate || moment(step.startedDate).isAfter(acc.startedDate))
    ) {
      return step
    }
    return acc
  }, null)

const formatRerunFlowSteps = (steps = [], currentStep) =>
  map(MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER, defaultStepType => {
    const defaultStep = { type: defaultStepType }

    const currentStepOrder = findIndex(
      MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER,
      t => t === currentStep.type,
    )
    const stepOrder = findIndex(
      MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER,
      t => t === defaultStepType.type,
    )

    const filteredSteps = filter(
      steps,
      s =>
        s.type === defaultStep.type &&
        stepOrder <= currentStepOrder &&
        currentStep.startedDate &&
        moment(s.startedDate).isSameOrBefore(currentStep.startedDate),
    )
    return findLatestRerunFlowStep(filteredSteps) || defaultStep
  })

export default formatRerunFlowSteps
