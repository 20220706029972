import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Row, Table, Typography } from 'antd'

import { Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime, SearchBrandFilter } from '../../../common/components'
import { DEFAULT_PAGE, NO, YES } from '../../../common/constants'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'
import { tableOnRowClick } from '../../../common/utils'

import { BRAND_STATUSES, BUSINESS_STATUSES } from '../../constants'
import { BRANDS } from '../../graphql/queries'

import { BrandBusinessStatusFilter, BrandStatusFilter } from './sections'

const BrandsPage = () => {
  useDocumentTitle('Brands')
  const history = useHistory()
  const { search, pathname } = useLocation()

  const {
    brandSearchTerm,
    status,
    businessStatus,
    page = DEFAULT_PAGE,
  } = getQueryParams(search)

  const { data: { brands = {} } = {}, loading: isLoadingBrands } = useQuery(
    BRANDS,
    {
      variables: {
        filters: {
          brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
          status,
          businessStatus,
        },
        page,
      },
      fetchPolicy: 'no-cache',
    },
  )

  const tableColumns = [
    {
      title: 'Brand name',
      dataIndex: 'name',
      width: '200px',
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '120px',
    },
    {
      title: 'Messiness',
      dataIndex: 'messinessScore',
      width: '120px',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '130px',
      render: status => BRAND_STATUSES[status].name,
    },
    {
      title: 'Unsupported business',
      dataIndex: 'hasOnlyUnsupportedBusinessTypes',
      width: '100px',
      render: hasOnlyUnsupportedBusinessTypes =>
        hasOnlyUnsupportedBusinessTypes ? YES : NO,
    },
    {
      title: 'Business status',
      dataIndex: 'businessStatus',
      width: '110px',
      render: businessStatus => BUSINESS_STATUSES[businessStatus]?.name,
    },
    {
      title: 'Has menu',
      dataIndex: 'menuId',
      width: '100px',
      render: menuId => (menuId ? YES : NO),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '250px',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      title: 'Updated',
      dataIndex: 'modifiedDate',
      width: '160px',
      render: date => <LondonDateTime date={date} />,
    },
  ]

  const redirectToBrand = useCallback(
    brand => history.push(getCachedFilterForPage(`/brands/${brand.id}`)),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event => tableOnRowClick(event, row, redirectToBrand),
    }),
    [redirectToBrand],
  )

  const handlePageChange = useCallback(
    page => history.push(createUrl(pathname, search, { page })),
    [history, search, pathname],
  )

  return (
    <>
      <Panel margin="small no">
        <Row gutter={16}>
          <Col span={10}>
            <SearchBrandFilter />
          </Col>
        </Row>

        <Panel margin="small no">
          <Row gutter={16}>
            <Col span={5}>
              <BrandStatusFilter />
            </Col>
            <Col span={5}>
              <BrandBusinessStatusFilter />
            </Col>
          </Row>
        </Panel>
      </Panel>
      <Panel>
        <Typography.Text>
          {isLoadingBrands
            ? 'Loading records...'
            : `${brands.total} matching records`}
        </Typography.Text>
        <Table
          loading={isLoadingBrands}
          rowKey="id"
          columns={tableColumns}
          dataSource={brands.brands}
          pagination={{
            current: page,
            total: brands.total,
            onChange: handlePageChange,
          }}
          onRow={onRow}
        />
      </Panel>
    </>
  )
}

export default BrandsPage
