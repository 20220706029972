import { filter, isNull, map, mapValues, pick } from 'lodash-es'

const fsaLocationProps = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'postCode',
]

const googleLocationDetailProps = ['formattedAddress']

const normalizeCoordinates = coordinates =>
  mapValues(pick(coordinates, ['latitude', 'longitude']), Number)

const createPinDescription = (details, detailProps) =>
  filter(map(pick(details, detailProps))).join(', ')

const FSA_PIN = 'fsa'
const FSA_DISABLED_PIN = 'fsaDisabled'
const GOOGLE_PIN = 'googlePlace'
const GOOGLE_DISALBED_PIN = 'googlePlaceDisabled'

const createPinData = (
  type,
  locationPosition,
  locationId,
  id,
  coordinates,
  title,
  details,
  detailProps,
) => {
  const { longitude, latitude } = normalizeCoordinates(coordinates)

  return {
    type,
    properties: {
      cluster: false,
      index: locationPosition,
      locationId,
      id,
      title,
      description: createPinDescription(details, detailProps),
    },
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
  }
}

const createPinDataFromLocations = (locations, isDisabled) =>
  locations.reduce((pins, location) => {
    const {
      id: locationId,
      position,
      fsaLocationId,
      fsaLocation,
      googleLocationId,
      googleLocation,
    } = location

    if (!isNull(fsaLocation?.coordinates)) {
      const pinType = isDisabled ? FSA_DISABLED_PIN : FSA_PIN

      pins.push(
        createPinData(
          pinType,
          position,
          locationId,
          fsaLocationId,
          fsaLocation.coordinates,
          fsaLocation.name,
          fsaLocation,
          fsaLocationProps,
        ),
      )
    }

    if (!isNull(googleLocationId)) {
      const pinType = isDisabled ? GOOGLE_DISALBED_PIN : GOOGLE_PIN

      pins.push(
        createPinData(
          pinType,
          position,
          locationId,
          googleLocationId,
          googleLocation.coordinates,
          googleLocation.name,
          googleLocation,
          googleLocationDetailProps,
        ),
      )
    }

    return pins
  }, [])

export default createPinDataFromLocations
