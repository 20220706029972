import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Input } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../constants'

const SearchBrandFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { brandSearchTerm: queryParamBrandSearchTerm } = getQueryParams(search)
  const [brandSearchTerm, setBrandSearchTerm] = useState('')

  const handleSearch = useCallback(
    brandSearchTerm =>
      history.push(
        createUrl(pathname, search, {
          brandSearchTerm,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  useEffect(() => setBrandSearchTerm(queryParamBrandSearchTerm), [
    queryParamBrandSearchTerm,
  ])

  return (
    <Input.Search
      placeholder="Search brand"
      value={brandSearchTerm}
      allowClear
      onChange={({ target: { value } }) => setBrandSearchTerm(value)}
      onSearch={handleSearch}
    />
  )
}

export default SearchBrandFilter
