import { mapKeys } from 'lodash-es'

export const MESSY_BRAND = 'MESSY_BRAND'
export const NEED_ASSISTANCE = 'NEED_ASSISTANCE'

export const TASK_AWAITING_REASONS = mapKeys(
  [
    { name: 'Messy brand', value: MESSY_BRAND },
    { name: 'Need assistance', value: NEED_ASSISTANCE },
  ],
  'value',
)
