import { keys, map } from 'lodash-es'

import { TASK_TYPES } from '../../tasks/constants'

const PAGES_FOR_CACHING_FILTERS = {
  ...Object.assign(
    ...map(keys(TASK_TYPES), key => ({
      [key]: `/tasks/${TASK_TYPES[key].key}`,
    })),
  ),
  BRANDS: '/brands',
  MENUS: '/menus',
}

export default PAGES_FOR_CACHING_FILTERS
