export const getCachedFilterForPage = pageIdentifier =>
  localStorage.getItem(pageIdentifier) || pageIdentifier

export const cacheFilterForPage = (pageIdentifier, routeToNavigateTo) =>
  localStorage.setItem(pageIdentifier, routeToNavigateTo)

export const clearFilterForPage = pageIdentifier => {
  // we do this for the brand pages that also have the id in the path
  const keyToRemove = Object.keys(localStorage).find(localStorageKey =>
    localStorageKey.startsWith(pageIdentifier),
  )
  if (keyToRemove) localStorage.removeItem(keyToRemove)
}

export const clearAllFilters = () => localStorage.clear()
