import React, { useMemo } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import io from 'socket.io-client'

import { getEnvironmentVariable } from '../../../core/helpers'

import { getAccessToken } from '../../../account/services'
import { SocketContext } from '../../contexts'

const SocketProvider = ({ children }) => {
  const contextValue = useMemo(() => {
    const sessionId = nanoid()
    const socket = io(getEnvironmentVariable('REACT_APP_LOCKS_API_URL'), {
      auth: {
        accessToken: getAccessToken(),
      },
      query: { sessionId, platform: 'AUTOEAT' },
    })
    return { sessionId, socket }
  }, [])

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  )
}

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SocketProvider
