import { gql } from 'apollo-boost'

const CREATE_MENUS = gql`
  mutation createMenus($data: [CreateMenuInput!]!) {
    createMenus(data: $data) {
      id
      url
    }
  }
`

export default CREATE_MENUS
