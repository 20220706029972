import { useCallback, useState } from 'react'

import http from '../../core/services/http'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../common/helpers'

const useUpdateBrandImages = onComplete => {
  const [isLoading, setIsLoading] = useState(false)

  const upload = useCallback(
    async (id, icon, image, areAssetsActive) => {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', id)
        formData.append('areAssetsActive', areAssetsActive)
        formData.append('icon', icon)
        formData.append('image', image)

        await http.post('/brand/update-images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        onComplete(id)
        showSuccessNotification({
          message: 'Brand updated',
          description: `Brand has been successfully updated`,
        })
      } catch (error) {
        showErrorNotification({
          message: 'Brand update failed',
          description: error.message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [onComplete],
  )

  return [upload, { loading: isLoading }]
}

export default useUpdateBrandImages
