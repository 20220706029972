import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { UnconnectedUsersDropdown } from '../../users/components/pages/sections'
import {
  ADMIN,
  DEFAULT_PAGE,
  DEFINER_JUNIOR_SCRAPER,
  DEFINER_MIDDLE_SCRAPER,
  DEFINER_MIDDLE_SCRAPER_REVIEWER,
  DEFINER_SENIOR_SCRAPER_REVIEWER,
  JUNIOR_SCRAPER,
  MIDDLE_SCRAPER,
  MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_MIDDLE_SCRAPER,
  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
  SENIOR_SCRAPER,
  SENIOR_SCRAPER_REVIEWER,
  TEAM_LEADER,
} from '../constants'

const MenuScrapedByFilter = ({ queryParamName, label }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const menuScrapedById = getQueryParams(search)[queryParamName]

  const handleChange = useCallback(
    menuScrapedById =>
      history.push(
        createUrl(pathname, search, {
          [queryParamName]: menuScrapedById,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, queryParamName],
  )

  return (
    <UnconnectedUsersDropdown
      width="100%"
      label={label}
      value={menuScrapedById}
      onChange={handleChange}
      withRoles={[
        ADMIN,
        TEAM_LEADER,
        JUNIOR_SCRAPER,
        MIDDLE_SCRAPER,
        SENIOR_SCRAPER,
        DEFINER_JUNIOR_SCRAPER,
        DEFINER_MIDDLE_SCRAPER,
        DEFINER_MIDDLE_SCRAPER_REVIEWER,
        DEFINER_SENIOR_SCRAPER_REVIEWER,
        MIDDLE_SCRAPER_REVIEWER,
        SENIOR_SCRAPER_REVIEWER,
        QUALITY_CHECKER_MIDDLE_SCRAPER,
        QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
        QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
      ]}
    />
  )
}

MenuScrapedByFilter.propTypes = {
  queryParamName: PropTypes.string,
  label: PropTypes.string,
}

MenuScrapedByFilter.defaultProps = {
  queryParamName: 'menuScrapedById',
  label: 'Menu scraped by',
}

export default MenuScrapedByFilter
