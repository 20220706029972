import { REFRESH_ACCESS_TOKEN } from '../graphql/mutations'
import {
  createSession,
  decodeToken,
  getRefreshToken,
  getUser,
} from '../services'

const refreshAccessToken = async apolloClient => {
  const refreshToken = getRefreshToken()
  const {
    data: { refreshAccessToken: accessToken },
  } = await apolloClient.mutate({
    mutation: REFRESH_ACCESS_TOKEN,
    variables: { refreshToken },
  })

  const userData = decodeToken(accessToken)
  const { permissions } = getUser()
  const user = { ...userData, permissions }

  createSession(user, accessToken, refreshToken)

  return accessToken
}

export default refreshAccessToken
