import { useHistory, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash-es'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../constants'

const usePaginationFixer = (isLoadingTasks, tasks, entityName) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  if (isLoadingTasks) return
  const { page = DEFAULT_PAGE } = getQueryParams(search)

  if (page !== 1 && tasks.total !== 0 && isEmpty(tasks[entityName])) {
    history.push(createUrl(pathname, search, { page: page - 1 }))
  }
}

export default usePaginationFixer
