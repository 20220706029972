import { gql } from 'apollo-boost'

const MARK_DISH_REVIEW_TASK_MISC_AS_VERIFIED = gql`
  mutation markDishReviewTaskMiscAsVerified($id: ID!, $comment: String) {
    markDishReviewTaskMiscAsVerified(id: $id, comment: $comment) {
      id
      miscTaskVerifiedDate
      miscTaskVerifiedComment
    }
  }
`

export default MARK_DISH_REVIEW_TASK_MISC_AS_VERIFIED
