import * as Yup from 'yup'

const locationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`Please provide the location's name.`)
    .nullable(),
  website: Yup.string()
    .url('Website is not valid')
    .required(`Location's website is required`)
    .nullable(),
})

export default locationSchema
