/* global google */
import React, { useCallback, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Input, Typography } from 'antd'
import { includes, isNil, some } from 'lodash-es'
import PropTypes from 'prop-types'

import { SUPPORTED_GOOGLE_PLACE_TYPES } from '../../common/constants'
import { showErrorNotification } from '../../common/helpers'

import { CREATE_GOOGLE_LOCATION } from '../../brands/graphql/mutations'

import {
  MapSearchBox as MapSearchBoxContainer,
  MapSearchBoxControls,
} from './styled'

const preventDefaultOnEnter = event => {
  if (event.keyCode === 13) event.preventDefault()
}

const MapSearchBox = ({ map, onPlaceSelect, inputValue, setInputValue }) => {
  const inputRef = useRef(null)
  const autoCompleteRef = useRef(null)

  const [
    createGoogleLocation,
    { loading: isCreatingGoogleLocation },
  ] = useMutation(CREATE_GOOGLE_LOCATION, {
    onCompleted({ createGoogleLocation }) {
      setInputValue(createGoogleLocation.name)
      onPlaceSelect(createGoogleLocation)
    },
  })

  const onPlaceChanged = useCallback(async () => {
    const place = autoCompleteRef.current.getPlace()

    if (isNil(place.types)) return

    const isPlaceSupported = some(place.types, googlePlaceTypes =>
      includes(SUPPORTED_GOOGLE_PLACE_TYPES, googlePlaceTypes),
    )

    if (isPlaceSupported) {
      // eslint-disable-next-line consistent-return
      return createGoogleLocation({ variables: { placeId: place.place_id } })
    }

    showErrorNotification({
      message: 'Google place types is not supported.',
      description: `The selected Google place has the following types: ${place.types.toString()}, which are not supported`,
    })
  }, [createGoogleLocation])

  const cleanupAutocomplete = useCallback(() => {
    inputRef.current.input.removeEventListener('keydown', preventDefaultOnEnter)
  }, [])

  const initializeAutocomplete = useCallback(() => {
    const { Autocomplete } = google.maps.places
    autoCompleteRef.current = new Autocomplete(inputRef.current.input)
    autoCompleteRef.current.setFields(['place_id', 'type'])
    autoCompleteRef.current.bindTo('bounds', map)
    autoCompleteRef.current.setOptions({ strictBounds: true })
    autoCompleteRef.current.addListener('place_changed', onPlaceChanged)
    inputRef.current.input.addEventListener('keydown', preventDefaultOnEnter)
  }, [map, onPlaceChanged])

  useEffect(() => {
    if (!autoCompleteRef.current) initializeAutocomplete()
    return cleanupAutocomplete
  }, [initializeAutocomplete, cleanupAutocomplete])

  const onInputChange = ({ target: { value } }) => setInputValue(value)

  return (
    <MapSearchBoxContainer>
      <MapSearchBoxControls>
        <Input
          disabled={isCreatingGoogleLocation}
          placeholder="Find a place"
          ref={inputRef}
          value={inputValue}
          onChange={onInputChange}
        />
      </MapSearchBoxControls>

      <Typography.Text>
        Search results are limited to the map area.
      </Typography.Text>
    </MapSearchBoxContainer>
  )
}

MapSearchBox.propTypes = {
  map: PropTypes.object.isRequired,
  onPlaceSelect: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
}

export default MapSearchBox
