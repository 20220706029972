import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Table, Typography } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import {
  BRAND_GATEWAY_TASKS_PERMISSIONS,
  DEFAULT_PAGE,
  NO,
  YES,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useLockedEntities, usePaginationFixer } from '../../../common/hooks'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getTableSortingOrder, tableOnRowClick } from '../../../common/utils'

import { BRAND_GATEWAY_TASKS_SORTING_CRITERIA } from '../../../menus/constants'
import {
  ASSIGN_BRAND_GATEWAY_TASK,
  UNASSIGN_BRAND_GATEWAY_TASK,
} from '../../graphql/mutations'
import { BRAND_GATEWAY_TASKS } from '../../graphql/queries'

import { TaskTableAssignedColumn, TaskTableBrandColumn } from './sections'

const BrandGatewayTasksPage = () => {
  useDocumentTitle('Tasks - Brand Gateway')
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { getUserLockingEntity } = useLockedEntities()

  const {
    brandSearchTerm,
    statuses,
    reopenReasons,
    isAssigned,
    assignedToId,
    isQualityChecked,
    qualityCheckedById,
    isAwaiting,
    awaitingReason,
    brandStatus,
    brandRank,
    page = DEFAULT_PAGE,
    pageSize = 10,
    sortingCriterion,
    sortingOrder,
  } = getQueryParams(search)

  const {
    data: { brandGatewayTasks = {} } = {},
    loading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(BRAND_GATEWAY_TASKS, {
    variables: {
      filters: {
        brandSearchTerm: brandSearchTerm && String(brandSearchTerm),
        statuses,
        reopenReasons,
        isAssigned,
        assignedToId,
        isQualityChecked,
        qualityCheckedById,
        isAwaiting,
        awaitingReason,
        brandStatus,
        brandRank,
      },
      page,
      pageSize,
      sortingCriterion,
      sortingOrder,
    },
    fetchPolicy: 'no-cache',
  })

  usePaginationFixer(isLoadingTasks, brandGatewayTasks, 'brandGatewayTasks')

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_BRAND_GATEWAY_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Brand gateway task assigned.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Brand gateway task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_BRAND_GATEWAY_TASK,
    {
      onCompleted() {
        refetchTasks()
        showSuccessNotification({
          message: 'Brand gateway task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand gateway task unassign failed.',
          description: message,
        })
      },
    },
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'brand.id',
      width: '10%',
    },
    {
      title: 'Brand name',
      dataIndex: 'brand',
      width: '25%',
      render: (brand, task) => (
        <TaskTableBrandColumn
          getUserLockingEntity={getUserLockingEntity}
          taskId={task.id}
          brand={brand}
        />
      ),
    },
    {
      title: 'Assigned',
      width: '15%',
      render: task => (
        <TaskTableAssignedColumn
          task={task}
          permissions={BRAND_GATEWAY_TASKS_PERMISSIONS}
          assignTask={assignTask}
          unassignTask={unassignTask}
          refetchTasks={refetchTasks}
        />
      ),
    },
    {
      title: 'Included locations',
      dataIndex: 'brand.includedLocationCount',
      width: '10%',
    },
    {
      title: 'Total locations',
      dataIndex: 'brand.locationCount',
      width: '10%',
    },
    {
      title: 'Messiness score',
      dataIndex: 'brand.messinessScore',
      width: '10%',
    },
    {
      title: 'Unsupported business',
      dataIndex: 'brand.hasOnlyUnsupportedBusinessTypes',
      width: '10%',
      render: hasOnlyUnsupportedBusinessTypes =>
        hasOnlyUnsupportedBusinessTypes ? YES : NO,
    },
    {
      title: 'Updated at',
      dataIndex: 'modifiedDate',
      width: '20%',
      render: date => <LondonDateTime date={date} />,
      sorter: true,
    },
  ]

  const redirectToBrandGatewayTask = useCallback(
    task => history.push(`/tasks/brand-gateway/${task.id}`),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event => tableOnRowClick(event, row, redirectToBrandGatewayTask),
    }),
    [redirectToBrandGatewayTask],
  )

  const getSortingCriterion = (field, order) =>
    field === 'modifiedDate' && order
      ? BRAND_GATEWAY_TASKS_SORTING_CRITERIA.MODIFIED_DATE
      : ''

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { order, field } = sorter

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      history.push(
        createUrl(pathname, search, {
          page: pagination.current,
          sortingCriterion,
          sortingOrder,
        }),
      )
    },
    [history, search, pathname],
  )

  return (
    <>
      <Typography.Text>
        {isLoadingTasks
          ? 'Loading records...'
          : `${brandGatewayTasks.total} matching records`}
      </Typography.Text>
      <Table
        loading={isLoadingTasks || isAssigningTask || isUnassigningTask}
        rowKey="id"
        columns={tableColumns}
        dataSource={brandGatewayTasks.brandGatewayTasks}
        pagination={{
          current: page,
          total: brandGatewayTasks.total,
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
    </>
  )
}

export default BrandGatewayTasksPage
