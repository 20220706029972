import { gql } from 'apollo-boost'

const MOVE_MENU_TO_MENU_SCRAPING_CLUSTER = gql`
  mutation moveMenuToMenuScrapingCluster(
    $menuId: ID!
    $fromMenuScrapingClusterId: ID!
    $toMenuScrapingClusterId: ID!
  ) {
    moveMenuToMenuScrapingCluster(
      menuId: $menuId
      fromMenuScrapingClusterId: $fromMenuScrapingClusterId
      toMenuScrapingClusterId: $toMenuScrapingClusterId
    ) {
      id
    }
  }
`

export default MOVE_MENU_TO_MENU_SCRAPING_CLUSTER
