import { gql } from 'apollo-boost'

const TASK_COUNTS_BY_TYPE = gql`
  query taskCountsByType {
    taskCountsByType {
      brandGatewayTask
      brandDefinitionTask
      menuDefinitionTask
      menuScrapingTask
      dishReviewTask
    }
  }
`

export default TASK_COUNTS_BY_TYPE
