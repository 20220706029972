import { gql } from 'apollo-boost'

const UPDATE_MENU_FORMAT_AND_NOT_SCRAPEABLE_REASON = gql`
  mutation updateMenuFormatAndNotScrapeableReason(
    $id: ID!
    $data: UpdateMenuFormatAndNotScrapeableReasonInput!
  ) {
    updateMenuFormatAndNotScrapeableReasonInput(id: $id, data: $data) {
      id
      url
    }
  }
`

export default UPDATE_MENU_FORMAT_AND_NOT_SCRAPEABLE_REASON
