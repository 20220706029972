import http from '../../core/services/http'

export const generateMiscTasksVerificationReport = ({
  userIds,
  startDate,
  endDate,
  taskTypes,
}) =>
  http.get(
    `/reports/misc-tasks-verification?userIds=${userIds}&startDate=${startDate}&endDate=${endDate}&taskTypes=${taskTypes}`,
    { responseType: 'blob' },
  )
