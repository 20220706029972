import React from 'react'
import PropTypes from 'prop-types'

const VisibilityContainer = ({ isHidden, children }) => (
  <div className={isHidden ? 'is-hidden' : ''}>{children}</div>
)

VisibilityContainer.propTypes = {
  isHidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

VisibilityContainer.defaultProps = {
  isHidden: false,
}

export default VisibilityContainer
