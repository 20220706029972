import { map } from 'lodash-es'

const transformTreeToArray = tree => {
  const array = []

  const getChildrenForNode = item => {
    const { children, ...itemData } = item
    array.push(itemData)

    if (item.children) {
      map(item.children, child => getChildrenForNode(child))
    }
  }

  getChildrenForNode(tree)

  return array
}

export default transformTreeToArray
