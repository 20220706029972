import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Form, Icon, Row, Select, Spin, Typography } from 'antd'
import { debounce, get, join, map, uniq } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { MASTER } from '../../../constants'
import { BRAND_OPTIONS } from '../../../graphql/queries'

const BrandsDropdown = ({ name, label, includeOnlyMasterBrands }) => {
  const [brandSearchTerm, setBrandSearchTerm] = useState()

  const { control, errors } = useFormContext()
  const formErrors = get(errors, name)

  const messages = formErrors
    ? join(uniq(map(formErrors, 'message')), '. ')
    : undefined

  const getSelectedValue = useCallback(
    value =>
      value?.id
        ? { key: value.id, label: `${value.name} - ${value.id}` }
        : { key: '', label: '' },
    [],
  )

  const filters = useMemo(
    () =>
      includeOnlyMasterBrands
        ? {
            rank: MASTER,
            brandSearchTerm,
          }
        : {
            brandSearchTerm,
          },
    [includeOnlyMasterBrands, brandSearchTerm],
  )

  const { data: { brands } = {}, loading: isLoadingMasterBrands } = useQuery(
    BRAND_OPTIONS,
    {
      variables: { filters },
      skip: !brandSearchTerm,
      fetchPolicy: 'no-cache',
    },
  )

  const options = map(brands?.brands, ({ name, id, brandGatewayTask }) => ({
    name,
    value: id,
    brandGatewayTaskId: brandGatewayTask.id,
  }))

  return (
    <Form.Item
      label={label}
      help={messages}
      validateStatus={messages ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        render={({ onChange, value, ...props }) => (
          <Select
            showSearch
            labelInValue
            value={getSelectedValue(value)}
            filterOption={false}
            onSearch={debounce(value => setBrandSearchTerm(value), 1000)}
            onChange={({ key, label }) => onChange({ id: key, name: label })}
            notFoundContent={
              isLoadingMasterBrands ? <Spin size="small" /> : null
            }
            dropdownStyle={{ minWidth: 'fit-content' }}
            optionLabelProp="label"
            {...props}
          >
            {map(options, ({ name, value, brandGatewayTaskId }) => (
              <Select.Option
                key={value}
                value={value}
                label={`${name} - ${value}`}
              >
                <Row type="flex" justify="space-between">
                  <Typography.Text>{`${name} - ${value}`}</Typography.Text>
                  <Link
                    to={`/tasks/brand-gateway/${brandGatewayTaskId}`}
                    target="_blank"
                  >
                    <Button size="small" type="link">
                      <Icon type="link" />
                    </Button>
                  </Link>
                </Row>
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </Form.Item>
  )
}

BrandsDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  includeOnlyMasterBrands: PropTypes.bool.isRequired,
}

export default BrandsDropdown
