import { forEach, map, reduce, size } from 'lodash-es'

import getScrapingItemsOrderedForGallery from './get-scraped-items-ordered-for-gallery'

const getScreenshotPlaceholder = timestamp => ({
  text: 'no-screenshot',
  timestamp,
})

const getScrapedItemsScreenshots = (scrapedItems, menuId) => {
  const scrapedItemsOrdered = getScrapingItemsOrderedForGallery(
    scrapedItems,
    menuId,
  )

  const allScreenshots = reduce(
    scrapedItemsOrdered,
    (result, item) => {
      const itemScreenshotsCount = size(item.screenshots)

      const itemScreenshots = itemScreenshotsCount
        ? item.screenshots
        : [getScreenshotPlaceholder(item.timestamp)]

      const itemScreenshotsWithHash = map(itemScreenshots, screenshot => ({
        ...screenshot,
        scrapedItemHash: item.id,
        scrapedItemScreenshotsCount: itemScreenshotsCount,
      }))

      return [...result, ...itemScreenshotsWithHash]
    },
    [],
  )

  return forEach(allScreenshots, (screenshot, index) => {
    if (index > 0) {
      if (
        allScreenshots[index].scrapedItemHash ===
        allScreenshots[index - 1].scrapedItemHash
      ) {
        allScreenshots[index] = {
          ...screenshot,
          screenshotNumber: allScreenshots[index - 1].screenshotNumber + 1,
        }
      } else {
        allScreenshots[index] = {
          ...screenshot,
          screenshotNumber: 1,
        }
      }
    } else {
      allScreenshots[index] = {
        ...screenshot,
        screenshotNumber: 1,
      }
    }
  })
}

export default getScrapedItemsScreenshots
