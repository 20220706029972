import { gql } from 'apollo-boost'

const ABORT_MENU_SCRAPING_SESSION = gql`
  mutation abortMenuScrapingSession($id: ID!) {
    abortMenuScrapingSession(id: $id) {
      id
      status
    }
  }
`

export default ABORT_MENU_SCRAPING_SESSION
