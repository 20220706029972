/* eslint-disable func-names */
import isValidDomain from 'is-valid-domain'
import { get, isEmpty } from 'lodash-es'
import * as Yup from 'yup'

import { isValidRegex } from '../utils'

const uniquePropertyTest = function(value, propertyName, message) {
  if (
    this.parent.some(child => {
      const childProperty = get(child, propertyName)
      const valueProperty = get(value, propertyName)

      return (
        child !== value && !!valueProperty && valueProperty === childProperty
      )
    })
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    })
  }

  return true
}

Yup.addMethod(Yup.object, 'uniqueProperty', function(propertyName, message) {
  return this.test('unique', message, function(value) {
    return uniquePropertyTest.call(this, value, propertyName, message)
  })
})

Yup.addMethod(Yup.object, 'uniqueProperties', function(propertyNames) {
  return this.test('unique', '', function(value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message)
        } catch (error) {
          return error
        }
      })
      .filter(error => error instanceof Yup.ValidationError)

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

Yup.addMethod(Yup.string, 'validDomain', function(message) {
  return this.test('validDomain', message, function(value) {
    return isValidDomain(value)
  })
})

Yup.addMethod(Yup.string, 'validateRegex', function(message) {
  return this.test('validateRegex', message, isValidRegex)
})
