import React, { memo, useCallback, useState } from 'react'
import { Button, Select } from 'antd'
import PropTypes from 'prop-types'

import {
  MapPinPopover,
  MapPinPopoverDescription,
  MapPinPopoverTitle,
} from '../../styled'

import MapPin from './map-pin'

const { Option } = Select

const SearchedMapPin = ({
  title,
  description,
  locations,
  onLocationSelect,
}) => {
  const [locationId, setLocationId] = useState()

  const selectLocation = useCallback(() => {
    onLocationSelect(locationId)
  }, [locationId, onLocationSelect])

  return (
    <MapPin type="searched">
      <MapPinPopover>
        <MapPinPopoverTitle>{title}</MapPinPopoverTitle>
        <MapPinPopoverDescription>{description}</MapPinPopoverDescription>

        <Select onChange={setLocationId}>
          {locations.map(({ id, name, position }) => (
            <Option key={id} value={id}>
              {position} {name}
            </Option>
          ))}
        </Select>

        <Button
          block
          type="primary"
          size="small"
          className="u-marginTopSmall"
          onClick={selectLocation}
        >
          Select
        </Button>
      </MapPinPopover>
    </MapPin>
  )
}

SearchedMapPin.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
}

export default memo(SearchedMapPin)
