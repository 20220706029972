import React from 'react'
import PropTypes from 'prop-types'

const GoogleMapSearchByAddress = ({ location }) =>
  location ? (
    <a
      href={`https://www.google.com/maps/search/${location.formattedAddress}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {location.formattedAddress}
    </a>
  ) : null

GoogleMapSearchByAddress.propTypes = {
  location: PropTypes.shape({
    formattedAddress: PropTypes.string.isRequired,
  }).isRequired,
}

export default GoogleMapSearchByAddress
