import { mapKeys } from 'lodash-es'

export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const ASSIGN = 'ASSIGN'
export const UNASSIGN = 'UNASSIGN'
export const START = 'START'
export const MARK_AS_AWAITING = 'MARK_AS_AWAITING'
export const UNMARK_AS_AWAITING = 'UNMARK_AS_AWAITING'
export const MARK_AS_QUALITY_CHECKED = 'MARK_AS_QUALITY_CHECKED'
export const UNMARK_AS_QUALITY_CHECKED = 'UNMARK_AS_QUALITY_CHECKED'
export const SAVE = 'SAVE'
export const RESOLVE = 'RESOLVE'
export const REOPEN = 'REOPEN'
export const MANUALLY_ATTACH_TO_CLUSTER = 'MANUALLY_ATTACH_TO_CLUSTER'
export const MANUALLY_UNATTACH_FROM_CLUSTER = 'MANUALLY_UNATTACH_FROM_CLUSTER'
export const AUTOMATICALLY_UNATTACH_FROM_CLUSTER =
  'AUTOMATICALLY_UNATTACH_FROM_CLUSTER'
export const MARK_AS_MASTER = 'MARK_AS_MASTER'
export const UNMARK_AS_MASTER = 'UNMARK_AS_MASTER'
export const MARK_AS_SLAVE = 'MARK_AS_SLAVE'
export const UNMARK_AS_SLAVE = 'UNMARK_AS_SLAVE'
export const ATTACH_TO_MASTER = 'ATTACH_TO_MASTER'
export const UNATTACH_FROM_MASTER = 'UNATTACH_FROM_MASTER'
export const SCRAPE = 'SCRAPE'
export const SCRAPE_ERROR = 'SCRAPE_ERROR'
export const MARK_AS_VERIFIED = 'MARK_AS_VERIFIED'
export const UNMARK_AS_VERIFIED = 'UNMARK_AS_VERIFIED'
export const ADD_SCRAPING_ISSUE_COMMENT = 'ADD_SCRAPING_ISSUE_COMMENT'
export const REMOVE_SCRAPING_ISSUE_COMMENT = 'REMOVE_SCRAPING_ISSUE_COMMENT'

export const EVENT_TYPES = mapKeys(
  [
    { name: 'Create', value: CREATE },
    { name: 'Update', value: UPDATE },
    { name: 'Delete', value: DELETE },
    { name: 'Assign', value: ASSIGN },
    { name: 'Unassign', value: UNASSIGN },
    { name: 'Start', value: START },
    { name: 'Mark as awaiting', value: MARK_AS_AWAITING },
    { name: 'Unmark as awaiting', value: UNMARK_AS_AWAITING },
    { name: 'Mark as quality checked', value: MARK_AS_QUALITY_CHECKED },
    { name: 'Unmark as quality checked', value: UNMARK_AS_QUALITY_CHECKED },
    { name: 'Save', value: SAVE },
    { name: 'Resolve', value: RESOLVE },
    { name: 'Reopen', value: REOPEN },
    { name: 'Manually attach to cluster', value: MANUALLY_ATTACH_TO_CLUSTER },
    {
      name: 'Manually unattach from cluster',
      value: MANUALLY_UNATTACH_FROM_CLUSTER,
    },
    {
      name: 'Automatically unattach from cluster',
      value: AUTOMATICALLY_UNATTACH_FROM_CLUSTER,
    },
    { name: 'Mark as master', value: MARK_AS_MASTER },
    { name: 'Unmark as masater', value: UNMARK_AS_MASTER },
    { name: 'Mark as slave', value: MARK_AS_SLAVE },
    { name: 'Unmark as slave', value: UNMARK_AS_SLAVE },
    { name: 'Attach to master', value: ATTACH_TO_MASTER },
    { name: 'Unattach from master', value: UNATTACH_FROM_MASTER },
    { name: 'Scrape', value: SCRAPE },
    { name: 'Scrape Error', value: SCRAPE_ERROR },
    { name: 'Mark as verified', value: MARK_AS_VERIFIED },
    { name: 'Unmark as verified', value: UNMARK_AS_VERIFIED },
    { name: 'Add scraping issue comment', value: ADD_SCRAPING_ISSUE_COMMENT },
    {
      name: 'Remove scraping issue comment',
      value: REMOVE_SCRAPING_ISSUE_COMMENT,
    },
  ],
  'value',
)
