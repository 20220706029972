import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Modal, Row } from 'antd'
import PropTypes from 'prop-types'

import { TextArea } from '../../../../core/components'
import { Button } from '../../../../core/components/styled'

import { brandExclusionCommentSchema } from '../../forms/schemas'

const BrandExclusionCommentModal = ({
  exclusionComment,
  isModalOpen,
  onCancel,
  onSave,
}) => {
  const formMethods = useForm({
    defaultValues: { exclusionComment },
    resolver: yupResolver(brandExclusionCommentSchema),
  })

  return (
    <Modal
      title="Exclusion reason comment"
      visible={isModalOpen}
      onCancel={onCancel}
      footer={null}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSave)}>
          <Row>
            <TextArea
              name="exclusionComment"
              label="Exclusion reason comment"
            />
          </Row>
          <Row type="flex" justify="end">
            <Button onClick={onCancel} margin="small small no no">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" margin="small no no no">
              Save
            </Button>
          </Row>
        </form>
      </FormProvider>
    </Modal>
  )
}

BrandExclusionCommentModal.propTypes = {
  exclusionComment: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

BrandExclusionCommentModal.defaultProps = {
  exclusionComment: null,
}

export default BrandExclusionCommentModal
