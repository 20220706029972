import { notification } from 'antd'

const showSuccessNotification = ({ message, description }) =>
  notification.open({
    type: 'success',
    placement: 'bottomRight',
    message,
    description,
  })

export default showSuccessNotification
