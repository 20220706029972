import { gql } from 'apollo-boost'

const MENU_SCRAPING_JOB = gql`
  query menuScrapingJob($id: ID!) {
    menuScrapingJob(id: $id) {
      id
      createdDate
      existingMenuTitleCount
      scrapedMenuTitleCount
      createdMenuTitleCount
      significantlyUpdatedMenuTitleCount
      updatedMenuTitleCount
      deletedMenuTitleCount
      restoredMenuTitleCount
      createdMenuTitles
      updatedMenuTitles
      deletedMenuTitles
      restoredMenuTitles
      existingDishCount
      scrapedDishCount
      createdDishCount
      significantlyUpdatedDishCount
      updatedDishCount
      deletedDishCount
      restoredDishCount
      createdDishes
      updatedDishes
      deletedDishes
      restoredDishes
      completedDate
      menuScrapingResponse {
        id
        data
      }
    }
  }
`

export default MENU_SCRAPING_JOB
