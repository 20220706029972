import React, { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Icon, Pagination, Row, Table, Typography } from 'antd'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import { HiddenInputs, RadioGroupButtons } from '../../../../core/components'
import { Button } from '../../../../core/components/styled'

import { VisibilityContainer } from '../../../../common/components'

import { BRAND_RELATIONSHIPS } from '../../../../brands/constants'

const BrandGatewayRelatedBrands = ({
  brandCount,
  currentPage,
  onPageChange,
}) => {
  const [isTableHidden, setIsTableHidden] = useState(true)

  const { control } = useFormContext()

  const { fields: relatedBrands } = useFieldArray({
    control,
    name: 'relatedBrands',
  })

  const tableColumns = [
    {
      title: 'Brand name',
      dataIndex: 'name',
      width: '400px',
      render: (brandName, brand) => (
        <Link
          to={`/tasks/brand-gateway/${brand.brandGatewayTask.id}`}
          target="_blank"
        >
          {brandName}
        </Link>
      ),
    },
    {
      title: 'Relationship',
      width: '400px',
      render: (id, similarBrand, index) => (
        <RadioGroupButtons
          name={`relatedBrands.${index}.relationship`}
          options={values(BRAND_RELATIONSHIPS)}
        />
      ),
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '110px',
    },
    {
      width: '0px',
      render: (row, brand, index) => (
        <HiddenInputs
          names={[`relatedBrands.${index}.id`, `relatedBrands.${index}.name`]}
        />
      ),
    },
  ]

  return (
    <>
      <Typography.Title level={4}>
        {`Related brands (${brandCount})`}
        <Button
          margin="no small"
          size="small"
          shape="circle"
          onClick={() => setIsTableHidden(prevValue => !prevValue)}
        >
          <Icon type={isTableHidden ? 'down' : 'up'} />
        </Button>
      </Typography.Title>
      <VisibilityContainer isHidden={isTableHidden}>
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={relatedBrands}
          pagination={false}
        />
        <Row type="flex" justify="end">
          <Pagination
            size="small"
            current={currentPage}
            style={{ marginTop: '15px' }}
            defaultPageSize={5}
            total={brandCount}
            onChange={onPageChange}
            hideOnSinglePage
          />
        </Row>
      </VisibilityContainer>
    </>
  )
}

BrandGatewayRelatedBrands.propTypes = {
  brandCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default BrandGatewayRelatedBrands
