import { isEmpty } from 'lodash-es'

import { getTableSortingOrder } from '../../common/utils'

import { SIMILAR_BRANDS_SORTING_CRITERIA } from '../../brands/constants'

const getSortingCriterion = ({ field, order }) =>
  field === 'id' && order ? SIMILAR_BRANDS_SORTING_CRITERIA.BRAND_ID : undefined

const getSimilarBrandGatewayTableSortingData = (sorter, defaultOrder) => {
  const sortingCriterion = getSortingCriterion(sorter)

  if (isEmpty(sorter)) {
    return {
      criterion: sortingCriterion,
      order: defaultOrder,
    }
  }

  const order = getTableSortingOrder(sorter.order)

  return {
    criterion: sortingCriterion,
    order,
  }
}

export default getSimilarBrandGatewayTableSortingData
