import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Row } from 'antd'
import { values } from 'lodash-es'
import PropTypes from 'prop-types'

import { HiddenInputs, RadioGroup, TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import {
  ADMIN,
  DEFINER_JUNIOR_SCRAPER,
  DEFINER_MIDDLE_SCRAPER,
  DEFINER_MIDDLE_SCRAPER_REVIEWER,
  DEFINER_SENIOR_SCRAPER_REVIEWER,
  JUNIOR_SCRAPER,
  MIDDLE_SCRAPER,
  MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_MIDDLE_SCRAPER,
  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
  SENIOR_SCRAPER,
  SENIOR_SCRAPER_REVIEWER,
  TEAM_LEADER,
} from '../../../common/constants'

import {
  REJECTED_RESCRAPE,
  RESCRAPING_MENU_STATUSES,
} from '../../../menus/constants/menu-statuses'
import { UsersDropdown } from '../../../users/components/pages/sections'

import { rejectMenuFormInitialValues } from './initial-values'
import { rejectMenuSchema } from './schemas'

const RejectMenuForm = ({
  taskId,
  menuRescrapingComment,
  closeModal,
  isRejectingMenu,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: rejectMenuFormInitialValues(taskId, menuRescrapingComment),
    resolver: yupResolver(rejectMenuSchema),
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row>
          <UsersDropdown
            allowClear
            name="assignedToUserId"
            label="Assign scraping task to"
            showSearch
            withRoles={[
              ADMIN,
              TEAM_LEADER,
              DEFINER_JUNIOR_SCRAPER,
              DEFINER_MIDDLE_SCRAPER,
              DEFINER_MIDDLE_SCRAPER_REVIEWER,
              DEFINER_SENIOR_SCRAPER_REVIEWER,
              JUNIOR_SCRAPER,
              MIDDLE_SCRAPER,
              MIDDLE_SCRAPER_REVIEWER,
              SENIOR_SCRAPER,
              SENIOR_SCRAPER_REVIEWER,
              QUALITY_CHECKER_MIDDLE_SCRAPER,
              QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
              QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
            ]}
          />
        </Row>
        <Row style={{ margin: '1rem 0' }}>
          <RadioGroup
            name="rejectMenuStatus"
            defaultValue={REJECTED_RESCRAPE}
            options={values(RESCRAPING_MENU_STATUSES)}
          />
        </Row>
        <Row>
          <TextArea name="rescrapingComment" label="Rescraping comment" />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="small small no no">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isRejectingMenu}
            margin="small no no no"
          >
            Save
          </Button>
        </Row>

        <HiddenInputs names={['id']} />
      </form>
    </FormProvider>
  )
}

RejectMenuForm.propTypes = {
  taskId: PropTypes.string,
  menuRescrapingComment: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  isRejectingMenu: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

RejectMenuForm.defaultProps = {
  taskId: undefined,
  menuRescrapingComment: undefined,
}

export default RejectMenuForm
