import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Input as AntInput } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

const Input = ({ name, label, required, ...rest }) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      required={required}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <Controller
        control={control}
        name={name}
        render={props => <AntInput {...props} {...rest} />}
      />
    </Form.Item>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
}

Input.defaultProps = {
  label: '',
  required: false,
}

export default Input
