import { gql } from 'apollo-boost'

const CREATE_USER = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      id
      name
      email
    }
  }
`

export default CREATE_USER
