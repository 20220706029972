import { Breadcrumb } from 'antd'
import styled from 'styled-components'

const BreadcrumbItem = styled(Breadcrumb.Item)`
  font-size: 14px;

  a {
    color: ${() => '#1890ff !important'};
  }
`

export default BreadcrumbItem
