import { gql } from 'apollo-boost'

const MENU_SCRAPING_TEMPLATE_CLUSTERS = gql`
  query menuScrapingTemplateClusters($id: ID!, $page: Int, $pageSize: Int) {
    menuScrapingTemplateClusters(id: $id, page: $page, pageSize: $pageSize) {
      menuScrapingTemplateClusters {
        id
        menuCount
      }
      total
    }
  }
`

export default MENU_SCRAPING_TEMPLATE_CLUSTERS
