import { MENU_ITEM_STATUSES } from '../constants'

const getTableRowStyleForMenuItem = ({ status }) => {
  switch (status) {
    case MENU_ITEM_STATUSES.CREATED:
      return 'createdRow'
    case MENU_ITEM_STATUSES.UPDATED:
      return 'updatedRow'
    case MENU_ITEM_STATUSES.UPDATED_SIGNIFICANTLY:
      return 'updatedSignifcantlyRow'
    case MENU_ITEM_STATUSES.DELETED:
      return 'removedRow'
    case MENU_ITEM_STATUSES.RESTORED:
      return 'restoredRow'
    default:
      return undefined
  }
}

export default getTableRowStyleForMenuItem
