import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Checkbox, Table } from 'antd'
import { map } from 'lodash-es'

import { Button } from '../../../core/components/styled'

import { LondonDateTime } from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { USERS_PERMISSIONS } from '../../../common/constants'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { PermissionGuard } from '../../../account/components/routes'
import { USERS } from '../../graphql/queries'

import { UsersTableActionColumn } from './sections'

const { ROLES } = require('../../../common/constants')

const UsersPage = () => {
  useDocumentTitle('Users')

  const {
    data: { users } = {},
    loading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery(USERS, { fetchPolicy: 'no-cache' })

  const history = useHistory()

  const tableColumns = useMemo(
    () => [
      {
        title: 'User ID',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        render: roles => map(roles, ({ name }) => ROLES[name].name).join(', '),
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Latest login',
        dataIndex: 'loginDate',
        render: date => <LondonDateTime date={date} />,
      },
      {
        title: 'Enabled',
        dataIndex: 'isActive',
        render: isActive => <Checkbox checked={isActive} disabled />,
      },
      {
        align: 'right',
        render: user => (
          <UsersTableActionColumn user={user} refetchUsers={refetchUsers} />
        ),
      },
    ],
    [refetchUsers],
  )

  return (
    <>
      <PageHeader sticky>
        <PageTitle>Users</PageTitle>
        <PageActions>
          <PermissionGuard permissions={USERS_PERMISSIONS.CREATE}>
            <Button onClick={() => history.push('/users/create')}>
              Add user
            </Button>
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Table
        loading={isLoadingUsers}
        rowKey="id"
        columns={tableColumns}
        dataSource={users}
        pagination={false}
      />
    </>
  )
}

export default UsersPage
