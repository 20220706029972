import { gql } from 'apollo-boost'

const CREATE_MENU_SCRAPING_TEMPLATE = gql`
  mutation createMenuScrapingTemplate($data: CreateMenuScrapingTemplateInput!) {
    createMenuScrapingTemplate(data: $data) {
      id
      name
      scrapingInstructions
    }
  }
`

export default CREATE_MENU_SCRAPING_TEMPLATE
