import { gql } from 'apollo-boost'

const MENU_PROBING_JOBS = gql`
  query menuProbingJobs(
    $menuScrapingClusterId: ID!
    $page: Int
    $pageSize: Int
  ) {
    menuProbingJobs(
      menuScrapingClusterId: $menuScrapingClusterId
      page: $page
      pageSize: $pageSize
    ) {
      menuProbingJobs {
        id
        menuId
        jobStatus
        scheduleDate
        completedDate
        probingStatus
        addedIds
        removedIds
        addedClasses
        removedClasses
        addedXPathSignatures
        removedXPathSignatures
        updatedHashCodes
        hasChanges
        menuProbingSessionId
        menuProbingResponse {
          data
        }
      }
      total
    }
  }
`

export default MENU_PROBING_JOBS
