import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { BOOLEAN_TYPES, DEFAULT_PAGE } from '../constants'

const TaskIsAssignedFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { isAssigned, assignedToId } = getQueryParams(search)

  const handleChange = useCallback(
    isAssigned =>
      history.push(
        createUrl(pathname, search, {
          isAssigned,
          assignedToId: isAssigned === 'true' ? assignedToId : '',
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, assignedToId],
  )

  return (
    <Form.Item label="Task assigned">
      <Select
        style={{ width: '100%' }}
        value={isAssigned?.toString()}
        onChange={handleChange}
        allowClear
      >
        {map(BOOLEAN_TYPES, ({ name, value }) => (
          <Select.Option key={value} value={value?.toString()}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default TaskIsAssignedFilter
