import { gql } from 'apollo-boost'

const RESTART_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP = gql`
  mutation restartMenuScrapingClusterRerunFlowStep(
    $rerunFlowId: ID!
    $stepType: MenuScrapingClusterRerunFlowStepType!
  ) {
    restartMenuScrapingClusterRerunFlowStep(
      rerunFlowId: $rerunFlowId
      stepType: $stepType
    ) {
      id
    }
  }
`

export default RESTART_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP
