import { gql } from 'apollo-boost'

const MARK_BRAND_DEFINITION_TASK_MISC_AS_QC_VERIFIED = gql`
  mutation markBrandDefinitionTaskMiscAsQCVerified($id: ID!, $comment: String) {
    markBrandDefinitionTaskMiscAsQCVerified(id: $id, comment: $comment) {
      id
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment
    }
  }
`

export default MARK_BRAND_DEFINITION_TASK_MISC_AS_QC_VERIFIED
