import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Typography } from 'antd'
import PropTypes from 'prop-types'

const FormLink = ({ name, label }) => {
  const { control } = useFormContext()
  return (
    <Form.Item label={label}>
      <Controller
        control={control}
        name={name}
        render={({ value }) =>
          value ? (
            <Typography.Paragraph
              ellipsis
              style={{ lineHeight: '1.3rem', marginTop: '8px' }}
            >
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            </Typography.Paragraph>
          ) : null
        }
      />
    </Form.Item>
  )
}

FormLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

FormLink.defaultProps = {
  label: '',
}

export default FormLink
