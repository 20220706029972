import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'
import { map } from 'lodash-es'

import { Panel } from '../../../core/components/styled'

import {
  COMMON_WEBSITE_DOMAIN_PERMISSIONS,
  PLATFORM_PERMISSIONS,
} from '../../../common/constants'
import { useFilterOnPermission } from '../../../common/hooks'
import { useLastPathnameSegment } from '../../../common/hooks/router'
import { getCachedFilterForPage } from '../../../common/services'

const tabs = () => [
  {
    name: 'Common websites',
    key: 'common-websites',
    permissions: [COMMON_WEBSITE_DOMAIN_PERMISSIONS.LIST],
  },
  {
    name: 'Platforms',
    key: 'platforms',
    permissions: [PLATFORM_PERMISSIONS.LIST],
  },
]

const SettingsMainPage = () => {
  const currentTab = useLastPathnameSegment()
  const history = useHistory()

  const handleChangeTab = useCallback(
    tabKey => history.push(getCachedFilterForPage(`/settings/${tabKey}`)),
    [history],
  )

  const tabsOnPermissions = useFilterOnPermission(tabs())

  return (
    <Panel margin="small no">
      <Tabs type="card" activeKey={currentTab} onChange={handleChangeTab}>
        {map(tabsOnPermissions, tab => (
          <Tabs.TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>
    </Panel>
  )
}

export default SettingsMainPage
