import { gql } from 'apollo-boost'

const CLONE_LOCATION = gql`
  mutation cloneLocation($data: CloneLocationInput!) {
    cloneLocation(data: $data) {
      id
    }
  }
`

export default CLONE_LOCATION
