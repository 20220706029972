import { isNaN, map } from 'lodash-es'
import * as Yup from 'yup'

import { MENU_STATUSES } from '../../../../menus/constants'
import { SCRAPING_STATUSES } from '../../../constants'

import { validateMenuStatusesOrScrapingStatuses } from './validators'

const startMenuScrapingSessionForClusterSchema = Yup.object()
  .shape({
    useMinimumTimeSlotCount: Yup.boolean(),
    sessionTimeSlotCount: Yup.mixed().when('useMinimumTimeSlotCount', {
      is: true,
      then: Yup.number()
        .notRequired()
        .oneOf(
          [undefined],
          'Session time slot count must be empty when Minimum Time Slot Count is selected',
        ),
      otherwise: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .required('Session time slot count is required')
        .integer('Session Time Slot Count must be an integer'),
    }),
    ignoreWorkingHours: Yup.boolean(),
    menuStatuses: Yup.array().of(
      Yup.string().oneOf(map(MENU_STATUSES, s => s.value)),
    ),
    scrapingStatuses: Yup.array().of(
      Yup.string().oneOf(map(SCRAPING_STATUSES, s => s.value)),
    ),
  })
  .test(
    'validateMenuStatusesOrScrapingStatuses',
    'At least one of menuStatuses or scrapingStatuses is required',
    validateMenuStatusesOrScrapingStatuses,
  )

export default startMenuScrapingSessionForClusterSchema
