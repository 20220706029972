import React, { useCallback } from 'react'
import { Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'
import { map } from 'lodash-es'

import { Panel } from '../../../core/components/styled'

import { MENU_SCRAPING_TEMPLATE_PERMISSIONS } from '../../../common/constants'
import { useFilterOnPermission } from '../../../common/hooks'
import { useLastPathnameSegment } from '../../../common/hooks/router'
import { getCachedFilterForPage } from '../../../common/services'

import { PermissionRoute } from '../../../account/components/routes'

import { MenuScrapingClustersPage, MenuScrapingTemplatesPage } from '.'

const tabs = () => [
  {
    name: 'Templates',
    key: 'templates',
    permissions: [MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN],
  },
  {
    name: 'Clusters',
    key: 'clusters',
    permissions: [MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN],
  },
]

const MenuScrapingTemplatesMainPage = () => {
  const currentTab = useLastPathnameSegment()
  const history = useHistory()

  const handleChangeTab = useCallback(
    tabKey =>
      history.push(
        getCachedFilterForPage(`/menu-scraping-templates/${tabKey}`),
      ),
    [history],
  )

  const tabsOnPermissions = useFilterOnPermission(tabs())

  return (
    <Panel margin="small no">
      <Tabs type="card" activeKey={currentTab} onChange={handleChangeTab}>
        {map(tabsOnPermissions, tab => (
          <Tabs.TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>

      <Switch>
        <PermissionRoute
          permissions={[MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN]}
          path="/menu-scraping-templates/templates"
          component={MenuScrapingTemplatesPage}
        />

        <PermissionRoute
          permissions={[MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN]}
          path="/menu-scraping-templates/clusters"
          component={MenuScrapingClustersPage}
        />
      </Switch>
    </Panel>
  )
}

export default MenuScrapingTemplatesMainPage
