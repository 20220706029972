import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { BOOLEAN_TYPES, DEFAULT_PAGE } from '../constants'

const MenuIsAwaitingProbingFilter = ({ queryParamName }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const menuIsAwaitingProbing = getQueryParams(search)[queryParamName]

  const handleChange = useCallback(
    menuIsAwaitingProbing =>
      history.push(
        createUrl(pathname, search, {
          [queryParamName]: menuIsAwaitingProbing,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search, queryParamName],
  )

  return (
    <Form.Item label="Awaiting probing">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={menuIsAwaitingProbing?.toString()}
        onChange={handleChange}
      >
        {map(BOOLEAN_TYPES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

MenuIsAwaitingProbingFilter.propTypes = {
  queryParamName: PropTypes.string,
}

MenuIsAwaitingProbingFilter.defaultProps = {
  queryParamName: 'menuIsAwaitingProbing',
}

export default MenuIsAwaitingProbingFilter
