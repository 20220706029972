import { gql } from 'apollo-boost'

const MENU_SCRAPING_TASK = gql`
  query menuScrapingTask($id: ID!) {
    menuScrapingTask(id: $id) {
      id
      status
      isAwaiting
      isRerunFlow
      awaitingReason
      awaitingReasonComment
      verifiedDate
      isVerifiedInDebugMode
      modifiedDate
      reopenedDate
      reopenReason
      menu {
        id
        url
        scrapingInstructions
        scrapingInstructionsVersionNumber
        notScrapeableReason
        notScrapeableComment
        rejectionReason
        status
        format
        isAwaitingScraping
        isScrapedInDebugMode
        isDeleted
        scrapingResponseId
        menuScrapingClusterId
        menuScrapingTemplateId
        brand {
          id
          name
        }
        locations {
          id
          name
          formattedAddress
        }
        dishReviewTask {
          id
        }
        rejectedBy {
          id
          name
        }
        scrapingSummary {
          menuAnnotations {
            fullMenu
          }
          messages
          status
          scrapingReport {
            fileKey
          }
        }
        modifiedDate
        rejectedDate
        rescrapingComment
        scrapingIssueComment
        isAwaitingProbing
      }
      assignedTo {
        id
        name
      }
      resolvedBy {
        id
        name
      }
      verifiedBy {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      verifiedDate
      isVerifiedInDebugMode
      modifiedDate

      miscTaskVerifiedBy {
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment

      miscTaskQCVerifiedBy {
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
        menuScrapingTask {
          id
          status
        }
        dishReviewTask {
          id
          status
        }
      }
    }
  }
`

export default MENU_SCRAPING_TASK
