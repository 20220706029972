import { gql } from 'apollo-boost'

const SAVE_MENU_SCRAPING_TASK = gql`
  mutation saveMenuScrapingTask(
    $id: ID!
    $menuData: SaveMenuScrapingTaskMenuInput!
  ) {
    saveMenuScrapingTask(id: $id, menuData: $menuData) {
      id
    }
  }
`

export default SAVE_MENU_SCRAPING_TASK
