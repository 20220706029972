import React, { useCallback, useState } from 'react'
import { Drawer, Typography } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button, Panel } from '../../../../core/components/styled'

const LocationAddressDrawerRow = ({ label, value }) => (
  <Typography.Paragraph style={{ fontSize: '16px' }}>
    {`${label}: `}
    {value && (
      <Typography.Text copyable={{ text: value }}>{value}</Typography.Text>
    )}
  </Typography.Paragraph>
)

LocationAddressDrawerRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

LocationAddressDrawerRow.defaultProps = {
  value: undefined,
}

const LocationAddressDrawer = ({ location }) => {
  const [isVisible, setIsVisible] = useState(false)
  const onButtonClick = useCallback(() => setIsVisible(true), [])
  const onClose = useCallback(() => setIsVisible(false), [])

  return (
    <>
      <Button type="primary" onClick={onButtonClick}>
        Show
      </Button>
      <Drawer
        title="Location address"
        placement="right"
        onClose={onClose}
        visible={isVisible}
        width="30%"
      >
        <Panel withBorderBottom>
          <Typography.Title level={4}>
            Health and Safety Address
          </Typography.Title>
          <LocationAddressDrawerRow
            label="Address line 1"
            value={get(location, 'fsaLocation.addressLine1')}
          />
          <LocationAddressDrawerRow
            label="Address line 2"
            value={get(location, 'fsaLocation.addressLine2')}
          />
          <LocationAddressDrawerRow
            label="Address line 3"
            value={get(location, 'fsaLocation.addressLine3')}
          />
          <LocationAddressDrawerRow
            label="Address line 4"
            value={get(location, 'fsaLocation.addressLine4')}
          />
          <LocationAddressDrawerRow
            label="Post code"
            value={get(location, 'fsaLocation.postCode')}
          />
        </Panel>
        <Panel margin="small no">
          <Typography.Title level={4}>Google Address</Typography.Title>
          <LocationAddressDrawerRow
            label="Street number"
            value={get(location, 'googleLocation.streetNumber')}
          />
          <LocationAddressDrawerRow
            label="Street"
            value={get(location, 'googleLocation.street')}
          />
          <LocationAddressDrawerRow
            label="City"
            value={get(location, 'googleLocation.city')}
          />
          <LocationAddressDrawerRow
            label="Post code"
            value={get(location, 'googleLocation.postCode')}
          />
        </Panel>
      </Drawer>
    </>
  )
}

LocationAddressDrawer.propTypes = {
  location: PropTypes.object,
}
LocationAddressDrawer.defaultProps = {
  location: undefined,
}

export default LocationAddressDrawer
