import PropTypes from 'prop-types'

import { useUserHasPermissions } from '../../../common/hooks'

const PermissionGuard = ({ permissions, children }) => {
  const hasPermission = useUserHasPermissions(permissions)
  return hasPermission ? children : null
}

PermissionGuard.propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  children: PropTypes.node.isRequired,
}

export default PermissionGuard
