import { gql } from 'apollo-boost'

const UPDATE_PLATFORM = gql`
  mutation updatePlatform($id: ID!, $data: PlatformInput!) {
    updatePlatform(id: $id, data: $data) {
      id
      name
      urlIdentificationRegex
      urlNormalizationRegex
    }
  }
`

export default UPDATE_PLATFORM
