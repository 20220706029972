import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Icon, Menu } from 'antd'
import { map } from 'lodash-es'

import { AuthContext } from '../../account/contexts'
import {
  BRAND_DEFINITION_TASKS_PERMISSIONS,
  BRAND_GATEWAY_TASKS_PERMISSIONS,
  BRANDS_PERMISSIONS,
  COMMON_WEBSITE_DOMAIN_PERMISSIONS,
  DISH_REVIEW_TASKS_PERMISSIONS,
  MENU_DEFINITION_TASKS_PERMISSIONS,
  MENU_SCRAPING_RERUNS_PERMISSIONS,
  MENU_SCRAPING_TASKS_PERMISSIONS,
  MENU_SCRAPING_TEMPLATE_PERMISSIONS,
  MENUS_PERMISSIONS,
  REPORTS_PERMISSIONS,
  USERS_PERMISSIONS,
} from '../constants'
import { useFilterOnPermission } from '../hooks'
import { useFirstPathnameSegment } from '../hooks/router'
import { getCachedFilterForPage } from '../services'

import Logo from './logo'
import {
  NavigationBar as NavigationBarContainer,
  NavigationBarGreeting,
} from './styled'

const navigationTabs = [
  {
    name: 'Tasks',
    link: '/tasks/brand-gateway',
    key: 'tasks',
    permissions: [BRAND_GATEWAY_TASKS_PERMISSIONS.LIST],
  },
  {
    name: 'Tasks',
    link: '/tasks/brand-definition',
    key: 'tasks',
    permissions: [BRAND_DEFINITION_TASKS_PERMISSIONS.LIST],
  },
  {
    name: 'Tasks',
    link: '/tasks/menu-definition',
    key: 'tasks',
    permissions: [MENU_DEFINITION_TASKS_PERMISSIONS.LIST],
  },
  {
    name: 'Tasks',
    link: '/tasks/menu-scraping',
    key: 'tasks',
    permissions: [MENU_SCRAPING_TASKS_PERMISSIONS.LIST],
  },
  {
    name: 'Tasks',
    link: '/tasks/dish-review',
    key: 'tasks',
    permissions: [DISH_REVIEW_TASKS_PERMISSIONS.LIST],
  },
  {
    name: 'Brands',
    link: '/brands',
    key: 'brands',
    permissions: [BRANDS_PERMISSIONS.LIST],
  },
  {
    name: 'Menus',
    link: '/menus',
    key: 'menus',
    permissions: [MENUS_PERMISSIONS.LIST],
  },
  {
    name: 'Users',
    link: '/users',
    key: 'users',
    permissions: [USERS_PERMISSIONS.LIST],
  },
  {
    name: 'Reports',
    link: '/reports/user',
    key: 'reports',
    permissions: [REPORTS_PERMISSIONS.GET_FOR_USERS],
  },
  {
    name: 'Menu Scraping Reruns',
    link: '/menu-scraping-reruns',
    key: 'menu-scraping-reruns',
    permissions: [MENU_SCRAPING_RERUNS_PERMISSIONS.LIST],
  },
  {
    name: 'Menu Scraping Templates',
    link: '/menu-scraping-templates/templates',
    key: 'menu-scraping-templates',
    permissions: [MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN],
  },
  {
    name: 'Menu Scraping Templates',
    link: '/menu-scraping-templates/clusters',
    key: 'menu-scraping-templates',
    permissions: [MENU_SCRAPING_TEMPLATE_PERMISSIONS.ADMIN],
  },
  {
    name: 'Settings',
    link: '/settings/common-websites',
    key: 'settings',
    permissions: [COMMON_WEBSITE_DOMAIN_PERMISSIONS.LIST],
  },
]

const renderNavigationTabs = (navigationTabs, history) =>
  map(navigationTabs, ({ key, link, name }) => (
    <Menu.Item
      key={key}
      onClick={() => history.push(getCachedFilterForPage(link))}
    >
      {name}
    </Menu.Item>
  ))

const NavigationBar = () => {
  const selectedKey = useFirstPathnameSegment()
  const { user } = useContext(AuthContext)

  const history = useHistory()
  const navigationTabsOnPermissions = useFilterOnPermission(navigationTabs)

  return (
    <NavigationBarContainer>
      <Menu mode="horizontal" selectedKeys={[selectedKey]}>
        <Logo />
        {renderNavigationTabs(navigationTabsOnPermissions, history)}
      </Menu>
      <NavigationBarGreeting>Hi, {user.name}!</NavigationBarGreeting>
      <Menu mode="horizontal" selectedKeys={[selectedKey]}>
        <Menu.Item key="logout">
          <NavLink to="/account/logout" />
          <Icon type="logout" as={NavLink} />
          Logout
        </Menu.Item>
      </Menu>
    </NavigationBarContainer>
  )
}
export default NavigationBar
