import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Icon, InputNumber, Popover } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

const NumberInput = ({ name, label, infoMessage, ...rest }) => {
  const { control, errors } = useFormContext()
  const { message: errorMessage } = get(errors, name, {})

  return (
    <Form.Item
      label={label}
      help={errorMessage}
      validateStatus={errorMessage ? 'error' : 'success'}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Controller control={control} name={name} as={InputNumber} {...rest} />
        {infoMessage && (
          <div style={{ marginLeft: '10px' }}>
            <Popover content={infoMessage} placement="left">
              <Icon type="info-circle" />
            </Popover>
          </div>
        )}
      </div>
    </Form.Item>
  )
}

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  infoMessage: PropTypes.string,
}

NumberInput.defaultProps = {
  infoMessage: '',
}

export default NumberInput
