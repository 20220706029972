import { gql } from 'apollo-boost'

const ASSIGN_DISH_REVIEW_TASK = gql`
  mutation assignDishReviewTask($id: ID!, $toUserId: ID!) {
    assignDishReviewTask(id: $id, toUserId: $toUserId) {
      id
      modifiedDate
    }
  }
`

export default ASSIGN_DISH_REVIEW_TASK
