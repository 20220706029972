import parseJSON from './parse-json'

const getFingerprintsFile = ({
  ids = [],
  classes = [],
  xPathSignatures = [],
  hashCodes = {},
} = {}) =>
  JSON.stringify(
    {
      ids,
      classes,
      hashCodes:
        typeof hashCodes === 'string' ? parseJSON(hashCodes) : hashCodes,
      xPathSignatures,
    },
    null,
    2,
  )

export default getFingerprintsFile
