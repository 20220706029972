import { gql } from 'apollo-boost'

const REMOVE_MENU_FROM_MENU_SCRAPING_CLUSTER = gql`
  mutation removeMenuFromMenuScrapingCluster($id: ID!, $menuId: ID!) {
    removeMenuFromMenuScrapingCluster(id: $id, menuId: $menuId) {
      id
    }
  }
`

export default REMOVE_MENU_FROM_MENU_SCRAPING_CLUSTER
