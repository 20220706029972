import { gql } from 'apollo-boost'

const UNMARK_DISH_REVIEW_TASK_AS_AWAITING = gql`
  mutation unmarkDishReviewTaskAsAwaiting($id: ID!) {
    unmarkDishReviewTaskAsAwaiting(id: $id) {
      id
      modifiedDate
    }
  }
`

export default UNMARK_DISH_REVIEW_TASK_AS_AWAITING
