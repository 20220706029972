import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Form, Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'

import { EMPTY_VALUE } from '../../../../common/constants'

const AwaitingReasonField = ({ valueFieldName, titleFieldName, label }) => {
  const { watch } = useFormContext()

  const value = watch(valueFieldName)
  const contentValue = watch(titleFieldName)

  return (
    <Form.Item label={label}>
      <Tooltip placement="bottom" title={contentValue} trigger="hover">
        {value ? (
          <Typography.Text strong type="danger">
            {value}
          </Typography.Text>
        ) : (
          <Typography.Text>{EMPTY_VALUE}</Typography.Text>
        )}
      </Tooltip>
    </Form.Item>
  )
}

AwaitingReasonField.propTypes = {
  valueFieldName: PropTypes.string.isRequired,
  titleFieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
}

AwaitingReasonField.defaultProps = {
  label: '',
}

export default AwaitingReasonField
