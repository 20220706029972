import { mapKeys } from 'lodash-es'

export const BROKEN_SCRAPING_INSTRUCTIONS = 'BROKEN_SCRAPING_INSTRUCTIONS'
export const DUPLICATE_CONTENT = 'DUPLICATE_CONTENT'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const NO_DISHES_SCRAPED = 'NO_DISHES_SCRAPED'
export const PARTIAL_SUCCESS = 'PARTIAL_SUCCESS'
export const SCRAPING_TIMEOUT_REACHED = 'SCRAPING_TIMEOUT_REACHED'
export const STATE_RESTORATION_FAILED = 'STATE_RESTORATION_FAILED'
export const SUCCESS = 'SUCCESS'
export const WEBSITE_NOT_FOUND = 'WEBSITE_NOT_FOUND'
export const ERROR = 'ERROR'

export const SCRAPING_STATUSES = mapKeys(
  [
    { name: 'BrokenScrapingInstructions', value: BROKEN_SCRAPING_INSTRUCTIONS },
    { name: 'DuplicateContent', value: DUPLICATE_CONTENT },
    { name: 'InternalServerError', value: INTERNAL_SERVER_ERROR },
    { name: 'NoDishesScraped', value: NO_DISHES_SCRAPED },
    { name: 'PartialSuccess', value: PARTIAL_SUCCESS },
    { name: 'ScrapingTimeoutReached', value: SCRAPING_TIMEOUT_REACHED },
    { name: 'StateRestorationFailed', value: STATE_RESTORATION_FAILED },
    { name: 'Success', value: SUCCESS },
    { name: 'WebsiteNotFound', value: WEBSITE_NOT_FOUND },
    { name: 'Error', value: ERROR },
  ],
  'value',
)
