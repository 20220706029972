import { gql } from 'apollo-boost'

const UPDATE_MENU_SCRAPING_CLUSTER = gql`
  mutation updateMenuScrapingCluster(
    $id: ID!
    $data: UpdateMenuScrapingClusterInput!
  ) {
    updateMenuScrapingCluster(id: $id, data: $data) {
      id
    }
  }
`

export default UPDATE_MENU_SCRAPING_CLUSTER
