import React from 'react'
import PropTypes from 'prop-types'

import { THEME } from '../../core/constants'

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1001,
  },

  message: {
    fontSize: '18px',
    color: THEME.colors.black,
    backgroundColor: THEME.colors.lightRed,
    padding: '12px',
    textAlign: 'center',
  },
}

const ContentWithOverlay = ({ children, message }) => (
  <>
    {children}
    <div style={styles.overlay}>
      <p style={styles.message}>{message}</p>
    </div>
  </>
)

ContentWithOverlay.propTypes = {
  children: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
}

export default ContentWithOverlay
