import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, Form, Icon } from 'antd'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

import { VisibilityContainer } from '../../../../common/components'

import { MISC } from '../../../../brands/constants'

const BrandGatewayTaskBrandExclusionReasonComment = ({ onClick }) => {
  const { errors, watch } = useFormContext()

  const brandExclusionReason = watch('brand.exclusionReason')
  const { message: errorMessage } = get(
    errors,
    'brand.exclusionReasonComment',
    {},
  )

  return (
    <VisibilityContainer isHidden={brandExclusionReason !== MISC}>
      <Form.Item
        label="Exclusion comment"
        help={errorMessage}
        validateStatus={errorMessage ? 'error' : 'success'}
      >
        <Button size="small" shape="circle" onClick={onClick}>
          <Icon type="form" />
        </Button>
      </Form.Item>
    </VisibilityContainer>
  )
}

BrandGatewayTaskBrandExclusionReasonComment.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default BrandGatewayTaskBrandExclusionReasonComment
