import React from 'react'
import { Route, Switch } from 'react-router'

import MenuScrapingRerunsPage from './pages/menu-scraping-reruns-page'

const MenuScrapingRerunsRouter = () => (
  <Switch>
    <Route path="/menu-scraping-reruns" component={MenuScrapingRerunsPage} />
  </Switch>
)

export default MenuScrapingRerunsRouter
