import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

const MenuAnnotationsFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const [menuAnnotations, setMenuAnnotations] = useState('')

  const { fullAnnotationsSearchTerm } = getQueryParams(search)

  const handleSearch = useCallback(
    fullAnnotationsSearchTerm =>
      history.push(
        createUrl(pathname, search, {
          fullAnnotationsSearchTerm,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  useEffect(() => setMenuAnnotations(fullAnnotationsSearchTerm), [
    fullAnnotationsSearchTerm,
  ])

  return (
    <Form.Item label="Annotations">
      <Input.Search
        value={menuAnnotations}
        allowClear
        onChange={({ target: { value } }) => setMenuAnnotations(value)}
        onSearch={handleSearch}
      />
    </Form.Item>
  )
}

export default MenuAnnotationsFilter
