import React from 'react'
import { Link } from 'react-router-dom'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Breadcrumb as StyledBreadcrumb, BreadcrumbItem } from './styled'

export const Breadcrumb = ({ currentTaskType, items }) => {
  const renderItem = (currentTaskType, item) => (
    <BreadcrumbItem key={item.type}>
      {currentTaskType === item.type ? (
        item.name
      ) : (
        <Link to={item.path}>{item.name}</Link>
      )}
    </BreadcrumbItem>
  )

  return (
    <StyledBreadcrumb>
      {map(items, item => renderItem(currentTaskType, item))}
    </StyledBreadcrumb>
  )
}

Breadcrumb.propTypes = {
  currentTaskType: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Breadcrumb
