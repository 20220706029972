import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Empty, Layout, Modal, Row, Typography } from 'antd'
import { isEmpty, map, reduce } from 'lodash-es'
import PropTypes from 'prop-types'

import { Spinner } from '../../../common/components'

import { SCRAPED_ITEM_TYPES } from '../../constants'
import { MENU_SCRAPING_RESPONSE } from '../../graphql/queries'
import { getDishName, getMenuTitleName } from '../../helpers'

import {
  ScrapingDebugGallery,
  ScrapingDebugInformation,
  ScrapingDebugTreeSelect,
} from './sections'

const ScrapingDebugModal = ({ menu, itemHash, isModalOpen, closeModal }) => {
  const [scrapedItems, setScrapedItems] = useState([])
  const [scrapedItemsIndexByHash, setScrapedItemsIndexByHash] = useState(
    new Map(),
  )
  const [currentScrapedItem, setCurrentScrapedItem] = useState(null)
  const [selectedItemHash, setSelectedItemHash] = useState(itemHash)
  const [selectedItemSource, setSelectedItemSource] = useState(null)
  const [isScrapingResponseLoaded, setIsScrapingResponseLoaded] = useState(
    false,
  )

  const { data: { menuScrapingResponse } = {}, loading: isLoading } = useQuery(
    MENU_SCRAPING_RESPONSE,
    {
      variables: {
        id: menu.scrapingResponseId,
      },
      fetchPolicy: 'no-cache',
      skip: !isModalOpen || isScrapingResponseLoaded,
    },
  )

  useEffect(() => {
    if (!menuScrapingResponse) return

    setIsScrapingResponseLoaded(true)

    const { menuTree, scrapingReport, correlationEvent, ...rest } =
      menuScrapingResponse.data || {}

    const menuSpecificData = {
      type: SCRAPED_ITEM_TYPES.MENU,
      id: menu.id,
      hashCode: menu.id,
      ...rest,
    }

    const { menuTitles = [], dishes = [] } = menuTree

    const menuTitlesWithType = map(menuTitles, menuTitle => ({
      ...menuTitle,
      type: SCRAPED_ITEM_TYPES.MENU_TITLE,
      title: getMenuTitleName(menuTitle),
    }))

    const dishesWithType = map(dishes, dish => ({
      ...dish,
      type: SCRAPED_ITEM_TYPES.DISH,
      name: getDishName(dish),
    }))

    const allScrapedItems = [
      menuSpecificData,
      ...menuTitlesWithType,
      ...dishesWithType,
    ]

    setScrapedItems(allScrapedItems)

    const indexByHash = reduce(
      allScrapedItems,
      (result, item, index) => ({
        ...result,
        [item.id]: index,
      }),
      {},
    )

    setScrapedItemsIndexByHash(indexByHash)
  }, [menuScrapingResponse, menu])

  useEffect(() => setSelectedItemHash(itemHash), [itemHash])

  useEffect(() => {
    const scrapedItemIndex = scrapedItemsIndexByHash[selectedItemHash]
    setCurrentScrapedItem(scrapedItems[scrapedItemIndex])
  }, [scrapedItems, scrapedItemsIndexByHash, selectedItemHash])

  const handleOnCancel = useCallback(() => {
    closeModal()
    setSelectedItemSource(null)
  }, [closeModal])

  const handleSelectedHashChange = useCallback(
    (itemHash, itemSource) => {
      setSelectedItemHash(itemHash)
      setSelectedItemSource(itemSource)
    },
    [setSelectedItemHash, setSelectedItemSource],
  )

  const renderedContent = useMemo(
    () =>
      isEmpty(currentScrapedItem) ? (
        <Empty
          imageStyle={{ height: 200 }}
          description={
            <Typography.Text>
              No debug data available for this menu.
            </Typography.Text>
          }
        />
      ) : (
        <Layout>
          <ScrapingDebugInformation scrapedItem={currentScrapedItem} />
          <ScrapingDebugGallery
            menuId={menu.id}
            scrapedItems={scrapedItems}
            selectedItemHash={selectedItemHash}
            selectedItemSource={selectedItemSource}
            handleSelectedHashChange={handleSelectedHashChange}
          />
        </Layout>
      ),
    [
      menu,
      currentScrapedItem,
      handleSelectedHashChange,
      scrapedItems,
      selectedItemHash,
      selectedItemSource,
    ],
  )

  return (
    <Modal
      width="98vw"
      centered
      bodyStyle={{ height: '90vh' }}
      title={
        isEmpty(currentScrapedItem) ? (
          <br />
        ) : (
          <ScrapingDebugTreeSelect
            menuId={menu.id}
            scrapedItems={scrapedItems}
            selectedScrapedItem={currentScrapedItem}
            handleSelectedHashChange={handleSelectedHashChange}
          />
        )
      }
      visible={isModalOpen}
      onCancel={handleOnCancel}
      footer={null}
    >
      {isLoading ? (
        <Row type="flex" justify="center">
          <Spinner />
        </Row>
      ) : (
        renderedContent
      )}
    </Modal>
  )
}

ScrapingDebugModal.propTypes = {
  menu: PropTypes.object.isRequired,
  itemHash: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

ScrapingDebugModal.defaultProps = {
  itemHash: undefined,
}

export default ScrapingDebugModal
