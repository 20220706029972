import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { SET_MENU_EXCLUDE_FROM_CURATION } from '../../graphql/mutations'
import { MENU } from '../../graphql/queries'
import { transformToMenu } from '../../graphql/transformers'
import { MenuForm } from '../forms'

const MenuPage = () => {
  useDocumentTitle('Menu')
  const { menuId } = useParams()

  const {
    data,
    refetch: refetchMenu,
    networkStatus: menuQueryNetworkStatus,
  } = useQuery(MENU, {
    variables: { id: menuId },
    fetchPolicy: 'no-cache',
  })

  const [setMenuExcludeFromCuration, { loading: isUpdatingMenu }] = useMutation(
    SET_MENU_EXCLUDE_FROM_CURATION,
    {
      onCompleted() {
        refetchMenu()
        showSuccessNotification({
          message: 'Menu updated.',
          description: 'Menu has been successfully updated.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu update failed.',
          description: message,
        })
      },
    },
  )

  const onExcludeFromCurationChanged = useCallback(
    checked => {
      setMenuExcludeFromCuration({
        variables: { id: menuId, isExcludedFromCuration: checked },
      })
    },
    [setMenuExcludeFromCuration, menuId],
  )

  const menu = useMemo(() => data && data.menu && transformToMenu(data.menu), [
    data,
  ])

  return menuQueryNetworkStatus === NetworkStatus.loading ? (
    <Spinner />
  ) : (
    <MenuForm
      menu={menu}
      isUpdatingMenu={isUpdatingMenu}
      menuExcludeFromCurationChanged={onExcludeFromCurationChanged}
    />
  )
}

export default MenuPage
