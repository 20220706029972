import React from 'react'

const RerunFlowStepLine = () => (
  <div
    style={{
      width: '100%',
      height: 1,
      backgroundColor: '#d9d9d9',
      position: 'absolute',
      top: '16px',
    }}
  />
)

export default RerunFlowStepLine
