import React, { useEffect } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Icon, Row } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Input } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { additionalMenuUrlsFormInitialValues } from './initial-values'
import { additionalMenuUrlsSchema } from './schemas'

const AdditionalMenuUrlsForm = ({ onSave, menu }) => {
  const formMethods = useForm({
    defaultValues: additionalMenuUrlsFormInitialValues(menu),
    resolver: yupResolver(additionalMenuUrlsSchema),
  })

  const { control, reset, handleSubmit } = formMethods

  useEffect(() => {
    const defaultValues = additionalMenuUrlsFormInitialValues(menu)
    reset(defaultValues)
  }, [menu, reset])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additionalUrls',
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSave)}>
        {map(fields, (field, index) => (
          <Input
            key={field.id}
            name={`additionalUrls.${index}`}
            defaultValue=""
            suffix={<Icon type="delete" onClick={() => remove(index)} />}
          />
        ))}
        <Row type="flex" justify="end">
          <Button
            margin="no small"
            onClick={() => append('additionalUrls', { shouldFocus: true })}
          >
            <Icon type="plus-circle" theme="twoTone" />
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

AdditionalMenuUrlsForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  menu: PropTypes.object,
}

AdditionalMenuUrlsForm.defaultProps = {
  menu: undefined,
}

export default AdditionalMenuUrlsForm
