import { gql } from 'apollo-boost'

const DISH_REVIEW_TASKS = gql`
  query dishReviewTasks(
    $filters: DishReviewTaskFiltersInput!
    $page: Int
    $pageSize: Int
    $sortingCriterion: DishReviewTaskSortingCriterion
    $sortingOrder: SortingOrder
  ) {
    dishReviewTasks(
      filters: $filters
      page: $page
      pageSize: $pageSize
      sortingCriterion: $sortingCriterion
      sortingOrder: $sortingOrder
    ) {
      dishReviewTasks {
        id
        status
        menu {
          id
          url
          status
          format
          scrapedDate
          brand {
            id
            name
          }
          locationCount
          menuScrapingTemplateId
          menuScrapingClusterId
        }
        assignedTo {
          id
          name
        }
        resolvedBy {
          id
          name
        }
        modifiedBy {
          id
          name
        }
        createdDate
        modifiedDate
        resolvedDate
      }
      total
    }
  }
`

export default DISH_REVIEW_TASKS
