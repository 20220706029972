const createPinDataFromGooglePlace = ({
  placeId,
  name,
  coordinates,
  formattedAddress,
}) => ({
  id: placeId,
  title: name,
  coordinates,
  description: formattedAddress,
})

export default createPinDataFromGooglePlace
