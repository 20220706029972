import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Icon, Row, Table, Typography } from 'antd'
import { differenceBy, filter, isEmpty, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { VisibilityContainer } from '../../../../common/components'
import {
  BRAND,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  LOCKABLE_ENTITIES,
  SORTING_ORDERS_MAP,
} from '../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { useLockedEntities } from '../../../../common/hooks'

import { UNMARK_BRAND_AS_MASTER } from '../../../../brands/graphql/mutations'
import { getSimilarBrandGatewayTableSortingData } from '../../../helpers'

import SimilarMasterBrandsUnmarkAsMasterColumn from './similar-master-brands-unmark-as-master-column'
import TaskTableBrandLinkColumn from './task-table-brand-link-column'

const BrandGatewaySimilarMasterBrandsByLocationWebsite = ({
  brand,
  similarMasterBrandsByLocationWebsite,
  isLoading,
  refetchSimilarMasterBrandsByLocationWebsite,
  refetchSimilarBrands,
}) => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [sortingOrder, setSortingOrder] = useState()
  const [isTableHidden, setIsTableHidden] = useState(false)

  useEffect(() => {
    if (isLoading) return
    if (!similarMasterBrandsByLocationWebsite.total) {
      setIsTableHidden(true)
    }
  }, [isLoading, similarMasterBrandsByLocationWebsite.total])

  const {
    lockedEntities,
    lockEntities,
    getUserLockingEntity,
  } = useLockedEntities()

  useEffect(() => {
    if (
      isEmpty(similarMasterBrandsByLocationWebsite.brands) ||
      isEmpty(lockedEntities)
    )
      return

    const lockedBrands = filter(
      lockedEntities,
      ({ entityType }) => entityType === BRAND,
    )

    const brandsToLock = differenceBy(
      similarMasterBrandsByLocationWebsite,
      lockedBrands,
      brand => Number(brand.id) || brand.entityId,
    )

    if (isEmpty(brandsToLock)) return

    const brandsToLockIds = map(brandsToLock, 'id')
    lockEntities(LOCKABLE_ENTITIES.BRAND, brandsToLockIds)
  }, [similarMasterBrandsByLocationWebsite, lockedEntities, lockEntities])

  const [unmarkBrandAsMaster, { loading: isUnmarkingBrand }] = useMutation(
    UNMARK_BRAND_AS_MASTER,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Brand unmarked as master.',
        })

        refetchSimilarBrands()

        if (
          currentPage !== 1 &&
          similarMasterBrandsByLocationWebsite.total - 1 !== 0 &&
          (similarMasterBrandsByLocationWebsite.total - 1) %
            DEFAULT_PAGE_SIZE ===
            0
        ) {
          setCurrentPage(currentPage => currentPage - 1)
          refetchSimilarMasterBrandsByLocationWebsite({
            id: brand.id,
            name: brand.name,
            page: currentPage - 1,
            pageSize: DEFAULT_PAGE_SIZE,
          })
        } else {
          refetchSimilarMasterBrandsByLocationWebsite({
            id: brand.id,
            name: brand.name,
            page: currentPage,
            pageSize: DEFAULT_PAGE_SIZE,
          })
        }
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Unmark brand as master failed',
          description: message,
        })
      },
    },
  )

  const tableColumns = [
    {
      title: 'Brand ID',
      dataIndex: 'id',
      width: '110px',
      sorter: true,
    },
    {
      title: 'Brand name',
      dataIndex: 'brand.name',
      width: '400px',
      render: (brandName, brand) => (
        <TaskTableBrandLinkColumn
          getUserLockingEntity={getUserLockingEntity}
          to={`/tasks/brand-gateway/${brand.brandGatewayTask.id}`}
          brand={brand}
        />
      ),
    },
    {
      title: 'Locations',
      dataIndex: 'locationCount',
      width: '110px',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '400px',
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      align: 'right',
      // eslint-disable-next-line react/prop-types
      render: ({ id, locationCount }) => (
        <SimilarMasterBrandsUnmarkAsMasterColumn
          onConfirm={() => unmarkBrandAsMaster({ variables: { id } })}
          locationCount={locationCount}
        />
      ),
    },
  ]

  const getSortingData = useCallback(
    sorter => {
      const { order, criterion } = getSimilarBrandGatewayTableSortingData(
        sorter,
        sortingOrder,
      )

      setSortingOrder(order)

      return {
        sortingCriterion: criterion,
        sortingOrder: order,
      }
    },
    [sortingOrder],
  )

  const getPaginationData = useCallback(
    (page, sorter) => {
      const { current: currentPageNo } = page
      const shouldSetDefaultPage =
        SORTING_ORDERS_MAP[sortingOrder]?.antd !== sorter?.order

      const pageNo = shouldSetDefaultPage ? DEFAULT_PAGE : currentPageNo

      setCurrentPage(pageNo)

      return {
        page: pageNo,
        pageSize: DEFAULT_PAGE_SIZE,
      }
    },
    [sortingOrder],
  )

  const handleTableChange = useCallback(
    (page, filters, sorter) => {
      const paginationData = getPaginationData(page, sorter)
      const sortingData = getSortingData(sorter)

      refetchSimilarMasterBrandsByLocationWebsite({
        id: brand.id,
        ...paginationData,
        ...sortingData,
      })
    },
    [
      brand,
      getSortingData,
      getPaginationData,
      refetchSimilarMasterBrandsByLocationWebsite,
    ],
  )

  return (
    <>
      <Row style={{ display: 'flex' }}>
        <Typography.Title level={4}>
          {`Similar master brands by website`}
          {!isTableHidden &&
            ` (${similarMasterBrandsByLocationWebsite.total || 0})`}
        </Typography.Title>
        <Button
          onClick={() => setIsTableHidden(prevValue => !prevValue)}
          style={{ marginLeft: 'auto' }}
        >
          <Icon type={isTableHidden ? 'down' : 'up'} />
        </Button>
      </Row>
      <VisibilityContainer isHidden={isTableHidden}>
        <Table
          rowKey="id"
          loading={isLoading || isUnmarkingBrand}
          columns={tableColumns}
          dataSource={similarMasterBrandsByLocationWebsite.brands}
          pagination={{
            size: 'small',
            current: currentPage,
            pageSize: DEFAULT_PAGE_SIZE,
            total: similarMasterBrandsByLocationWebsite.total,
            hideOnSinglePage: true,
          }}
          onChange={handleTableChange}
        />
      </VisibilityContainer>
    </>
  )
}

BrandGatewaySimilarMasterBrandsByLocationWebsite.propTypes = {
  brand: PropTypes.object.isRequired,
  similarMasterBrandsByLocationWebsite: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refetchSimilarMasterBrandsByLocationWebsite: PropTypes.func.isRequired,
  refetchSimilarBrands: PropTypes.func.isRequired,
}

export default BrandGatewaySimilarMasterBrandsByLocationWebsite
