import * as Yup from 'yup'

const loginWithEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required(`User's email address is required`),
  code: Yup.string().required(`Code is required`),
})

export default loginWithEmailSchema
