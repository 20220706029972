import { mapKeys } from 'lodash-es'

export const MANUAL = 'MANUAL'
export const AUTOMATIC = 'AUTOMATIC'

export const TASK_RESOLVED_TYPES = mapKeys(
  [
    { name: 'Manual', value: MANUAL },
    { name: 'Automatic', value: AUTOMATIC },
  ],
  'value',
)
