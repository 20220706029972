import { gql } from 'apollo-boost'

const LOGIN_WITH_EMAIL = gql`
  mutation loginWithEmail($email: EmailAddress!, $code: String!) {
    loginWithEmail(email: $email, code: $code) {
      accessToken
      refreshToken
      permissions
    }
  }
`

export default LOGIN_WITH_EMAIL
