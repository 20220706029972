import { mapKeys } from 'lodash-es'

export const LEAD = 'LEAD'

export const COMPLEX = 'COMPLEX'

export const CORE = 'CORE'

export const MENU_SCRAPING_CLUSTER_ROLES = mapKeys(
  [
    { name: 'Lead', value: LEAD },
    { name: 'Complex', value: COMPLEX },
    { name: 'Core', value: CORE },
  ],
  'value',
)
