import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormDate,
  HiddenInputs,
  Input,
  Select,
} from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { HistoryDrawer, Spinner } from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { ROLES, USER } from '../../../common/constants'

import { userEditorFormInitialValues } from '../initial-values'
import { userEditorSchema } from '../schemas'

const UserEditorForm = ({
  user,
  roles,
  isCreatingUser,
  isUpdatingUser,
  onSubmit,
}) => {
  const history = useHistory()
  const defaultValues = useMemo(() => userEditorFormInitialValues(user), [user])

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(userEditorSchema),
  })

  const roleOptions = useMemo(
    () =>
      map(roles, ({ id, name }) => ({
        name: ROLES[name].name,
        value: String(id),
      })),
    [roles],
  )

  return (
    <>
      {(isCreatingUser || isUpdatingUser) && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeader sticky>
            <PageTitle>
              {user ? 'Edit user' : 'Create user'}
              {user && <HistoryDrawer entityType={USER} entityId={user.id} />}
            </PageTitle>
            <PageActions>
              <Button onClick={() => history.push('/users')} margin="no small">
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </PageActions>
          </PageHeader>
          <Row>
            <Col span={3}>
              <Input name="name" label="Name" />
            </Col>
            <Col span={4} offset={1}>
              <Select name="roleId" label="Role" options={roleOptions} />
            </Col>
            <Col span={5} offset={1}>
              <Input name="email" label="Email" />
            </Col>
            <Col span={1} offset={1}>
              <Checkbox name="isActive" label="Enable user" />
            </Col>
            {user && (
              <>
                <Col span={3} offset={1}>
                  <FormDate name="loginDate" label="Login date" />
                </Col>
                <Col span={3} offset={1}>
                  <FormDate name="modifiedDate" label="Updated date" />
                </Col>
              </>
            )}
          </Row>
          <HiddenInputs names={['id']} />
        </form>
      </FormProvider>
    </>
  )
}

UserEditorForm.propTypes = {
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  isCreatingUser: PropTypes.bool.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

UserEditorForm.defaultProps = {
  user: undefined,
}

export default UserEditorForm
