import decodeToken from './decode-token'

const checkIfTokenIsValid = token => {
  const { exp } = decodeToken(token)
  const now = Math.floor(Date.now() / 1000)

  return exp > now
}

export default checkIfTokenIsValid
