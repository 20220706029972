import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Col, Drawer, Icon, List, Select, Typography } from 'antd'
import copyToClipboard from 'copy-to-clipboard'
import fileDownload from 'js-file-download'
import { filter, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { MENU_SCRAPING_INSTRUCTIONS_VERSIONS } from '../../menus/graphql/queries'

import LondonDateTime from './london-date-time'
import ScrapingInstructionsDiffModal from './scraping-instructions-diff-modal'
import Spinner from './spinner'

const ScrapingInstructionVersionDetails = ({
  createdDate,
  createdBy,
  number,
}) => (
  <Typography.Text>
    At <LondonDateTime date={createdDate} /> ${createdBy.name} uploaded version
    ${number}
  </Typography.Text>
)

ScrapingInstructionVersionDetails.propTypes = {
  createdDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
}

const ScrapingInstructionsVersionsDrawer = ({ menu, buttonSize, disabled }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const [
    scrapingInstructionsDiffModalSettings,
    setScrapingInstructionsDiffModalSettings,
  ] = useState({ isOpen: false })

  const {
    data: { menuScrapingInstructionsVersions } = {},
    loading: isLoadingScrapingInstructions,
    refetch: refechScrapingInstructions,
  } = useQuery(MENU_SCRAPING_INSTRUCTIONS_VERSIONS, {
    variables: { menuId: menu.id },
    fetchPolicy: 'no-cache',
  })

  const onButtonClick = useCallback(() => {
    refechScrapingInstructions()
    setIsDrawerVisible(true)
  }, [refechScrapingInstructions])

  const onClose = useCallback(() => setIsDrawerVisible(false), [])

  return (
    <>
      <Button
        disabled={disabled}
        size={buttonSize}
        style={{ margin: '5px' }}
        onClick={onButtonClick}
      >
        Version history
        <Icon type="eye" />
      </Button>
      {isLoadingScrapingInstructions ? (
        <Spinner />
      ) : (
        <Drawer
          title="Scraping instructions versions"
          placement="right"
          onClose={onClose}
          visible={isDrawerVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={menuScrapingInstructionsVersions}
            renderItem={menuScrapingInstructionsVersion => (
              <List.Item>
                <Col span={14}>
                  <ScrapingInstructionVersionDetails
                    {...menuScrapingInstructionsVersion}
                  />
                </Col>
                <Col span={2} offset={1}>
                  <Button
                    size="medium"
                    onClick={() => {
                      const version =
                        menu.scrapingInstructionsVersionNumber !==
                        menuScrapingInstructionsVersion.number
                          ? `v${menuScrapingInstructionsVersion.number}`
                          : 'current'

                      fileDownload(
                        menuScrapingInstructionsVersion?.scrapingInstructions,
                        `${menuScrapingInstructionsVersion?.menuId}-menu-${version}.json`,
                      )
                    }}
                  >
                    <Icon type="download" />
                  </Button>
                </Col>
                <Col span={2}>
                  <Button
                    size="medium"
                    onClick={() =>
                      copyToClipboard(
                        menuScrapingInstructionsVersion?.scrapingInstructions,
                      )
                    }
                  >
                    <Icon type="copy" />
                  </Button>
                </Col>
                <Col span={5}>
                  <Select
                    style={{ width: '100%' }}
                    value="Compare with..."
                    onChange={number =>
                      setScrapingInstructionsDiffModalSettings({
                        isOpen: true,
                        currentScrapingInstructions: {
                          instructions: menuScrapingInstructionsVersions.find(
                            v => v.number === number,
                          ).scrapingInstructions,
                          version: number,
                        },
                        otherScrapingInstructions: {
                          instructions:
                            menuScrapingInstructionsVersion.scrapingInstructions,
                          version: menuScrapingInstructionsVersion.number,
                        },
                      })
                    }
                  >
                    {map(
                      filter(
                        menuScrapingInstructionsVersions,
                        v =>
                          v.number !== menuScrapingInstructionsVersion.number,
                      ),
                      ({ number }) => (
                        <Select.Option key={number} value={number}>
                          V.{number}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Col>
              </List.Item>
            )}
          />
        </Drawer>
      )}

      <ScrapingInstructionsDiffModal
        isModalOpen={scrapingInstructionsDiffModalSettings.isOpen}
        scrapingInstructions={
          scrapingInstructionsDiffModalSettings.currentScrapingInstructions
        }
        otherScrapingInstructions={
          scrapingInstructionsDiffModalSettings.otherScrapingInstructions
        }
        onCancel={() =>
          setScrapingInstructionsDiffModalSettings({
            isOpen: false,
            otherScrapingInstructions: undefined,
            currentScrapingInstructions: undefined,
          })
        }
      />
    </>
  )
}

ScrapingInstructionsVersionsDrawer.propTypes = {
  menu: PropTypes.object.isRequired,
  buttonSize: PropTypes.string,
  disabled: PropTypes.bool,
}

ScrapingInstructionsVersionsDrawer.defaultProps = {
  buttonSize: 'small',
  disabled: false,
}

export default ScrapingInstructionsVersionsDrawer
