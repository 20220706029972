import { gql } from 'apollo-boost'

const LEADS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER = gql`
  query leadsManuallyRemovedFromScrapingCluster(
    $id: ID!
    $page: Int
    $pageSize: Int
  ) {
    leadsManuallyRemovedFromScrapingCluster(
      id: $id
      page: $page
      pageSize: $pageSize
    ) {
      leads {
        menuId
        createdDate
        processedDate
        newMenuIds
      }
      total
    }
  }
`

export default LEADS_MANUALLY_REMOVED_FROM_MENU_SCRAPING_CLUSTER
