import { mapKeys } from 'lodash-es'

export const GROCERY = 'GROCERY'
export const CATERING = 'CATERING'
export const NO_WEBSITE = 'NO_WEBSITE'
export const NO_SCRAPABLE_WEBSITE = 'NO_SCRAPABLE_WEBSITE'
export const MISC = 'MISC'
export const DELIVERY = 'DELIVERY'
export const DUPLICATE_LOCATION = 'DUPLICATE'
export const NO_GOOGLE_MATCH = 'NO_GOOGLE_MATCH'
export const NO_MENU = 'NO_MENU'
export const CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY'

export const LOCATION_EXCLUSION_REASONS = mapKeys(
  [
    { name: 'Grocery', value: GROCERY },
    { name: 'Catering', value: CATERING },
    { name: 'No website', value: NO_WEBSITE },
    { name: 'No scrapable website', value: NO_SCRAPABLE_WEBSITE },
    { name: 'Misc', value: MISC },
    { name: 'Delivery', value: DELIVERY },
    { name: 'Duplicate location', value: DUPLICATE_LOCATION, disabled: true },
    { name: 'No Google match', value: NO_GOOGLE_MATCH, disabled: true },
    { name: 'No Menu', value: NO_MENU, disabled: true },
    { name: 'Closed permanently', value: CLOSED_PERMANENTLY, disabled: true },
  ],
  'value',
)
