const rejectMenuFormInitialValues = (
  id,
  { scrapedById, assignedToId, rescrapingComment },
) => ({
  id,
  rescrapingComment,
  assignedToUserId: assignedToId || scrapedById,
})

export default rejectMenuFormInitialValues
