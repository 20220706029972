import * as Yup from 'yup'

const commonWebsiteDomainSchema = Yup.object().shape({
  commonWebsiteDomains: Yup.array().of(
    Yup.object()
      .shape({
        id: Yup.string(),
        websiteDomain: Yup.string().validDomain('Domain must be valid'),
      })
      .uniqueProperties([['websiteDomain', 'Domains must be unique']]),
  ),
})

export default commonWebsiteDomainSchema
