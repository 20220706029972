import moment from 'moment-timezone'

const currencyFormatter = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
})

export const formatDate = (date, toLondonTimezone = true) => {
  if (!date) throw new Error(`Invalid date: ${date}.`)

  const dateAsMoment = toLondonTimezone
    ? moment(date).tz('Europe/London')
    : moment(date)
  return dateAsMoment.format('HH:mm D MMM YYYY')
}

export const formatTime = time => moment(time, 'H:m:s').format('HH:mm')
export const currency = amount => currencyFormatter.format(amount)

export const formatSecondsToTime = noOfSeconds =>
  new Date(noOfSeconds * 1000).toISOString().substr(11, 8)
