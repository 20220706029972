const platformEditorFormInitialValues = values => {
  if (!values) {
    return {
      name: '',
      urlIdentificationRegex: '',
      urlNormalizationRegex: '',
    }
  }
  return values
}

export default platformEditorFormInitialValues
