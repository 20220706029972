import { gql } from 'apollo-boost'

const RESOLVE_DISH_REVIEW_TASK_BY_RESCRAPE_MENU = gql`
  mutation resolveDishReviewTaskByRescrapeMenu(
    $id: ID!
    $isDebugModeScraping: Boolean!
  ) {
    resolveDishReviewTaskByRescrapeMenu(
      id: $id
      isDebugModeScraping: $isDebugModeScraping
    ) {
      id
    }
  }
`

export default RESOLVE_DISH_REVIEW_TASK_BY_RESCRAPE_MENU
