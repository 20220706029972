import { gql } from 'apollo-boost'

const MENU_SCRAPING_CLUSTER = gql`
  query menuScrapingCluster($id: ID!) {
    menuScrapingCluster(id: $id) {
      id
      menuScrapingTemplate {
        id
        name
        status
      }
      platforms {
        id
        name
      }
      masterId
      masterScrapingCluster {
        id
      }
      menuScrapingClusterFingerprints {
        menuId
        ids
        classes
        hashCodes
        xPathSignatures
      }
      createdDate
      modifiedDate
      expectedMenuFormat
      areWorkingHoursIrrelevant
      modifiedBy {
        id
        name
      }
      averageScrapingDuration
      assignedTo {
        id
        name
      }
      rerunFlow {
        id
        startedDate
        finishedDate
        steps {
          type
          startedDate
          finishedDate
          abortedDate
        }
        currentStep {
          type
          startedDate
          finishedDate
          abortedDate
        }
      }
      oldestMenuScrapedDate
      nextRerunStartDate
    }
  }
`

export default MENU_SCRAPING_CLUSTER
