import React from 'react'
import { Badge } from 'antd'
import PropTypes from 'prop-types'

import { THEME } from '../../../../core/constants'

import {
  HIGH_GOOGLE_MATCH,
  HIGHEST_GOOGLE_MATCH,
  LOCATION_GOOGLE_MATCH_RANKS,
  LOW_GOOGLE_MATCH,
  MANUAL_GOOGLE_MATCH,
  MEDIUM_GOOGLE_MATCH,
  NO_GOOGLE_MATCH,
} from '../../../constants'

const backgroundColorByRank = {
  [MANUAL_GOOGLE_MATCH]: THEME.colors.blue,
  [HIGHEST_GOOGLE_MATCH]: THEME.colors.green,
  [HIGH_GOOGLE_MATCH]: THEME.colors.lime,
  [MEDIUM_GOOGLE_MATCH]: THEME.colors.yellow,
  [LOW_GOOGLE_MATCH]: THEME.colors.orange,
  [NO_GOOGLE_MATCH]: THEME.colors.red,
}

const LocationGoogleMatchRank = ({ matchRank }) => (
  <Badge
    count={LOCATION_GOOGLE_MATCH_RANKS[matchRank].name}
    style={{
      backgroundColor: backgroundColorByRank[matchRank],
    }}
  />
)

LocationGoogleMatchRank.propTypes = {
  matchRank: PropTypes.string.isRequired,
}

export default LocationGoogleMatchRank
