import { mapKeys } from 'lodash-es'

export const UNSET = 'UNSET'
export const SLAVE = 'SLAVE'
export const NOT_SAME = 'NOT_SAME'

export const BRAND_RELATIONSHIPS = mapKeys(
  [
    { name: 'Unset', value: UNSET },
    { name: 'Slave', value: SLAVE },
    { name: 'Not the same', value: NOT_SAME },
  ],
  'value',
)
