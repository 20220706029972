import { assign, pickBy } from 'lodash-es'
import { parse, stringify } from 'qs'

import isFalsy from './isFalsy'

const customDecoder = ({
  parseNumbers = true,
  parseBool = true,
  ignoreNull = true,
  ignoreEmptyString = true,
}) => {
  return function decoder(str, decoder, charset) {
    const strWithoutPlus = str.replace(/\+/g, ' ')
    if (charset === 'iso-8859-1') {
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
    }

    if (parseNumbers && /^[1-9][0-9]*$|^\d*\.\d+$/.test(str)) {
      return parseFloat(str)
    }

    if (ignoreEmptyString && str.length === 0) {
      // eslint-disable-next-line consistent-return
      return
    }

    const keywords = {
      null: ignoreNull ? undefined : null,
      undefined,
    }

    if (str in keywords) {
      return keywords[str]
    }

    const boolKeywords = {
      true: true,
      false: false,
    }

    if (parseBool && str in boolKeywords) {
      return boolKeywords[str]
    }

    try {
      return decodeURIComponent(strWithoutPlus)
    } catch (e) {
      return strWithoutPlus
    }
  }
}

export const getQueryParams = (queryString, options = {}) =>
  parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: customDecoder({ ...options }),
  })

export const setQueryParams = (
  queryString,
  params,
  options = { allowZero: true },
) =>
  stringify(
    pickBy(
      assign(getQueryParams(queryString), params),
      value => !isFalsy(value, { ...options }),
    ),
    {
      addQueryPrefix: true,
      encode: true,
    },
  )

export const createUrl = (urlPath, queryString, params, options) =>
  `${urlPath}${setQueryParams(queryString, params, options)}`
