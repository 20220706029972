import { gql } from 'apollo-boost'

const CLONE_BRAND = gql`
  mutation cloneBrand($id: ID!) {
    cloneBrand(id: $id) {
      name
    }
  }
`

export default CLONE_BRAND
