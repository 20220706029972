import { reduce, size } from 'lodash-es'

const getPrevAndNextItemByScrapedItemHash = firstScreenshotIndexByScrapedItemHash => {
  const scrapedItemHashes = Array.from(
    firstScreenshotIndexByScrapedItemHash.keys(),
  )

  return reduce(
    scrapedItemHashes,
    (result, itemHash, index) => {
      if (index === 0) {
        result.set(itemHash, {
          prev: undefined,
          next: scrapedItemHashes[1],
        })
      } else if (index === size(scrapedItemHashes) - 1) {
        result.set(itemHash, {
          prev: scrapedItemHashes[index - 1],
          next: undefined,
        })
      } else {
        result.set(itemHash, {
          prev: scrapedItemHashes[index - 1],
          next: scrapedItemHashes[index + 1],
        })
      }
      return result
    },
    new Map(),
  )
}

export default getPrevAndNextItemByScrapedItemHash
