import { gql } from 'apollo-boost'

const UPDATE_CLUSTER_MENU = gql`
  mutation updateClusterMenu($id: ID!, $data: UpdateClusterMenuInput!) {
    updateClusterMenu(id: $id, data: $data) {
      id
    }
  }
`

export default UPDATE_CLUSTER_MENU
