import * as Yup from 'yup'

const cloneLocationSchema = Yup.object().shape({
  brand: Yup.object().shape({
    name: Yup.string()
      .required('Brand is required')
      .nullable(),
  }),
})

export default cloneLocationSchema
