import { mapKeys } from 'lodash-es'

export const MASTER = 'MASTER'
export const SLAVE = 'SLAVE'

export const BRAND_RANKS = mapKeys(
  [
    { name: 'Master', value: MASTER },
    { name: 'Slave', value: SLAVE },
  ],
  'value',
)
