import { lighten, transparentize } from 'polished'
import styled from 'styled-components'

import { colorMapper } from '../../../core/utils/styles'

export const MapPinPopoverTitle = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  line-height: 15px;
  font-size: 12px;
`

export const MapPinPopoverDescription = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  line-height: 15px;
  font-size: 12px;
`

export const MapPinPopover = styled.div`
  position: absolute;
  bottom: 90px;
  left: -105px;
  width: 210px;
  padding: 8px;
  z-index: 1000;
  box-shadow: 0 2px 9px ${transparentize(0.7, '#000')};
`

export const MapPin = styled.div`
  position: relative;

  ${MapPinPopover} {
    background-color: ${props => lighten(0.4, colorMapper(props))};
  }
`
