import React from 'react'
import PropTypes from 'prop-types'

import { createUrl } from '../../core/utils/services/queryParams'

import { EMPTY_VALUE } from '../constants'

const GoogleSearch = ({ text, queryText }) =>
  text ? (
    <a
      href={createUrl('https://google.com/search', {
        q: queryText,
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  ) : (
    EMPTY_VALUE
  )

GoogleSearch.propTypes = {
  text: PropTypes.string,
  queryText: PropTypes.string,
}

GoogleSearch.defaultProps = {
  text: undefined,
  queryText: undefined,
}

export default GoogleSearch
