import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Typography } from 'antd'
import PropTypes from 'prop-types'

import { LondonDateTime } from '../../common/components'
import { EMPTY_VALUE } from '../../common/constants'

const FormDate = ({ name, label, dateDetails }) => {
  const { control } = useFormContext()
  return (
    <Form.Item label={label}>
      <Controller
        control={control}
        name={name}
        render={({ value }) => (
          <Typography.Paragraph>
            {value ? (
              <LondonDateTime date={value} dateDetails={dateDetails} />
            ) : (
              EMPTY_VALUE
            )}
          </Typography.Paragraph>
        )}
      />
    </Form.Item>
  )
}

FormDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  dateDetails: PropTypes.string,
}

FormDate.defaultProps = {
  label: '',
  dateDetails: undefined,
}

export default FormDate
