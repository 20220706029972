import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NetworkStatus } from 'apollo-boost'

import { Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { UPDATE_LOCATION } from '../../graphql/mutations'
import { LOCATION } from '../../graphql/queries'
import { transformToUpdateLocationInput } from '../../graphql/transformers'
import { LocationForm } from '../forms'

const onSubmit = (location, updateLocation) =>
  updateLocation({
    variables: {
      id: location.id,
      data: transformToUpdateLocationInput(location),
    },
  })

const LocationPage = () => {
  useDocumentTitle('Location')
  const { locationId } = useParams()

  const {
    data: { location } = {},
    refetch: refetchLocation,
    networkStatus: locationQueryNetworkStatus,
  } = useQuery(LOCATION, {
    variables: { id: locationId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [updateLocation, { loading: isUpdatingLocation }] = useMutation(
    UPDATE_LOCATION,
    {
      onCompleted() {
        refetchLocation()
        showSuccessNotification({
          message: 'Location updated',
          description: `Location has been successfully updated`,
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Location update failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    location => onSubmit(location, updateLocation),
    [updateLocation],
  )

  return locationQueryNetworkStatus === NetworkStatus.loading ? (
    <Spinner />
  ) : (
    <LocationForm
      location={location}
      isUpdatingLocation={isUpdatingLocation}
      isRefetchingLocation={
        locationQueryNetworkStatus === NetworkStatus.refetch
      }
      onSubmit={handleSubmit}
    />
  )
}

export default LocationPage
