import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import {
  Checkbox as AntCheckbox,
  Col,
  Form as AntForm,
  Icon,
  Input as AntInput,
  Row,
  Table,
  Typography,
} from 'antd'
import fileDownload from 'js-file-download'
import { filter, get, isNil, isNull, isUndefined, toLower } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormDate,
  FormLink,
  FormText,
  Input,
} from '../../../core/components'
import ImageUpload from '../../../core/components/image-upload'
import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  HistoryDrawer,
  LondonDateTime,
  OpenLink,
  Spinner,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { BRAND, BRANDS_PERMISSIONS } from '../../../common/constants'
import {
  useCanUserClone,
  useCanUserMarkBrandAsTesting,
  useUserHasPermissions,
} from '../../../common/hooks'
import { getCachedFilterForPage } from '../../../common/services'
import { tableOnRowClick } from '../../../common/utils'

import { PermissionGuard } from '../../../account/components/routes'
import {
  BUSINESS_STATUSES,
  LOCATION_MENU_STATUSES,
  LOCATION_STATUSES,
} from '../../constants'

import {
  brandFormInitialValues,
  brandImagesFormInitialValues,
} from './initial-values'
import { brandSchema } from './schemas'

const onSearch = (searchTerm, event, pathname, history) => {
  event.preventDefault()
  history.push(createUrl(pathname, { searchTerm }))
}

const BrandForm = ({
  brand,
  isUpdatingBrand,
  isRefetchingBrand,
  onSave,
  onSaveImages,
  onClone,
}) => {
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { searchTerm } = getQueryParams(search)
  const [locationName, setLocationName] = useState(searchTerm)

  const defaultValues = useMemo(() => brandFormInitialValues(brand), [brand])

  const initialImagesFormValues = useMemo(
    () => brandImagesFormInitialValues(brand),
    [brand],
  )
  const [icon, setIcon] = useState(() => initialImagesFormValues.icon)
  const [image, setImage] = useState(() => initialImagesFormValues.image)
  const [areAssetsActive, setAreAssetsActive] = useState(
    () => initialImagesFormValues.areAssetsActive,
  )

  useEffect(() => {
    setIcon(initialImagesFormValues.icon)
    setImage(initialImagesFormValues.image)
    setAreAssetsActive(initialImagesFormValues.areAssetsActive)
  }, [
    initialImagesFormValues.icon,
    initialImagesFormValues.image,
    initialImagesFormValues.areAssetsActive,
  ])

  const handleSubmitImagesForm = useCallback(() => {
    let iconForSave
    if (initialImagesFormValues.icon !== icon) {
      iconForSave = icon?.fileList[0]?.originFileObj ?? null
    }

    let imageForSave
    if (initialImagesFormValues.image !== image) {
      imageForSave = image?.fileList[0]?.originFileObj ?? null
    }

    const doesIconExist = isUndefined(iconForSave)
      ? Boolean(initialImagesFormValues.icon[0])
      : !isNull(iconForSave)

    const doesImageExist = isUndefined(imageForSave)
      ? Boolean(initialImagesFormValues.image[0])
      : !isNull(imageForSave)

    return onSaveImages(
      brand.id,
      iconForSave,
      imageForSave,
      (doesIconExist || doesImageExist) && areAssetsActive,
    )
  }, [
    brand.id,
    icon,
    image,
    initialImagesFormValues,
    areAssetsActive,
    onSaveImages,
  ])

  const handleAreAssetsActiveChange = useCallback(e => {
    setAreAssetsActive(e.target.checked)
  }, [])

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(brandSchema),
  })

  const { getValues, handleSubmit, reset, watch } = formMethods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const canUserClone = useCanUserClone()
  const canUserMarkBrandAsTesting = useCanUserMarkBrandAsTesting()

  useEffect(() => {
    if (isNil(locationName) && searchTerm) {
      setLocationName(searchTerm)
    }
  }, [locationName, searchTerm, setLocationName])

  const handleSearchLocations = useCallback(
    (searchTerm, event) => onSearch(searchTerm, event, pathname, history),
    [pathname, history],
  )

  const tableColumns = [
    {
      title: 'Location',
      dataIndex: 'name',
      width: '250px',
      render: name =>
        watch('useBrandNameAsLocationName') ? watch('name') : name,
    },
    {
      title: 'Location status',
      dataIndex: 'status',
      width: '100px',
      render: status => LOCATION_STATUSES[status]?.name,
    },
    {
      title: 'Business status',
      dataIndex: 'businessStatus',
      width: '100px',
      render: businessStatus => BUSINESS_STATUSES[businessStatus]?.name,
    },

    {
      title: 'Menu status',
      dataIndex: 'menuStatus',
      width: '100px',
      render: menuStatus => LOCATION_MENU_STATUSES[menuStatus]?.name,
    },
    {
      title: 'Menu URL',
      dataIndex: 'menu.url',
      width: '200px',
      ellipsis: true,
      render: menuUrl => (
        <Typography.Text ellipsis>
          <a href={menuUrl} target="_blank" rel="noopener noreferrer">
            {menuUrl}
          </a>
        </Typography.Text>
      ),
    },
    {
      title: 'Updated',
      dataIndex: 'modifiedDate',
      width: '160px',
      render: date => <LondonDateTime date={date} />,
    },
  ]

  const canUserUpdateBrand = useUserHasPermissions(BRANDS_PERMISSIONS.UPDATE)

  const canUserUpdateImages = useUserHasPermissions(
    BRANDS_PERMISSIONS.UPDATE_IMAGES,
  )

  const { locations: brandLocations, menu } = brand

  const locations = searchTerm
    ? filter(
        brandLocations,
        ({ name }) => toLower(name).search(toLower(searchTerm)) !== -1,
      )
    : brand.locations

  const redirectToLocation = useCallback(
    location => history.push(`/brands/locations/${location.id}`),
    [history],
  )

  const onRow = useCallback(
    row => ({
      onClick: event => tableOnRowClick(event, row, redirectToLocation),
    }),
    [redirectToLocation],
  )

  const handleClone = useCallback(() => onClone(brand.id), [brand, onClone])

  return (
    <>
      {(isUpdatingBrand || isRefetchingBrand) && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <form>
          <PageHeader sticky>
            <PageTitle>
              Brand
              <HistoryDrawer entityType={BRAND} entityId={brand.id} />
            </PageTitle>
            <PageActions>
              <Button
                onClick={() => history.push(getCachedFilterForPage('/brands'))}
                margin="no small"
              >
                Go back
              </Button>
              {canUserClone && (
                <Button onClick={handleClone} margin="no small no no">
                  Clone
                </Button>
              )}
              <PermissionGuard permissions={BRANDS_PERMISSIONS.UPDATE}>
                <Button onClick={handleSubmit(onSave)} type="primary">
                  Save
                </Button>
              </PermissionGuard>
            </PageActions>
          </PageHeader>
          <Panel withBorderBottom>
            <Row gutter={[16, 16]}>
              <Col span={3}>
                <FormText name="id" label="Brand ID" />
              </Col>
              <Col span={5}>
                <Input
                  name="name"
                  label="Brand"
                  disabled={!canUserUpdateBrand}
                />
              </Col>
              <Col span={7}>
                <Input
                  name="website"
                  label="Website"
                  disabled={!canUserUpdateBrand}
                  addonAfter={
                    <OpenLink
                      onClick={() => window.open(getValues('website'))}
                    />
                  }
                />
              </Col>
              <Col span={2} offset={1}>
                <FormText name="status" label="Status" />
              </Col>
              <Col span={3}>
                <Checkbox
                  name="hasOnlyUnsupportedBusinessTypes"
                  label="Unsupported business"
                  disabled
                />
              </Col>
              <Col span={2}>
                <FormText name="businessStatus" label="Business status" />
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <FormDate name="modifiedDate" label="Updated date" />
              </Col>
              <Col span={5}>
                <Checkbox
                  name="useBrandNameAsLocationName"
                  label="Use brand name as location name"
                />
              </Col>
              <Col span={3}>
                <Checkbox name="useDishTypeIcon" label="Use dish type icon" />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={3}>
                <FormText name="menu.id" label="Menu ID" />
              </Col>

              <Col span={5}>
                <FormLink name="menu.url" label="Menu URL" />
              </Col>

              <Col span={3}>
                <FormText name="menu.format" label="Menu format" />
              </Col>

              <Col span={2}>
                <FormText name="menu.dishCount" label="Dishes" />
              </Col>

              <Col span={3}>
                <AntForm.Item label="JSON">
                  <Button
                    margin="no small no no"
                    disabled={isNil(get(menu, 'scrapingInstructions'))}
                    onClick={() =>
                      fileDownload(menu.scrapingInstructions, `${menu.id}.json`)
                    }
                  >
                    Download
                    <Icon type="download" />
                  </Button>
                </AntForm.Item>
              </Col>
              {canUserMarkBrandAsTesting && (
                <Col span={3}>
                  <Checkbox name="isForTesting" label="For testing" />
                </Col>
              )}
            </Row>
          </Panel>
          <Panel withBorderBottom margin="small no">
            <Row>
              <Col span={3}>
                <AntForm.Item label="Icon">
                  <ImageUpload
                    disabled={!canUserUpdateImages}
                    onChange={setIcon}
                    fileList={icon}
                    onRemove={setIcon}
                  />
                </AntForm.Item>
              </Col>
              <Col span={3}>
                <AntForm.Item label="Image">
                  <ImageUpload
                    disabled={!canUserUpdateImages}
                    onChange={setImage}
                    fileList={image}
                    onRemove={setImage}
                  />
                </AntForm.Item>
              </Col>
              <Col span={3}>
                <AntForm.Item label="Are assets active">
                  <AntCheckbox
                    disabled={!canUserUpdateImages}
                    onChange={handleAreAssetsActiveChange}
                    checked={areAssetsActive}
                  />
                </AntForm.Item>
              </Col>
              <Col span={3}>
                <Button
                  disabled={!canUserUpdateImages}
                  onClick={handleSubmitImagesForm}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Panel>
          <Panel margin="small no">
            <Row>
              <Col span={5}>
                <AntInput.Search
                  placeholder="Search location"
                  allowClear
                  value={locationName}
                  onChange={({ target: { value } }) => setLocationName(value)}
                  onSearch={handleSearchLocations}
                />
              </Col>
            </Row>
          </Panel>
          <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={locations}
            pagination={false}
            onRow={onRow}
          />
        </form>
      </FormProvider>
    </>
  )
}

BrandForm.propTypes = {
  brand: PropTypes.object.isRequired,
  onSaveImages: PropTypes.func.isRequired,
  isUpdatingBrand: PropTypes.bool.isRequired,
  isRefetchingBrand: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
}

export default BrandForm
