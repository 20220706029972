import http from '../../core/services/http'

import { getAccessToken } from './session'

const requestInterceptor = config => {
  const authToken = getAccessToken()

  if (authToken) {
    const configWithAuth = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: authToken,
      },
    }
    return configWithAuth
  }

  return config
}

const requestErrorInterceptor = error => Promise.reject(error)

const registerAuthInterceptor = () => {
  http.interceptors.request.use(requestInterceptor, requestErrorInterceptor)
}

export default registerAuthInterceptor
