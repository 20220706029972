import { gql } from 'apollo-boost'

const RESOLVE_DISH_REVIEW_TASK_BY_REJECT_MENU = gql`
  mutation resolveDishReviewTaskByRejectMenu(
    $id: ID!
    $assignedToUserId: ID
    $rejectMenuStatus: MenuStatus!
    $rescrapingComment: String!
  ) {
    resolveDishReviewTaskByRejectMenu(
      id: $id
      assignedToUserId: $assignedToUserId
      rejectMenuStatus: $rejectMenuStatus
      rescrapingComment: $rescrapingComment
    ) {
      id
    }
  }
`

export default RESOLVE_DISH_REVIEW_TASK_BY_REJECT_MENU
