import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'

import { MOVE_MENU_TO_MENU_SCRAPING_CLUSTER } from '../../graphql/mutations'
import { MenuScrapingClustersDropdown } from '../pages/sections'

const UpdateMenuScrapingClusterForm = ({
  menuId,
  menuScrapingClusterId,
  disabled,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: {
      menuScrapingCluster: {
        id: menuScrapingClusterId,
      },
    },
  })

  const [moveMenuToMenuScrapingCluster, { loading }] = useMutation(
    MOVE_MENU_TO_MENU_SCRAPING_CLUSTER,
    {
      onCompleted() {
        onSubmit()
        showSuccessNotification({
          message: `Successfully updated menu scraping cluster for menu ${menuId}.`,
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: `Failed to update menu scraping cluster for menu ${menuId}.`,
          description: message,
        })
      },
    },
  )

  const handleOnSelect = ({ id }) => {
    moveMenuToMenuScrapingCluster({
      variables: {
        menuId,
        fromMenuScrapingClusterId: menuScrapingClusterId,
        toMenuScrapingClusterId: id,
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <MenuScrapingClustersDropdown
        name="menuScrapingCluster"
        disabled={disabled || loading}
        allowClear={false}
        onSelect={handleOnSelect}
        currentMenuScrapingClusterId={menuScrapingClusterId}
      />
    </FormProvider>
  )
}

UpdateMenuScrapingClusterForm.propTypes = {
  menuId: PropTypes.string.isRequired,
  menuScrapingClusterId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default UpdateMenuScrapingClusterForm
