import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from 'styled-components'

import { THEME } from './core/constants'
import { getEnvironmentVariable } from './core/helpers'
import { createApolloClient, initSentry, mergeResolvers } from './core/services'

import './common/bootstrap'
import { Page } from './common/components'
import { SocketProvider } from './common/components/providers'
import { useCacheUrl } from './common/hooks/effects'

import { AccountRouter } from './account/components'
import { AuthProvider } from './account/components/providers'
import { AuthRoute } from './account/components/routes'
import accountResolvers from './account/graphql/resolvers'
import { getAccessToken, registerAuthInterceptor } from './account/services'
import { BrandsRouter } from './brands/components'
import { MenuScrapingRerunsRouter } from './menu-scraping-reruns/components'
import { MenuScrapingTemplatesRouter } from './menu-scraping-templates/components'
import { MenusRouter } from './menus/components'
import { ReportsRouter } from './reports/components'
import { SettingsRouter } from './settings/components'
import { TasksRouter } from './tasks/components'
import { UsersRouter } from './users/components'

import './index.css'

if (getEnvironmentVariable('NODE_ENV') === 'production') initSentry()

registerAuthInterceptor()

const resolvers = mergeResolvers([accountResolvers])
const apolloClient = createApolloClient({ getAccessToken, resolvers })

const AppRouter = () => {
  // We cache the url if there were changes to the pathname and search params
  useCacheUrl()
  return (
    <Page>
      <AuthRoute path="/tasks" component={TasksRouter} />
      <AuthRoute path="/brands" component={BrandsRouter} />
      <AuthRoute path="/menus" component={MenusRouter} />
      <AuthRoute path="/users" component={UsersRouter} />
      <AuthRoute path="/reports" component={ReportsRouter} />
      <AuthRoute
        path="/menu-scraping-reruns"
        component={MenuScrapingRerunsRouter}
      />
      <AuthRoute
        path="/menu-scraping-templates"
        component={MenuScrapingTemplatesRouter}
      />
      <AuthRoute path="/settings" component={SettingsRouter} />
    </Page>
  )
}

render(
  <ThemeProvider theme={THEME}>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <SocketProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/account" component={AccountRouter} />
              <Redirect exact from="/" to="/users" />
              <AuthRoute component={AppRouter} />
            </Switch>
          </BrowserRouter>
        </SocketProvider>
      </AuthProvider>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById('root'),
)
