import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  ExcludedFromSimilarMenusTable,
  LeadsManuallyRemovedFromClusterMenusTable,
  MenuScrapingClusterMenusTable,
  MenusManuallyRemovedFromClusterMenusTable,
  MenuStatusesTable,
  SimilarMenusByUrlSignatureTable,
} from './tables'

const MenuScrapingClusterDoneStep = ({ menuScrapingCluster }) => {
  const [
    isManuallyRemovedMenusTableHidden,
    setIsManuallyRemovedMenusTableHidden,
  ] = useState(true)

  const [
    isManuallyRemovedLeadsTableHidden,
    setIsManuallyRemovedLeadsTableHidden,
  ] = useState(true)

  const [
    isExcludedFromSimilarMenusTableHidden,
    setIsExcludedFromSimilarMenusTableHidden,
  ] = useState(true)

  const toggleIsManuallyRemovedMenusTableHidden = useCallback(() => {
    setIsManuallyRemovedMenusTableHidden(prev => !prev)
  }, [])

  const toggleIsManuallyRemovedLeadsTableHidden = useCallback(() => {
    setIsManuallyRemovedLeadsTableHidden(prev => !prev)
  }, [])

  const toggleIsExcludedFromSimilarMenusTableHidden = useCallback(() => {
    setIsExcludedFromSimilarMenusTableHidden(prev => !prev)
  }, [])

  return (
    <>
      <MenuStatusesTable
        menuScrapingCluster={menuScrapingCluster}
        isResetMenusButtonHidden
      />
      <MenuScrapingClusterMenusTable
        menuScrapingCluster={menuScrapingCluster}
        isUpdateRoleButtonHidden
        isRemoveButtonHidden
        isUpdateMenuScrapingClusterHidden
        isMenuUpdateDisabled
      />
      <MenusManuallyRemovedFromClusterMenusTable
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedMenusTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedMenusTableHidden()
        }}
        isAttachMenuBackToClusterButtonHidden
      />
      <LeadsManuallyRemovedFromClusterMenusTable
        menuScrapingCluster={menuScrapingCluster}
        isHidden={isManuallyRemovedLeadsTableHidden}
        toggleIsHidden={() => {
          toggleIsManuallyRemovedLeadsTableHidden()
        }}
        isAttachMenuBackToClusterButtonHidden
      />
      <SimilarMenusByUrlSignatureTable
        menuScrapingCluster={menuScrapingCluster}
        isAttachButtonHidden
        isExcludeButtonHidden
      />
      <ExcludedFromSimilarMenusTable
        isHidden={isExcludedFromSimilarMenusTableHidden}
        toggleIsHidden={() => {
          toggleIsExcludedFromSimilarMenusTableHidden()
        }}
        menuScrapingCluster={menuScrapingCluster}
        isRemoveButtonHidden
      />
    </>
  )
}

MenuScrapingClusterDoneStep.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
}

export default MenuScrapingClusterDoneStep
