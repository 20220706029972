import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Input } from 'antd'

import {
  createUrl,
  getQueryParams,
} from '../../../../core/utils/services/queryParams'

import { DEFAULT_PAGE } from '../../../../common/constants'

const SearchCommonWebsiteDomainFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { websiteDomain } = getQueryParams(search)
  const [website, setWebsite] = useState(websiteDomain)

  const handleSearch = useCallback(
    websiteDomain =>
      history.push(
        createUrl(pathname, search, {
          websiteDomain,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  useEffect(() => setWebsite(websiteDomain), [websiteDomain])

  return (
    <Input.Search
      style={{ width: '30%', margin: '10px 0px' }}
      placeholder="Search website"
      value={website}
      allowClear
      onChange={({ target: { value } }) => setWebsite(value)}
      onSearch={handleSearch}
    />
  )
}

export default SearchCommonWebsiteDomainFilter
