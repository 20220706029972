import { gql } from 'apollo-boost'

const REGENERATE_COMPLEX_MENUS_FOR_SCRAPING_CLUSTER = gql`
  mutation regenerateComplexMenusForScrapingCluster($id: ID!) {
    regenerateComplexMenusForScrapingCluster(id: $id) {
      id
    }
  }
`

export default REGENERATE_COMPLEX_MENUS_FOR_SCRAPING_CLUSTER
