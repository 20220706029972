import React from 'react'
import PropTypes from 'prop-types'

import {
  CLEANUP,
  DONE,
  PREPARATION,
  SCRAPING,
  TEMPLATE_VALIDATION,
} from '../../../../../menus/constants'

import MenuScrapingClusterCleanupStep from './menu-scraping-cluster-cleanup-step'
import MenuScrapingClusterDoneStep from './menu-scraping-cluster-done-step'
import MenuScrapingClusterNoTemplateStep from './menu-scraping-cluster-no-template-step'
import MenuScrapingClusterPreparationStep from './menu-scraping-cluster-preparation-step'
import MenuScrapingClusterScrapingStep from './menu-scraping-cluster-scraping-step'
import MenuScrapingClusterTemplateValidationStep from './menu-scraping-cluster-template-validation-step'
import { LeadMenuProbingJobsTable } from './tables'

const MenuScrapingClusterSteps = ({
  menuScrapingCluster,
  refetchMenuScrapingCluster,
}) => {
  let step
  switch (menuScrapingCluster.rerunFlow?.currentStep?.type) {
    case PREPARATION:
      step = (
        <MenuScrapingClusterPreparationStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
    case TEMPLATE_VALIDATION:
      step = (
        <MenuScrapingClusterTemplateValidationStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
    case SCRAPING:
      step = (
        <MenuScrapingClusterScrapingStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
    case CLEANUP:
      step = (
        <MenuScrapingClusterCleanupStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
    case DONE:
      step = (
        <MenuScrapingClusterDoneStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
    default:
      step = (
        <MenuScrapingClusterNoTemplateStep
          menuScrapingCluster={menuScrapingCluster}
          refetchMenuScrapingCluster={refetchMenuScrapingCluster}
        />
      )
      break
  }

  return (
    <>
      {step}
      <LeadMenuProbingJobsTable
        menuScrapingCluster={menuScrapingCluster}
        onApplyNewFingerprints={() => refetchMenuScrapingCluster()}
      />
    </>
  )
}

MenuScrapingClusterSteps.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  refetchMenuScrapingCluster: PropTypes.func.isRequired,
}

export default MenuScrapingClusterSteps
