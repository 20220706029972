import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Form, Icon, Input, Modal, Pagination, Row } from 'antd'
import { get, isNil, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { Spinner } from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { DEFAULT_PAGE } from '../../../common/constants'

import { SearchCommonWebsiteDomainFilter } from '../pages/sections'

import { commonWebsiteDomainsFormInitialValues } from './initial-values'
import { commonWebsiteDomainSchema } from './schemas'

const CommonWebsiteDomainForm = ({
  commonWebsiteDomains,
  total,
  onSubmit,
  isSaving,
  isRefetching,
}) => {
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { page = DEFAULT_PAGE } = getQueryParams(search)

  const defaultValues = useMemo(
    () => commonWebsiteDomainsFormInitialValues(commonWebsiteDomains),
    [commonWebsiteDomains],
  )

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(commonWebsiteDomainSchema),
  })

  const { handleSubmit, control, reset, errors, formState } = formMethods

  useEffect(() => reset(defaultValues), [defaultValues, reset])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'commonWebsiteDomains',
    keyName: 'formId',
  })

  const onPageChange = useCallback(
    page => {
      if (formState.isDirty) {
        Modal.confirm({
          title: 'Unsaved changes',
          content: 'There are changes are not saved. Do you want to continue?',
          onOk() {
            history.push(createUrl(pathname, search, { page }))
          },
        })
      } else {
        history.push(createUrl(pathname, search, { page }))
      }
    },
    [formState, history, pathname, search],
  )

  return (
    <>
      {(isSaving || isRefetching) && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(formValues =>
            onSubmit(
              formValues.commonWebsiteDomains,
              defaultValues.commonWebsiteDomains,
            ),
          )}
        >
          <PageHeader sticky>
            <PageTitle>Common websites</PageTitle>
            <PageActions>
              <Button
                margin="no small no no"
                disabled={!formState.isDirty}
                onClick={() => reset({ commonWebsiteDomains })}
              >
                Cancel
              </Button>

              <Button
                margin="no small no no"
                type="primary"
                htmlType="submit"
                disabled={!formState.isDirty}
              >
                Save
              </Button>
            </PageActions>
          </PageHeader>
          <Panel withBorderBottom>
            <SearchCommonWebsiteDomainFilter />
          </Panel>
          <Panel margin="small no">
            {map(fields, (field, index) => (
              <Fragment key={field.formId}>
                <Form.Item
                  help={get(
                    errors,
                    `commonWebsiteDomains[${index}].websiteDomain.message`,
                  )}
                  validateStatus={
                    isNil(
                      get(
                        errors,
                        `commonWebsiteDomains[${index}].websiteDomain.message`,
                      ),
                    )
                      ? 'success'
                      : 'error'
                  }
                >
                  <Controller
                    style={{ width: '30%' }}
                    as={<Input />}
                    name={`commonWebsiteDomains[${index}].websiteDomain`}
                    control={control}
                    defaultValue={field.websiteDomain}
                    placeholder="example.co.uk"
                    suffix={
                      <Icon type="delete" onClick={() => remove(index)} />
                    }
                  />
                </Form.Item>

                <Controller
                  as={<input type="hidden" />}
                  name={`commonWebsiteDomains[${index}].id`}
                  control={control}
                  defaultValue={field.id}
                />
              </Fragment>
            ))}
          </Panel>
          <Row>
            <Button
              margin="no small"
              onClick={() => append({ id: '', websiteDomain: '' })}
            >
              Add new website
            </Button>
          </Row>
        </form>
      </FormProvider>
      <Row type="flex" justify="end">
        <Pagination
          current={page}
          style={{ marginTop: '15px' }}
          total={total}
          onChange={onPageChange}
        />
      </Row>
    </>
  )
}

CommonWebsiteDomainForm.propTypes = {
  commonWebsiteDomains: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isRefetching: PropTypes.bool.isRequired,
}

export default CommonWebsiteDomainForm
