import React, { useCallback, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Icon, Row, Table, Typography } from 'antd'
import { includes, without } from 'lodash-es'
import PropTypes from 'prop-types'

import { PopoverReactJson } from '../../../../../../core/components'
import { Button } from '../../../../../../core/components/styled'

import { LondonDateTime } from '../../../../../../common/components'
import {
  ADMIN,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'
import {
  useIsLoggedInUser,
  useUserHasRoles,
} from '../../../../../../common/hooks'

import {
  IN_PROGRESS,
  MENU_SCRAPING_RERUN_STATUSES,
} from '../../../../../../menu-scraping-reruns/constants'
import { ABORT_MENU_SCRAPING_SESSION } from '../../../../../../menu-scraping-reruns/graphql/mutations'
import { MENU_SCRAPING_RERUN_REPORT_URL } from '../../../../../../menu-scraping-reruns/graphql/queries'
import {
  SCRAPING,
  TEMPLATE_VALIDATION,
} from '../../../../../../menus/constants'
import { MENU_SCRAPING_SESSIONS } from '../../../../../graphql/queries'
import StartMenuScrapingSessionForClusterForm from '../../../../forms/start-menu-scraping-session-for-cluster-form'
import StartTemplateValidationSessionForClusterForm from '../../../../forms/start-template-validation-session-for-cluster-form'

const MenuScrapingSessionsTable = ({
  menuScrapingCluster,
  rerunStepType,
  onStartScrapingSession,
  onAbortScrapingSession,
}) => {
  const [scrapingSessionsPage, setScrapingSessionsPage] = useState(DEFAULT_PAGE)
  const [
    reportMenuScrapingSessionId,
    setReportMenuScrapingSessionId,
  ] = useState()

  const [abortSessionIds, setAbortSessionIds] = useState([])

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const {
    data: {
      menuScrapingSessionsForCluster: {
        menuScrapingSessions = [],
        total: scrapingSessionCount,
      } = {},
    } = {},
    loading: isLoadingMenuScrapingSessions,
    refetch: refetchMenuScrapingSessions,
  } = useQuery(MENU_SCRAPING_SESSIONS, {
    variables: {
      menuScrapingClusterId: menuScrapingCluster.id,
      page: scrapingSessionsPage,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    fetchMenuScrapingRerunReportUrl,
    { loading: isLoadingMenuScrapingSessionReportUrl },
  ] = useLazyQuery(MENU_SCRAPING_RERUN_REPORT_URL, {
    onCompleted(data) {
      window.open(data.menuScrapingRerunReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download menu scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const [abortMenuScrapingSession] = useMutation(ABORT_MENU_SCRAPING_SESSION, {
    onCompleted({ abortMenuScrapingSession: { id } }) {
      setAbortSessionIds(without(abortSessionIds, id))

      refetchMenuScrapingSessions()
      onAbortScrapingSession()
      showSuccessNotification({
        message: 'Menu scraping session aborted.',
        description: `Menu scraping session ${id} has been successfully aborted.`,
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping session abort failed.',
        description: message,
      })
    },
  })

  const handleScrapingSessionsPageChange = useCallback(
    page => {
      setScrapingSessionsPage(page)
    },
    [setScrapingSessionsPage],
  )

  const handleClickDownloadReport = menuScrapingRerun => {
    setReportMenuScrapingSessionId(menuScrapingRerun.id)

    fetchMenuScrapingRerunReportUrl({
      variables: { id: menuScrapingRerun.id },
    })
  }

  const handleClickAbort = menuScrapingRerun => {
    setAbortSessionIds([...abortSessionIds, menuScrapingRerun.id])

    abortMenuScrapingSession({
      variables: { id: menuScrapingRerun.id },
    })
  }

  const tableColumns = [
    {
      title: 'ID',
      width: '5%',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      width: '15%',
      dataIndex: 'status',
      render: status => MENU_SCRAPING_RERUN_STATUSES[status].name,
    },
    {
      title: 'Created date',
      width: '10%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Created by',
      width: '10%',
      dataIndex: 'createdBy.name',
    },
    {
      title: 'Template version',
      width: '10%',
      dataIndex: 'scrapingInstructionsVersionNumber',
    },
    {
      title: 'Time slot count',
      width: '10%',
      dataIndex: 'timeSlotCount',
    },
    {
      title: 'Start time',
      width: '10%',
      dataIndex: 'startTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Estimated completion time',
      width: '15%',
      dataIndex: 'estimatedCompletionTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Completed date',
      width: '10%',
      dataIndex: 'completedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Total menu count',
      width: '10%',
      dataIndex: 'totalMenuCount',
    },
    {
      title: 'Scraped menu count',
      width: '12%',
      dataIndex: 'scrapedMenuCount',
    },
    {
      title: 'Configuration',
      dataIndex: 'configuration',
      width: '200px',
      render: configuration =>
        configuration && (
          <PopoverReactJson source={JSON.parse(configuration)} />
        ),
    },
    {
      title: 'Job counts',
      width: '120px',
      dataIndex: 'jobCountByScrapingStatus',
      render: jobCountByScrapingStatus => (
        <PopoverReactJson source={JSON.parse(jobCountByScrapingStatus)} />
      ),
    },
    {
      title: 'Report',
      width: '150px',
      render: menuScrapingRerun => (
        <Button
          margin="no small no no"
          loading={
            isLoadingMenuScrapingSessionReportUrl &&
            menuScrapingRerun.id === reportMenuScrapingSessionId
          }
          onClick={() => handleClickDownloadReport(menuScrapingRerun)}
        >
          Download
          <Icon type="file" />
        </Button>
      ),
    },
    {
      title: 'Abort',
      width: '120px',
      render: menuScrapingRerun => (
        <Button
          margin="no small no no"
          disabled={
            menuScrapingRerun.status !== IN_PROGRESS ||
            (!isLoggedInUser && !isAdmin)
          }
          loading={includes(abortSessionIds, menuScrapingRerun.id)}
          onClick={() => handleClickAbort(menuScrapingRerun)}
        >
          Abort
          <Icon type="stop" />
        </Button>
      ),
    },
  ]

  const renderStartScrapingSessionForm = rerunStepType => {
    switch (rerunStepType) {
      case TEMPLATE_VALIDATION:
        return (
          <StartTemplateValidationSessionForClusterForm
            menuScrapingCluster={menuScrapingCluster}
            onStartMenuScrapingSessionForCluster={() => {
              refetchMenuScrapingSessions()
              onStartScrapingSession()
            }}
          />
        )
      case SCRAPING:
        return (
          <StartMenuScrapingSessionForClusterForm
            menuScrapingCluster={menuScrapingCluster}
            onStartMenuScrapingSessionForCluster={() => {
              refetchMenuScrapingSessions()
              onStartScrapingSession()
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <Row>
      <Typography.Title level={3}>Menu scraping sessions</Typography.Title>
      {renderStartScrapingSessionForm(rerunStepType)}
      <Table
        rowKey="id"
        loading={isLoadingMenuScrapingSessions}
        columns={tableColumns}
        dataSource={menuScrapingSessions}
        scroll={{ x: 2200 }}
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          total: scrapingSessionCount,
          current: scrapingSessionsPage,
          onChange: handleScrapingSessionsPageChange,
        }}
      />
    </Row>
  )
}

MenuScrapingSessionsTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  rerunStepType: PropTypes.string.isRequired,
  onStartScrapingSession: PropTypes.func,
  onAbortScrapingSession: PropTypes.func,
}

MenuScrapingSessionsTable.defaultProps = {
  onStartScrapingSession: () => {},
  onAbortScrapingSession: () => {},
}

export default MenuScrapingSessionsTable
