import { map, pick } from 'lodash-es'

const transformBrand = values =>
  pick(
    values,
    'id',
    'name',
    'website',
    'isHasNoWebsiteConfirmed',
    'useBrandNameAsLocationName',
  )

const transformLocation = values => {
  const location = pick(
    values,
    'id',
    'duplicateOfId',
    'googleLocationId',
    'isHasNoGoogleMatchConfirmed',
  )

  return {
    ...location,
    duplicateOfId: location.duplicateOfId ? location.duplicateOfId : null,
  }
}

const transformToBrandDefinitionTaskInput = values => {
  const { task } = values
  const brandData = transformBrand(values.brand)
  const locationsData = map(values.locations, transformLocation)

  return {
    id: task.id,
    brandData,
    locationsData,
  }
}

export default transformToBrandDefinitionTaskInput
