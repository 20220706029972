import { mapKeys } from 'lodash-es'

export const NAME = 'NAME'
export const NAME_SIMILARITY = 'NAME_SIMILARITY'

export const BRAND_SORTING_CRITERIA = mapKeys(
  [
    { name: 'Name', value: NAME },
    { name: 'Name similarity', value: NAME_SIMILARITY },
  ],
  'value',
)
