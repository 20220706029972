import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

const MenuAvailabilityCommentModal = ({
  comment,
  isModalOpen,
  setIsModalOpen,
}) => (
  <Modal
    title="Menu availability comment"
    visible={isModalOpen}
    onCancel={() => setIsModalOpen(false)}
    footer={null}
  >
    {comment}
  </Modal>
)

MenuAvailabilityCommentModal.propTypes = {
  comment: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

MenuAvailabilityCommentModal.defaultProps = {
  comment: undefined,
}

export default MenuAvailabilityCommentModal
