import { reduce, uniq } from 'lodash-es'

import { SCRAPED_ITEM_TYPES } from '../constants'

const getScrapedItemsHashCodesToExpand = scrapedItems => {
  const hashCodesToExpand = reduce(
    scrapedItems,
    (result, item) => {
      if (item.type === SCRAPED_ITEM_TYPES.MENU) {
        return [...result, item.id]
      }

      if (item.type === SCRAPED_ITEM_TYPES.MENU_TITLE && item.parentId) {
        return [...result, item.parentId]
      }

      return result
    },
    [],
  )

  return uniq(hashCodesToExpand)
}

export default getScrapedItemsHashCodesToExpand
